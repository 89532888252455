import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import {faMapMarker as marker, faPhone as phone,faEnvelope as envelope} from "@fortawesome/free-solid-svg-icons";
import { faFacebook as facebook,faLinkedin as linkedin,faGooglePlusG as google,faYoutube as youtube,faInstagram as instagram,faTwitter as twitter } from "@fortawesome/free-brands-svg-icons"
import {SCREEN_WIDTH} from "../functions";
import payment_1 from '../../static/img/payment-1.png';
import payment_2 from '../../static/img/payment-2.png';
import payment_3 from '../../static/img/payment-3.png';
import payment_4 from '../../static/img/payment-4.png';
import chariot_img from '../../static/img/chariot_img.png';
import '../../stylesheet/style.css';
import '../../stylesheet/revslider.css';
import '../../stylesheet/owl.carousel.css';
import '../../stylesheet/owl.theme.css';
import '../../stylesheet/services.css';
import '../../stylesheet/enquiry_form.css';
import '../../stylesheet/responsive.css';
import '../../stylesheet/bootstrap.min.css';
import '../../stylesheet/font-awesome.css';
import '../../stylesheet/jquery.bxslider.css';
import '../../stylesheet/jquery.mobile-menu.css';
import '../../stylesheet/watsapp.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';

const FooterPage = () => {
    return (
            <footer> 
                <div class="footer-inner">
                    <div class="footer-middle">
                        <div class="container">
                            <div class="row" style={{fontSize:"15px"}}>
                                <div class="col-md-3 col-sm-6">
                                    <div class="footer-column">
                                        <h4 style={{textAlign:"left"}}>OUR PRODUCTS</h4>
                                        <p style={{color: "#b7bcc8",textAlign:"justify"}}>Chariot Gold Premium Quality Whole Wheat Chakki Atta, Chariot Gold Maida, Chariot Gold Suji Rawa, Chariot Gold Rawa and Chariot Gold Dry Fruits and Nuts.</p>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6">
                                    <div class="footer-column" style={{textAlign:"left",lineHeight:"2em",color:"#b7bcc8"}}>
                                        <h4 >Company</h4>
                                        <ul class="links">
                                            <Link to={"/aboutus"}><li>About Us</li></Link>
                                            <Link to={"/vision"}><li>Vision</li></Link>
                                            <Link to={"/milling"}><li>Milling</li></Link>
                                        </ul>
                                        <h4>Contact Us</h4>
                                        <ul class="links">
                                            <Link to={"/helpsupport"}><li>Help & Support</li></Link>
                                            <Link to={"/partner"}><li>Partner with Us</li></Link>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6">
                                    <div class="footer-column" style={{textAlign:"left",lineHeight:"2em",color:"#b7bcc8"}}>
                                        <h4>Our Products</h4>
                                        <ul class="links">
                                            <Link to={"/allProducts"}><li>Whole Wheat Chakki Atta</li></Link>
                                            <Link to={"/allProducts"}><li>Maida</li></Link>
                                            <Link to={"/allProducts"}><li>Suji Rawa</li></Link>
                                            <Link to={"/allProducts"}><li>Rawa</li></Link>
                                            <Link to={"/allProducts"}><li>Dry Fruits And Nuts</li></Link>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6">
                                    <div class="footer-column" style={{textAlign:"left",lineHeight:"2em",color:"#b7bcc8"}}>
                                        <h4>Quick Links</h4>
                                        <ul class="links">
                                            <Link to={"/termsConditions"}><li>Terms & Conditions</li></Link>
                                            <Link to={"/returnCancellation"}><li>Return & Cancellation</li></Link>
                                            <Link to={"/privacyPolicy"}><li>Privacy Policy</li></Link>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-6">
                                    <h4></h4>
                                    <div class="footer-column">
                                        <div class="contacts-info">
                                        <address style={{display:"flex",alignItems:"center"}}>
                                            <FontAwesomeIcon icon={marker} style={{color:"#b7bcc8",borderRadius:"999px",border:"2px #b7bcc8 dotted",fontSize:"28px",height:"45px",width:"45px",marginRight:"10px",lineHeight:"40px",padding:"10px"}}></FontAwesomeIcon><a href="https://goo.gl/maps/LvPcbvDwEsYzKwCGA" target="_blank">3446/40, 01st F cross, Next to Bunts Sangha, Vijayanagar, Bangalore – 560040</a><br/>
                                        </address>
                                        <div class="phone-footer" style={{display:"flex",alignItems:"center"}}><FontAwesomeIcon icon={phone} style={{color:"#b7bcc8",borderRadius:"999px",border:"2px #b7bcc8 dotted",fontSize:"28px",height:"45px",width:"45px",marginRight:"10px",lineHeight:"40px",padding:"10px"}}></FontAwesomeIcon>+91 994 577 5555</div>
                                        <div class="email-footer" style={{display:"flex",alignItems:"center"}}><FontAwesomeIcon icon={envelope} style={{color:"#b7bcc8",borderRadius:"999px",border:"2px #b7bcc8 dotted",fontSize:"28px",height:"45px",width:"45px",marginRight:"10px",lineHeight:"40px",padding:"10px"}}></FontAwesomeIcon><a href="mailto:atmanand@chariotagro.com" target="_blank">feedback@chariotagro.com</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-top">
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12 col-sm-4">
                                <div class="social">
                                    <ul> 
                                        <a href="https://www.facebook.com/chariotagrolimited" target="_blank" style={{color:"inherit"}}><li class="fb" style={{background:"#3C5B9B",padding:"10px",borderRadius:"3px",textAlign:"center"}}><FontAwesomeIcon icon={facebook}/></li></a>
                                        <a href="https://twitter.com/AgroChariot" target="_blank" style={{color:"inherit"}}><li class="twitter" style={{background:"#1DA1F2",padding:"10px",borderRadius:"3px",textAlign:"center"}}><FontAwesomeIcon icon={twitter}/></li></a>
                                        <a href="https://www.instagram.com/chariot_agro/" target="_blank" style={{color:"inherit"}}><li class="instagram" style={{background:"#8a3ab9",padding:"10px",borderRadius:"3px",textAlign:"center"}}><FontAwesomeIcon icon={instagram}/></li></a>
                                        <a href="https://www.youtube.com/channel/UC5RrKtV0HK6biKUP4OybP1g" target="_blank" style={{color:"inherit"}}><li class="youtube" style={{background:"#F03434",padding:"10px",borderRadius:"3px",textAlign:"center"}}><FontAwesomeIcon icon={youtube}/></li></a>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-sm-4 col-xs-12 coppyright"> © 2021. All Rights Reserved. </div>
                            <div class="col-xs-12 col-sm-4">
                                <div class="payment-accept"> <img src={payment_1} alt=""/> <img src={payment_2} alt=""/> <img src={payment_3} alt=""/> <img src={payment_4} alt=""/> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
    );
}

export default FooterPage;
