import React from 'react';
import BrowsePageNavigation from '../navigation/browseNavigation'
import FooterPage from '../Footer';
import HomeComponentTop from '../homepage/homeTop';
import aboutUsHeader from '../../static/img/ban_2.jpg';


class returnCancellationComponent extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
        totalPrice: 0,
        cartItems: {"wheat":[],"maida":[],"suji":[],"chiroti":[]},
    }
  }

  render(){
    return(
      <div>
                <HomeComponentTop cartItems={this.state.cartItems} totalPrice={this.state.totalPrice}/>
        <div className="page_heading" style={{backgroundImage:`url(`+aboutUsHeader+`)`,backgroundRepeat:"no-repeat",color:"#ffb637",textAlign:"center",padding:"60px 0 60px 0"}}>
                    <div className="container">
                        <div className="row" style={{marginLeft: "0px"}}>
                            <div className="col-xs-12">
                                <div className="page-title">
                                    <h2>Return Cancellation</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        <div style={{marginLeft:"15vw",marginRight:"15vw",textAlign: "justify" ,textJustify: "inter-word"}} className = "c1">
          <br/><br/>
          <h1>Cancellation Policy</h1>
          <p>Chariot Agro Limited is a Public Limited Company and the website www.chariotagro.com .
          (” The Site”) is owned by Chariot Agro Limited.</p>
          <p>While there is no option for a customer to cancel the order on their own, you can get in
touch with Customer Care as soon as possible with your order number. As long as your
order has not been processed, we can cancel it. In such a case we will refund any payments
already made by you for the order as credits which could be used further anytime later for
shopping. If we suspect any fraudulent transaction by any customer or any transaction
which defies the terms &amp; conditions of using the website, we at our sole discretion could
cancel such orders. We will maintain a negative list of all fraudulent transactions and
customers and would deny access to them or cancel any orders placed by them.</p>
<p>CAL shall not be liable for any delay / non-delivery of purchased goods by the vendors, trade
organization/s, manufacturers / shop etc. (vendors) due to flood, fire, wars, acts of God,
communal riots or any cause that is beyond the control of CAL.</p>
<br/>
        <h3>Returns &amp; Refund Policy</h3>
        <ol>
          <li>Orders once placed &amp; processed through chariotagro.com cannot be cancelled or
refunded.</li>
<li>Modifications and alterations to orders once placed are subject approval by Chariot Argo
Limited.</li>
<li>Estimated Delivery Time is the approximate 03 – 04 days to deliver the ordered goods to
the customer. In the event of any delay or default in such delivery, CAL shall in no event or
circumstances be held liable or responsible for the same.</li>
<li>In the event the customer is dissatisfied with the quality of the goods ordered, the
customer shall not be entitled to seek cancellation or refund of the billed amounts.
However, if there is any shortfall in delivery of the ordered goods, the customer shall at the
time of delivery return the ordered goods to the delivery boy with a written complaint of
the same on the Bill against signature of the delivery boy. Moreover, in the event of any
shortfall in delivery of the ordered goods having spilled/ or being damaged, the Customer
shall inform CAL of the same within 24 hours from the ordered goods being received by the
customer. CAL shall thereafter initiate investigations in the matter of complaint received
and shall make reasonable efforts to complete such investigation within a period of ten days
from the date of such commencement. The decision of CAL on completion of such
investigation shall be final, binding and conclusive on the customer and the customer shall
not dispute the same.</li>
<li>After mutual agreement of refund of payment, CAL shall reverse the payment in the same
mode the customer has paid, or CAL shall issue a cheque in favour of the customer. Such
refund shall be fulfilled in 7 working days from the date of mutual agreement.</li>
        </ol>
        </div>
        <div style={{marginLeft:"15vw",marginRight:"15vw",textAlign: "center" ,textJustify: "inter-word", fontSize:"20px"}} className = "c1">
                        <hr/>
                        <h2>Enquiry</h2>
                        <p style={{font:"23px !important"}}>Please call us on <b>0091 9945775555</b> or email to <b>atmanand@chariotagro.com</b><br/>for any product enquiry or for any information which you may need from<br/>Chariot Agro Limited.</p>
                </div>
        <FooterPage/>
      </div>

    )
  }
}

export default returnCancellationComponent;