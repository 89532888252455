import React,{useEffect,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {Button,Form} from 'react-bootstrap'
import DryFruits from '../static/img/DryFruits.jpg'
import newlogo from '../static/img/logo/newlogo.png';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Typography from "@material-ui/core/Typography"
import {faShoppingBasket as basket,faCreditCard as creditCard} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Badge from '@material-ui/core/Badge';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import {useLocation} from 'react-router-dom';
import {useHistory} from "react-router-dom";
import afgan from '../static/img/afghanraisinsfront.jpg';
import indian from '../static/img/indianraisinsfront.jpg';
import safawi from '../static/img/safawifront.jpg';
import mabroom from '../static/img/mabroomfront.jpg';
import almond from '../static/img/almond1.jpg';
import cashew from '../static/img/cashew1.jpg';
import pista from '../static/img/pista1.jpg';
import chiroti from '../static/img/chiroti.png';
import suji_rava from '../static/img/suji_rava.png';
import maida from '../static/img/maida.png';
import wheat from '../static/img/wheat.png';



const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      [theme.breakpoints.only('xs')]: {
        padding:'10px'
      },
    },
    paperleft: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
        backgroundColor:'#efefef'
      },
    rigthbar:{
        display:'block',
        [theme.breakpoints.only('xs')]: {
            display:'none',
          },
    },
    leftbar:{
        margin:'15%',
        [theme.breakpoints.only('xs')]: {
            margin:'5%',
          },
    },
    logo:{
        width:'50%',
        height:'60px',
        textAlign:'start',
        marginBottom:'20px',
        display:'flex',
        objectFit:'contain'
    },
    cartlogo:{
       display:'none',
       [theme.breakpoints.only('xs')]: {
        display:'block',
        marginTop:'22px'
      },
    },
    contacttitle:{
        textAlign:'start',
        display:'flex',
        fontFamily:' Oswald, "sans serif"',
        letterSpacing:'1px',
        color:'#3c763d'
    },
    button:{
        padding:'10px',
        backgroundColor:'#ffb637',
        fontFamily:' Oswald, "sans serif"',
        marginLeft:'10px',
        color:'white',
        letterSpacing:'1.5px',
        fontWeight:'600',
        textTransform:'uppercase',
        cursor:'pointer',
        [theme.breakpoints.only('xs')]: {
            marginTop:'10px'
          },
    },
    tabletitle:{
        textAlign:'start',
        fontSize:'15px',
        marginTop:'15px',
        fontWeight:'300',
        fontFamily:' Rubik, "sans serif"',
        letterSpacing:'1px',
        textTransform :'uppercase',
        [theme.breakpoints.only('xs')]: {
           fontSize:'10px',
           marginTop:'0px',
          },
        

    },
    gridheader:{
        width:'80%',
        marginLeft:'10%',
        borderBottom:'1px solid #e5e5e5',
        [theme.breakpoints.only('xs')]: {
            width:'100%',
            marginLeft:'0%',
          },
    } ,
    productimage:{
        height:'100px',
        width:'100%',
        objectFit:'cover',
    },
    testImage:{
      height:'100px',
      width:'50%',
      objectFit:'cover',
    },
    producttitle:{
        textAlign:'start',
        fontSize:'12px',
        marginTop:'15px',
        fontWeight:'300',
        fontFamily:' Rubik, "sans serif"',
        letterSpacing:'1px',
        textTransform :'uppercase',
        marginLeft:'20px',
        [theme.breakpoints.only('xs')]: {
            marginLeft:'5px',
            fontSize:'10px',
            textAlign:'start'
          },
    },
    inputtext:{
        textAlign:'start',
        fontSize:'12px',
        fontWeight:'300',
        fontFamily:' Rubik, "sans serif"',
        letterSpacing:'1px',
        textTransform :'uppercase',
    },
    productdelete:{
        textAlign:'start',
        fontSize:'22px',
        marginTop:'15px',
        fontWeight:'300',
        fontFamily:' Rubik, "sans serif"',
        letterSpacing:'1px',
        textTransform :'uppercase',
        fill:'#f65f73'
    },
    mobilecounter:{
        marginTop:'10px',
        fontSize:'12px',
        fontWeight:'300',
        fontFamily:' Rubik, "sans serif"',
        letterSpacing:'1px',
        textTransform :'uppercase'
    },
    pricning:{
        width:'80%',
        marginRight:'10%',
        marginTop:'20px',
        float:'right',
        display:'flex',
        justifyContent:'flex-end',
        [theme.breakpoints.only('xs')]: {
           width:'100%',
           float:'left',
           display:'flex',
           justifyContent:'flex-start',
          },
       
    },
    pricingdetails:{
        width:'50%',
        borderTop:'1px solid #8c8c8c',
        [theme.breakpoints.only('xs')]: {
            width:'100%',
           },
    },
    mycartsubtotal:{
        fontSize:'15px',
        fontWeight:'400',
        fontFamily:' Rubik, "sans serif"',
        letterSpacing:'0px'
      },
      footer:{
        display:'flex',justifyContent:'space-between',
        [theme.breakpoints.only('xs')]: {
            flexDirection:'column-reverse'
          },
      },
      returncart:{
          color:'#f65f73',
          marginTop:'10px',
          fontSize:'14px',
          fontWeight:'600',
          cursor:'pointer',
          marginBottom:'10px'
      },
      buttonFooter:{
        display:'flex',
        justifyContent:'space-between',
        [theme.breakpoints.only('xs')]: {
            flexDirection:'column',
            marginTop:'10px'
          },
      },
      basketmenu:{
        '& , .MuiList-padding':{
          padding:'0px'
        },
        width:'400px',

      },
      basketdropdown:{
        backgroundColor:'#5B9D4F',
        color:'white',
        width:'500px',
        padding:'10px',
        fontFamily:' Oswald, "sans serif"',

      },
      basketdropdownimage:{
        width:'50%'
      },

      basketdropdowntitle:{
        width:'40%',
        margin:'20px',
        textAlign:'start',
        margin:'20px',
        marginTop:'20px',
        fontSize:"14px",
        fontFamily:' Oswald, "sans serif"',
        letterSpacing:'1px'

      },
      basketdropdowndelete:{
        width:'15%',
        margin:'20px',
        margin:'20px',
        marginTop:'20px',
        fontSize:"16px",
        textAlign:'center'

      },
      basketdropdownitemimage:{
        width:'100%',
        height:'80px',
        margin:'5px',
        objectFit:'cover',
       
      },
    basketdropdownprice:{
      fontSize:'10px',
      margin:'5px',
      fontFamily:' serif, "sans serif"',
      letterSpacing:'1px'
    },
    basketdropdownsubtotalfooter:{
      padding:'10px',
      display:'flex',
      flexDirection:'row',
      justifyContent:'space-between',
      backgroundColor:'#efefef',
      width:'100%'
    },
    basketdropdownsubtotal:{
     fontSize:'10px',
     paddingTop:5,
     fontFamily:' Oswald, "sans serif"',
     letterSpacing:'1px'
    },
    basketdropdownsubtotalprice:{
      fontSize:'15px',
      marginLeft:'10px',
      paddingTop:4,
      fontFamily:' Oswald, "sans serif"',
      letterSpacing:'1px'
    },
    basketdropdownbutton:{
      padding:'8px',
      backgroundColor:'#5B9D4F',
      marginLeft:'10px',
      color:'#fff',
      letterSpacing:'0.5px',
      borderRadius:'3px',
      fontSize:'12px',
      fontWeight:'600',
      fontFamily:' Oswald, "sans serif"',
      letterSpacing:'1px',
      cursor:'pointer'
    },
    bottomsubtotal:{
        position:'absolute',
        bottom:'0',
        width:'100%'
      },
      mycartsubtotal:{
        fontSize:'12px',
        padding:'10px',
        fontWeight:'600',
        fontFamily:' Rubik, "sans serif"',
        letterSpacing:'2px'
      },
      mycartsubtotalprice:{
        fontSize:'16px',
        padding:'10px',
        fontWeight:'600',
        fontFamily:' Oswald, "sans serif"',
        letterSpacing:'2px'
      },
      cartappBar: {
        position: 'relative',
        width:'100%',
        backgroundColor:'#ffb637'
      },
      carttitle: {
        marginLeft: theme.spacing(2),
        flex: 1,
        fontSize:'20px',
        fontFamily:' Oswald, "sans serif"',
        fonrWeight:'600'
      },
      thankyouview:{
          display:'flex',
          flexDirection:'row'
      },
      thankyyoutext:{
          fontSize:20,
          fontFamily:'Qswald, "sans serif',
          padding:'8px 10px',
          fontWeight:600
      },
      paper:{
          textAlign:'start',
          padding:"10px 20px",
          marginTop:30
      },
      cardtitle:{
        fontSize:16,
        fontWeight:600
      },
      carddesc:{
        fontSize:16,
        display:'flex'
      },
      shoppingnutton:{
          textAlign:'center',
          margin:'20px 0px',
          padding:'10px 20px',
          color:'#fff',
          backgroundColor:'#ffc107',
          width:200,
          fontSize:16,
          fontWeight:600,
          marginBottom:20,
          cursor:"pointer",
      }
  }));
  

export default function ThankYou() {
    const classes = useStyles();
    const [opencCart, setOpenCart] = React.useState(false);
    const location = useLocation();
    const history = useHistory();
    const [cartItems, setCartItems] = React.useState([]);
    var [totalPrice,setTotalPrice] = React.useState(0);
    var [cartImages,setCartImages] = React.useState([]);
    const [totalQuantity,setTotalQuantity] = React.useState(0);

    const handleClickOpenCart = () => {
        setOpenCart(true);
      };
    
      const handleCloseCart = () => {
        setOpenCart(false);
      };

      const continueShopping = () => {
        localStorage.removeItem("cart");
        localStorage.removeItem("totalPrice");
        history.push("/");
      }

      useEffect( () => {
        var cartItems = JSON.parse(localStorage.getItem("cart"));
        var cartArrayImages = [];
        var cartArray = [];
        for(var key in cartItems){
          if(key == "wheat" && cartItems[key].length!=0){
            cartArrayImages.push(wheat);
            cartArray = cartArray.concat(cartItems[key]);

          }else if(key == "maida" && cartItems[key].length!=0){
            cartArrayImages.push(maida);
            cartArray = cartArray.concat(cartItems[key]);

          }else if(key == "chiroti" && cartItems[key].length!=0){
            cartArrayImages.push(chiroti);
            cartArray = cartArray.concat(cartItems[key]);

          }else if(key == "afgan" && cartItems[key].length!=0){
            cartArrayImages.push(afgan);
            cartArray = cartArray.concat(cartItems[key]);

          }else if(key == "indian" && cartItems[key].length!=0){
            cartArrayImages.push(indian);
            cartArray = cartArray.concat(cartItems[key]);

          }else if(key == "mabroom" && cartItems[key].length!=0){
            cartArrayImages.push(mabroom);
            cartArray = cartArray.concat(cartItems[key]);

          }else if(key == "safawi" && cartItems[key].length!=0){
            cartArrayImages.push(safawi);
            cartArray = cartArray.concat(cartItems[key]);

          }else if(key == "almond" && cartItems[key].length!=0){
            cartArrayImages.push(almond);
            cartArray = cartArray.concat(cartItems[key]);

          }else if(key == "pista" && cartItems[key].length!=0){
            cartArrayImages.push(pista);
            cartArray = cartArray.concat(cartItems[key]);

          }else if(key == "cashew" && cartItems[key].length!=0){
            cartArrayImages.push(cashew);
            cartArray = cartArray.concat(cartItems[key]);

          }else if(key=="suji" && cartItems[key].length!=0){
            cartArrayImages.push(suji_rava);
            cartArray = cartArray.concat(cartItems[key]);
          }


          else if(key=="vapor" && cartItems[key].length!=0){
            cartArrayImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/newNikVaporizer.png?alt=media&token=cff0e0e0-ef8b-40de-bcd1-bdc887acfb04");
            cartArray = cartArray.concat(cartItems[key]);
          }else if(key=="oxymeter" && cartItems[key].length!=0){
            cartArrayImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/oxymeter.png?alt=media&token=2d9527ab-fc78-4f71-8069-dd05f4d278a4");
            cartArray = cartArray.concat(cartItems[key]);
          }else if(key=="scale1" && cartItems[key].length!=0){
            cartArrayImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/pse101.png?alt=media&token=3bca7b96-e0ba-42ec-9261-a8151aa9245b");
            cartArray = cartArray.concat(cartItems[key]);
          }else if(key=="scale2" && cartItems[key].length!=0){
            cartArrayImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/pse2011.png?alt=media&token=53567ae9-6142-4f88-b6a3-8364efaeec90");
            cartArray = cartArray.concat(cartItems[key]);
          }else if(key=="respiratory" && cartItems[key].length!=0){
            cartArrayImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/respiratory1.png?alt=media&token=6e031a24-490e-4c32-9779-1e7550c6b569");
            cartArray = cartArray.concat(cartItems[key]);
          }else if(key=="bodyfat" && cartItems[key].length!=0){
            cartArrayImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/wf2601.png?alt=media&token=c846b6bb-a886-4b66-ba14-3a59082ee55c");
            cartArray = cartArray.concat(cartItems[key]);
          }else if(key=="eb600" && cartItems[key].length!=0){
            cartArrayImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb6001.png?alt=media&token=b0e02464-0fb5-412a-8953-7e16e1a1d4c5");
            cartArray = cartArray.concat(cartItems[key]);
          }else if(key=="eb500" && cartItems[key].length!=0){
            cartArrayImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb5001.png?alt=media&token=2cbecd7b-bf8a-41d1-ade8-94d7bbc1dd79");
            cartArray = cartArray.concat(cartItems[key]);
          }else if(key=="st309" && cartItems[key].length!=0){
            cartArrayImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/stethescope1.png?alt=media&token=19f78807-a1dd-410c-ad92-478bb819957f");
            cartArray = cartArray.concat(cartItems[key]);
          }else if(key=="eb200" && cartItems[key].length!=0){
            cartArrayImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb2001.png?alt=media&token=598cf0bc-8461-423b-ae30-c2b527276f8a");
            cartArray = cartArray.concat(cartItems[key]);
          }else if(key=="pf120a" && cartItems[key].length!=0){
            cartArrayImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/pf120A1.png?alt=media&token=13638d0c-0a24-41e5-bec7-18dd970cdb5d");
            cartArray = cartArray.concat(cartItems[key]);
          }else if(key=="eb100" && cartItems[key].length!=0){
            cartArrayImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb100.png?alt=media&token=5ff832f2-29c9-4e48-a1eb-9b72f31829c4");
            cartArray = cartArray.concat(cartItems[key]);
          }else if(key=="ic900" && cartItems[key].length!=0){
            cartArrayImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/coolPack1.png?alt=media&token=9fa36616-6cf6-41d8-8035-006332bd2f6d");
            cartArray = cartArray.concat(cartItems[key]);
          }else if(key=="hing" && cartItems[key].length!=0){
            cartArrayImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/asafoetida1.png?alt=media&token=c2868abf-e208-4e22-b23c-73c617854468");
            cartArray = cartArray.concat(cartItems[key]);
          }else if(key=="rasam" && cartItems[key].length!=0){
            cartArrayImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Rasam-Powder.jpg?alt=media&token=ae9d8ede-74eb-46a1-a78d-69d05955dbe7");
            cartArray = cartArray.concat(cartItems[key]);
          }
        }
        setCartImages(cartArrayImages);
        setCartItems(cartArray);
        setTotalPrice(JSON.parse(localStorage.getItem("totalPrice")))
      },[])

    return (
        <div className={classes.root}>
        <Grid container spacing={0}>

        <Grid item xs={12} sm={6} style={{backgroundColor:'#efefef',paddingBottom:20}}>
          <div className={classes.paperleft} elevation={0}>
              <div className={classes.leftbar}>
                  <div style={{display:'flex',justifyContent:'space-between'}}>
                <img src ={newlogo} className={classes.logo} />
                <div className={classes.cartlogo}>

                <Badge badgeContent={location.state.totalQuantity} max={9999} color="error"  classes={{ badge: classes.badge }} >
                          <FontAwesomeIcon icon={basket} onClick={handleClickOpenCart} style={{paddingRight:"5px",fontSize:"25px",color:"#97bc77"}}></FontAwesomeIcon>
                </Badge>
                </div>
                </div>

                    <div className={classes.thankyouview}>
                            <CheckCircleOutlinedIcon style={{fontSize:45,color:'#97bc77'}} />
                            <Typography className={classes.thankyyoutext}>Thank You</Typography>
                    </div>

                    <Paper elevation={2} className={classes.paper}>
                        <Typography className={classes.cardtitle}>Your Order is Confirmed</Typography>
                        <Typography className={classes.carddesc}>You'll recieve a confirmation email with your order number shortly.</Typography>
                    </Paper>

                    <Paper elevation={2} className={classes.paper}>
                        <Typography className={classes.cardtitle}>Order Updates</Typography>
                        <Typography className={classes.carddesc}>You'll get shipping and delivery updates by email</Typography>
                    </Paper>

                    <Paper elevation={2} className={classes.paper} >
                        <Typography className={classes.cardtitle} >Customer information</Typography>
                       <Grid container style={{marginTop:30}}>
                            <Grid item xs={6} >
                                <Typography className={classes.cardtitle}>Contact information</Typography>
                                <Typography className={classes.carddesc}>{location.state.email}</Typography>
                            </Grid>
                            <Grid item xs={6} >
                                <Typography className={classes.cardtitle}>Payment Method</Typography>
                                {location.state.type=="manual"?<Typography className={classes.carddesc}>Manual - ₹{location.state.total}</Typography>:<Typography className={classes.carddesc}><FontAwesomeIcon icon={creditCard} style={{paddingRight:"5px",fontSize:"25px",color:"#97bc77"}}></FontAwesomeIcon> - ₹{location.state.total}</Typography>}
                            </Grid>
                    
                       </Grid>

                       <Grid container style={{marginTop:30}} spacing={2}>
                         <Grid item xs={6}>
                                <Typography className={classes.cardtitle}>Shipping Address</Typography>
                                <Typography className={classes.carddesc}>{location.state.name}</Typography>
                                <Typography className={classes.carddesc}>{location.state.address}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.cardtitle}>Billing Address</Typography>
                                <Typography className={classes.carddesc}>{location.state.name}</Typography>
                                <Typography className={classes.carddesc}>{location.state.address}</Typography>
                            </Grid>
                       </Grid>

                       <Grid container style={{marginTop:30}}>
                            <Grid item xs={6} >
                                <Typography className={classes.cardtitle}>Contact Number</Typography>
                                <Typography className={classes.carddesc}>{location.state.number}</Typography>
                            </Grid>
                            {
                              location.state.gst.length!=0 && location.state.gst!=null? <Grid item xs={6}>
                              <Typography className={classes.cardtitle}>GST Number</Typography>
                              <Typography className={classes.carddesc}>{location.state.gst}</Typography>
                          </Grid>:null
                            }
                       </Grid>


                    </Paper>
                    <Typography className={classes.shoppingnutton} onClick={continueShopping}>Continue Shopping</Typography>
              </div>
          </div>
        </Grid>
        <Grid item xs={0} sm={6} className={classes.rigthbar}>
          <Paper className={classes.paper} elevation={0}>
          <Grid container spacing={1} className={classes.gridheader}>
                <Grid item xs={4}>
                <Paper className={classes.paper} elevation={0}>
                    <div className={classes.tabletitle}>Products‎</div>
                </Paper>
                </Grid>
                <Grid item xs={6}>
                <Paper className={classes.paper}  elevation={0} >
                <div className={classes.tabletitle}>Product Name‎</div>
                </Paper>
                </Grid>
           
                <Grid item xs={2}>
                <Paper className={classes.paper}  elevation={0} >
                      <div className={classes.tabletitle}>Subtotal</div>
                </Paper>
                </Grid>
            </Grid>


            {
              cartItems.map((data,index)=>{
                return <Grid container spacing={1} className={classes.gridheader}>
                  <Grid item xs={4}>
                  <Paper className={classes.paperproduct} elevation={0}>
                    <img className={classes.testImage} style={{width:"50% !important"}} src={cartImages[index]}></img>
                  </Paper>
                  </Grid>
                  <Grid item xs={6}>
                  <Paper className={classes.paperproduct}  elevation={0} >
                  <div className={classes.producttitle}>{data.name}</div>
                  </Paper>
                  </Grid>
                  <Grid item xs={2}>
                  <Paper className={classes.paperproduct}  elevation={0} >
                  <div style={{display:'flex',justifyContent:'space-between'}}>
                  <div className={classes.producttitle}>₹{data.quantity * data.price}</div>
                  </div>
                  </Paper>
                  </Grid>
                </Grid>

              })
            }

            <div className={classes.pricning}>
                <div className={classes.pricingdetails} >
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',padding:'10px'}}>
                        <div className={classes.mycartsubtotal}>Item subtotal</div>
                        <div className={classes.mycartsubtotal}>₹{totalPrice}</div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',padding:'10px'}}>
                        <div className={classes.mycartsubtotal}>Grand Total</div>
                        <div className={classes.mycartsubtotal}>₹{totalPrice}</div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',padding:'10px'}}>
                        <div className={classes.mycartsubtotal}style={{color:'#5B9D4F'}} >Amount to Pay</div>
                        <div className={classes.mycartsubtotal}>₹{totalPrice}</div>
                    </div>

                </div>
            </div>

          </Paper>
        </Grid>
        </Grid>

        <Dialog fullScreen open={opencCart} onClose={handleCloseCart} style={{width:'100%'}} >
            <AppBar className={classes.cartappBar} elevation={0}>
            <Toolbar style={{display:'flex',justifyContent:"space-between"}}>
                <Typography variant="h6" className={classes.carttitle}>
                        My Cart
                </Typography>
                <IconButton edge="start" color="inherit" onClick={handleCloseCart} aria-label="close">
                        <CloseIcon style={{fontSize:'18px'}}/>
                </IconButton>
            </Toolbar>
            </AppBar>
            <Paper className={classes.paper} elevation={0}>
          <Grid container spacing={0} className={classes.gridheader}>
                <Grid item xs={5}>
                <Paper className={classes.paper} elevation={0}>
                    <div className={classes.tabletitle}>Products‎</div>
                </Paper>
                </Grid>
                <Grid item xs={4}>
                <Paper className={classes.paper}  elevation={0} >
                <div className={classes.tabletitle}>Product Name‎</div>
                </Paper>
                </Grid>
           
                <Grid item xs={2}>
                <Paper className={classes.paper}  elevation={0} >
                      <div className={classes.tabletitle}>Subtotal</div>
                </Paper>
                </Grid>
            </Grid>
            {
              cartItems.map((data,index)=>{
                return <Grid container spacing={1} className={classes.gridheader}>
                <Grid item xs={4}>
                <Paper className={classes.paperproduct} elevation={0}>
                   <img className={classes.productimage} src={cartImages[index]}></img>
                </Paper>
                </Grid>
                <Grid item xs={5}>
                <Paper className={classes.paperproduct}  elevation={0} >
                <div className={classes.producttitle}>{data.name}</div>
                </Paper>
                </Grid>
                <Grid item xs={3}>
                <Paper className={classes.paperproduct}  elevation={0} >
    
                <div className={classes.producttitle}>₹{data.quantity * data.price}	</div>
    
                </Paper>
                </Grid>
            </Grid>

              })
            }


            <div className={classes.pricning}>
                <div className={classes.pricingdetails} >
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',padding:'10px'}}>
                        <div className={classes.mycartsubtotal}>Item subtotal</div>
                        <div className={classes.mycartsubtotal}>₹{totalPrice}</div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',padding:'10px'}}>
                        <div className={classes.mycartsubtotal}>Grand Total</div>
                        <div className={classes.mycartsubtotal}>₹{totalPrice}</div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',padding:'10px'}}>
                        <div className={classes.mycartsubtotal}style={{color:'#5B9D4F'}} >Amount to Pay</div>
                        <div className={classes.mycartsubtotal}>₹{totalPrice}</div>
                    </div>

                </div>
            </div>

        </Paper>
                            
        </Dialog>

        </div>
    )
}