import React from 'react';
import HomeComponentTop from '../homepage/homeTop';
import aboutUsHeader from '../../static/img/ban_2.jpg';
import FooterPage from '../Footer'

class ContactUsComponent extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            totalPrice: 0,
            cartItems: {"wheat":[],"maida":[],"suji":[],"chiroti":[]},
        }
    }
    render(){

        return (
            <div>
                                <div style={{position:"sticky",top:"0px",zIndex:"1000"}}>
                <HomeComponentTop cartItems={this.state.cartItems} totalPrice={this.state.totalPrice}/>
                </div>
                <div className="page_heading" style={{backgroundImage:`url(`+aboutUsHeader+`)`,backgroundRepeat:"no-repeat",color:"#ffb637",textAlign:"center",padding:"60px 0 60px 0"}}>
                    <div className="container">
                        <div className="row" style={{marginLeft: "0px"}}>
                            <div className="col-xs-12">
                                <div className="page-title">
                                    <h2>Contact Us</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <div style={{marginLeft:"15vw",marginRight:"15vw",textAlign: "center" ,textJustify: "inter-word", fontSize:"20px"}} className = "c1">
                        <h1>Enquiry</h1>
                        <p style={{font:"23px !important"}}>Please call us on <b>0091 9945775555</b> or email to <b>atmanand@chariotagro.com</b><br/>for any product enquiry or for any information which you may need from<br/>Chariot Agro Limited.</p>
                </div>
                <br/>
                <div style={{marginBottom:"-50vw"}}>
                    <FooterPage/>
                </div>
            </div>
        );
    }
}

export default ContactUsComponent;