import React from 'react';
import './App.css';
import HomeComponent from './components/homepage/homeComponent';
import AboutUsComponent from './components/AdditionalLinks/aboutUsComponent';
import CustomerComponent from './components/AdditionalLinks/customerReview';
import VisionComponent from './components/AdditionalLinks/visionComponent';
import HelpSupportComponent from './components/AdditionalLinks/helpSupportComponent';
import ContactUsComponent from './components/AdditionalLinks/contactUsComponent';
import PrivacyPolicyComponent from './components/AdditionalLinks/privacyPolicyComponent';
import TermsConditionsComponent from './components/AdditionalLinks/termsConditionsComponent';
import AllProductsComponent from './components/AdditionalLinks/allProductsComponent';
import returnCancellationComponent from './components/AdditionalLinks/returnCancellationComponent';
import PartnerComponent from './components/AdditionalLinks/partnerWithUsComponent';
import MillingComponent from './components/AdditionalLinks/millingComponent'
import BrowsePage from './pages/Browse';
import ProductPage from './pages/Product';
import CategoryPage from './pages/Category';
import SearchPage from './pages/Search';
import CartPage from './pages/Cart';
import NotFoundPage from './pages/Notfound'
import AddressBook from './pages/AddressBook';
import Orders from './pages/Orders';
import AccountPage from './pages/Account'
import EditAccount from './pages/EditAccount'
import OrderPlaced from './pages/OrderPlaced'
import UserPage from './pages/User';
import { Switch, Route} from 'react-router-dom';
import Checkout  from "./pages/CheckoutPage";
import EachProduct from './pages/EachProduct';
import ThankYou from './pages/ThankYou';
import StockMaintanence from './pages/StockMaintanence';

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={HomeComponent}></Route>
        <Route exact path="/customer" component={CustomerComponent}></Route>
        <Route exact path="/aboutus" component={AboutUsComponent}></Route>
        <Route exact path="/allProducts" component={AllProductsComponent}></Route>
        <Route exact path="/contactus" component={ContactUsComponent}></Route>
        <Route exact path="/vision" component={VisionComponent}></Route>
        <Route exact path="/milling" component={MillingComponent}></Route>
        <Route exact path="/helpsupport" component={HelpSupportComponent}></Route>
        <Route exact path="/returnCancellation" component={returnCancellationComponent}></Route>
        <Route exact path="/privacyPolicy" component={PrivacyPolicyComponent}></Route>
        <Route exact path="/partner" component={PartnerComponent}></Route>
        <Route exact path="/termsConditions" component={TermsConditionsComponent}></Route>
        <Route exact path="/thankyou" component={ThankYou }></Route>
        <Route exact path="/eachproduct" component={EachProduct}></Route>
        <Route exact path="/browse"> <BrowsePage /></Route>
        <Route exact path="/search"> <SearchPage /> </Route>
        <Route exact path="/cart"> <CartPage /> </Route>
        <Route exact path="/orders"><Orders />  </Route>
        <Route exact path="/address"><AddressBook />  </Route>
        <Route exact path="/account"> <AccountPage /> </Route>
        <Route exact path="/editaccount"><EditAccount /></Route>
        <Route exact path="/checkout"><Checkout/></Route>
        <Route exact path="/user"><UserPage /></Route>
        <Route exact path="/orderconfirmed"><OrderPlaced/></Route>
        <Route path="/browse/product/:id"> <ProductPage /></Route>
        <Route path="/browse/category/:id"><CategoryPage /></Route>
        <Route path="/stock"><StockMaintanence /></Route>
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </div>
  );
}

export default App;
