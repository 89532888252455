import React,{useContext, createContext,useReducer} from 'react';


//Create a context
const CartContext = createContext();

//CART variable local storage
const CART = "cart";

//Create a reducer
const reducer = (state,action) => {
    console.log(action);
    //console.log("Basket :",state.basket);
    var newCart = {};
    var storageObj = {};
    switch(action.type){

        case "ADD_TO_CART":
                const id = action.item.product_id;
                // console.log("ITEM :: ", action.item);
                // console.log("ID: ",id);
                // console.log("State : ",JSON.stringify(state[0]));
                // console.log("Previous state keys", Object.keys(state));
                storageObj = JSON.parse(localStorage.getItem(CART));
                newCart = {
                    "basket": {
                        ...(storageObj?storageObj.basket:[]),
                        [id] : action.item,  
                    }
                };

                //console.log("NEW CART : ",newCart);
                localStorage.setItem(CART, JSON.stringify(newCart));
                return newCart;

        case "REMOVE_FROM_CART" : 
                newCart = {
                    basket: {
                        ...state.basket,
                        [action.item.product_id]: undefined
                    }
                };

                localStorage.setItem(CART,JSON.stringify(newCart));
                return newCart;

        case "CLEAR_CART" :
                newCart = {
                    basket : {}
                };
                
                localStorage.setItem(CART,JSON.stringify(newCart));
                return newCart;
    }
    
    return state;
}

//Create initial State
var initialState = {
    basket : {}
} 

//Create provider
export const CartContextProvider = ({children}) => {
    const cartString = localStorage.getItem(CART);
    //console.log("Cart string: ",cartString);
    const cart = cartString? JSON.parse(cartString): null;
    //console.log("CARTTTTTTTT: ",cart);
    if(cart)
    {
        initialState = cart;
    }
    //console.log("Initial state: ",typeof(initialState));
    return <CartContext.Provider value={useReducer(reducer,initialState)}> 
                {children}
            </CartContext.Provider>
}

//useBasket
export const useCart = () => useContext(CartContext);

