import React from 'react';
import {Container,OuterContainer, Title, SubTitle, Rating, DeliveryTime, Image, Price, Text, Button, ExtraCard} from './styles/carouselCard';
import {faStar as star } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export default function CarouselCard({children,...restProps}){
    return <Container {...restProps}> {children} </Container>
}

CarouselCard.Image = function CardImage({...restProps}){
    return <Image {...restProps}/>
}


CarouselCard.Title = function CardTitle({children,...restProps}){
    return <Title {...restProps}> {children} </Title>;
}

CarouselCard.SubTitle = function CardSubTitle({children,...restProps}){
    return <SubTitle {...restProps}> {children} </SubTitle>
}

CarouselCard.Rating = function CardRating({children, ...restProps}){
    return <Rating {...restProps}> <FontAwesomeIcon icon={star} />{children} </Rating>
}

CarouselCard.DeliveryTime = function CardDeliveryTime({children, ...restProps}){
    return <DeliveryTime {...restProps}> {children} </DeliveryTime>
}

CarouselCard.Price = function CardPrice({children,...restProps}){
    return <Price {...restProps}> ₹{children}</Price>
}

CarouselCard.Text = function CardText({children,...restProps}){
    return <Text {...restProps}> {children}</Text>
}

CarouselCard.Button = function CardButton({children,...restProps}){
    return <div className="container"><Button {...restProps}> {children} </Button></div>
}

CarouselCard.ExtraCard = function CardExtraCard({children,...restProps}){
    return <ExtraCard {...restProps}>{children}</ExtraCard>
}