import React,{useEffect,useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import './styles.css';

export default function OrderModal({item,...props}){

    
    const [Products,setProducts] = useState([]);
    
    useEffect(() => {
        if(!item)
            return;
        console.log("ITEM::",item);
        console.log("ITEM NAMES : ",item.item_names);
        const productNames = item.item_names.split(",,,");
        const productQuantity = item.item_quantity.split(",,,");
        const productAmount = item.item_amount.split(",,,");
        var tempArray = [];

        for(var i=0;i<productNames.length;i++){

            if(!productNames[i])
                continue;
            const prod = {
                name: productNames[i],
                quantity: productQuantity[i],
                amount: productAmount[i]
            }
            tempArray = [...tempArray,prod];
        }

        setProducts(tempArray);
        console.log("SETTING PRODUCTS :",tempArray);
    }, [item])

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="ModalContainer"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className="title">
                    <h3 className="titleLeft">Your Order</h3>
                    <h3 className="titleRight">Order Id #{item && item.order_id}</h3>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <table>
                    <tr className="orderTableRow">
                        <th className="orderTableHeader"> Product Name </th>
                        <th className="orderTableHeader"> Quantity </th>
                        <th className="orderTableHeader"> Price/unit </th>
                        <th className="orderTableHeader"> Subtotal </th>
                    </tr>
                    {
                        Products.map((item) => {
                            return (
                                <tr className="orderTableRow">
                                    <td className="OrderTableData"> {item.name }</td>
                                    <td className="OrderTableData"> {item.quantity} </td>
                                    <td className="OrderTableData"> {item.amount} </td>
                                    <td className="OrderTableData"> {item.quantity* item.amount} </td>
                                </tr>
                            )
                        })
                    }
                </table>
                <h5 className="total"> Total : {item && item.total_amount}</h5>
                <h5 className="status"> 
                
                    Order status : {
                        item && (item.status=="done"?<h5 className="order_done">Order Completed</h5>:<h5 className="order_pending">Order Pending</h5>)
                    }
                
                </h5>
            </Modal.Body>
        </Modal>
    )
}