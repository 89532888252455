import React from 'react';
import HomeComponentTop from './homeTop';
import slider_2 from '../../static/img/slider/slider_2.jpg';
import slider_3 from '../../static/img/slider/slider_3.jpg';
import slider_5 from '../../static/img/slider/broture.png';
import small_img_1 from '../../static/img/slider/small_img_1.png';
import suji_rava_slider from '../../static/img/slider/suji_rava.png';
import chiroti_rava_slider from '../../static/img/slider/chiroti_rava.png';
import maida_slider from '../../static/img/slider/maida.png';
import slider_banner_1 from '../../static/img/header_img/slider_banner_1.png';
import flour from '../../static/img/header_img/flour.jpg';
import about_us from '../../static/img/about_us.png';
import dry_fruits from '../../static/img/dry_fruits.png';
import get_money from '../../static/img/get-money.png';
import baby_food from '../../static/img/baby-food.png';
import vitamins from '../../static/img/vitamins.png';
import hand_wash from '../../static/img/hand-wash.png';
import leaves from '../../static/img/leaves.png';
import './homeComponent.css';
import FooterPage from "../Footer";
import '../../stylesheet/style.css';
import '../../stylesheet/revslider.css';
import '../../stylesheet/owl.carousel.css';
import '../../stylesheet/owl.theme.css';
import '../../stylesheet/services.css';
import '../../stylesheet/enquiry_form.css';
import '../../stylesheet/responsive.css';
import {Link} from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import {faComment as comment,faUser as user,faTruck as truck, faPhone as phone,faDollarSign as dollar,faBriefcase as briefcase,faLeaf as leaf, faPlus as plus, faWindowClose as close} from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import {Carousel,DropdownButton,Dropdown} from 'react-bootstrap';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import Fade from '@material-ui/core/Fade';
import {auth, database} from "../../lib/firebase.prod";
import PubSub from 'pubsub-js';
import { parse } from '@fortawesome/fontawesome-svg-core';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


class HomeComponent extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      activewheat: "wheat0",
      activemaida: "maida0",
      activesuji: "suji0",
      activechiroti: "chiroti0",
      activeafgan: "afgan0",
      activeindian: "indian0",
      activesafawi: "safawi0",
      activemabroom: "mabroom0",
      activealmond: "almond0",
      activecashew: "cashew0",
      activepista: "pista0",
      activevapor:"vapor0",
      activeoxymeter:"oxymeter0",
      activescale1:"scale10",
      activescale2:"scale20",
      activerespiratory:"respiratory0",
      activebodyfat:"bodyfat0",
      activeeb600:"eb6000",
      activeeb500:"eb5000",
      activest309:"st3090",
      activeeb200:"eb2000",
      activepf120a:"pf120a0",
      activeeb100:"eb1000",
      activeic900:"ic9000",
      activehing:"hing0",
      activerasam:"rasam0",
      activesambar:"sambar0",
      activegaram:"garam0",
      activepulav:"pulav0",
      activefish:"fish0",
      activebiryani:"biryani0",
      activechicken:"chicken0",
      activekabab:"kabab0",
      activechilli:"chilli0",
      activeturmeric:"turmeric0",
      activecoriander:"coriander0",
      activejeera:"jeera0",
      activepepper:"pepper0",

      wheatQuantity: 1,
      maidaQuantity: 1,
      sujiQuantity:1,
      chirotiQuantity:1,
      afganQuantity:1,
      indianQuantity:1,
      safawiQuantity:1,
      mabroomQuantity:1,
      almondQuantity:1,
      cashewQuantity:1,
      pistaQuantity:1,
      vaporQuantity:1,
      oxymeterQuantity:1,
      scale1Quantity:1,
      scale2Quantity:1,
      respiratoryQuantity:1,
      bodyfatQuantity:1,
      eb600Quantity:1,
      eb500Quantity:1,
      st309Quantity:1,
      eb200Quantity:1,
      pf120aQuantity:1,
      eb100Quantity:1,
      ic900Quantity:1,
      hingQuantity:1,
      rasamQuantity:1,
      sambarQuantity:1,
      garamQuantity:1,
      pulavQuantity:1,
      fishQuantity:1,
      biryaniQuantity:1,
      chickenQuantity:1,
      kababQuantity:1,
      chilliQuantity:1,
      turmericQuantity:1,
      corianderQuantity:1,
      jeeraQuantity:1,
      pepperQuantity:1,

      wheatWeight: "1Kg",
      maidaWeight: "500g",
      sujiWeight: "500g",
      chirotiWeight: "500g",
      afganWeight: "100g",
      indianWeight: "100g",
      safawiWeight: "250g",
      mabroomWeight: "250g",
      almondWeight: "250g",
      cashewWeight: "100g",
      pistaWeight: "80g",
      hingWeight:"10g",
      rasamWeight:"25g",
      sambarWeight:"25g",
      garamWeight:"20g",
      pulavWeight:"20g",
      fishWeight:"40g",
      biryaniWeight:"20g",
      chickenWeight:"25g",
      kababWeight:"40g",
      chilliWeight:"40g",
      turmericWeight:"40g",
      corianderWeight:"40g",
      jeeraWeight:"30g",
      pepperWeight:"10g",
      vaporWeight:"1",
      oxymeterWeight:"1",
      scale1Weight:"1",
      scale2Weight:"1",
      respiratoryWeight:"1",
      bodyfatWeight:"1",
      eb600Weight:"1",
      eb500Weight:"1",
      st309Weight:"1",
      eb200Weight:"1",
      pf120aWeight:"1",
      eb100Weight:"1",
      ic900Weight:"1",

      wheatPrice: 38,
      maidaPrice: 20,
      sujiPrice: 23,
      chirotiPrice: 22,
      afganPrice: 80,
      indianPrice: 40,
      safawiPrice: 190,
      mabroomPrice: 250,
      almondPrice: 300,
      cashewPrice: 130,
      pistaPrice: 110,
      hingPrice:55,
      vaporPrice: 2000,
      oxymeterPrice: 2400,
      scale1Price: 1200,
      scale2Price: 1320,
      respiratoryPrice: 400,
      bodyfatPrice: 2800,
      eb600Price: 2000,
      eb500Price: 960,
      st309Price: 880,
      eb200Price: 550,
      pf120aPrice: 720,
      eb100Price: 400,
      ic900Price: 210,
      rasamPrice: 10,
      sambarPrice: 10,
      garamPrice: 10,
      pulavPrice: 10,
      fishPrice: 10,
      biryaniPrice: 10,
      chickenPrice: 10,
      kababPrice: 10,
      chilliPrice: 10,
      turmericPrice: 10,
      corianderPrice: 10,
      jeeraPrice: 10,
      pepperPrice: 10,

      wheatBrokerPrice:31.5,
      maidaBrokerPrice:17,
      sujiBrokerPrice:20,
      chirotiBrokerPrice:18,
      indianBrokerPrice:32,
      afganBrokerPrice:70,
      safawiBrokerPrice:170,
      mabroomBrokerPrice:220,
      almondBrokerPrice:270,
      cashewBrokerPrice:115,
      pistaBrokerPrice:100,
      hingBrokerPrice:42,
      vaporBrokerPrice:1500,
      oxymeterBrokerPrice:1800,
      scale1BrokerPrice:900,
      scale2BrokerPrice:1000,
      respiratoryBrokerPrice:280,
      eb600BrokerPrice:1500,
      eb500BrokerPrice:700,
      st309BrokerPrice:650,
      eb200BrokerPrice:400,
      pf120aBrokerPrice:540,
      eb100BrokerPrice:300,
      ic900BrokerPrice:155,
      bodyfatBrokerPrice:2100,
      rasamBrokerPrice:6.5,
      sambarBrokerPrice:6.5,
      garamBrokerPrice:6.5,
      pulavBrokerPrice:6.5,
      fishBrokerPrice:6.5,
      biryaniBrokerPrice:6.5,
      chickenBrokerPrice:6.5,
      kababBrokerPrice:6.5,
      chilliBrokerPrice:6.5,
      turmericBrokerPrice:6.5,
      corianderBrokerPrice:6.5,
      jeeraBrokerPrice:6.5,
      pepperBrokerPrice:6.5,

      cartItems: {"wheat":[],"maida":[],"suji":[],"chiroti":[],"afgan":[],"indian":[],"safawi":[],"mabroom":[],"almond":[],"cashew":[],"pista":[],"rasam":[],"sambar":[],"garam":[],"pulav":[],"fish":[],"biryani":[],"chicken":[],"kabab":[],"chilli":[],"turmeric":[],"coriander":[],"jeera":[],"pepper":[]},
      totalPrice: 0,
      showSnackbar: false,
      showSnackbarWarning: false,
      testing: [],
      alertText: "",
      "activewheatImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/wheat.png?alt=media&token=cb186b98-a4a9-48f1-bfa6-0f2872255292",
      "activemaidaImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/maida.png?alt=media&token=5de8b706-f941-48c2-8e5c-128f0777df8d",
      "activesujiImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/suji_rava.png?alt=media&token=28ecba7b-1360-41d6-9ee5-76c89c2a63b8",
      "activechirotiImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/chiroti.png?alt=media&token=167a4950-e172-4740-9740-fc1973e7b5ed",
      "activeafganImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/afghanraisinsfront.jpg?alt=media&token=1f27d626-e4dd-4395-a990-79caf5e6fc9c",
      "activeindianImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/indianraisinsfront.jpg?alt=media&token=39f2dba6-8b6b-470a-a842-6861bf24c32e",
      "activesafawiImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/safawifront.jpg?alt=media&token=e2698b80-063d-48c5-b43a-e435e4355e10",
      "activemabroomImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/mabroomfront.jpg?alt=media&token=d462969b-4497-4ed9-9daf-3031f5e036b3",
      "activealmondImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/almond1.jpg?alt=media&token=eab27d9a-a30b-441c-8160-c25e02b072ed",
      "activecashewImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/cashew1.jpg?alt=media&token=b1e9747d-d85d-456a-8323-1b2bc0893572",
      "activepistaImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/pista1.jpg?alt=media&token=20daec7c-a17f-4e20-b529-446fb1e5c578",
      "activevaporImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/newNikVaporizer.png?alt=media&token=cff0e0e0-ef8b-40de-bcd1-bdc887acfb04",
      "activeoxymeterImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/oxymeter.png?alt=media&token=2d9527ab-fc78-4f71-8069-dd05f4d278a4",
      "activescale1Image":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/pse101.png?alt=media&token=3bca7b96-e0ba-42ec-9261-a8151aa9245b",
      "activescale2Image":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/pse2011.png?alt=media&token=53567ae9-6142-4f88-b6a3-8364efaeec90",
      "activerespiratoryImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/respiratory1.png?alt=media&token=6e031a24-490e-4c32-9779-1e7550c6b569",
      "activebodyfatImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/wf2601.png?alt=media&token=c846b6bb-a886-4b66-ba14-3a59082ee55c",
      "activeeb600Image":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb6001.png?alt=media&token=b0e02464-0fb5-412a-8953-7e16e1a1d4c5",
      "activeeb500Image":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb5001.png?alt=media&token=2cbecd7b-bf8a-41d1-ade8-94d7bbc1dd79",
      "activest309Image":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/stethescope1.png?alt=media&token=19f78807-a1dd-410c-ad92-478bb819957f",
      "activeeb200Image":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb2001.png?alt=media&token=598cf0bc-8461-423b-ae30-c2b527276f8a",
      "activepf120aImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/pf120A1.png?alt=media&token=13638d0c-0a24-41e5-bec7-18dd970cdb5d",
      "activeeb100Image":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb100.png?alt=media&token=5ff832f2-29c9-4e48-a1eb-9b72f31829c4",
      "activeic900Image":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/coolPack1.png?alt=media&token=9fa36616-6cf6-41d8-8035-006332bd2f6d",
      "activehingImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Hing-1.jpg?alt=media&token=3ec12f0d-7044-4ae0-9e1d-14f1ab0db888",
      "activerasamImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Rasam-Powder.jpg?alt=media&token=ae9d8ede-74eb-46a1-a78d-69d05955dbe7",
      "activesambarImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Sambar-Powder.jpg?alt=media&token=9075867c-35bd-4c7f-af4c-709a79467a9f",
      "activegaramImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Garam-Masala.jpg?alt=media&token=da95e516-c346-4bee-a221-89479c4653c4",
      "activepulavImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Veg-Pulav-Masala.jpg?alt=media&token=7fb7aaca-4401-4ad5-9104-6578b59d9002",
      "activefishImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Fish-Fry-Masala.jpg?alt=media&token=eb3659fc-f792-4c40-bd1e-c8082630a4ab",
      "activebiryaniImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Chicken-Biryani.jpg?alt=media&token=d0706d57-2575-4883-9f45-45bb0f5f874f",
      "activechickenImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Chicken-Masala.jpg?alt=media&token=8e4f1cbc-c2d4-43c6-b07e-b76b84656ae9",
      "activekababImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Chicken-Kabab.jpg?alt=media&token=5b334f71-8f03-41c0-aebc-a5a6f540b434",
      "activechilliImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Red-Chilli-Powder.jpg?alt=media&token=51f4c5b1-df6b-451e-9f3e-f6f47c7334e9",
      "activeturmericImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Turmeric-Powder.jpg?alt=media&token=8ea5a9ba-83a3-4c7d-869f-8776b94bb279",
      "activecorianderImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Coriander-Powder.jpg?alt=media&token=faab4eba-6929-450f-9ac1-276f68645ac7",
      "activejeeraImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Jeera-Powder.jpg?alt=media&token=3211b88c-9efa-446c-86e7-0ba9c261834c",
      "activepepperImage":"https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Pepper-Powder.jpg?alt=media&token=1cfc6de3-2ce7-41be-bb30-ebaf71f11d70",
      hideHighlight: false,
      enlargedImage:"",

      products:{},
      productKeys:[],

      empty:[],
      loopMe:["0","0","0"],
      space:" ",
      user:"customer",
    }
  }

  
  componentDidMount(){
    if(localStorage.getItem("user")==null){
      localStorage.setItem("user","customer");
    }else{
      this.setState({"user":localStorage.getItem("user")});
      
    }
    if(JSON.parse(localStorage.getItem("cart"))!=null){
      var cartItems = JSON.parse(localStorage.getItem("cart"));
      console.log("IN IF");
      if(!('wheat' in cartItems)){
        cartItems.wheat = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('rasam' in cartItems)){
        cartItems.rasam = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('sambar' in cartItems)){
        cartItems.sambar = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('garam' in cartItems)){
        cartItems.garam = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }if(!('pulav' in cartItems)){
        cartItems.pulav = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }if(!('fish' in cartItems)){
        cartItems.fish = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('biryani' in cartItems)){
        cartItems.biryani = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('chicken' in cartItems)){
        cartItems.chicken = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('kabab' in cartItems)){
        cartItems.kabab = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('chilli' in cartItems)){
        cartItems.chilli = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('turmeric' in cartItems)){
        cartItems.turmeric = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('coriander' in cartItems)){
        cartItems.coriander = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('jeera' in cartItems)){
        cartItems.jeera = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('pepper' in cartItems)){
        cartItems.jeera = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('maida' in cartItems)){
        cartItems.maida = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));

      }
      if(!('suji' in cartItems)){
        cartItems.suji = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));

      }
      if(!('chiroti' in cartItems)){
        cartItems.chiroti = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));

      }
      if(!('afgan' in cartItems)){
        cartItems.afgan = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));

      }
      if(!('indian' in cartItems)){
        cartItems.indian = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));

      }
      if(!('safawi' in cartItems)){
        cartItems.safawi = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));

      }
      if(!('mabroom' in cartItems)){
        cartItems.mabroom = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));

      }
      if(!('almond' in cartItems)){
        cartItems.almond = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));

      }
      if(!('cashew' in cartItems)){
        cartItems.cashew = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));

      }
      if(!('pista' in cartItems)){
        cartItems.pista = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));

      }

      if(!('hing' in cartItems)){
        cartItems.hing = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));

      }

      if(!('vapor' in cartItems)){
        cartItems.vapor = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));

      }if(!('oxymeter' in cartItems)){
        cartItems.oxymeter = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));

      }if(!('scale1' in cartItems)){
        cartItems.scale1 = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));

      }if(!('scale2' in cartItems)){
        cartItems.scale2 = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));

      }if(!('respiratory' in cartItems)){
        cartItems.respiratory = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));

      }if(!('bodyfat' in cartItems)){
        cartItems.bodyfat = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));

      }if(!('eb600' in cartItems)){
        cartItems.eb600 = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));

      }if(!('eb500' in cartItems)){
        cartItems.eb500 = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));

      }if(!('st309' in cartItems)){
        cartItems.st309 = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));

      }if(!('eb200' in cartItems)){
        cartItems.eb200 = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));

      }if(!('pf120a' in cartItems)){
        cartItems.pf120a = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));

      }if(!('eb100' in cartItems)){
        cartItems.eb100 = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));

      }if(!('ic900' in cartItems)){
        cartItems.ic900 = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));

      }
    }else{
      var cartItems = this.state.cartItems;
      console.log("IN ELSE");
      if(!('wheat' in cartItems)){
        cartItems.wheat = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('maida' in cartItems)){
        cartItems.maida = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('rasam' in cartItems)){
        cartItems.rasam = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('sambar' in cartItems)){
        cartItems.sambar = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('garam' in cartItems)){
        cartItems.garam = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('pulav' in cartItems)){
        cartItems.pulav = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('fish' in cartItems)){
        cartItems.fish = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('biryani' in cartItems)){
        cartItems.biryani = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('chicken' in cartItems)){
        cartItems.chicken = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('kabab' in cartItems)){
        cartItems.kabab = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('chilli' in cartItems)){
        cartItems.chilli = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('turmeric' in cartItems)){
        cartItems.turmeric = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('coriander' in cartItems)){
        cartItems.coriander = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('jeera' in cartItems)){
        cartItems.jeera = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('pepper' in cartItems)){
        cartItems.pepper = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('suji' in cartItems)){
        cartItems.suji = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('chiroti' in cartItems)){
        cartItems.chiroti = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('afgan' in cartItems)){
        cartItems.afgan = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('indian' in cartItems)){
        cartItems.indian = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('safawi' in cartItems)){
        cartItems.safawi = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('mabroom' in cartItems)){
        cartItems.mabroom = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('almond' in cartItems)){
        cartItems.almond = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('cashew' in cartItems)){
        cartItems.cashew = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('pista' in cartItems)){
        cartItems.pista = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('hing' in cartItems)){
        cartItems.hing = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
      if(!('vapor' in cartItems)){
        cartItems.vapor = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }if(!('oxymeter' in cartItems)){
        cartItems.oxymeter = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }if(!('scale1' in cartItems)){
        cartItems.scale1 = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }if(!('scale2' in cartItems)){
        cartItems.scale2 = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }if(!('respiratory' in cartItems)){
        cartItems.respiratory = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }if(!('bodyfat' in cartItems)){
        cartItems.bodyfat = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }if(!('eb600' in cartItems)){
        cartItems.eb600 = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }if(!('eb500' in cartItems)){
        cartItems.eb500 = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }if(!('st309' in cartItems)){
        cartItems.st309 = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }if(!('eb200' in cartItems)){
        cartItems.eb200 = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }if(!('pf120a' in cartItems)){
        cartItems.pf120a = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }if(!('eb100' in cartItems)){
        cartItems.eb100 = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }if(!('ic900' in cartItems)){
        cartItems.ic900 = [];
        localStorage.setItem("cart",JSON.stringify(cartItems));
      }
    }
    database.ref("Product").on('value',(snapshot)=>{
      this.setState({products:snapshot.val()});
      this.setState({productKeys:Object.keys(snapshot.val())});
    })
    var token = PubSub.subscribe('checkUser', this.mySubscriber);
  }

  optionClicked(type,weight,brokerPrice,customerPrice,identifier,e){
    e.stopPropagation();
    this.setState({["active"+type]:identifier})
    this.setState({[type+"Price"]:customerPrice})
    this.setState({[type+"BrokerPrice"]:brokerPrice})
    this.setState({[type+"Weight"]:weight})
  }

  mySubscriber = (msg, data) => {
    if(data=="broker"){
      this.setState({user:"broker"});
    }else{
      this.setState({user:"customer"});
    }
  }

  handleInputChange = (e,type) => {
    e.stopPropagation();
    const {name,value} = e.target;
    this.setState({[type+"Quantity"]:value})
  }

  addToCart = (type,pdtName,multiple,category,gst,e) => {
    e.stopPropagation();
      if(this.state[type+"Quantity"]=="" || this.state[type+"Quantity"]==0){
        this.setState({showSnackbarWarning:true})
        this.setState({alertText:"Please enter quantity"});
      }
      else if(localStorage.getItem("user")=="broker"){
        console.log("pdtName",pdtName);
        if(category=="food"){
          if(this.state[type+"Weight"].slice(-2)=="Kg"){
            var div = multiple/parseInt(this.state[type+"Weight"].slice(0,-2));
            if(this.state[type+"Quantity"]%div==0){
              this.inPlaceOfAddToCart(type,pdtName,div,category,gst,e)
            }else{
              this.setState({alertText:"Please enter in multiples of "+div.toString()});  
              this.setState({showSnackbarWarning:true});  
            }
          }else if(this.state[type+"Weight"].slice(-2)=="0g"){
            if(pdtName=="Indian Raisins" || pdtName=="Afghan Raisins"){
              if(this.state[type+"Weight"]=="100g"){
                if(this.state[type+"Quantity"]%36==0){
                  this.inPlaceOfAddToCart(type,pdtName,36,category,gst,e);
                }else{
                  this.setState({alertText:"Please enter in multiples of 36"});  
                  this.setState({showSnackbarWarning:true});      
                }
              }else if(this.state[type+"Weight"]=="250g"){
                if(this.state[type+"Quantity"]%16==0){
                  this.inPlaceOfAddToCart(type,pdtName,16,category,gst,e);
                }else{
                  this.setState({alertText:"Please enter in multiples of 16"});  
                  this.setState({showSnackbarWarning:true});      
                }
              }else if(this.state[type+"Weight"]=="500g"){
                if(this.state[type+"Quantity"]%8==0){
                  this.inPlaceOfAddToCart(type,pdtName,8,category,gst,e);
                }else{
                  this.setState({alertText:"Please enter in multiples of 8"});  
                  this.setState({showSnackbarWarning:true});      
                }
              }
            }else if(pdtName=="Safawi Dates" || pdtName=="Mabroom Dates"){
              if(this.state[type+"Quantity"]%16==0){
                this.inPlaceOfAddToCart(type,pdtName,16,category,gst,e);
              }else{
                this.setState({alertText:"Please enter in multiples of 16"});  
                this.setState({showSnackbarWarning:true});      
              }
            }else if(pdtName=="Roasted & Salted Almonds"){
              if(this.state[type+"Weight"]=="250g"){
                if(this.state[type+"Quantity"]%16==0){
                  this.inPlaceOfAddToCart(type,pdtName,16,category,gst,e);
                }else{
                  this.setState({alertText:"Please enter in multiples of 16"});  
                  this.setState({showSnackbarWarning:true});      
                }
              }else if(this.state[type+"Weight"]=="500g"){
                if(this.state[type+"Quantity"]%8==0){
                  this.inPlaceOfAddToCart(type,pdtName,8,category,gst,e);
                }else{
                  this.setState({alertText:"Please enter in multiples of 8"});  
                  this.setState({showSnackbarWarning:true});      
                }
              }
            }else if(pdtName=="Roasted & Salted Cashews"){
              if(this.state[type+"Weight"]=="100g"){
                if(this.state[type+"Quantity"]%36==0){
                  this.inPlaceOfAddToCart(type,pdtName,36,category,gst,e);
                }else{
                  this.setState({alertText:"Please enter in multiples of 36"});  
                  this.setState({showSnackbarWarning:true});      
                }
              }else if(this.state[type+"Weight"]=="250g"){
                if(this.state[type+"Quantity"]%16==0){
                  this.inPlaceOfAddToCart(type,pdtName,16,category,gst,e);
                }else{
                  this.setState({alertText:"Please enter in multiples of 16"});  
                  this.setState({showSnackbarWarning:true});      
                }
              }
            }else if(pdtName=="Roasted & Salted Pistachio"){
              if(this.state[type+"Weight"]=="80g"){
                if(this.state[type+"Quantity"]%36==0){
                  this.inPlaceOfAddToCart(type,pdtName,36,category,gst,e);
                }else{
                  this.setState({alertText:"Please enter in multiples of 36"});  
                  this.setState({showSnackbarWarning:true});      
                }
              }else if(this.state[type+"Weight"]=="200g"){
                if(this.state[type+"Quantity"]%16==0){
                  this.inPlaceOfAddToCart(type,pdtName,16,category,gst,e);
                }else{
                  this.setState({alertText:"Please enter in multiples of 16"});  
                  this.setState({showSnackbarWarning:true});      
                }
              }else if(this.state[type+"Weight"]=="400g"){
                if(this.state[type+"Quantity"]%8==0){
                  this.inPlaceOfAddToCart(type,pdtName,8,category,gst,e);
                }else{
                  this.setState({alertText:"Please enter in multiples of 8"});  
                  this.setState({showSnackbarWarning:true});      
                }
              }
            }else if(pdtName=="Strong Hing"){
              if(this.state[type+"Weight"]=="10g"){
                if(this.state[type+"Quantity"]%100==0){
                  this.inPlaceOfAddToCart(type,pdtName,100,category,gst,e);
                }else{
                  this.setState({alertText:"Please enter in multiples of 100"});  
                  this.setState({showSnackbarWarning:true});      
                }
              }else if(this.state[type+"Weight"]=="20g"){
                if(this.state[type+"Quantity"]%50==0){
                  this.inPlaceOfAddToCart(type,pdtName,50,category,gst,e);
                }else{
                  this.setState({alertText:"Please enter in multiples of 100"});  
                  this.setState({showSnackbarWarning:true});      
                }
              }
            }else if(pdtName=="Garam Masala Powder"){
              if(this.state[type+"Weight"]=="20g"){
                if(this.state[type+"Quantity"]%20==0){
                  this.inPlaceOfAddToCart(type,pdtName,20,category,gst,e);
                }else{
                  this.setState({alertText:"Please enter in multiples of 20"});  
                  this.setState({showSnackbarWarning:true});      
                }
              }
            }else if(pdtName=="Veg Pulav Masala Powder"){
              if(this.state[type+"Weight"]=="20g"){
                if(this.state[type+"Quantity"]%20==0){
                  this.inPlaceOfAddToCart(type,pdtName,20,category,gst,e);
                }else{
                  this.setState({alertText:"Please enter in multiples of 20"});  
                  this.setState({showSnackbarWarning:true});      
                }
              }
            }else if(pdtName=="Fish Fry Masala Powder"){
              if(this.state[type+"Weight"]=="40g"){
                if(this.state[type+"Quantity"]%20==0){
                  this.inPlaceOfAddToCart(type,pdtName,20,category,gst,e);
                }else{
                  this.setState({alertText:"Please enter in multiples of 20"});  
                  this.setState({showSnackbarWarning:true});      
                }
              }
            }else if(pdtName=="Chicken Biryani Masala Powder"){
              if(this.state[type+"Weight"]=="20g"){
                if(this.state[type+"Quantity"]%20==0){
                  this.inPlaceOfAddToCart(type,pdtName,20,category,gst,e);
                }else{
                  this.setState({alertText:"Please enter in multiples of 20"});  
                  this.setState({showSnackbarWarning:true});      
                }
              }
            }else if(pdtName=="Chicken Kabab Masala Powder"){
              if(this.state[type+"Weight"]=="40g"){
                if(this.state[type+"Quantity"]%20==0){
                  this.inPlaceOfAddToCart(type,pdtName,20,category,gst,e);
                }else{
                  this.setState({alertText:"Please enter in multiples of 20"});  
                  this.setState({showSnackbarWarning:true});      
                }
              }
            }else if(pdtName=="Red Chilli Powder"){
              if(this.state[type+"Weight"]=="40g"){
                if(this.state[type+"Quantity"]%20==0){
                  this.inPlaceOfAddToCart(type,pdtName,20,category,gst,e);
                }else{
                  this.setState({alertText:"Please enter in multiples of 20"});  
                  this.setState({showSnackbarWarning:true});      
                }
              }
            }else if(pdtName=="Turmeric Powder"){
              if(this.state[type+"Weight"]=="40g"){
                if(this.state[type+"Quantity"]%20==0){
                  this.inPlaceOfAddToCart(type,pdtName,20,category,gst,e);
                }else{
                  this.setState({alertText:"Please enter in multiples of 20"});  
                  this.setState({showSnackbarWarning:true});      
                }
              }
            }else if(pdtName=="Coriander Powder"){
              if(this.state[type+"Weight"]=="40g"){
                if(this.state[type+"Quantity"]%20==0){
                  this.inPlaceOfAddToCart(type,pdtName,20,category,gst,e);
                }else{
                  this.setState({alertText:"Please enter in multiples of 20"});  
                  this.setState({showSnackbarWarning:true});      
                }
              }
            }else if(pdtName=="Jeera Powder"){
              if(this.state[type+"Weight"]=="30g"){
                if(this.state[type+"Quantity"]%20==0){
                  this.inPlaceOfAddToCart(type,pdtName,20,category,gst,e);
                }else{
                  this.setState({alertText:"Please enter in multiples of 20"});  
                  this.setState({showSnackbarWarning:true});      
                }
              }
            }else if(pdtName=="Pepper Powder"){
              if(this.state[type+"Weight"]=="10g"){
                if(this.state[type+"Quantity"]%20==0){
                  this.inPlaceOfAddToCart(type,pdtName,20,category,gst,e);
                }else{
                  this.setState({alertText:"Please enter in multiples of 20"});  
                  this.setState({showSnackbarWarning:true});      
                }
              }
            }
            else{
              var div = multiple*1000/parseInt(this.state[type+"Weight"].slice(0,-1));
              if(this.state[type+"Quantity"]%div==0){
                this.inPlaceOfAddToCart(type,pdtName,div,category,gst,e);
              }else{
                this.setState({alertText:"Please enter in multiples of "+div.toString()});  
                this.setState({showSnackbarWarning:true});  
              }  
            }
          }else if(this.state[type+"Weight"].slice(-2)=="5g"){
            if(pdtName=="Rasam Powder"){
              if(this.state[type+"Weight"]=="25g"){
                if(this.state[type+"Quantity"]%20==0){
                  this.inPlaceOfAddToCart(type,pdtName,20,category,gst,e);
                }else{
                  this.setState({alertText:"Please enter in multiples of 20"});  
                  this.setState({showSnackbarWarning:true});      
                }
              }
            }else if(pdtName=="Sambar Powder"){
              if(this.state[type+"Weight"]=="25g"){
                if(this.state[type+"Quantity"]%20==0){
                  this.inPlaceOfAddToCart(type,pdtName,20,category,gst,e);
                }else{
                  this.setState({alertText:"Please enter in multiples of 20"});  
                  this.setState({showSnackbarWarning:true});      
                }
              }
            }else if(pdtName=="Chicken Masala Powder"){
              if(this.state[type+"Weight"]=="25g"){
                if(this.state[type+"Quantity"]%20==0){
                  this.inPlaceOfAddToCart(type,pdtName,20,category,gst,e);
                }else{
                  this.setState({alertText:"Please enter in multiples of 20"});  
                  this.setState({showSnackbarWarning:true});      
                }
              }
            }
          }
        }
        else if(category=="electronics"){
          if(this.state[type+"Quantity"]%multiple==0){
            this.inPlaceOfAddToCart(type,pdtName,multiple,category,gst,e)
          }else{
            this.setState({alertText:"Please enter in multiples of "+multiple.toString()});  
            this.setState({showSnackbarWarning:true});  
          }
        }  
      }else{
        this.inPlaceOfAddToCart(type,pdtName,1,category,gst,e);
      }  
    // }
  }

  inPlaceOfAddToCart = (type,pdtName,multiple,category,gst,e) => {
    const item = {};
    var check = false;
    var items;
    var price = 0;
    if(localStorage.getItem('cart')==null){
      items = JSON.parse(JSON.stringify(this.state.cartItems));
    }else{
      items = JSON.parse(localStorage.getItem('cart'))      
    }
    if(localStorage.getItem('totalPrice')!=null){
      price = JSON.parse(localStorage.getItem('totalPrice'));
    }
    item["type"] = type;
    item["quantity"] = parseInt(this.state[type+"Quantity"]);
    item["weight"] = this.state[type+"Weight"];
    item["multiple"] = parseInt(multiple);
    item["gst"] = gst;
    if(this.state.user=="broker"){
      item["price"] = parseFloat(this.state[type+"BrokerPrice"]).toFixed(1);
      item["inactivePrice"] = parseFloat(this.state[type+"Price"]).toFixed(1);
    }else{
      item["price"] = parseFloat(this.state[type+"Price"]).toFixed(1);
      item["inactivePrice"] = parseFloat(this.state[type+"BrokerPrice"]).toFixed(1);
    }
    item["name"] = pdtName;
    if(!(type in items)){
      items.type = [];
    }
    items[type].map(existingItem => {
      if(existingItem['weight']==item['weight']){
        check = true;
        existingItem.quantity=parseInt(existingItem.quantity)+parseInt(this.state[type+"Quantity"]);
        this.setState({cartItems: items})
        this.setState({totalPrice: price+item['quantity']*item['price']})
        this.setState({showSnackbar:true});
        localStorage.setItem("cart",JSON.stringify(items));
        localStorage.setItem("totalPrice",JSON.stringify(price+item['quantity']*item['price']));      
      }
    })
    if(check == false){
      items[type].push(item);
      this.setState({cartItems: items})
      this.setState({totalPrice: price+item['quantity']*item['price']})
      this.setState({showSnackbar:true});
      localStorage.setItem("cart",JSON.stringify(items));
      localStorage.setItem("totalPrice",JSON.stringify(price+item['quantity']*item['price']));    
    }  
  }


  handleClose = () => {
    this.setState({showSnackbar:false})
    this.setState({showSnackbarWarning:false})
  }

  hideDiv = () => {
    this.setState({hideHighlight:false});
  }


  imageClicked = (type,source,stateName,e) => {
    e.stopPropagation();
    this.setState({[stateName]:source})
  }

  openNewPage = (weights,prices,inactivePrices,numOfImages,name,cartName,multiple,gst) => {
    this.props.history.push({pathname:"/eachproduct/",state:{'weights':weights,"prices":prices,'inactivePrices':inactivePrices,'numOfImages':numOfImages,'name':name,'cartItem':this.state.cartItems,'totalPrice':this.state.totalPrice,'cartName':cartName,'multiple':multiple,'gst':gst}});
  }

  highlightImage = (type,e) => {
    e.stopPropagation();
    this.setState({enlargedImage:type});
    this.setState({hideHighlight:true});
  }


    render(){
        return (
            <>
            {
              this.state.hideHighlight?                
              <div style={{width:"100vw",height:"100vh",zIndex:"2000",background:"#fffffff0",position:"fixed",top:"0",bottom:"0",left:"0",right:"0"}}>
                <img src={this.state.enlargedImage} className="imageSize"/>
                <div style={{position:"fixed",right:"1.56rem",top:"0.625rem",height:"34px",width:"34px",margin:"0",padding:"0",zIndex:"2006",cursor:"pointer"}} onClick={()=>this.hideDiv()}>
                  <div style={{fontSize:"15px",lineHeight:"15px",height:"34px",width:"34px",border:"2px solid #28292b",borderRadius:"100%",opacity:"0.3",textAlign:"center",textDecoration:"none",color:"#222",backgroundColor:"#fff"}}>
                    <FontAwesomeIcon icon={close} style={{verticalAlign:"-webkit-baseline-middle"}}></FontAwesomeIcon>
                  </div>
                </div>
              </div>
              :null
            }
                <div style={{position:"sticky",top:"0px",zIndex:"1000"}}>
                    <HomeComponentTop cartItems={this.state.cartItems} totalPrice={this.state.totalPrice}/>
                </div>
                <div class="content">
                    <div id="thmg-slider-slideshow" class="thmg-slider-slideshow">
                        <div class="container3 fullwidthbanner-container" style={{position:"relative",zIndex:"100"}}>
                          <Carousel style={{marginTop:"0px !important"}}>
                          <Carousel.Item style={{marginTop:"0px !important"}}>
                              <img src={slider_5} style={{width:"100%"}}/>
                            </Carousel.Item>

                            <Carousel.Item style={{marginTop:"0px !important"}}>
                                <img src={slider_2} style={{width:"100%"}}/>
                                <div class="header-landing-home-title" style={{}}><span style={{fontFamily:"Oswald,sans serif"}}>CHARIOT GOLD</span></div>
                                <div class="header-landing-home-subtitle"style={{}}><span style={{fontFamily:"Oswald,sans serif"}}>Simply Delicious</span></div>
                                <div class="header-landing-home-subtitle2"style={{fontWeight:"600"}}><span style={{fontFamily:"Open Sans,sans serif"}}>Premium Quality Whole Wheat Chakki Atta</span></div>
                                <Link to="/allProducts"><div  class="header-landing-home-caption" ><span class ="shopnow-button" >Shop Now</span></div></Link>
                              
                            </Carousel.Item>
                            <Carousel.Item style={{marginTop:"0px !important"}}>
                              <img src={slider_3} style={{width:"100%"}}/>
                              <div class="header-landing-home-title" style={{}}><span style={{fontFamily:"Oswald,sans serif"}}>CHARIOT GOLD</span></div>
                              <div  class="header-landing-home-subtitle"><span style={{fontFamily:"Oswald,sans serif"}}>PREMIUM PRODUCTS</span></div>
                              <Link to="/allProducts"><div  class="header-landing-home-caption"><span class ="shopnow-button" >Shop Now</span></div></Link>
                            </Carousel.Item>

                          </Carousel>
                        </div>
                    </div>
                    <div id="top">
                        <div class="container">
                            <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12"> <a href="#" data-scroll-goto="1"> <img src={slider_banner_1} class="img-thumbnail" alt="promotion-banner1"/> </a> </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12"> <a href="#" data-scroll-goto="2"> <img src={flour} class="img-thumbnail" alt="promotion-banner2"/> </a> </div>
                            </div>
                        </div>
                    </div>
                    <section class="about-us py-2 " id="about_us">
                        <div class="container mt-2">
                            <div class="row">
				                <div class="col-md-8" style={{textAlign:"left"}}>
                                    <h1 class='text-success'>Welcome To Chariot Agro Limited!</h1>
                                    <h2 class="knowabout">About Us</h2>
                                    <hr/>
					                <p style={{textAlign:"justify"}}>Chariot Agro Limited is a closely held public limited company headquartered in Bengaluru, registered under the Company’s Act of 2013. The key promoter of Chariot Agro Limited is Chariot World Tours Limited which is mainly promoted by first generation entrepreneur, Atmanand Krishna Shanbhag along with K C Madhukiran and Koushik Varaghur. K. C. Madhukiran and Koushik Varaghur no longer serve on the board of Chariot World Tours Limited since March 2020. Nicholas Karthik, Sachin Shetty, Karthik P. Nayak, Deepa Shanbhag and Deepak Prabhu are the other board of directors of CWTL. Chariot Agro Limited will be in the business of procuring high quality agricultural produce such as wheat, wheat-based products, rice and other food grains, pulses, oil seeds, spices, edible oil, all kind of dry fruits and nuts, sugar, Tea, coffee etc. and get them processed in hygienic conditions and deliver to the customers after getting them well packed to protect all the nutritional properties of the products.</p>
					                <Link to={"/aboutus"} style={{color:"#ffb637"}}><strong>Read More...</strong></Link>
				                </div>
                                <div class="col-md-4">
                                    <br/><br/><br/><br/><br/>
                                    <img src={about_us} alt=""/>
                                </div>
			                </div>
                        </div>
	                </section>
                  <hr></hr>


                    <div class="hot-section">
                      <div class="container">
                        <div class="row1">
                          <div class="ad-info">
                            <h2>Hurry Up!</h2>
                            <h3>Deal of the week</h3>
                            <h4>Fastest delivery right to your doorstep.</h4>
                          </div>
                        </div>
                        <div class="row">
                          <div class="hot-deal">   
                          <div id="products">
                    <section class=" wow bounceInUp animated">
                        <div class="best-pro  container" >
                            <div class="new_title">
                                <h2>Our Products</h2>
                            </div>
                            <div class="row">
                            <div id="best-seller" class="product-flexslider hidden-buttons">
                            <div class="slider-items slider-width-col4 products-grid" style={{display:'flex',flexWrap:'wrap'}}>
                              {
                                this.state.productKeys.map((key)=>{
                                  return <div class="item col-lg-3 col-md-3 col-sm-6 col-xs-12" >
                                  <div class="item-inner forHover" onClick={()=>{this.state.user=="broker"?this.openNewPage(this.state.products[key].quantities,this.state.products[key].broker_price,this.state.products[key].customer_price,this.state.products[key].image_url,this.state.products[key].name,this.state.products[key].cart_name,this.state.products[key].multiple,this.state.products[key].gst):this.openNewPage(this.state.products[key].quantities,this.state.products[key].customer_price,this.state.products[key].broker_price,this.state.products[key].image_url,this.state.products[key].name,this.state.products[key].cart_name,this.state.products[key].multiple,this.state.products[key].gst)}}>
                                      <div class="item-img">
                                        <div class="item-img-info">
                                        <a title={this.state.products[key].name} class="product-image" style={{cursor:"pointer"}} onClick={()=>{this.state.user=="broker"?this.openNewPage(this.state.products[key].quantities,this.state.products[key].broker_price,this.state.products[key].customer_price,this.state.products[key].image_url,this.state.products[key].name,this.state.products[key].cart_name,this.state.products[key].multiple,this.state.products[key].gst):this.openNewPage(this.state.products[key].quantities,this.state.products[key].customer_price,this.state.products[key].broker_price,this.state.products[key].image_url,this.state.products[key].name,this.state.products[key].cart_name,this.state.products[key].multiple,this.state.products[key].gst)}}><img src={this.state["active"+this.state.products[key].cart_name+"Image"]} alt={this.state.products[key].name}/></a>
                                        </div>
                                      </div>
                                      <div style={{display:"flex",height:"64px",justifyContent:"center"}}>
                                        {
                                          this.state.products[key].image_url.map((source)=>{
                                            return <img src={source} onClick={(e)=>this.imageClicked(this.state.products[key].cart_name,source,"active"+this.state.products[key].cart_name+"Image",e)} className={this.state["active"+this.state.products[key].cart_name+"Image"]==source?"imageClicked":"imageNotClicked"}/>
                                          })
                                        }
                                      </div>
                                      <div class="item-info">
                                        <div class="info-inner">
                                          {this.state.products[key].category=="electronics"?<div class="item-title">{this.state.products[key].name}<br/>{this.state.products[key].lines==1?<span style={{visibility:"hidden"}}>empty</span>:null}</div>:<div class="item-title">Chariot Gold <br/>{this.state.products[key].name}</div>}                                         
                                          <div class="item-content">
                                          </div>
                                        </div>
                                        {
                                          this.state.loopMe.map((data,index)=>{
                                            return  index<=this.state.products[key].quantities.length-1?
                                            <div className={this.state["active"+this.state.products[key].cart_name]==""+this.state.products[key].cart_name+index?"quantity highlighted wheat1":"quantity wheat1"} onClick={(e) => this.optionClicked(this.state.products[key].cart_name,this.state.products[key].quantities[index],this.state.products[key].broker_price[index],this.state.products[key].customer_price[index],this.state.products[key].cart_name+index,e)}> 
                                              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:"center"}}>
                                              {this.state.products[key].category=="electronics"?
                                                <div class="quantity-title-block">
                                                  <div class="quantity-title">1</div>
                                                  <div class="quantity-subtitle">Unit</div>
                                                </div>
                                              :
                                                <div class="quantity-title-block">
                                                  <div class="quantity-title">{this.state.products[key].quantities[index]}</div>
                                                </div>
                                            }
                                                {this.state.user=="customer"?<div class="quantity-price">Rs {this.state.products[key].customer_price[index]}</div>:<div class="quantity-price">Rs {this.state.products[key].broker_price[index]}</div>}
                                                <div className={this.state["active"+this.state.products[key].cart_name]==""+this.state.products[key].cart_name+index?"selectedquantityicon wheat1":"quantityicon wheat1"}>
                                                  <CheckIcon />
                                                </div>
                                              </div>
                                            </div>:<div style={{visibility:"hidden"}} className="quantity highlighted suji_rava3"> 
                                          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:"center"}}>
                                            <div class="quantity-title-block">
                                             <div class="quantity-title"><span>&nbsp;&nbsp;1Kg&nbsp;&nbsp;</span></div>
                                            </div>
                                            <div class="quantity-price">Rs 40</div>
                                            <div>
                                              <CheckIcon />
                                            </div>
                                          </div>
                                        </div>
                                          })
                                        }

                                          <div class="quantity-input-block">
                                            <div  style={{display:'flex',flexDirection:'row'}}>
                                              <label class="quantity-textfeils-name">Qty</label>
                                              <input class="quantity-textfeils" type="text" id="fname" name="fname" value={this.state[this.state.products[key].cart_name+"Quantity"]} onChange={(e) => this.handleInputChange(e,this.state.products[key].cart_name)} onClick={(e)=>e.stopPropagation()}/>
                                            </div>   
                                            <div class="quantitycart" onClick={(e)=>this.addToCart(this.state.products[key].cart_name,this.state.products[key].name,this.state.products[key].multiple,this.state.products[key].category,this.state.products[key].gst,e)}>
                                              Add to cart <span><ShoppingBasketIcon style={{marginBottom :"5px"}}/></span>
                                            </div>            
                                          </div>
                                        </div>
                                      </div>
                                    </div>  
                                })
                              }
          </div>
        </div>
        </div>
      </div>
    </section>
                    </div>
                          </div>
                        </div>        
      </div>
    </div>
    <div class="latest-blog wow bounceInUp animated animated container1" id="blog">
        <div class="row">
        <div class="mid-section" >
    <div class="container-fluid">
      <div class="row1">
        <h3>Fresh food delivery made easy</h3>
        <h2>Special Product</h2>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <div class="block1"> <strong>Whole Wheat Chakki Atta</strong>
            <p style={{color:"#fff9"}}>It is a Good Source of Energy. Whole wheat atta is a rich source of vitamin B, which is a major energy provider.</p>
          </div>
          <div class="block2"> <strong>Maida</strong>
            <p style={{color:"#ffffffb3"}}>Maida is a white flour from the Indian subcontinent, made from wheat..</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <div class="spl-pro"><a href="" title=""><img src={dry_fruits} alt="Fresh Organic Mustard Leaves "/></a>
            
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <div class="block3"> <strong>Suji Rawa/ Rawa</strong>
            <p style={{color:"#fff9"}}>Both Rawa are made from granulated durum wheat and are used in the preparation of several recipes both, sweet and savory.</p>
          </div>
          <div class="block4"> <strong>Dry Fruits And Nuts</strong>
            <p style={{color:"#fff9"}}>Dry fruits, especially nuts like walnut, pecan nut, pine nuts, etc. are a great source of minerals.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br/>
	<div class="Features-section paddingTB60 ">
		<div class="container-fluid">
			<div class="row">
				<div class="col-sm-6 col-md-1">
				</div>
				<div class="col-sm-6 col-md-2">
					<div class="col-md-12 feature-box1">
						<img src={get_money} class=""/>
						<p><strong> Procured from Best Available source at a very competitive price.</strong></p>
					</div>
				</div> 
				<div class="col-sm-6 col-md-2">
					<div class="col-md-12 feature-box1">
						<img src={baby_food} class=""/>
						<p><strong>Packed in clean and hygienic  environment.</strong></p>
					</div>
				</div> 	
				<div class="col-sm-6 col-md-2">
					<div class="feature-box1">
						<img src={hand_wash} class=""/>
						<p><strong>Assured Food Safety with no adulterations.</strong></p>
					</div>
				</div> 
				<div class="col-sm-6 col-md-2">
					<div class="col-md-12 feature-box1">
						<img src={leaves} class=""/>
						<p><strong> Natural and No colours added.</strong></p>
					</div>
				</div> 
				<div class="col-sm-6 col-md-2">
					<div class="col-md-12 feature-box1">
						<img src={vitamins} class=""/>
						<p><strong>Packed to retain all nutritional Values, Freshness and Aroma.</strong></p>
					</div>
				</div> 
				<div class="col-sm-6 col-md-1">
				</div>
			</div>
		</div>
	</div>
    <hr/>
            <Snackbar open={this.state.showSnackbar} autoHideDuration={2000} onClose={this.handleClose}>
                <Alert onClose={this.handleClose} severity="success">
                    <p style={{color:"white"}}>Item Added to Cart!</p>
                </Alert>
            </Snackbar>
            <Snackbar open={this.state.showSnackbarWarning} autoHideDuration={2000} onClose={this.handleClose}>
                <Alert onClose={this.handleClose} severity="warning">
                    <p style={{color:"white"}}>{this.state.alertText}.</p>
                </Alert>
            </Snackbar>
  <div class="container-fluid">
    <div class="row our-features-box">
      <ul>
        <li>
          <div class="feature-box hideThis">
            <div><FontAwesomeIcon style={{border:"2px #97bc77 dotted",color:"#97bc77",fontSize:"26px",width:"60px",height:"60px",borderRadius:"999px",marginBottom:"26px",padding:"13px"}} icon={dollar}></FontAwesomeIcon></div>
            <div class="content">100% Money Back<br/> Guarantee</div>
          </div>
        </li>
        <li>
          <div class="feature-box">
            <div><FontAwesomeIcon style={{border:"2px #97bc77 dotted",color:"#97bc77",fontSize:"26px",width:"60px",height:"60px",borderRadius:"999px",marginBottom:"26px",padding:"13px"}} icon={truck}></FontAwesomeIcon></div>
            <div class="content">FREE SHIPPING in all taluk headquaters in Karnataka</div>
          </div>
        </li>
        <li>
          <div class="feature-box">
            <div><FontAwesomeIcon style={{border:"2px #97bc77 dotted",color:"#97bc77",fontSize:"26px",width:"60px",height:"60px",borderRadius:"999px",marginBottom:"26px",padding:"13px"}} icon={phone}></FontAwesomeIcon></div>
            <div class="content">Have a question?<br/>
             9945775555 </div>
          </div>
        </li>
        <li class="last">
          <div class="feature-box">
            <div><FontAwesomeIcon style={{border:"2px #97bc77 dotted",color:"#97bc77",fontSize:"26px",width:"60px",height:"60px",borderRadius:"999px",marginBottom:"26px",padding:"13px"}} icon={leaf}></FontAwesomeIcon></div>
            <div class="content">Fresh & Healthy</div>
          </div>
        </li>
        <li>
          <div class="feature-box hideThis">
            <div><FontAwesomeIcon style={{border:"2px #97bc77 dotted",color:"#97bc77",fontSize:"26px",width:"60px",height:"60px",borderRadius:"999px",marginBottom:"26px",padding:"13px"}} icon={briefcase}></FontAwesomeIcon></div>
            <div class="content">30 days return<br/> Service</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <hr/>
  <div class="row1" style={{width:"100%"}}>                    
		<div class="std">
			<div class="wrapper_bl" style={{marginTop: "1px"}}>
				<h4 class="text_align"><center><strong>Enquiry</strong></center></h4>
				<h4 class="text_align"><center>Please call us on <strong> 0091 9945775555 </strong>  or email to <strong>atmanand@chariotagro.com </strong> <br/>for any product enquiry or for any information which you may need from <br/> Chariot Agro Limited.</center></h4>
			</div>
		</div> 
	</div> 
    </div>
    </div>
    </div>
    <FooterPage/>
            </>
        );
    }
}

export default HomeComponent;