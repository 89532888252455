import Modal from 'react-bootstrap/Modal'
import Button from "react-bootstrap/cjs/Button";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import {auth} from "../../lib/firebase.prod";
import Snackbar from "@material-ui/core/Snackbar";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export function LoginModal(props) {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const signInWithEmailAndPasswordHandler =
        (event,email, password) => {
            event.preventDefault();
            auth.signInWithEmailAndPassword(email, password).then(() => {
                let uid = auth.currentUser.uid;
                localStorage.setItem('token', uid);
                props.onHide()
                // alert('Login Success')
                history.push("/browse");
                console.log('success=', uid)
            })
                .catch(error => {
                    setOpen(true)
                    // setTimeout(() => setOpen(false),100);
                    // alert('Please check Email/Password and try again!')
                    setError("Error signing in with password and email!");
                    console.error("Error signing in with password and email", error);
                    console.log('failed signin',error);
            });
        };

    const onChangeHandler = (event) => {
        const {name, value} = event.currentTarget;

        if(name === 'userEmail') {
            setEmail(value);
        }
        else if(name === 'userPassword'){
            setPassword(value);
        }
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h3>Login</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className ="row" style={{alignItems:'center'}}>
                    <div style={{width: "10%"}}></div>
                    <div style={{width: "90%"}}>
                <h5 style={{marginTop:'10px'}}>Email</h5>
                <input style={{width: "80%", padding: "10px", marginBottom: "25px 0", border: "2px solid #ccc",}}
                       type="text" placeholder="Enter your Email" id="autocomplete"
                       name="userEmail"
                       onChange={event => onChangeHandler(event)}
                ></input>
                <h5 style={{marginTop:'10px'}}>Password</h5>
                <input style={{width: "80%", padding: "10px", marginBottom: "25px 0", border: "2px solid #ccc",}}
                       type="password" placeholder="Enter your password" id="autocomplete"
                       name="userPassword"
                       onChange={event => onChangeHandler(event)}
                ></input>
                <button
                    onClick = {(event) => {
                        signInWithEmailAndPasswordHandler(event, email, password)
                        // props.onHide()
                    }}
                    style={{width: "80%", padding: "10px", marginBottom: "25px 0", border: "2px solid #ccc",backgroundColor:'#050b30', color:'white', marginTop:'25px', borderRadius:10, fontWeight:'bold'} }>LOGIN</button>
                        <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity="warning">
                                Please check your Email/Password and try again!
                            </Alert>
                        </Snackbar>
                        <button
                            onClick={props.onSwitch}
                            style={{width: "80%", padding: "10px", marginBottom: "25px 0", border: "0px solid #ccc",backgroundColor:'white', color:'black', marginTop:'25px', borderRadius:10, fontWeight:'bold'} }>I don't have an account</button>
                </div>
                    <div style={{width: "10%"}}></div>
                    {error && <span className="incorrectUsernamePassword">Incorrect username or password</span>}
                </div>
            </Modal.Body>
        </Modal>
    );
}
