import React,{useState,useEffect} from 'react';
import './styles.css';
import {auth,database} from '../../lib/firebase.prod';
import BrowsePageNavigation from '../../components/navigation/browseNavigation'
import FooterPage from "../../components/Footer";
import AddressImage from '../../static/img/address_image.jpg';
import {CheckoutButton} from "../../components/checkout/styles/checkout";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import {useHistory} from "react-router-dom";
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));


export default function Address(){


    let uid = localStorage.getItem('token');
    const [addressLine1,setAddressLine1] = useState("");
    const [addressLine2,setAddressLine2] = useState("");
    const [country,setCountry] = useState("");
    const [state,setState] = useState("");
    const [city,setCity] = useState("");
    const [pincode,setPincode] = useState("");
    const [open,setOpen] = useState("");
    const [landmark,setLandmark] = useState("");



    useEffect(() => {

        var get_user = database.ref("User").child(uid);
        get_user.once("value")
        .then(function(snapshot){
            var user_id = snapshot.child("user_id").val();
            //take any data in similar fashion
            const address = JSON.parse(snapshot.val().user_address);
            console.log("Snapshot.val : ", snapshot.val());
            // console.log("USER ADDRESS : ", address);
            setAddressLine1(address.addressLine1);
            setAddressLine2(address.addressLine2);
            setCountry(address.country);
            setState(address.addressState);
            setCity(address.city);
            setPincode(address.pincode);
            setLandmark(address.landmark);

        });
    }, [])



    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log("City", city);
        // console.log("Address 1", addressLine1);
        // console.log("Address 2", addressLine2);
        // console.log("Country", country);
        // console.log("Pincode", pincode);
        // console.log("State", state);
        // console.log("Landmark", landmark);

        const address = {
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            addressState: state,
            city: localStorage.getItem('location_LongName'),
            country: country,
            customerId: uid,
            landmark: landmark,
            pincode: pincode
        }

        if(uid === null)
        {
            alert("You need to login");
        }
        else
        {
            var data_res = JSON.stringify(address);
            var update_user = database.ref('User');
            var new_update_user = update_user.child(uid);
            new_update_user.update({
                'user_address': data_res,
                'user_lat': 0,
                'user_long': 0
            }).then(() => {
                setOpen(true)
                // alert('Address Updated!')
                //this.props.navigation.goBack();

            });
        }

    }
    console.log("ADDRESXX",localStorage.getItem('location_LongName'))

    return (
        <div style={{alignItems:'center', alignContent:'center', justifyContent:"center"}}>

            <h3 className="page_title"> My Address </h3>

                <div style={{alignSelf:'center'}} className="container-xl card address_container">
                    <img src={AddressImage} className="address_image"/>
                    <form onSubmit={handleSubmit} className="form">
                        <div className="form_container container">
                        <label> Address Line 1 </label>
                        <input type="text" className="large_input" onChange={(e) => {
                            setAddressLine1(e.target.value);
                        }}
                        value={addressLine1}
                        />

                        <label> Address Line 2 </label>
                        <input type="text" className="large_input" onChange={(e) => {
                            setAddressLine2(e.target.value);
                        }} value={addressLine2} />

                        <label>State </label>
                        <input type="text" className="large_input" onChange={(e) => {
                            setState(e.target.value);
                        }} value={state}/>

                        <label> City </label>
                        <input type="text" className="large_input" onChange={(e) => {
                            setCity(e.target.value);
                        }} value={city} />

                        <label> Country </label>
                        <input type="text" className="large_input" onChange={(e) => {
                            setCountry(e.target.value);
                        }}
                            value={country}
                        />

                        <label> Landmark </label>
                        <input type="text" className="large_input" onChange={(e) => {
                            setLandmark(e.target.value);
                        }} value={landmark}/>

                        <label> Pincode </label>
                        <input type="text" className="large_input" onChange={(e) => {
                            setPincode(e.target.value);
                        }} value={pincode}/>

                        <CheckoutButton style={{background:'#050b30'}} type="submit">Update Address</CheckoutButton>
                            <Snackbar open={open} autoHideDuration={2000} onClose={() => setOpen(false)}>
                                <Alert onClose={() => setOpen(false)} severity="success">
                                    Address Updated!
                                </Alert>
                            </Snackbar>
                        </div>
                    </form>

                </div>
                {/* <FooterPage /> */}
            </div>

    );
}
