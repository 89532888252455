import Firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

var firebaseConfig = {
    apiKey: "AIzaSyDhoNLjr8RyNXqXAw-2EObj7qShl5hhM9U",
    authDomain: "chariot-trade-aca9d.firebaseapp.com",
    databaseURL: "https://chariot-trade-aca9d.firebaseio.com",
    projectId: "chariot-trade-aca9d",
    storageBucket: "chariot-trade-aca9d.appspot.com",
    messagingSenderId: "94255404761",
    appId: "1:94255404761:web:da763668d6909955e510c8",
    measurementId: "G-RQF5361ZCS"
  };

  // Initialize Firebase
  const firebase = Firebase.initializeApp(firebaseConfig);
  const database = firebase.database();
  export {database};
  const auth = firebase.auth();
  export {auth}
