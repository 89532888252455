import React from 'react';
import BrowsePageNavigation from '../navigation/browseNavigation'
import FooterPage from '../Footer'
import HomeComponentTop from '../homepage/homeTop';
import aboutUsHeader from '../../static/img/ban_2.jpg';
import {Carousel} from 'react-bootstrap';
import client from '../../static/img/client.png';


class CustomerComponent extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
        totalPrice: 0,
        cartItems: {"wheat":[],"maida":[],"suji":[],"chiroti":[]},
    }
}
  render(){
    return (
      <div>
                <HomeComponentTop cartItems={this.state.cartItems} totalPrice={this.state.totalPrice}/>
        <div className="page_heading" style={{backgroundImage:`url(`+aboutUsHeader+`)`,backgroundRepeat:"no-repeat",color:"#ffb637",textAlign:"center",padding:"60px 0 60px 0"}}>
            <div className="container">
                <div className="row" style={{marginLeft: "0px"}}>
                    <div className="col-xs-12">
                        <div className="page-title">
                            <h2>Happy Customer</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" id="happy_customer" style={{marginTop:"50px",marginBottom:"30px"}}>
          <div class="testimonials-section slider-items-products">
            <div  id="testimonials" class="offer-slider parallax parallax-2">
              <div class="slider-items slider-width-col6">
              <Carousel style={{marginTop:"0px !important"}}>
                <Carousel.Item style={{marginTop:"0px !important"}}>
                  <div class="avatar"><img src={client} alt="Image"/></div>
                  <div class="testimonials">Oh my goodness... You know, this place has very good groceries at reasonable price for all items like Whole Wheat Chakki Atta, Maida, Suji Rava,Chiroti Rava,Dry Fruits And Nuts and so on. I have saved a lot both time and money by shopping through Grocery.</div>
                  <div class="clients_author"> Chandhana S <span>Happy Customer</span> </div>
                </Carousel.Item>
                <Carousel.Item class="item">
                  <div class="avatar"><img src={client} alt="Image"/></div>
                  <div class="testimonials">Prices are very fair. Excellent grocery store. All products are selected and delivered with considerable care.</div>
                  <div class="clients_author">Kavitha S <span>Happy Customer</span> </div>
                </Carousel.Item>
                <Carousel.Item class="item">
                  <div class="avatar"><img src={client} alt="Image"/></div>
                  <div class="testimonials">Impressed by their selection of goods, discount rates and quality of service.</div>
                  <div class="clients_author">Sudeep M <span>Happy Customer</span> </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <div style={{marginLeft:"15vw",marginRight:"15vw",textAlign: "center" ,textJustify: "inter-word", fontSize:"20px"}} className = "c1">
                        <hr/>
                        <h2>Enquiry</h2>
                        <p style={{font:"23px !important"}}>Please call us on <b>0091 9945775555</b> or email to <b>atmanand@chariotagro.com</b><br/>for any product enquiry or for any information which you may need from<br/>Chariot Agro Limited.</p>
                </div>
      <div style={{marginBottom:"-50vw"}} >
                <FooterPage/>
                </div>

      </div>
    )
  }
}

export default CustomerComponent;
