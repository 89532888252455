import React from 'react';
import FooterPage from '../Footer';
import BrowsePageNavigation from '../navigation/browseNavigation';
import HomeComponentTop from '../homepage/homeTop';
import aboutUsHeader from '../../static/img/ban_2.jpg';


class PrivacyPolicyComponent extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            totalPrice: 0,
            cartItems: {"wheat":[],"maida":[],"suji":[],"chiroti":[]},
        }
    }

    render(){

        return (
            <div>
                <HomeComponentTop cartItems={this.state.cartItems} totalPrice={this.state.totalPrice}/>
                <div className="page_heading" style={{backgroundImage:`url(`+aboutUsHeader+`)`,backgroundRepeat:"no-repeat",color:"#ffb637",textAlign:"center",padding:"60px 0 60px 0"}}>
                    <div className="container">
                        <div className="row" style={{marginLeft: "0px"}}>
                            <div className="col-xs-12">
                                <div className="page-title">
                                    <h2>Privacy Policy</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{marginLeft:"15vw",marginRight:"15vw",textAlign: "justify" ,textJustify: "inter-word"}} className = "c1">
                    <br/>
                    <h1> Privacy Policy </h1>
                    <p>Chariot Agro Limited is a Public Limited Company and the website www.chariotagro.com .
                    (” The Site”) is owned by Chariot Agro Limited.</p>
                    <br/>
                    <h3>Request Personal Data:</h3>
                    <p>We request your personal data while filling the signup form.</p>
                    <h3>Email:</h3>
                    <p>I consent to Chariot Agro Limited collect my email so that they can send me requested
                    information. For more information, please read our privacy policy where you will get
                    more information on where, how and why we store your data.</p>
                    <br/>
                    <h3>Personal Information</h3>
                    <p>CAL respects your privacy. This Privacy Policy provides succinctly the manner your data is
                    collected and used by CAL on the Site. As a visitor to the Site/ Customer, you are advised to
                    please read the Privacy Policy carefully. By accessing the services provided by the Site you
                    agree to the collection and use of your data by CAL in the manner provided in this privacy
                    policy.</p>
                    <br/>
                    <h3>Privacy Policy</h3>
                    <p>What information is, or maybe, collected from you? As part of the registration process on
                    the Site, CAL may collect the following personally identifiable information about you. Name
                    including first and last name, alternate email address, mobile phone number and contact
                    details, Postal code, Demographic profile (like your age, gender, occupation, education,
                    address etc.) and information about the pages on the site you visit/access, the links you click
                    on the site, the number of times you access the page and any such browsing information.</p>
                    <br/>
                    <h3>How do we collect the Information?</h3>
                    <p>CAL will collect personally identifiable information about you only as part of a voluntary
                    registration process, on-line survey or any combination thereof. The Site may contain links
                    to other Websites. CAL is not responsible for the privacy practices of such Websites which it
                    does not own, manage, or control. The Site and third-party vendors, including Google, use
                    first-party cookies (such as the Google Analytics cookie) and third-party cookies (such as the
                    DoubleClick cookie) together to inform, optimize, and serve ads based on someone&#39;s past
                    visits to the Site.</p>
                    <br/>
                    <h3>How is the information used?</h3>
                    <p>CAL will use your personal information to provide personalized features to you on the Site
                    and to provide promotional offers to you through the Site and other channels. CAL may
                    also provide this information to its business associates and partners to get in touch with you
                    when necessary to provide the services requested by you. CAL will use this information to
                    preserve transaction history as governed by existing law or policy. CAL may also use contact
                    information internally to direct its efforts for product improvement, to contact you as a
                    survey respondent, to notify you if you win any contest and to send you promotional
                    materials from its contest sponsors or advertisers. CAL will also use this information to serve
                    various promotional and advertising materials to you via display advertisements through the
                    Google Ad network on third party websites. You can opt-out of Google Analytics for Display
                    Advertising and customize Google Display Network ads using the Ads Preferences Manager.
                    Information about Customers on an aggregate (excluding any information that may identify
                    you specifically) covering Customer transaction data and Customer demographic and
                    location data may be provided to partners of CAL for the purpose of creating additional
                    features on the website, creating appropriate merchandising or creating new products and
                    services and conducting marketing research and statistical analysis of customer behaviour
                    and transactions.</p>
                    <br/>
                    <h3>​With whom your information will be shared</h3>
                    <p>CAL will not use your financial information for any purpose other than to complete a
                    transaction with you. CAL does not rent or sell your personal information and will not
                    disclose any of your personally identifiable information to third parties. In cases where it has
                    your permission to provide products or services, you have requested, and such information
                    is necessary to provide these products or services with the information may be shared with
                    CAL business associates and partners. CAL may, however, share consumer information on an
                    aggregate with its partners or third parties where it deems necessary. In addition, CAL may
                    use this information for promotional offers, to help investigate, prevent or take action
                    regarding unlawful and illegal activities, suspected fraud, potential threat to the safety or
                    security of any person, violations of the Site’s terms of use or to defend against legal claims;
                    special circumstances such as compliance with subpoenas, court orders, requests/order
                    from legal authorities or law enforcement agencies requiring such disclosure.</p>
                    <br/>
                    <h3>​What choices are available regarding the collection, use and distribution of your information?</h3>
                    <p>To protect against the loss, misuse, and alteration of the information under its control, CAL
                    has in place appropriate physical, electronic and managerial procedures. For example, CAL
                    servers are accessible only to authorized personnel and your information is shared with
                    employees and authorised personnel on a need-to-know basis to complete the transaction
                    and to provide the services requested by you. Although CAL will endeavour to safeguard the
                    confidentiality of your personally identifiable information, transmissions made by means of
                    the Internet cannot be made absolutely secure. By using this site, you agree that CAL will

                    have no liability for disclosure of your information due to errors in transmission or
                    unauthorized acts of third parties.</p>
                    <br/>
                    <h3>​How can you correct inaccuracies in the information?</h3>
                    <p>To correct or update any information you have provided, the Site allows you to do it online.
                    In the event of loss of access details, you can send an email to us.</p>
                    <br/>
                    <h3>​Policy updates</h3>
                    <p>CAL reserves the right to change or update this privacy policy at any time. Such changes
                    shall be effective immediately upon posting to the Site.</p>
                    <br/>
                </div>
                <div style={{marginLeft:"15vw",marginRight:"15vw",textAlign: "center" ,textJustify: "inter-word", fontSize:"20px"}} className = "c1">
                        <hr/>
                        <h2>Enquiry</h2>
                        <p style={{font:"23px !important"}}>Please call us on <b>0091 9945775555</b> or email to <b>atmanand@chariotagro.com</b><br/>for any product enquiry or for any information which you may need from<br/>Chariot Agro Limited.</p>
                </div>
                <FooterPage/>
            </div>  
        );
    }
}

export default PrivacyPolicyComponent;