import React, {useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import './homeComponentTop.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import newlogo from '../../static/img/logo/newlogo.png';
import {Form,Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {faAlignJustify as arrows, faShoppingBasket as basket,faSearch as search } from '@fortawesome/free-solid-svg-icons';
import { fade , makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';  
import ListItem from '@material-ui/core/ListItem';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { mainListItems } from './listItems';
import Badge from '@material-ui/core/Badge';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {MDBBtn} from "mdbreact";
import {faTrash as trash} from "@fortawesome/free-solid-svg-icons";
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import wheat from '../../static/img/wheat.png';
import chiroti from '../../static/img/chiroti.png';
import suji_rava from '../../static/img/suji_rava.png';
import maida from '../../static/img/maida.png';
import afgan from '../../static/img/afghanraisinsfront.jpg';
import indian from '../../static/img/indianraisinsfront.jpg';
import safawi from '../../static/img/safawifront.jpg';
import mabroom from '../../static/img/mabroomfront.jpg';
import almond from '../../static/img/almond1.jpg';
import cashew from '../../static/img/cashew1.jpg';
import pista from '../../static/img/pista1.jpg';

import EmptyCart from "../../static/img/empty-cart.png";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import {auth, database} from "../../lib/firebase.prod";
import {useHistory} from "react-router-dom";
import useLocalStorage from 'react-use-localstorage';
import PubSub from 'pubsub-js';
import { Hidden } from '@material-ui/core';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const textArray = ['Fresh Dry Fruits?', 'The highes`t quality?', 'Fast Delivery?',];

  function HomeTop(props){
    const drawerWidth = 200;  

      const useStyles = makeStyles((theme) => ({
        form: {
          display: 'flex',
          flexDirection: 'column',
          margin: 'auto',
          width: 'fit-content',
        },
        formControl: {
          marginTop: theme.spacing(2),
          minWidth: 120,
        },
        formControlLabel: {
          marginTop: theme.spacing(1),
        },
        list: {
          width: 250,
        },
        fullList: {
          width: 'auto',
        },
        list: {
          width: 250,
        },
        fullList: {
          width: 'auto',
        },

        paper: {
          padding: theme.spacing(2),
          display: 'flex',
          overflow: 'auto',
          flexDirection: 'column',
        },
        fixedHeight: {
          height: 240,
        },
        drawerPaper: {
          position: 'relative',
          whiteSpace: 'nowrap',
          width: drawerWidth,
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
        drawerPaperClose: {
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: theme.spacing(7),
          [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
          },
        },
        toolbarIcon:{
          display:'flex',
          flexDirection:'row',
        },
        search: {
          display:'flex',
          flexDirection:'row',
          position: 'relative',
          borderRadius: theme.shape.borderRadius,
          backgroundColor: fade(theme.palette.common.white, 0.15),
          '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
          },
          marginRight: theme.spacing(2),
          marginLeft: 0,
          width: '100%',
          [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
          },
        },
        searchIcon: {
          padding: theme.spacing(0, 1),
          height: '100%',
          position: 'absolute',
          pointerEvents: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        inputRoot: {
          color: 'inherit',
        },
        inputInput: {
          padding: theme.spacing(1, 1, 1, 0),
          paddingLeft:"5px",
          transition: theme.transitions.create('width'),
          width: '100%',
          [theme.breakpoints.up('md')]: {
            width: '10ch',
          },
        },
        sectionDesktop: {
          display: 'none',
          [theme.breakpoints.up('md')]: {
            display: 'flex',
          },
        },
        sectionMobile: {
          display: 'flex',
          [theme.breakpoints.up('md')]: {
            display: 'none',
          },
        },

        basketdropdown:{
          backgroundColor:'#5B9D4F',
          color:'white',
          width:'500px',
          padding:'10px',
          fontFamily:' Oswald, "sans serif"',
  
        },
        
        basketmenu:{
        '& .MuiListItem-root': {
          padding:'0px',
          disableRipple:true
        },
          '&, .MuiTouchRipple-ripple': {
           disableRipple:true
          },
          '& , .MuiList-padding':{
            padding:'0px'
          },
          width:'400px',

        },
        basketmenuitemripple:{

        },
        basketdropdownimage:{
          width:'50%'
        },
        // testing:{
        //   overflow:'hidden'
        // },
        basketdropdowntitle:{
          width:'40%',
          margin:'20px',
          textAlign:'start',
          margin:'20px',
          marginTop:'20px',
          fontSize:"14px",
          fontFamily:' Oswald, "sans serif"',
          letterSpacing:'1px'

        },
        basketdropdowndelete:{
          width:'15%',
          margin:'20px',
          margin:'20px',
          marginTop:'20px',
          fontSize:"16px",
          textAlign:'center'

        },
        basketdropdownitemimage:{
          width:'100%',
          height:'80px',
          margin:'5px',
          objectFit:'cover',
         
        },
      basketdropdownprice:{
        fontSize:'12px',
        fontFamily:' serif, "sans serif"',
        letterSpacing:'1px'
      },
      basketdropdownsubtotalfooter:{
        padding:'10px',
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        backgroundColor:'#efefef',
        width:'100%'
      },
      basketdropdownsubtotal:{
       fontSize:'10px',
       paddingTop:5,
       fontFamily:' Oswald, "sans serif"',
       letterSpacing:'1px'
      },
      basketdropdownsubtotalprice:{
        fontSize:'15px',
        marginLeft:'10px',
        paddingTop:4,
        fontFamily:' Oswald, "sans serif"',
        letterSpacing:'1px'
      },
      basketdropdownbutton:{
        padding:'8px',
        backgroundColor:'#5B9D4F',
        marginLeft:'10px',
        color:'#fff',
        letterSpacing:'0.5px',
        borderRadius:'3px',
        fontSize:'12px',
        fontWeight:'600',
        fontFamily:' Oswald, "sans serif"',
        letterSpacing:'1px',
        cursor:'pointer',
        textDecoration:'none'
      },
      counter:{
        fontSize:'12px',
        fontFamily:' Oswald, "sans serif"',
        letterSpacing:'1px'
      },
      mobilecounter:{
        fontSize:'12px',
        fontFamily:' Oswald, "sans serif"',
        marginTop:'10px',
        letterSpacing:'1px'
      },
      badge: {
        fontSize: 10,
        fontWeight:'600'
      },
      dialog:{
      '& , .MuiDialog-paperWidthSm':{
        [theme.breakpoints.up('sm')]: {
          minWidth:'450px',
        },
      },
      },
      cartappBar: {
        position: 'relative',
        width:'100%',
        backgroundColor:'#ffb637'
      },
      carttitle: {
        marginLeft: theme.spacing(2),
        flex: 1,
        fontSize:'20px',
        fontFamily:' Oswald, "sans serif"',
        fonrWeight:'600'
      },
      bottomsubtotal:{
        position:'fixed',
        bottom:'0',
        width:'100%'
      },
      mycartsubtotal:{
        fontSize:'12px',
        padding:'10px',
        fontWeight:'600',
        fontFamily:' Rubik, "sans serif"',
        letterSpacing:'2px'
      },
      mycartsubtotalprice:{
        fontSize:'16px',
        padding:'10px',
        fontWeight:'600',
        fontFamily:' Oswald, "sans serif"',
        letterSpacing:'2px'
      },
      }));

      
      const styles = (theme) => ({
        root: {
          margin: 0,
          padding: theme.spacing(2),
          minWidth:'600px',
          backgroundColor:'blue'
        
        },
        closeButton: {
          position: 'absolute',
          right: theme.spacing(1),
          top: 0,
          color: 'white',
          marginTop:'5px',

        },
        dialogname:{
          fontSize:'20px',
          textAlign:'center',
          paddingTop:'10px',
          paddingBottom:'10px',
          backgroundColor:'#ffb637',
          color:'white',
          fontWeight:'600',
          letterSpacing:'1px',
          fontFamily:' Oswald, "sans serif"'
        },

      });
      
      const classes = useStyles();

      const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
          <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" className={classes.dialogname}>{children}</Typography>
            {onClose ? (
              <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon style={{fontSize:20}} />
              </IconButton>
            ) : null}
          </MuiDialogTitle>
        );
      });
      
      const DialogContent = withStyles((theme) => ({
        root: {
          padding: theme.spacing(2),
        },
      }))(MuiDialogContent);
      
      const DialogActions = withStyles((theme) => ({
        root: {
          margin: 0,
          padding: theme.spacing(1),
        },
      }))(MuiDialogActions);

      const [anchorEl, setAnchorEl] = React.useState(null);

      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleClose = () => {
        setAnchorEl(null);
      };


      const [open, setOpen] = React.useState(false);
      const [openSignin, setOpenSignin] = React.useState(false);
      const [openSignup, setOpenSignup] = React.useState(false);
      const [openForgetPassword, setOpenForgetPassword] = React.useState(false);
      const [opencCart, setOpenCart] = React.useState(false);
      const [cartItems, setCartItems] = React.useState([]);
      var [cartItemImages, setCartItemImages] = React.useState([]);
      var [totalPrice,setTotalPrice] = React.useState(0);
      const [showSnackbar,setShowSnackbar] = React.useState(false);
      const [snackbarSeverity,setSnackbarSeverity] = React.useState("");
      const [snackbarMessage,setSnackbarMessage] = React.useState("");
      const [mobileNumber,setMobileNumber] = React.useState("+91");
      const [testing,setTesting] = useLocalStorage("totalPrice");
      const [totalQuantity,setTotalQuantity] = React.useState(0);
      const [fullName,setFullName] = React.useState("");


      const history = useHistory();

      const handleDrawerOpen = () => {
        setOpen(!open);
      };

      const handleDrawerClose = () => {
       setOpen(false);
       console.log(open)
      };

      const handleSignin = () => {
        setOpenSignin(true);
      };
      const handleSignUp = () => {
        setOpenSignup(true);
      };

      const handleNewAccount = () => {
        setOpenSignin(false);
        setOpenSignup(true);
      }

      const handleForgetPasswordopen =() => {
        setOpenForgetPassword(true);
        setOpenSignin(false);
      }
      const handlealreadyaccount =() =>{
        setOpenSignin(true);
        setOpenSignup(false);
      }
      const handleClickOpenCart = () => {
        setOpenCart(true);
      };
    
      const handleCloseCart = () => {
        setOpenCart(false);
      };


      const quantityChange = (type,itemIndex,itemType,weight,multiple) => {
        var localCartItems = JSON.parse(localStorage.getItem("cart"));
        var quan = totalQuantity;
        if(type=="plus"){
          setTotalQuantity(parseInt(quan)+multiple);
          cartItems[itemIndex].quantity=parseInt(cartItems[itemIndex].quantity)+multiple;
          totalPrice=(parseFloat(totalPrice)+multiple*parseFloat(cartItems[itemIndex].price)).toFixed(1);
          if(itemType=="suji_rava"){
            localCartItems["suji"].map((item,index) => {
              if(item["weight"]==weight){
                localCartItems["suji"][index].quantity=parseInt(localCartItems["suji"][index].quantity)+multiple;
                localStorage.setItem("cart",JSON.stringify(localCartItems));
                localStorage.setItem("totalPrice",JSON.stringify(totalPrice))
              }
            })
          }else{
            localCartItems[itemType].map((item,index) => {
              if(item["weight"]==weight){
                localCartItems[itemType][index].quantity=parseInt(localCartItems[itemType][index].quantity)+multiple;
                localStorage.setItem("cart",JSON.stringify(localCartItems));
                localStorage.setItem("totalPrice",JSON.stringify(totalPrice))
              }
            });  
          }
          setCartItems(cartItems);
          setTotalPrice(totalPrice);
        }else if(type=="minus"){
          if(cartItems[itemIndex].quantity>multiple){
            setTotalQuantity(parseInt(quan)-multiple);
            cartItems[itemIndex].quantity=parseInt(cartItems[itemIndex].quantity)-multiple;
            totalPrice-=multiple*cartItems[itemIndex].price;
            localStorage.setItem("totalPrice",JSON.stringify(totalPrice))  
            setCartItems(cartItems);
            setTotalPrice(totalPrice.toFixed(1));  
            if(itemType=="suji_rava"){
              localCartItems["suji"].map((item,index) => {
                if(item["weight"]==weight){
                  localCartItems["suji"][index].quantity=parseInt(localCartItems["suji"][index].quantity)-multiple;
                  localStorage.setItem("cart",JSON.stringify(localCartItems));
                  localStorage.setItem("totalPrice",JSON.stringify(totalPrice))
                }
              })
            }else{
              localCartItems[itemType].map((item,index) => {
                if(item["weight"]==weight){
                  localCartItems[itemType][index].quantity=parseInt(localCartItems[itemType][index].quantity)-multiple;
                  localStorage.setItem("cart",JSON.stringify(localCartItems));
                  localStorage.setItem("totalPrice",JSON.stringify(totalPrice))
                }
              });  
            }
          }
        }
        PubSub.publish('quantityChange', 'hello world!');

      }

      const deleteItem = (arrayIndex,itemType,weight,quantity,price) => {
        PubSub.publish('MY TOPIC', 'hello world!');
        var quan = totalQuantity;
        setTotalQuantity(quan-quantity);
        var localCartItems = JSON.parse(localStorage.getItem("cart"));
        if(itemType=="suji_rava"){
          localCartItems["suji"].map((item,index) => {
            if(item["weight"]==weight){
              localCartItems["suji"].splice(index,1);
              localStorage.setItem("cart",JSON.stringify(localCartItems));
            }
          })
        }else{
          localCartItems[itemType].map((item,index) => {
            if(item["weight"]==weight){
              localCartItems[itemType].splice(index,1);
              localStorage.setItem("cart",JSON.stringify(localCartItems));
            }
          });  
        }
        var currentPrice = JSON.parse(localStorage.getItem("totalPrice"))
        var deletePrice = parseFloat(quantity*price).toFixed(1)
        localStorage.setItem("totalPrice",JSON.stringify(currentPrice - deletePrice))
        setTotalPrice(currentPrice- deletePrice)
        var copyCartItems = JSON.parse(JSON.stringify(cartItems))
        copyCartItems.splice(arrayIndex,1);
        setCartItems(copyCartItems);
      }


      const signInWithEmailAndPasswordHandler = (event) => {
          event.preventDefault();
          var myemail = document.getElementById("myemail").value;
          var mypassword = document.getElementById("mypassword").value;
          var addedEmail = false;
          if( myemail == "" || mypassword==""){
            setShowSnackbar(true);
            setSnackbarSeverity("error");
            setSnackbarMessage("Please enter login credentials");
          }else{
            if(myemail.includes("@gmail.com") || myemail.includes("@chariotagro.com" || myemail.includes("@yahoo.com") || myemail.includes("@rediff.com") || myemail.includes("@hotmail.com") || myemail.includes("@outlook.com"))){
            }else{
              addedEmail=true;
              myemail = myemail+"@gmail.com";
            }
            auth.signInWithEmailAndPassword(myemail, mypassword).then(() => {
              if(addedEmail==true){
                var t = JSON.parse((localStorage.getItem('cart')));
                for(var key in t){
                  t[key] = [];
                }
                localStorage.setItem("cart",JSON.stringify(t));
              }
              let uid = auth.currentUser.uid;
              localStorage.setItem('token', uid);
              if(localStorage.getItem("token")!=null){
                var get_user = database.ref("User").child(uid);
                get_user.once("value")
                .then(function(snapshot){
                    setFullName(snapshot.val().user_name.split(" ")[0]);
                })
              }
              var check_user = database.ref("Broker");
              check_user.once('value', (snapshot) => {
                  var b = snapshot.child(uid).exists();
                  if(b == true){
                    localStorage.setItem("user","broker");
                    var localcartItems = JSON.parse(localStorage.getItem("cart"));
                    var total = 0;
                    for(var key in localcartItems){
                      localcartItems[key].map(item => {
                        var x = item['inactivePrice'];
                        item['inactivePrice'] = item['price'];
                        item['price'] = x;
                        total += item['quantity']*item['price']
                      })
                    }
                    localStorage.setItem("totalPrice",total.toFixed(2));
                    localStorage.setItem('cart',JSON.stringify(localcartItems)); 
                    loadAll();
                    PubSub.publish('checkUser', 'broker');
                  }else{
                    localStorage.setItem("user","customer");
                    PubSub.publish('checkUser', 'customer');
                  }
              });
              setShowSnackbar(true);
              setSnackbarSeverity("success");
              setSnackbarMessage("Login Successful");  
              setOpenSignin(false);
            })
              .catch(error => {
                setShowSnackbar(true);
                setSnackbarSeverity("error");
                setSnackbarMessage("Please enter proper credentials");
                console.error("Error signing in with password and email", error);
                console.log('failed signin')
            });
          }
      };

      const createUserWithEmailAndPassword = () => {
        var fullName = document.getElementById("signupfullname").value;
        var signUpEmail = document.getElementById("signupemail").value;
        var signUpNumber = document.getElementById("signupnumber").value;
        var signUpPassword = document.getElementById("signuppassword").value;
        var signUpConfirmPassword = document.getElementById("signupconfirmpassword").value;
        if(fullName == "" || signUpEmail == "" || signUpNumber=="" || signUpPassword == "" || signUpConfirmPassword == ""){
          setShowSnackbar(true);
          setSnackbarSeverity("error");
          setSnackbarMessage("Please enter all details.");
        }else if(signUpPassword !== signUpConfirmPassword){
          setShowSnackbar(true);
          setSnackbarSeverity("error");
          setSnackbarMessage("Passwords are not matching . Please enter again.");
        }else{
          auth.createUserWithEmailAndPassword(signUpEmail, signUpPassword)
            .then(() => {
                let uid = auth.currentUser.uid
                localStorage.setItem('token', uid);
                if(localStorage.getItem("token")!=null){
                  var get_user = database.ref("User").child(uid);
                  get_user.once("value")
                  .then(function(snapshot){
                      setFullName(snapshot.val().user_name.split(" ")[0]);
                  })
      
                }    
                var create_user = database.ref('User');
                var new_user = create_user.child(uid);
                new_user.set({
                    'user_id': uid,
                    'user_name': fullName,
                    'user_phone': signUpNumber,
                    'user_email': signUpEmail,
                    'token': '',
                    'user_address': '{}',
                    'user_lat': '',
                    'user_long': '',
                }).then(() => {
                  setShowSnackbar(true);
                  setSnackbarSeverity("success");
                  setSnackbarMessage("Sign Up Successful");
                  setOpenSignup(false)        
                });
            })
            .catch(error => {
                console.log('error',error);
                setShowSnackbar(true);
                setSnackbarSeverity("error");
                setSnackbarMessage("Something went wrong please try again.");      
            });
        }
      }

      const handleCheckout = () => {
        if(localStorage.getItem("token")==null){
          setOpenCart(false);
          setOpenSignin(true);
        }else{
          history.push("/checkout");
        }
      }

      const resetPassword = () => {
        var resetEmail = document.getElementById("resetEmail").value;
        
        if(resetEmail==""){
          setShowSnackbar(true);
          setSnackbarSeverity("error");
          setSnackbarMessage("Please enter an email");
        }else{
          auth.sendPasswordResetEmail(resetEmail).then(() => {
            setShowSnackbar(true);
            setSnackbarSeverity("success");
            setSnackbarMessage("Reset password email sent");
            setOpenForgetPassword(false);
          })  
        }
      }

      const handleCloseSnackbar = () => {
        setShowSnackbar(false);
      }
      
      const handleLogOut = () => {
        localStorage.removeItem("token");
        localStorage.setItem("user","customer");
        setShowSnackbar(true);
        setSnackbarSeverity("success");
        setSnackbarMessage("Logout Successful");
        var localcartItems = JSON.parse(localStorage.getItem("cart"));
        var total = 0;
        for(var key in localcartItems){
          localcartItems[key].map(item => {
            var x = item['inactivePrice'];
            item['inactivePrice'] = item['price'];
            item['price'] = x;
            total += item['quantity']*item['price']
          })
        }
        localStorage.setItem("totalPrice",total.toFixed(2));
        localStorage.setItem('cart',JSON.stringify(localcartItems)); 
        loadAll();

        PubSub.publish('checkUser', 'hello world!');
      }

      const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

      const theme = useTheme();
      const fullScreen = useMediaQuery(theme.breakpoints.down('sm')); 
      
      
      const loadAll = () => {
        if(JSON.parse(localStorage.getItem("cart"))!=null){
          var quan = 0;
          var cartItems = JSON.parse(localStorage.getItem("cart"));
          if(!('wheat' in cartItems)){
            cartItems.wheat = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
          }
          if(!('rasam' in cartItems)){
            cartItems.rasam = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
          }    
          if(!('sambar' in cartItems)){
            cartItems.sambar = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
          }    
          if(!('garam' in cartItems)){
            cartItems.garam = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
          }    
          if(!('pulav' in cartItems)){
            cartItems.pulav = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
          } 
          if(!('fish' in cartItems)){
            cartItems.fish = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
          }   
          if(!('biryani' in cartItems)){
            cartItems.biryani = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
          }    
          if(!('chicken' in cartItems)){
            cartItems.chicken = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
          }    
          if(!('kabab' in cartItems)){
            cartItems.kabab = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
          }   
          if(!('chilli' in cartItems)){
            cartItems.chilli = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
          } 
          if(!('turmeric' in cartItems)){
            cartItems.turmeric = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
          }
          if(!('coriander' in cartItems)){
            cartItems.coriander = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
          }
          if(!('jeera' in cartItems)){
            cartItems.jeera = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
          } 
          if(!('pepper' in cartItems)){
            cartItems.pepper = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
          }    
          if(!('maida' in cartItems)){
            cartItems.maida = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));

          }
          if(!('suji' in cartItems)){
            cartItems.suji = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));

          }
          if(!('chiroti' in cartItems)){
            cartItems.chiroti = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));

          }
          if(!('afgan' in cartItems)){
            cartItems.afgan = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));

          }
          if(!('indian' in cartItems)){
            cartItems.indian = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));

          }
          if(!('safawi' in cartItems)){
            cartItems.safawi = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));

          }
          if(!('mabroom' in cartItems)){
            cartItems.mabroom = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));

          }
          if(!('almond' in cartItems)){
            cartItems.almond = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));

          }
          if(!('cashew' in cartItems)){
            cartItems.cashew = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));

          }
          if(!('pista' in cartItems)){
            cartItems.pista = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));

          }
          if(!('hing' in cartItems)){
            cartItems.hing = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
    
          }
          if(!('vapor' in cartItems)){
            cartItems.vapor = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
    
          }if(!('oxymeter' in cartItems)){
            cartItems.oxymeter = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
    
          }if(!('scale1' in cartItems)){
            cartItems.scale1 = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
    
          }if(!('scale2' in cartItems)){
            cartItems.scale2 = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
    
          }if(!('respiratory' in cartItems)){
            cartItems.respiratory = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
    
          }if(!('bodyfat' in cartItems)){
            cartItems.bodyfat = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
    
          }if(!('eb600' in cartItems)){
            cartItems.eb600 = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
    
          }if(!('eb500' in cartItems)){
            cartItems.eb500 = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
    
          }if(!('st309' in cartItems)){
            cartItems.st309 = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
    
          }if(!('eb200' in cartItems)){
            cartItems.eb200 = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
    
          }if(!('pf120a' in cartItems)){
            cartItems.pf120a = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
    
          }if(!('eb100' in cartItems)){
            cartItems.eb100 = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
    
          }if(!('ic900' in cartItems)){
            cartItems.ic900 = [];
            localStorage.setItem("cart",JSON.stringify(cartItems));
    
          }
    
    
          var cartArray = [];
          cartArray = cartArray.concat(cartItems['wheat']);
          cartArray = cartArray.concat(cartItems['maida']);
          cartArray = cartArray.concat(cartItems['suji']);
          cartArray = cartArray.concat(cartItems['chiroti']);
          cartArray = cartArray.concat(cartItems['afgan']);
          cartArray = cartArray.concat(cartItems['indian']);
          cartArray = cartArray.concat(cartItems['mabroom']);
          cartArray = cartArray.concat(cartItems['safawi']);
          cartArray = cartArray.concat(cartItems['almond']);
          cartArray = cartArray.concat(cartItems['cashew']);
          cartArray = cartArray.concat(cartItems['pista']);
          cartArray = cartArray.concat(cartItems['hing']);
          cartArray = cartArray.concat(cartItems['vapor']);
          cartArray = cartArray.concat(cartItems['oxymeter']);
          cartArray = cartArray.concat(cartItems['scale1']);
          cartArray = cartArray.concat(cartItems['scale2']);
          cartArray = cartArray.concat(cartItems['respiratory']);
          cartArray = cartArray.concat(cartItems['bodyfat']);
          cartArray = cartArray.concat(cartItems['eb600']);
          cartArray = cartArray.concat(cartItems['eb500']);
          cartArray = cartArray.concat(cartItems['st309']);
          cartArray = cartArray.concat(cartItems['eb200']);
          cartArray = cartArray.concat(cartItems['pf120a']);
          cartArray = cartArray.concat(cartItems['eb100']);
          cartArray = cartArray.concat(cartItems['ic900']);
          cartArray = cartArray.concat(cartItems['rasam']);          
          cartArray = cartArray.concat(cartItems['sambar']);
          cartArray = cartArray.concat(cartItems['garam']);
          cartArray = cartArray.concat(cartItems['pulav']);
          cartArray = cartArray.concat(cartItems['fish']);
          cartArray = cartArray.concat(cartItems['biryani']);
          cartArray = cartArray.concat(cartItems['chicken']);
          cartArray = cartArray.concat(cartItems['kabab']);
          cartArray = cartArray.concat(cartItems['chilli']);
          cartArray = cartArray.concat(cartItems['turmeric']);
          cartArray = cartArray.concat(cartItems['coriander']);
          cartArray = cartArray.concat(cartItems['jeera']);
          cartArray = cartArray.concat(cartItems['pepper']);

          setCartItems(cartArray);
          var images = [];
          if(localStorage.getItem('totalPrice')==null){
            setTotalPrice(props.totalPrice);
          }else{
            setTotalPrice(JSON.parse(localStorage.getItem("totalPrice")))
          }
          cartArray.map(item => {
            quan+=parseInt(item.quantity);
            if(item.type == "wheat"){
              images.push(wheat)
            }else if(item.type == "maida"){
              images.push(maida)
            }else if(item.type == "chiroti"){
              images.push(chiroti)
            }else if(item.type == "afgan"){
              images.push(afgan)
            }else if(item.type == "indian"){
              images.push(indian)
            }else if(item.type == "mabroom"){
              images.push(mabroom)
            }else if(item.type == "safawi"){
              images.push(safawi);
            }else if(item.type == "almond"){
              images.push(almond);
            }else if(item.type == "pista"){
              images.push(pista);
            }else if(item.type == "cashew"){
              images.push(cashew);
            }

            else if(item.type == "vapor"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/newNikVaporizer.png?alt=media&token=cff0e0e0-ef8b-40de-bcd1-bdc887acfb04");
            }else if(item.type == "oxymeter"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/oxymeter.png?alt=media&token=2d9527ab-fc78-4f71-8069-dd05f4d278a4");
            }else if(item.type == "scale1"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/pse101.png?alt=media&token=3bca7b96-e0ba-42ec-9261-a8151aa9245b");
            }else if(item.type == "scale2"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/pse2011.png?alt=media&token=53567ae9-6142-4f88-b6a3-8364efaeec90");
            }else if(item.type == "respiratory"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/respiratory1.png?alt=media&token=6e031a24-490e-4c32-9779-1e7550c6b569");
            }else if(item.type == "bodyfat"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/wf2601.png?alt=media&token=c846b6bb-a886-4b66-ba14-3a59082ee55c");
            }else if(item.type == "eb600"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb6001.png?alt=media&token=b0e02464-0fb5-412a-8953-7e16e1a1d4c5");
            }else if(item.type == "eb500"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb5001.png?alt=media&token=2cbecd7b-bf8a-41d1-ade8-94d7bbc1dd79");
            }else if(item.type == "st309"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/stethescope1.png?alt=media&token=19f78807-a1dd-410c-ad92-478bb819957f");
            }else if(item.type == "eb200"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb2001.png?alt=media&token=598cf0bc-8461-423b-ae30-c2b527276f8a");
            }else if(item.type == "pf120a"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/pf120A1.png?alt=media&token=13638d0c-0a24-41e5-bec7-18dd970cdb5d");
            }else if(item.type == "eb100"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb100.png?alt=media&token=5ff832f2-29c9-4e48-a1eb-9b72f31829c4");
            }else if(item.type == "ic900"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/coolPack1.png?alt=media&token=9fa36616-6cf6-41d8-8035-006332bd2f6d");
            }else if(item.type=="hing"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Hing-1.jpg?alt=media&token=3ec12f0d-7044-4ae0-9e1d-14f1ab0db888");
            }else if(item.type=="rasam"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Rasam-Powder.jpg?alt=media&token=ae9d8ede-74eb-46a1-a78d-69d05955dbe7");
            }else if(item.type=="sambar"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Sambar-Powder.jpg?alt=media&token=9075867c-35bd-4c7f-af4c-709a79467a9f");
            }else if(item.type=="garam"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Garam-Masala.jpg?alt=media&token=da95e516-c346-4bee-a221-89479c4653c4");
            }else if(item.type=="pulav"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Veg-Pulav-Masala.jpg?alt=media&token=7fb7aaca-4401-4ad5-9104-6578b59d9002");
            }else if(item.type=="fish"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Fish-Fry-Masala.jpg?alt=media&token=eb3659fc-f792-4c40-bd1e-c8082630a4ab");
            }else if(item.type=="biryani"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Chicken-Biryani.jpg?alt=media&token=d0706d57-2575-4883-9f45-45bb0f5f874f");
            }else if(item.type=="chicken"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Chicken-Masala.jpg?alt=media&token=8e4f1cbc-c2d4-43c6-b07e-b76b84656ae9");
            }else if(item.type=="kabab"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Chicken-Kabab.jpg?alt=media&token=5b334f71-8f03-41c0-aebc-a5a6f540b434");
            }else if(item.type=="chilli"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Red-Chilli-Powder.jpg?alt=media&token=51f4c5b1-df6b-451e-9f3e-f6f47c7334e9");
            }else if(item.type=="turmeric"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Turmeric-Powder.jpg?alt=media&token=8ea5a9ba-83a3-4c7d-869f-8776b94bb279");
            }else if(item.type=="coriander"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Coriander-Powder.jpg?alt=media&token=faab4eba-6929-450f-9ac1-276f68645ac7");
            }else if(item.type=="jeera"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Jeera-Powder.jpg?alt=media&token=3211b88c-9efa-446c-86e7-0ba9c261834c");
            }else if(item.type=="pepper"){
              images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Pepper-Powder.jpg?alt=media&token=1cfc6de3-2ce7-41be-bb30-ebaf71f11d70");
            }
            else{
              images.push(suji_rava)
            }
          })
          setTotalQuantity(quan);
          setCartItemImages(images);
          if(localStorage.getItem("token")!=null){
            var uid = localStorage.getItem("token");
            var get_user = database.ref("User").child(uid);
            get_user.once("value")
            .then(function(snapshot){
                setFullName(snapshot.val().user_name.split(" ")[0]);
            })
          }
        }else{
          setCartItems([]);
        }
      }

      useEffect(() => {
        if(props.cartItems == undefined){
          setCartItems([])
        }else{
          if(JSON.parse(localStorage.getItem("cart"))!=null){
            var quan = 0;
            var cartItems = JSON.parse(localStorage.getItem("cart"));
            if(!('wheat' in cartItems)){
              cartItems.wheat = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            }
            if(!('maida' in cartItems)){
              cartItems.maida = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));

            }
            if(!('rasam' in cartItems)){
              cartItems.rasam = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            }   
            if(!('sambar' in cartItems)){
              cartItems.sambar = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            }   
            if(!('garam' in cartItems)){
              cartItems.garam = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            }        
            if(!('pulav' in cartItems)){
              cartItems.pulav = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            }     
            if(!('fish' in cartItems)){
              cartItems.fish = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            }   
            if(!('chicken' in cartItems)){
              cartItems.chicken = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            }      
            if(!('biryani' in cartItems)){
              cartItems.biryani = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            }
            if(!('kabab' in cartItems)){
              cartItems.kabab = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            }  
            if(!('chilli' in cartItems)){
              cartItems.chilli = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            }   
            if(!('turmeric' in cartItems)){
              cartItems.turmeric = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            }  
            if(!('coriander' in cartItems)){
              cartItems.coriander = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            }   
            if(!('jeera' in cartItems)){
              cartItems.jeera = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            }
            if(!('pepper' in cartItems)){
              cartItems.pepper = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            }       
            if(!('suji' in cartItems)){
              cartItems.suji = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));

            }
            if(!('chiroti' in cartItems)){
              cartItems.chiroti = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));

            }
            if(!('afgan' in cartItems)){
              cartItems.afgan = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));

            }
            if(!('indian' in cartItems)){
              cartItems.indian = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));

            }
            if(!('safawi' in cartItems)){
              cartItems.safawi = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));

            }
            if(!('mabroom' in cartItems)){
              cartItems.mabroom = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));

            }
            if(!('almond' in cartItems)){
              cartItems.almond = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));

            }
            if(!('cashew' in cartItems)){
              cartItems.cashew = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));

            }
            if(!('pista' in cartItems)){
              cartItems.pista = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));

            }
            if(!('hing' in cartItems)){
              cartItems.hing = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }
            if(!('vapor' in cartItems)){
              cartItems.vapor = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }if(!('oxymeter' in cartItems)){
              cartItems.oxymeter = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }if(!('scale1' in cartItems)){
              cartItems.scale1 = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }if(!('scale2' in cartItems)){
              cartItems.scale2 = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }if(!('respiratory' in cartItems)){
              cartItems.respiratory = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }if(!('bodyfat' in cartItems)){
              cartItems.bodyfat = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }if(!('eb600' in cartItems)){
              cartItems.eb600 = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }if(!('eb500' in cartItems)){
              cartItems.eb500 = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }if(!('st309' in cartItems)){
              cartItems.st309 = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }if(!('eb200' in cartItems)){
              cartItems.eb200 = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }if(!('pf120a' in cartItems)){
              cartItems.pf120a = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }if(!('eb100' in cartItems)){
              cartItems.eb100 = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }if(!('ic900' in cartItems)){
              cartItems.ic900 = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }
      
      
            var cartArray = [];
            cartArray = cartArray.concat(cartItems['wheat']);
            cartArray = cartArray.concat(cartItems['maida']);
            cartArray = cartArray.concat(cartItems['suji']);
            cartArray = cartArray.concat(cartItems['chiroti']);
            cartArray = cartArray.concat(cartItems['afgan']);
            cartArray = cartArray.concat(cartItems['indian']);
            cartArray = cartArray.concat(cartItems['mabroom']);
            cartArray = cartArray.concat(cartItems['safawi']);
            cartArray = cartArray.concat(cartItems['almond']);
            cartArray = cartArray.concat(cartItems['cashew']);
            cartArray = cartArray.concat(cartItems['pista']);
            cartArray = cartArray.concat(cartItems['hing']);
            cartArray = cartArray.concat(cartItems['vapor']);
            cartArray = cartArray.concat(cartItems['oxymeter']);
            cartArray = cartArray.concat(cartItems['scale1']);
            cartArray = cartArray.concat(cartItems['scale2']);
            cartArray = cartArray.concat(cartItems['respiratory']);
            cartArray = cartArray.concat(cartItems['bodyfat']);
            cartArray = cartArray.concat(cartItems['eb600']);
            cartArray = cartArray.concat(cartItems['eb500']);
            cartArray = cartArray.concat(cartItems['st309']);
            cartArray = cartArray.concat(cartItems['eb200']);
            cartArray = cartArray.concat(cartItems['pf120a']);
            cartArray = cartArray.concat(cartItems['eb100']);
            cartArray = cartArray.concat(cartItems['ic900']);
            cartArray = cartArray.concat(cartItems['rasam']);
            cartArray = cartArray.concat(cartItems['sambar']);
            cartArray = cartArray.concat(cartItems['garam']);
            cartArray = cartArray.concat(cartItems['pulav']);
            cartArray = cartArray.concat(cartItems['fish']);
            cartArray = cartArray.concat(cartItems['biryani']);
            cartArray = cartArray.concat(cartItems['chicken']);
            cartArray = cartArray.concat(cartItems['kabab']);
            cartArray = cartArray.concat(cartItems['chilli']);
            cartArray = cartArray.concat(cartItems['turmeric']);
            cartArray = cartArray.concat(cartItems['coriander']);
            cartArray = cartArray.concat(cartItems['jeera']);
            cartArray = cartArray.concat(cartItems['pepper']);

            setCartItems(cartArray);
            var images = [];
            if(localStorage.getItem('totalPrice')==null){
              setTotalPrice(props.totalPrice);
            }else{
              setTotalPrice(JSON.parse(localStorage.getItem("totalPrice")))
            }
            cartArray.map(item => {
              quan+=parseInt(item.quantity);
              if(item.type == "wheat"){
                images.push(wheat)
              }else if(item.type == "maida"){
                images.push(maida)
              }else if(item.type == "chiroti"){
                images.push(chiroti)
              }else if(item.type == "afgan"){
                images.push(afgan)
              }else if(item.type == "indian"){
                images.push(indian)
              }else if(item.type == "mabroom"){
                images.push(mabroom)
              }else if(item.type == "safawi"){
                images.push(safawi);
              }else if(item.type == "almond"){
                images.push(almond);
              }else if(item.type == "pista"){
                images.push(pista);
              }else if(item.type == "cashew"){
                images.push(cashew);
              }

              else if(item.type == "vapor"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/newNikVaporizer.png?alt=media&token=cff0e0e0-ef8b-40de-bcd1-bdc887acfb04");
              }else if(item.type == "oxymeter"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/oxymeter.png?alt=media&token=2d9527ab-fc78-4f71-8069-dd05f4d278a4");
              }else if(item.type == "scale1"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/pse101.png?alt=media&token=3bca7b96-e0ba-42ec-9261-a8151aa9245b");
              }else if(item.type == "scale2"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/pse2011.png?alt=media&token=53567ae9-6142-4f88-b6a3-8364efaeec90");
              }else if(item.type == "respiratory"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/respiratory1.png?alt=media&token=6e031a24-490e-4c32-9779-1e7550c6b569");
              }else if(item.type == "bodyfat"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/wf2601.png?alt=media&token=c846b6bb-a886-4b66-ba14-3a59082ee55c");
              }else if(item.type == "eb600"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb6001.png?alt=media&token=b0e02464-0fb5-412a-8953-7e16e1a1d4c5");
              }else if(item.type == "eb500"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb5001.png?alt=media&token=2cbecd7b-bf8a-41d1-ade8-94d7bbc1dd79");
              }else if(item.type == "st309"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/stethescope1.png?alt=media&token=19f78807-a1dd-410c-ad92-478bb819957f");
              }else if(item.type == "eb200"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb2001.png?alt=media&token=598cf0bc-8461-423b-ae30-c2b527276f8a");
              }else if(item.type == "pf120a"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/pf120A1.png?alt=media&token=13638d0c-0a24-41e5-bec7-18dd970cdb5d");
              }else if(item.type == "eb100"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb100.png?alt=media&token=5ff832f2-29c9-4e48-a1eb-9b72f31829c4");
              }else if(item.type == "ic900"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/coolPack1.png?alt=media&token=9fa36616-6cf6-41d8-8035-006332bd2f6d");
              }else if(item.type=="hing"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Hing-1.jpg?alt=media&token=3ec12f0d-7044-4ae0-9e1d-14f1ab0db888");
              }else if(item.type=="rasam"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Rasam-Powder.jpg?alt=media&token=ae9d8ede-74eb-46a1-a78d-69d05955dbe7");
              }else if(item.type=="sambar"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Sambar-Powder.jpg?alt=media&token=9075867c-35bd-4c7f-af4c-709a79467a9f");
              }else if(item.type=="garam"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Garam-Masala.jpg?alt=media&token=da95e516-c346-4bee-a221-89479c4653c4");
              }else if(item.type=="pulav"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Veg-Pulav-Masala.jpg?alt=media&token=7fb7aaca-4401-4ad5-9104-6578b59d9002");
              }else if(item.type=="fish"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Fish-Fry-Masala.jpg?alt=media&token=eb3659fc-f792-4c40-bd1e-c8082630a4ab");
              }else if(item.type=="biryani"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Chicken-Biryani.jpg?alt=media&token=d0706d57-2575-4883-9f45-45bb0f5f874f");
              }else if(item.type=="chicken"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Chicken-Masala.jpg?alt=media&token=8e4f1cbc-c2d4-43c6-b07e-b76b84656ae9");
              }else if(item.type=="kabab"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Chicken-Kabab.jpg?alt=media&token=5b334f71-8f03-41c0-aebc-a5a6f540b434");
              }else if(item.type=="chilli"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Red-Chilli-Powder.jpg?alt=media&token=51f4c5b1-df6b-451e-9f3e-f6f47c7334e9");
              }else if(item.type=="turmeric"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Turmeric-Powder.jpg?alt=media&token=8ea5a9ba-83a3-4c7d-869f-8776b94bb279");
              }else if(item.type=="coriander"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Coriander-Powder.jpg?alt=media&token=faab4eba-6929-450f-9ac1-276f68645ac7");
              }else if(item.type=="jeera"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Jeera-Powder.jpg?alt=media&token=3211b88c-9efa-446c-86e7-0ba9c261834c");
              }else if(item.type=="pepper"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Pepper-Powder.jpg?alt=media&token=1cfc6de3-2ce7-41be-bb30-ebaf71f11d70");
              }
              else{
                images.push(suji_rava)
              }
            })
            setTotalQuantity(quan);
            setCartItemImages(images);
            if(localStorage.getItem("token")!=null){
              var uid = localStorage.getItem("token");
              var get_user = database.ref("User").child(uid);
              get_user.once("value")
              .then(function(snapshot){
                  setFullName(snapshot.val().user_name.split(" ")[0]);
              })
  
            }
          }else{
            setCartItems([]);

          }
        }
      },[testing]);
    

        return (
          <header> 
            <div id="header">
              <div class="container2">
                <div class="header-container row">
                  <div class="logo">
                      <Link to={"/"}><img src={newlogo} style={{height:"74px",marginTop:"-12px",width:'90%',marginLeft:'5px',objectFit:'cover'}} alt="logo"/></Link>
                  </div>
                    <div class="fl-nav-menu">
                      <nav>
                        <div class="mm-toggle-wrap">
                        </div>
                        <div class="nav-inner">
                          <ul id="nav">
                            <li><Link to={"/"}><span>Home</span></Link></li>
								          	<li><Link to={"/aboutus"}><span>About Us</span></Link></li>
                            <li><Link to={"/allProducts"}><span>Our Products</span></Link>
                            </li>
                            <li><Link to={"/partner"}><span>Partner With Us</span></Link></li>
                            <li><Link to={"/contactus"}><span>Contact Us</span></Link></li>
                          </ul>
                        </div>
                      </nav>
                    </div>

                    <div class="fl-header-right">

                    <div class="arrow-mobile-icon">
                      {
                        localStorage.getItem("token")!==null?<div class="fl-links" style={{padding:"18px 0px 0px 25px",height:"90px",display:" block"}}>
                        {localStorage.getItem("token")!==null?<span style={{color:"white",fontSize:"14px"}}>Hello,{fullName}</span>:null}<div class="no-js"><FontAwesomeIcon icon={arrows} title="Company" style={{color:"#fff",fontSize:"30px",paddingLeft:"4px",paddingRight:"6px",paddingTop:"2px"}}></FontAwesomeIcon>
                            <div class="fl-nav-links">
                                {localStorage.getItem("token")===null?
                                  <ul class="links">
                                    <li class="last"><span style={{cursor:'pointer'}} onClick={handleSignUp}>Sign Up</span></li>
                                    <li class="last"><span style={{cursor:'pointer'}} onClick={handleSignin}>Log In</span></li>
                                  </ul>:
                                  <ul class="links">
                                    <li class="last"><Link style={{textDecoration:"none"}} to={"/account"}>My Account</Link></li>
                                    <li class="last" style={{cursor:"pointer"}} onClick={handleLogOut}>Logout</li>
                                  </ul>
                                 }
                            </div>
                          </div>
                        </div>:<div class="fl-links" style={{padding:"5px 0px 0px 25px",lineHeight:"100px",height:"90px",display:" block"}}>
                        <div class="no-js"><FontAwesomeIcon icon={arrows} title="Company" style={{color:"#fff",fontSize:"30px",paddingLeft:"4px",paddingRight:"6px",paddingTop:"2px"}}></FontAwesomeIcon>
                            <div class="fl-nav-links">
                                {localStorage.getItem("token")===null?
                                  <ul class="links">
                                    <li class="last"><span style={{cursor:'pointer'}} onClick={handleSignUp}>Sign Up</span></li>
                                    <li class="last"><span style={{cursor:'pointer'}} onClick={handleSignin}>Log In</span></li>
                                  </ul>:
                                  <ul class="links">
                                    <li class="last"><Link style={{textDecoration:"none"}} to={"/account"}>My Account</Link></li>
                                    <li class="last" style={{cursor:"pointer"}} onClick={handleLogOut}>Logout</li>
                                  </ul>
                                 }
                            </div>
                          </div>
                        </div>
                      }
                      
                    </div>
                      

                      <div class="fl-links"  class="top-heading-icons" >
                        <div class="no-js" class="arrow-web-icon"><FontAwesomeIcon icon={arrows}  onClick={handleDrawerOpen}  title="Company" style={{color:"#fff",fontSize:"30px",paddingLeft:"4px",paddingRight:"6px",paddingTop:"2px"}}></FontAwesomeIcon>
                         
                        <Drawer
                          classes={{
                            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                          }}
                          open={open}
                        >
                          <div className={classes.toolbarIcon}>
                            <IconButton onClick={handleDrawerClose}>
                              <ChevronLeftIcon />
                            </IconButton>
                            <h5 style={{display:"flex",alignItems:"center",marginTop:"5px",color:"#696969"}}>Hey there!</h5>
                          </div>
                          <Divider />
                          <List>{mainListItems}</List>
                          <Divider />
                          {localStorage.getItem("token")===null?
                            <List>
                              <ListItem button>
                                <div class="drawermainlisttext" onClick={handleSignin}>LogIn</div>
                              </ListItem>
                              <ListItem button>
                                <div class="drawermainlisttext" onClick={handleSignUp}>SignUp</div>
                              </ListItem>
                            </List>
                          :
                          <List>
                            <ListItem button>
                              <Link to="/account"><div class="drawermainlisttext" >My Account</div></Link>
                            </ListItem>
                            <ListItem button>
                              <div class="drawermainlisttext" style={{cursor:"pointer"}} onClick={handleLogOut}>Logout</div>
                            </ListItem>
                          </List>
                          }
                        </Drawer>
                              <Dialog  aria-labelledby="customized-dialog-title"   fullScreen={fullScreen}  open={openSignin}
                              className={classes.dialog}
                              >
                                {/* ::-webkit-scrollbar {
    width: 0px;  
    background: transparent;  
} */}
                                <div style={{minwidth:'80%'}}>
                                    <DialogTitle class="dialog-title" id="customized-dialog-title" onClose={()=>setOpenSignin(false)}>
                                     Login<br/><p style={{fontSize:"13px"}}>Don't have an account?<span style={{color:"#5B9D4F",cursor:"pointer"}} onClick={handleNewAccount}> Sign Up</span></p>
                                    </DialogTitle>
                                    <DialogContent dividers>
                                    <Form>
                                    <Form.Group controlId="formBasicEmail">
                                      <Form.Label style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}}>Email address/Distributor ID</Form.Label>
                                      <Form.Control id="myemail" style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}} type="email" placeholder="Enter email" name="userEmail"/>
                                      <Form.Text  style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}} className="text-muted">
                                        We'll never share your email with anyone else.
                                      </Form.Text>
                                    </Form.Group>
                                   
                                    <Form.Group controlId="formBasicPassword">
                                      <Form.Label  style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}} >Password</Form.Label>
                                      <Form.Control id="mypassword" style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}} type="password" placeholder="Password" name="userPassword"/>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicCheckbox" >
                                      <Form.Check type="checkbox" label="Remember Me"  style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}} />
                                    </Form.Group>

                                    <Typography  class="dialogloginbutton" onClick = {(event) => {
                                        signInWithEmailAndPasswordHandler(event)
                                      }}>LogIn</Typography>
                                    
                                    <Typography  class="dialogforgetpassword">Forget Password?  <span onClick={handleForgetPasswordopen}  class="dialogresetpassword">Reset Password</span></Typography>
                                  
                                    </Form>
                                    </DialogContent>
                                    </div>
                                    <Snackbar open={showSnackbar} autoHideDuration={2000} onClose={handleCloseSnackbar}>
                                      <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                                        <p style={{fontSize:"14px"}}>{snackbarMessage}</p>
                                      </Alert>
                                    </Snackbar>

                                  </Dialog>


                                  <Dialog aria-labelledby="customized-dialog-title"  fullScreen={fullScreen} open={openSignup}  
                                 className={classes.dialog}>
                                  <DialogTitle class="dialog-title" id="customized-dialog-title" onClose={()=>setOpenSignup(false)}>
                                      Register
                                    </DialogTitle>
                                    <DialogContent dividers>
                                    <Form>

                                    <Form.Group controlId="formBasicFullName">
                                      <Form.Label  style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}}>Enter Full Name</Form.Label>
                                      <Form.Control id="signupfullname"  style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}} type="email" placeholder="Enter full name" />
                                    </Form.Group>

                                    <Form.Group controlId="formBasicEmail">
                                      <Form.Label  style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}}>Enter Email address</Form.Label>
                                      <Form.Control id="signupemail"  style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}} type="email" placeholder="Enter email" />
                                    </Form.Group>

                                    <Form.Group controlId="formBasicMobileNumber">
                                      <Form.Label  style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}} >Mobile Number</Form.Label>
                                      <Form.Control id="signupnumber"
                                       style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}} 
                                       type="number" placeholder="Enter Mobile Number"/>
                                    </Form.Group>
                                    

                                    <Form.Group controlId="formBasicPassword">
                                      <Form.Label  style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}} >Password</Form.Label>
                                      <Form.Control id="signuppassword"  style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}} type="password" placeholder="Password"  />
                                    </Form.Group>

                                    <Form.Group controlId="formBasicConfirmPassword">
                                      <Form.Label  style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}} >Confirm Password</Form.Label>
                                      <Form.Control id="signupconfirmpassword" style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}} type="password" placeholder="Confirm Password" />
                                    </Form.Group>

                                    <Typography class="dialogloginbutton" onClick={createUserWithEmailAndPassword}>Sign Up</Typography>
                                    
                                    <Typography  class="dialogforgetpassword">Already Have Account?  <span  onClick={handlealreadyaccount}   class="dialogresetpassword">LogIn</span></Typography>
                                    </Form>
                                    </DialogContent>
                                    <Snackbar open={showSnackbar} autoHideDuration={2000} onClose={handleCloseSnackbar}>
                                      <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                                        <p style={{fontSize:"14px"}}>{snackbarMessage}</p>
                                      </Alert>
                                    </Snackbar>
                                 
                                  </Dialog>

                                  <Dialog aria-labelledby="customized-dialog-title"  fullScreen={fullScreen} open={openForgetPassword}  
                                 className={classes.dialog}>
                                  <DialogTitle class="dialog-title" id="customized-dialog-title" onClose={()=>setOpenForgetPassword(false)}>
                                      Forget Password
                                    </DialogTitle>
                                    <DialogContent dividers>
                                    <Form>
                                    <Form.Group controlId="formBasicEmail">
                                      <Form.Label  style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}}>Enter Email address</Form.Label>
                                      <Form.Control id="resetEmail"  style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}} type="email" placeholder="Enter email" />
                                      <Form.Text  style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}} className="text-muted">
                                      Enter your email address and we'll send you a link to reset your password.
                                      </Form.Text>
                                    </Form.Group>
                                  

                                    <Typography class="dialogloginbutton" onClick={resetPassword}>Send Request to email</Typography>
        
                                    </Form>
                                    </DialogContent>
                                 
                                  </Dialog>
                        </div>
                      </div>
                      <div class="fl-cart-contain">
                      <div class="mini-cart" class="top-heading-icons" style={{}}>

                        <div class="basket-web">
                          {cartItems.length == 0?<Badge color="error" classes={{ badge: classes.badge }}><FontAwesomeIcon icon={basket} onClick={handleClick} style={{paddingRight:"5px",fontSize:"25px",color:"#fff"}}></FontAwesomeIcon></Badge>:
                          <Badge badgeContent={totalQuantity} color="error"  classes={{ badge: classes.badge }} max={9999} >
                            <FontAwesomeIcon icon={basket} onClick={handleClick} style={{paddingRight:"5px",fontSize:"25px",color:"#fff"}}></FontAwesomeIcon>
                          </Badge>}
                          
                        </div>
                        <div class="basket-mobile">
                          {
                            cartItems.length == 0?<Badge color="error"  classes={{ badge: classes.badge }} onClick={handleClickOpenCart}>
                            <FontAwesomeIcon icon={basket} style={{paddingRight:"5px",fontSize:"25px",color:"#fff"}}></FontAwesomeIcon>
                          </Badge>:<Badge max={9999} badgeContent={totalQuantity} color="error"  classes={{ badge: classes.badge }} onClick={handleClickOpenCart}>
                            <FontAwesomeIcon icon={basket} style={{paddingRight:"5px",fontSize:"25px",color:"#fff"}}></FontAwesomeIcon>
                            </Badge>
                          }
                          

                          <Dialog fullScreen open={opencCart} onClose={handleCloseCart} style={{width:'100%',zIndex:"3000"}} >
                            <AppBar className={classes.cartappBar} elevation={0}>
                              <Toolbar style={{display:'flex',justifyContent:"space-between"}}>
                                <Typography variant="h6" className={classes.carttitle}>
                                  My Cart
                                </Typography>
                                <IconButton edge="start" color="inherit" onClick={handleCloseCart} aria-label="close">
                                  <CloseIcon style={{fontSize:'18px'}}/>
                                </IconButton>
                              </Toolbar>
                            </AppBar>
                            <div className='mycart'>
                                {
                                    cartItems.length === 0 ? 
                                      <div style={{display:'flex',justifyContent:'center',flexDirection:'column'}}>
                                        <img style={{height:300,width:'90%',marginLeft:'5%',objectFit:'contain'}} src ={EmptyCart} alt="no items image" />
                                      </div>
                                    
                                  :
                                  cartItems.map((cartItem,index) => {
                                  
                                    return (<div style={{display:'flex',width:'100%',borderBottom:'1px solid #cdcdcd'}}>
                                      <div className={classes.basketdropdownimage}>
                                        <img src={cartItemImages[index]} className={classes.basketdropdownitemimage}></img>
                                      </div>
                                      <div className={classes.basketdropdowntitle}>
                                      {cartItem.name}
                                      <div className={classes.basketdropdownprice}>
                                      Price: ₹{cartItem.price}
                                      </div>
                                      <div  className={classes.basketdropdownprice}>
                                      Qty: {cartItem.weight}
                                      </div>
                                    </div>
                                    <div className={classes.basketdropdowndelete}>
                                      <FontAwesomeIcon icon={trash} onClick={()=>deleteItem(index,cartItem.type,cartItem.weight,cartItem.quantity,cartItem.price)} style={{paddingRight:"5px",marginLeft:'20%',textAlign:'center',fontSize:"20px",color:"#000"}}><span>Search</span></FontAwesomeIcon> 
                                      <div style={{width:'40%',marginRight:'10px',display:'flex'}}>
                                      <MDBBtn onClick={()=>quantityChange("minus",index,cartItem.type,cartItem.weight,cartItem.multiple)} style={{borderRadius:8, borderWidth:1, borderColor:'#efefef',paddingRight:'12px',paddingLeft:'8px', margin:3,backgroundColor:'#f65f73',color:'white'}} >-</MDBBtn>
                                      <span className={classes.mobilecounter}>{cartItem.quantity}</span>
                                            <MDBBtn onClick={()=>quantityChange("plus",index,cartItem.type,cartItem.weight,cartItem.multiple)} style={{borderRadius:8, borderWidth:1,paddingRight:'15px',paddingLeft:'7px',borderColor:'#efefef', margin:3,backgroundColor:'#97bc77',color:'white'}}>+</MDBBtn>
                                      </div>
                                    </div>
                                  </div>)
                                  })
                                }

                        
                  {       
                    cartItems.length === 0 ?
                     <div></div> :

                    <div style={{marginTop:'40px'}}>
                            <div style={{display:'flex',justifyContent:'space-between',width:'70%'}}>
                              <Typography className={classes.mycartsubtotal}>Subtotal:</Typography>
                              <Typography className={classes.mycartsubtotalprice}> ₹ {totalPrice}</Typography>
                            </div>
                            <div style={{display:'flex',justifyContent:'space-between' ,width:'70%'}}>
                              <Typography className={classes.mycartsubtotal}>Total Units:</Typography>
                              <Typography className={classes.mycartsubtotalprice}>{totalQuantity}</Typography>
                            </div>
                            <div style={{display:'flex',justifyContent:'space-between' ,width:'70%'}}>
                              <Typography className={classes.mycartsubtotal}>Grand Total:</Typography>
                              <Typography className={classes.mycartsubtotalprice}> ₹ {totalPrice}</Typography>
                            </div>
                            <div style={{display:'flex',justifyContent:'space-between',width:'70%'}}>
                              <Typography className={classes.mycartsubtotal} style={{color:'#3c763d'}}>Amount to pay:</Typography>
                              <Typography className={classes.mycartsubtotalprice}> ₹ {totalPrice}</Typography>
                            </div>
                          </div>
                  }
                     {       
                    cartItems.length === 0 ?
                     <div></div> :

                            <div className={classes.bottomsubtotal}>
                            <div className={classes.basketdropdownsubtotalfooter}>
                              <div className={classes.basketdropdownsubtotal}>
                                Grand Total:<span className={classes.basketdropdownsubtotalprice}>&#x20B9;{totalPrice}</span>
                              </div>
                              <div style={{display:'flex'}}>
                              <div className={classes.basketdropdownbutton} onClick={handleCheckout}>Checkout</div>
                              </div>
                            </div>
                            </div> 
                      }
                            </div>
           
                          </Dialog>

                        </div>
                        <div class="fl-mini-cart-content" style={{display: "none"}}>
                          <div class="block-subtitle">
                            <div class="top-subtotal">2 items, <span class="price">₹ 300</span> </div>
                          </div>
                    
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            transformOrigin={{ vertical: "top", horizontal: "right" }}
                            ripple={false}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            className={classes.basketmenu}
                          >
                            <div className={classes.basketdropdown}>
                              <div style={{display:'flex',justifyContent:'space-between'}}>
                              {cartItems.length==0?"No items in the cart":[totalQuantity==1?"1 item in the cart":totalQuantity+" items in the cart"]}
                              <div style={{marginRight:'130px'}}>
                                  <CloseIcon style={{cursor:'pointer'}} onClick={handleClose}/>
                              </div>
                              </div>
                            </div>
                            <div style={{maxHeight:'250px',overflow:'auto'}}>
                            {
                                cartItems.map((cartItem,index) => {
                                  return  <MenuItem
                                  button disableRipple disableTouchRipple disableFocusRibble
                                  onMouseEnter={(e) => e.target.style.backgroundColor= '#ffffff'}
                                  onMouseLeave={(e) => e.target.style.backgroundColor = '#ffffff'}
                                  >
                                <div style={{display:'flex',width:'100%',borderBottom:'1px solid #cdcdcd',paddingRight:'20px',paddingTop:'5px',paddingBottom:'0px',backroundColor:'white',alignItems:"center"}}>
                                    <div className={classes.basketdropdownimage}>
                                      <img src={cartItemImages[index]} className={classes.basketdropdownitemimage}></img>
                                    </div >
                                    <div className={classes.basketdropdowntitle}>
                                    {cartItem.name} 
                                    <div className={classes.basketdropdownprice}>
                                      Price: ₹{cartItem.price}
                                    </div>
                                    <div  className={classes.basketdropdownprice}>
                                      Qty: {cartItem.weight}
                                    </div>
                                    </div>
                                    <div className={classes.basketdropdowndelete}>
            
                                    <FontAwesomeIcon onClick={()=>deleteItem(index,cartItem.type,cartItem.weight,cartItem.quantity,cartItem.price)} icon={trash} style={{paddingRight:"5px",marginLeft:'70%',textAlign:'center',fontSize:"20px",color:"#000"}}><span>Search</span></FontAwesomeIcon> 
                                      <div style={{width:'40%',marginRight:'10px'}}>
                                      <MDBBtn onClick={()=>quantityChange("minus",index,cartItem.type,cartItem.weight,cartItem.multiple)} style={{borderRadius:8, borderWidth:1, borderColor:'#efefef', margin:3}} >-</MDBBtn>
                                  <span className={classes.counter}>{cartItem.quantity}</span>
                                            <MDBBtn onClick={(e)=>{quantityChange("plus",index,cartItem.type,cartItem.weight,cartItem.multiple); e.target.style.backgroundColor = '#ffffff'}} style={{borderRadius:8, borderWidth:1, borderColor:'#efefef', margin:3}}>+</MDBBtn>
                                    </div>
                                    </div>
                                </div>
                            </MenuItem>
                                  })
                                }
                            </div>
                            {localStorage.getItem("totalPrice") == 0?null:
                            <div className={classes.basketdropdownsubtotalfooter} 
                            >
                              <div className={classes.basketdropdownsubtotal}>
                            Subtotal:<span className={classes.basketdropdownsubtotalprice}>&#x20B9;{totalPrice}</span>
                              </div>
                              <div style={{display:'flex'}}>
                                <Link style={{textDecoration:"none"}} to={"/cart"}><div className={classes.basketdropdownbutton}>My Cart</div></Link>
                                <div className={classes.basketdropdownbutton} style={{cursor:"pointer"}} onClick={handleCheckout}>Checkout</div>
                              </div>
                            </div>
                            }
                          </Menu>
                          <div class="actions">
                            <button class="btn-checkout" title="Checkout" type="button" onClick=""><span>Checkout</span></button>
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Snackbar open={showSnackbar} autoHideDuration={2000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                  <p style={{fontSize:"14px"}}>{snackbarMessage}</p>
                </Alert>
              </Snackbar>

          </header>
        );
    // }
}

export default withRouter(HomeTop);