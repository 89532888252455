import React from 'react';
import BrowsePageNavigation from '../components/navigation/browseNavigation';
import ProductsContainer from '../containers/products/productsContainer';
import FooterPage from "../components/Footer";
export default function CategoryPage(){


    return (
        <>
            <BrowsePageNavigation/>
            <ProductsContainer />
            <FooterPage/>
        </>
        );
}
