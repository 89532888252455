import React from 'react';
import { Link } from 'react-router-dom';
import FooterPage from '../components/Footer';
import OrderSuccess from '../static/img/order_placed.gif'
import HomeTop from "../components/homepage/homeTop";
import './OrderPlaced.css';

class OrderPlaced extends React.Component{
    render(){
        return <div>
            <HomeTop />
            <h1 style={{color:"#008000"}}>Yay! Order Received</h1>
            <img class="thankyou" src={OrderSuccess} alt="Order Success"  />
            <p style={{textAlign:"center", fontSize:"2.5rem"}}>Your Order will be delivered shortly.</p>
            <FooterPage/>
          </div>;
    }
}
export default OrderPlaced;
