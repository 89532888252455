import React,{useEffect} from 'react';
import BrowsePageNavigation from '../components/navigation/browseNavigation'
import Carousel from 'react-bootstrap/Carousel'
import './styles.css';
import FooterPage from "../components/Footer";
import {auth, database} from "../lib/firebase.prod";
import Grid from '../components/Grid/index';
import Card from '../components/card/index';
import * as ROUTES from "../constants/routes";
import {Link, useHistory} from "react-router-dom";
import NutsLoading from "../static/img/nutsloading.gif";
import chariotatta from '../static/img/chariotatta.jpg';
import history from './history';
import {withRouter} from 'react-router-dom'


class BrowsePage extends React.Component{
    state = {
        bannersData: [{bannerImageUrl:'https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/8.png?alt=media&token=eb42e5d6-84a3-4c99-b428-8b6a6fb9af29'}, {bannerImageUrl: 'https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/9.png?alt=media&token=f9ee626f-d118-4b35-a608-788bfa682f7b'}, {bannerImageUrl: 'https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/7.png?alt=media&token=7df351a8-951e-4ce2-bc4c-0fb960d8c652'}],
        categories: [],
        featured:[]
    };



    constructor(props) {
        super(props);
        this.setState({
            categories: [],
            active: {},
        });
    }

    componentDidMount() {
       let uid = localStorage.getItem('token');
       this.getAllCategories();
       this.getFeaturedProducts();
    }

    getAllCategories = () => {
        this.setState({categories: []})
        var arr = [];
        var getall_categories = database.ref("Category");
        getall_categories.on('value', (snapshot) => {
            snapshot.forEach(function(childSnapshot){
                var key = childSnapshot.key;
                var childData = childSnapshot.val();
                arr.push(childData);
            })
            this.setState({
                categories: arr
            })
        });
    }

    getFeaturedProducts = () => {
        var getall_feature = database.ref("Feature");
        var featured_products = [];
        getall_feature.on('value', (snapshot) => {
            snapshot.forEach((childSnapshot) => {
                const product = childSnapshot.val();
                const featured = {
                    product_id: product.product_id,
                    product_name : product.product_name,
                    product_description : product.product_description,
                    product_url : product.product_url,
                    product_price_user : product.product_price_user
                }
                featured_products.push(featured);
            });
            this.setState({
                featured : featured_products
            });
        });
    }

    clickMe = () => {
        console.log('clicked');
        this.props.history.push("/search");
    }

    render(){
        // console.log('featured products', this.state.featured)

        return (

            <div>
                <BrowsePageNavigation/>
                <Carousel>
                    {
                        this.state.bannersData.map(bannersData=>
                            <Carousel.Item interval={10} style={{backgroundColor:"#f0f8ff"}}>
                                {bannersData.bannerImageUrl == chariotatta ? 
                                    <img
                                        className="d-block"
                                        src={bannersData.bannerImageUrl}
                                        alt="banner"
                                        style={{width:"60%",maxHeight:"500px",margin:"auto"}}
                                    /> :
                                    <img
                                    className="d-block w-100"
                                    src={bannersData.bannerImageUrl}
                                    alt="banner"
                                    />
                                }   
                            </Carousel.Item>
                        )
                    }
                </Carousel>
                {
                    this.state.categories.length == 0  &&
                    <div>
                        <center>
                            <img src={NutsLoading} style={{borderRadius:8, width:400}} className="noorders_image"/>
                            <p className="noorders_text"> Loading your awesome shop! </p>
                            <button className="continue_shopping"> Continue Shopping! </button>
                        </center>
                    </div>

                }
                <div>
                    <div className="container">
                        <div>
                            { this.state.categories.length != 0? <Grid.Title style={{alignSelf:'center'}}> Categories </Grid.Title> : null}
                            <Grid.Section>
                                {
                                    this.state.categories.map((item,index,array) => {
                                        const ele = item;
                                        if(!ele)
                                            return null;
                                        return (
                                            // <Link to={'/browse/category/'+ ele.category_name} style={{textDecoration:"none",color:"#000"}}>
                                                <Card style={{borderRadius:8}}>
                                                    <Card.Image style={{borderRadius:8}} src={ele.category_url}/>
                                                    <Card.Title> {ele.category_name} </Card.Title>
                                                    <Link to="/allProducts"><Card.Button>SHOP NOW</Card.Button></Link>
                                                </Card>
                                            // </Link>
                                        );
                                    })

                                }
                            </Grid.Section>
                        </div>


                    </div>
                    <div className="container">
                        <div>
                            { this.state.featured.length != 0? <Grid.Title style={{alignSelf:'center'}}> Featured Products </Grid.Title> : null}
                            <Grid.Section>
                                {
                                    this.state.featured.map((item,index,array) => {
                                        //console.log("item: ", item.item);
                                        const ele = item;
                                        if(!ele)
                                            return null;
                                        return (
                                            // <Link to={{
                                            //     pathname: '/browse/product/'+ ele.product_id,
                                            //     state:ele
                                            // }} style={{textDecoration:"none",color:"#000"}}>
                                            <Card style={{borderRadius:8}}>
                                                <Card.Image style={{borderRadius:8}} src={ele.product_url}/>
                                                <Card.Title> {ele.product_name} </Card.Title>
                                                <Card.SubTitle> {ele.product_description}</Card.SubTitle>
                                                <Card.Text>
                                                    <Card.DeliveryTime> {ele.product_multiple}</Card.DeliveryTime>
                                                    <Card.Price> {ele.product_price_user} </Card.Price>
                                                </Card.Text>
                                                <Card.Button> SHOP NOW</Card.Button>
                                            </Card>
                                            // </Link>
                                        );
                                    })

                                }
                            </Grid.Section>
                        </div>


                    </div>
                </div>
                <Carousel>
                    <Carousel.Item interval={300}>
                                <img
                                    className="d-block w-100"
                                    src={'https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/7.png?alt=media&token=7df351a8-951e-4ce2-bc4c-0fb960d8c652'}
                                    alt="banner"
                                />
                    </Carousel.Item>
                </Carousel>
                <FooterPage/>
            </div>
        );
    }

}

export default withRouter(BrowsePage)

