import Modal from 'react-bootstrap/Modal'
import Button from "react-bootstrap/cjs/Button";
import React, {useState} from "react"
import {auth, database} from "../../lib/firebase.prod";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import {useHistory} from "react-router-dom";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));
export function SignUpModal(props) {
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const [error, setError] = useState(null);
    const createUserWithEmailAndPassword =
        (event,email, password) => {
            event.preventDefault();
            auth.createUserWithEmailAndPassword(email, password)
                .then(() => {
                    let uid = auth.currentUser.uid
                    console.log('user created! =', uid)
                    localStorage.setItem('token', uid);
                    var create_user = database.ref('User');
                    var new_user = create_user.child(uid);
                    new_user.set({
                        'user_id': uid,
                        'user_name': name,
                        'user_phone': phone,
                        'user_email': email,
                        'token': '',
                        'user_address': '{}',
                        'user_lat': '',
                        'user_long': '',
                    }).then(() => { console.log('createUserApi executed') });
                    // alert('Login Success')
                    history.push("/browse");
                    // alert('Account created successfully!')
                })
                .catch(error => {
                    setOpen(true)
                    // alert('Something went wrong')
                    setError("Error signing in with password and email!");
                    console.error("Error signing in with password and email", error);
                    console.log('failed signin')
            });
        };

    const onChangeHandler = (event) => {
        const {name, value} = event.currentTarget;

        if(name === 'userName') {
            setName(value);
        }
        if(name === 'userPhone') {
            setPhone(value);
        }
        if(name === 'userEmail') {
            setEmail(value);
        }
        else if(name === 'userPassword'){
            setPassword(value);
        }
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h3>SignUp</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className ="row" style={{alignItems:'center'}}>
                    <div style={{width: "10%"}}></div>
                    <div style={{width: "90%"}}>
                        <h5 style={{marginTop:'10px'}}>Name</h5>
                        <input style={{width: "80%", padding: "10px", marginBottom: "25px 0", border: "2px solid #ccc",}}
                               type="text" placeholder="Enter your Name" id="autocomplete"
                               name="userName"
                               onChange={event => onChangeHandler(event)}
                        ></input>
                        <h5 style={{marginTop:'10px'}}>Phone Number</h5>
                        <input style={{width: "80%", padding: "10px", marginBottom: "25px 0", border: "2px solid #ccc",}}
                               type="text" placeholder="Enter your Phone Cumber" id="autocomplete"
                               name="userPhone"
                               onChange={event => onChangeHandler(event)}
                        ></input>
                <h5 style={{marginTop:'10px'}}>Email</h5>
                <input style={{width: "80%", padding: "10px", marginBottom: "25px 0", border: "2px solid #ccc",}}
                       type="text" placeholder="Enter your Email" id="autocomplete"
                       name="userEmail"
                       onChange={event => onChangeHandler(event)}
                ></input>
                <h5 style={{marginTop:'10px'}}>Password</h5>
                <input style={{width: "80%", padding: "10px", marginBottom: "25px 0", border: "2px solid #ccc",}}
                       type="password" placeholder="Enter your password" id="autocomplete"
                       name="userPassword"
                       onChange={event => onChangeHandler(event)}
                ></input>
                <button
                    onClick = {(event) => {
                        createUserWithEmailAndPassword(event, email, password)
                        // props.onHide()
                    }}
                    style={{width: "80%", padding: "10px", marginBottom: "25px 0", border: "2px solid #ccc",backgroundColor:'#050b30', color:'white', marginTop:'25px', borderRadius:10, fontWeight:'bold'} }>SignUp</button>
                        <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity="warning">
                                Please check your Email/Password and try again!
                            </Alert>
                        </Snackbar>
                        <button
                            onClick={props.onSwitch}
                            style={{width: "80%", padding: "10px", marginBottom: "25px 0", border: "0px solid #ccc",backgroundColor:'white', color:'black', marginTop:'25px', borderRadius:10, fontWeight:'bold'} }>I already have an account</button>
                </div>
                    <div style={{width: "10%"}}></div>

                </div>
            </Modal.Body>
            {/*<Modal.Footer>*/}
            {/*    <Button onClick={props.onHide}>Close</Button>*/}
            {/*</Modal.Footer>*/}
        </Modal>
    );
}
