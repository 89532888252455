import React, {useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {Button,Form} from 'react-bootstrap'
import DryFruits from '../static/img/DryFruits.jpg'
import newlogo from '../static/img/logo/newlogo.png';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Typography from "@material-ui/core/Typography"
import {faAlignJustify as arrows, faShoppingBasket as basket,faSearch as search } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Badge from '@material-ui/core/Badge';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {Link} from 'react-router-dom';
import chiroti from '../static/img/chiroti.png';
import suji_rava from '../static/img/suji_rava.png';
import maida from '../static/img/maida.png';
import wheat from '../static/img/wheat.png';
import {MDBBtn} from "mdbreact";
import {faTrash as trash} from "@fortawesome/free-solid-svg-icons";
import EmptyCart from "../static/img/empty-cart.png";
import './CheckoutPage.css';
import {auth, database} from "../lib/firebase.prod";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import {useHistory} from "react-router-dom";
import axios from "axios";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import afgan from '../static/img/afghanraisinsfront.jpg';
import indian from '../static/img/indianraisinsfront.jpg';
import safawi from '../static/img/safawifront.jpg';
import mabroom from '../static/img/mabroomfront.jpg';
import almond from '../static/img/almond1.jpg';
import cashew from '../static/img/cashew1.jpg';
import pista from '../static/img/pista1.jpg';



function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      [theme.breakpoints.only('xs')]: {
        padding:'10px'
      },
    },
    paperleft: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
        backgroundColor:'#efefef'
      },
    rigthbar:{
        display:'block',
        [theme.breakpoints.only('xs')]: {
            display:'none',
          },
    },
    leftbar:{
        margin:'15%',
        [theme.breakpoints.only('xs')]: {
            margin:'5%',
          },
    },
    logo:{
        width:'50%',
        height:'60px',
        textAlign:'start',
        marginBottom:'20px',
        display:'flex',
        objectFit:'contain'
    },
    dialog:{
      '& , .MuiDialog-paperWidthSm':{
        [theme.breakpoints.up('sm')]: {
          minWidth:'450px',
        },
      },
      },
      dialogname:{
        fontSize:'20px',
        textAlign:'center',
        paddingTop:'10px',
        paddingBottom:'10px',
        backgroundColor:'#ffb637',
        color:'white',
        fontWeight:'600',
        letterSpacing:'1px',
        fontFamily:' Oswald, "sans serif"'
      },

    cartlogo:{
       display:'none',
       [theme.breakpoints.only('xs')]: {
        display:'block',
        marginTop:'22px'
      },
    },
    contacttitle:{
        textAlign:'start',
        display:'flex',
        fontFamily:' Oswald, "sans serif"',
        letterSpacing:'1px',
        color:'#3c763d'
    },
    button:{
        padding:'10px',
        backgroundColor:'#ffb637',
        fontFamily:' Oswald, "sans serif"',
        marginLeft:'10px',
        color:'white',
        letterSpacing:'1.5px',
        fontWeight:'600',
        textTransform:'uppercase',
        cursor:'pointer',
        [theme.breakpoints.only('xs')]: {
            marginTop:'10px'
          },
    },
    tabletitle:{
        textAlign:'start',
        fontSize:'15px',
        marginTop:'15px',
        fontWeight:'300',
        fontFamily:' Rubik, "sans serif"',
        letterSpacing:'1px',
        textTransform :'uppercase',
        [theme.breakpoints.only('xs')]: {
           fontSize:'10px',
           marginTop:'0px',
          },
        

    },
    gridheader:{
        width:'80%',
        marginLeft:'10%',
        borderBottom:'1px solid #e5e5e5',
        [theme.breakpoints.only('xs')]: {
            width:'100%',
            marginLeft:'0%',
          },
    } ,
    productimage:{
        height:'100px',
        width:'100%',
        objectFit:'contain',
    },
    producttitle:{
        textAlign:'start',
        fontSize:'12px',
        marginTop:'15px',
        fontWeight:'300',
        fontFamily:' Rubik, "sans serif"',
        letterSpacing:'1px',
        textTransform :'uppercase',
        marginLeft:'20px',
        [theme.breakpoints.only('xs')]: {
            marginLeft:'5px',
            fontSize:'10px',
            textAlign:'start'
          },
    },
    inputtext:{
        textAlign:'start',
        fontSize:'12px',
        fontWeight:'300',
        fontFamily:' Rubik, "sans serif"',
        letterSpacing:'1px',
        textTransform :'uppercase',
    },
    productdelete:{
        textAlign:'start',
        fontSize:'22px',
        marginTop:'15px',
        fontWeight:'300',
        fontFamily:' Rubik, "sans serif"',
        letterSpacing:'1px',
        textTransform :'uppercase',
        fill:'#f65f73'
    },
    mobilecounter:{
        marginTop:'10px',
        fontSize:'12px',
        fontWeight:'300',
        fontFamily:' Rubik, "sans serif"',
        letterSpacing:'1px',
        textTransform :'uppercase'
    },
    pricing:{
        width:'80%',
        marginRight:'10%',
        marginTop:'20px',
        float:'right',
        display:'flex',
        justifyContent:'flex-end',
        [theme.breakpoints.only('xs')]: {
           width:'100%',
           float:'left',
           display:'flex',
           justifyContent:'flex-start',
          },
       
    },
    pricingdetails:{
        width:'50%',
        borderTop:'1px solid #8c8c8c',
        [theme.breakpoints.only('xs')]: {
            width:'100%',
           },
    },
    mycartsubtotal:{
        fontSize:'15px',
        fontWeight:'400',
        fontFamily:' Rubik, "sans serif"',
        letterSpacing:'0px'
      },
      footer:{
        display:'flex',justifyContent:'space-between',
        [theme.breakpoints.only('xs')]: {
            flexDirection:'column-reverse'
          },
      },
      returncart:{
          color:'#f65f73',
          marginTop:'10px',
          fontSize:'14px',
          fontWeight:'600',
          cursor:'pointer',
          marginBottom:'10px'
      },
      buttonFooter:{
        display:'flex',
        justifyContent:'space-between',
        [theme.breakpoints.only('xs')]: {
            flexDirection:'column',
            marginTop:'10px'
          },
      },
      basketmenu:{
        '& , .MuiList-padding':{
          padding:'0px'
        },
        width:'400px',

      },
      basketdropdown:{
        backgroundColor:'#5B9D4F',
        color:'white',
        width:'500px',
        padding:'10px',
        fontFamily:' Oswald, "sans serif"',

      },
      basketdropdownimage:{
        width:'50%'
      },

      basketdropdowntitle:{
        width:'40%',
        margin:'20px',
        textAlign:'start',
        margin:'20px',
        marginTop:'20px',
        fontSize:"14px",
        fontFamily:' Oswald, "sans serif"',
        letterSpacing:'1px'

      },
      basketdropdowndelete:{
        width:'15%',
        margin:'20px',
        margin:'20px',
        marginTop:'20px',
        fontSize:"16px",
        textAlign:'center'

      },
      basketdropdownitemimage:{
        width:'100%',
        height:'80px',
        margin:'5px',
        objectFit:'cover',
       
      },
    basketdropdownprice:{
      fontSize:'10px',
      margin:'5px',
      fontFamily:' serif, "sans serif"',
      letterSpacing:'1px'
    },
    basketdropdownsubtotalfooter:{
      padding:'10px',
      display:'flex',
      flexDirection:'row',
      justifyContent:'space-between',
      backgroundColor:'#efefef',
      width:'100%'
    },
    basketdropdownsubtotal:{
     fontSize:'10px',
     paddingTop:5,
     fontFamily:' Oswald, "sans serif"',
     letterSpacing:'1px'
    },
    basketdropdownsubtotalprice:{
      fontSize:'15px',
      marginLeft:'10px',
      paddingTop:4,
      fontFamily:' Oswald, "sans serif"',
      letterSpacing:'1px'
    },
    basketdropdownbutton:{
      padding:'8px',
      backgroundColor:'#5B9D4F',
      marginLeft:'10px',
      color:'#fff',
      letterSpacing:'0.5px',
      borderRadius:'3px',
      fontSize:'12px',
      fontWeight:'600',
      fontFamily:' Oswald, "sans serif"',
      letterSpacing:'1px',
      cursor:'pointer'
    },
    bottomsubtotal:{
        position:'absolute',
        bottom:'0',
        width:'100%'
      },
      mycartsubtotal:{
        fontSize:'12px',
        padding:'10px',
        fontWeight:'600',
        fontFamily:' Rubik, "sans serif"',
        letterSpacing:'2px'
      },
      mycartsubtotalprice:{
        fontSize:'16px',
        padding:'10px',
        fontWeight:'600',
        fontFamily:' Oswald, "sans serif"',
        letterSpacing:'2px'
      },
      cartappBar: {
        position: 'relative',
        width:'100%',
        backgroundColor:'#ffb637'
      },
      carttitle: {
        marginLeft: theme.spacing(2),
        flex: 1,
        fontSize:'20px',
        fontFamily:' Oswald, "sans serif"',
        fonrWeight:'600'
      },
      mChild: {
        width:"100%",
        height:"100%"
      },
  }));
  

export default function CheckoutPage() {
    const classes = useStyles();
    const styles = (theme) => ({
      root: {
        margin: 0,
        padding: theme.spacing(2),
        minWidth:'600px',
        backgroundColor:'blue'
      
      },
      closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: 0,
        color: 'white',
        marginTop:'5px',

      },
      dialogname:{
        fontSize:'20px',
        textAlign:'center',
        paddingTop:'10px',
        paddingBottom:'10px',
        backgroundColor:'#ffb637',
        color:'white',
        fontWeight:'600',
        letterSpacing:'1px',
        fontFamily:' Oswald, "sans serif"'
      },

    });

    const DialogTitle = withStyles(styles)((props) => {
      const { children, classes, onClose, ...other } = props;
      return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
          <Typography variant="h6" className={classes.dialogname}>{children}</Typography>
          {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon style={{fontSize:20}} />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
      );
    });

    const DialogContent = withStyles((theme) => ({
      root: {
        padding: theme.spacing(2),
      },
    }))(MuiDialogContent);
    
    const DialogActions = withStyles((theme) => ({
      root: {
        margin: 0,
        padding: theme.spacing(1),
      },
    }))(MuiDialogActions);

    var [cartItemImages, setCartItemImages] = React.useState([]);
    const [cartItems, setCartItems] = React.useState([]);
    var [totalPrice,setTotalPrice] = React.useState(0);
    const [opencCart, setOpenCart] = React.useState(false);
    const [openEditCart, setOpenEditCart] = React.useState(false);
    const [addAddress,setAddAddress] = React.useState(true);
    const [showSnackbar,setShowSnackbar] = React.useState(false);
    const [snackbarSeverity,setSnackbarSeverity] = React.useState("");
    const [snackbarMessage,setSnackbarMessage] = React.useState("");
    const [open,setOpen] = React.useState(false);
    const [order_id,setOrderId] = React.useState(0);
    const history = useHistory();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [checkoutImages,setCheckoutImages] = React.useState([]);
    const [activeRadioButton,setActiveRadioButton] = React.useState("online");
    const [totalQuantity,setTotalQuantity] = React.useState(0);

      const handleClickOpenCart = () => {
        setOpenCart(true);
      };
    
      const handleCloseCart = () => {
        setOpenCart(false);
      };

      const handleClickOpenEditCart = () => {
        setOpenEditCart(true);
      };
    
      const handleCloseEditCart = () => {
        setOpenEditCart(false);
      };
      const quantityChange = (type,itemIndex,itemType,weight) => {
        var localCartItems = JSON.parse(localStorage.getItem("cart"));
        if(type=="plus"){
          cartItems[itemIndex].quantity+=1;
          totalPrice=parseInt(totalPrice)+parseInt(cartItems[itemIndex].price);
          if(itemType=="suji_rava"){
            localCartItems["suji"].map((item,index) => {
              if(item["weight"]==weight){
                localCartItems[itemType][index].quantity+=1;
                localStorage.setItem("cart",JSON.stringify(localCartItems));
                localStorage.setItem("totalPrice",JSON.stringify(totalPrice))
              }
            })
          }else{
            localCartItems[itemType].map((item,index) => {
              if(item["weight"]==weight){
                localCartItems[itemType][index].quantity+=1;
                localStorage.setItem("cart",JSON.stringify(localCartItems));
                localStorage.setItem("totalPrice",JSON.stringify(totalPrice))
              }
            });  
          }
          setCartItems(cartItems);
          setTotalPrice(totalPrice);
        }else if(type=="minus"){
          if(cartItems[itemIndex].quantity>1){
            cartItems[itemIndex].quantity-=1;
            totalPrice-=cartItems[itemIndex].price;
            localStorage.setItem("totalPrice",JSON.stringify(totalPrice))  
            setCartItems(cartItems);
            setTotalPrice(totalPrice);  
            if(itemType=="suji_rava"){
              localCartItems["suji"].map((item,index) => {
                if(item["weight"]==weight){
                  localCartItems[itemType][index].quantity-=1;
                  localStorage.setItem("cart",JSON.stringify(localCartItems));
                  localStorage.setItem("totalPrice",JSON.stringify(totalPrice))
                }
              })
            }else{
              localCartItems[itemType].map((item,index) => {
                if(item["weight"]==weight){
                  localCartItems[itemType][index].quantity-=1;
                  localStorage.setItem("cart",JSON.stringify(localCartItems));
                  localStorage.setItem("totalPrice",JSON.stringify(totalPrice))
                }
              });  
            }
          }
        }
      }

      const deleteItem = (arrayIndex,itemType,weight,quantity,price) => {
        var localCartItems = JSON.parse(localStorage.getItem("cart"));
        if(itemType=="suji_rava"){
          localCartItems["suji"].map((item,index) => {
            if(item["weight"]==weight){
              localCartItems["suji"].splice(index,1);
              localStorage.setItem("cart",JSON.stringify(localCartItems));
            }
          })
        }else{
          localCartItems[itemType].map((item,index) => {
            if(item["weight"]==weight){
              localCartItems[itemType].splice(index,1);
              localStorage.setItem("cart",JSON.stringify(localCartItems));
            }
          });  
        }
        var currentPrice = JSON.parse(localStorage.getItem("totalPrice"))
        localStorage.setItem("totalPrice",JSON.stringify(currentPrice - quantity*price))
        setTotalPrice(currentPrice- quantity*price)
        var copyCartItems = JSON.parse(JSON.stringify(cartItems))
        copyCartItems.splice(arrayIndex,1);
        setCartItems(copyCartItems);
      }

      const checkModeOfPayment = () => {
        if(localStorage.getItem("user")=="broker"){
          var updatedEmail = document.getElementById("checkoutEmail").value;
          var updatedFullName = document.getElementById("checkoutFullName").value;
          var updatedPhoneNumber = document.getElementById("checkoutMobileNumber").value;
          var updatedAddress1 = document.getElementById("checkoutAddress1").value;
          var updatedLandmark = document.getElementById("checkoutLandmark").value;
          var updatedCity = document.getElementById("checkoutCity").value;
          var updatedPincode = document.getElementById("checkoutPincode").value;
          if(updatedEmail == "" || updatedFullName == "" || updatedAddress1=="" || updatedCity=="" || updatedPhoneNumber=="" || updatedPincode==""){
            setShowSnackbar(true);
            setSnackbarSeverity("error");
            setSnackbarMessage("Please enter all address details");  
          }else{
            setOpen(true);
          }  
        }else{
          placeOrder();
        }
      }

      const proceed = () => {
        if(activeRadioButton=="online"){
          placeOrder();
        }else{
          placeorderApi("manual","manual");
        }
      }

      const addOrUpdateAddress = () => {
        var updatedEmail = document.getElementById("checkoutEmail").value;
        var updatedFullName = document.getElementById("checkoutFullName").value;
        var updatedPhoneNumber = document.getElementById("checkoutMobileNumber").value;
        var updatedAddress1 = document.getElementById("checkoutAddress1").value;
        var updatedLandmark = document.getElementById("checkoutLandmark").value;
        var updatedCity = document.getElementById("checkoutCity").value;
        var updatedPincode = document.getElementById("checkoutPincode").value;
        var updatedGST = document.getElementById("checkoutGstin").value;
        if(updatedEmail == "" || updatedFullName == "" || updatedAddress1=="" || updatedCity=="" || updatedPhoneNumber==""){
          setShowSnackbar(true);
          setSnackbarSeverity("error");
          setSnackbarMessage("Please enter all details");  
        }else{
          const address = {
            addressLine1: updatedAddress1,
            city: updatedCity,
            customerId: localStorage.getItem("token"),
            landmark: updatedLandmark,
            pincode: updatedPincode,
            gst: updatedGST,
          }
          let uid = localStorage.getItem('token');
          var data_res = JSON.stringify(address);
          var update_user = database.ref('User');
          var new_update_user = update_user.child(uid);
          new_update_user.update({
              'user_address': data_res,
              'user_lat': 0,
              'user_long': 0,
              'user_phone': updatedPhoneNumber,
              'user_email': updatedEmail,
              'user_name': updatedFullName,
          }).then(() => {
            setShowSnackbar(true);
            setSnackbarSeverity("success");
            setSnackbarMessage("Address Added!");  
          });
        }
      }

    const loadScript = (src) => {
      return new Promise((resolve => {
          const script = document.createElement('script');
          script.src = src;
          script.onload = () => {
              resolve(true);
          }
          script.onerror = () => {
              resolve(false);
          }
          document.body.appendChild(script);
      }))
    }

    
    const placeorderApi = (payment_id,type) => {
      let num = Math.floor(Math.random() * 1000000) + 1;
      var invoice_id = "CAL - "+num.toString();
      var item_names = "";
      var item_images = "";
      var item_quantities = "";      
      var item_prices = "";
      var item_gst = "";
      var item_weights = "";
      var cart_data_to_post = [];
      let uid = localStorage.getItem('token');
      addOrUpdateAddress();
      var placeOrderEmail = document.getElementById("checkoutEmail").value;
      var placeOrderFullName = document.getElementById("checkoutFullName").value;
      var placeOrderPhoneNumber = document.getElementById("checkoutMobileNumber").value;
      var placeOrderGSTIN = document.getElementById("checkoutGstin").value;
      if(placeOrderGSTIN==""){
        placeOrderGSTIN="29AAJCC2522P1Z9";
      }
      var placeOrderAddress1 = document.getElementById("checkoutAddress1").value;
      var placeOrderLandmark = document.getElementById("checkoutLandmark").value;
      var placeOrderCity = document.getElementById("checkoutCity").value;
      var placeOrderPincode = document.getElementById("checkoutPincode").value;
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
      const address = {
        addressLine1: placeOrderAddress1,
        addressState: "Karnataka",
        city: placeOrderCity,
        customerId: uid,
        landmark: placeOrderLandmark,
        pincode: placeOrderPincode,
        phone: placeOrderPhoneNumber,
        gst: placeOrderGSTIN,
      }
      var tax_total = 0.0;
      cartItems.map((item,index)=> {
        item_names+=item.name+",";
        item_images+=cartItemImages[index]+",";
        item_quantities+=item.quantity+",";
        item_prices+=item.price+",";        
        item_gst+=item.gst+",";
        item_weights+=item.weight+",";
        var cgst = item.gst/2;
        var sgst = item.gst/2;
        item.item_subtotal_price = (item.quantity)*(item.price);
        var cgst = parseFloat(item.gst).toFixed(2)/2;
        var sgst = parseFloat(item.gst).toFixed(2)/2;
        item.cgst = cgst.toFixed(2);
        item.sgst = sgst.toFixed(2);
        item.item_subtotal_price=parseFloat((item.quantity)*(item.price)).toFixed(2);
        var tax_pdt_value = parseFloat(item.quantity*(item.price)/(1+item.gst)).toFixed(2);
        var cgst_value = tax_pdt_value*cgst;
        var sgst_value = tax_pdt_value*sgst;
        item.tax_pdt_value = tax_pdt_value;
        item.cgst_value = parseFloat(cgst_value).toFixed(2);
        item.sgst_value = parseFloat(sgst_value).toFixed(2);
        tax_total+=tax_pdt_value*cgst*2;
        if(index==cartItems.length-1){
          item.tax_total = parseFloat(tax_total).toFixed(2);
        }
        cart_data_to_post.push(item);
      })
      const currentdate = new Date();
      if(localStorage.getItem("user")=="broker"){
        var create_order = database.ref('BrokerOrder');
      }else{
        var create_order = database.ref('NewOrder');
      }
        var new_create_order = create_order.child(order_id.toString());
        new_create_order.set({
            'order_id': order_id,
            'invoice_id':invoice_id,
            'real_address': placeOrderAddress1,
            'item_amount':  item_prices , // all item price respectively
            'order_month': monthNames[currentdate.getMonth()],
            'item_names': item_names, //all names string seprated  by comma
            'item_quantity': item_quantities, // all quantity respectively
            "item_weight": item_weights,
            'item_gst': item_gst,
            "item_images":JSON.stringify(checkoutImages), 
            'order_date': currentdate.getDate(),
            'order_year': currentdate.getFullYear(),
            'payment_id': payment_id,
            'payment_type':type,
            'cart_data': JSON.stringify(cart_data_to_post),
            'total_amount': totalPrice,
            'user_id': uid,
            'user_name': placeOrderFullName,
            'user_gst':placeOrderGSTIN,
            'organisation': null,
            'user_email': placeOrderEmail,
            'user_address': JSON.stringify(address),
            'user_phone': placeOrderPhoneNumber,
            'user_type':'user',
            'user_lat': '0',
            'user_long': '0'
        }).then(() => {
            var create_my_order = database.ref('MyOrder').child(uid);
            var new_create_my_order = create_my_order.child(order_id.toString());
            new_create_my_order.set({
              'order_id': order_id,
              'invoice_id':invoice_id,
              'real_address': placeOrderAddress1,
              'item_amount':  item_prices , // all item price respectively
              'order_month': monthNames[currentdate.getMonth()],
              'item_names': item_names, //all names string seprated  by comma
              'item_quantity': item_quantities, // all quantity respectively
              'item_weight': item_weights,
              'item_gst': item_gst,
              "item_images":JSON.stringify(checkoutImages), 
              'order_date': currentdate.getDate(),
              'order_year': currentdate.getFullYear(),
              'payment_id': payment_id,
              'payment_type':type,
              'cart_data': JSON.stringify(cart_data_to_post),
              'total_amount': totalPrice,
              'user_id': uid,
              'user_gst':placeOrderGSTIN,
              'user_name': placeOrderFullName,
              'organisation': null,
              'user_email': placeOrderEmail,
              'user_address': JSON.stringify(address),
              'user_phone': placeOrderPhoneNumber,
              'user_type':'user',
              'user_lat': '0',
              'user_long': '0'
            }).then(() => {
              database.ref("OrderId").update({id:order_id})
                history.push({pathname:"/thankyou",state:{'name':placeOrderFullName,'address':placeOrderAddress1,'total':totalPrice,'email':placeOrderEmail,'number':placeOrderPhoneNumber,'gst':placeOrderGSTIN,'type':type,'totalQuantity':totalQuantity}});
            })
        })
        console.log("tax_total",tax_total);
    }


    const placeOrder = async () => {
      var updatedEmail = document.getElementById("checkoutEmail").value;
      var updatedFullName = document.getElementById("checkoutFullName").value;
      var updatedPhoneNumber = document.getElementById("checkoutMobileNumber").value;
      var updatedAddress1 = document.getElementById("checkoutAddress1").value;
      var updatedLandmark = document.getElementById("checkoutLandmark").value;
      var updatedCity = document.getElementById("checkoutCity").value;
      var updatedPincode = document.getElementById("checkoutPincode").value;
      var updatedGST = document.getElementById("checkoutGstin").value;
      if(updatedEmail == "" || updatedFullName == "" || updatedAddress1=="" || updatedCity=="" || updatedPhoneNumber=="" || updatedPincode==""){
        setShowSnackbar(true);
        setSnackbarSeverity("error");
        setSnackbarMessage("Please enter all address details");  
      }else{
        const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
        if(!res){
          alert('Razorpay failed . Please try again');
          return
        }
        axios.post('https://asia-south1-chariot-trade-aca9d.cloudfunctions.net/razorpayOrderId',{
          amount: totalPrice
        }).then(res => {
          var options = {
            "key": "rzp_live_352i6AK01jeSlw",
            "amount": totalPrice*100,
            "currency": "INR",
            "name": "Chariot Agro Limited",
            "description": "Get the best deals",
            "order_id": res.data.id,
            "image": newlogo,
            "handler": function (response){
                console.log("response",response);
                placeorderApi(response.razorpay_payment_id,"online")
            },
            "prefill": {
                "name": updatedFullName,
                "email": updatedEmail,
                "contact": updatedPhoneNumber
            },
          };
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
          setOpen(false);
        })
      }
    }

      const handleCloseSnackbar = () => {
        setShowSnackbar(false);
      }

      const handleClose = () => {
        setOpen(false);
      }

      useEffect(() => {
        database.ref("OrderId").on('value',(snapshot)=>{
          // console.log("value",snapshot.val().id);
          setOrderId(snapshot.val().id+1);
        });  
        if(JSON.parse(localStorage.getItem("cart"))!=null){
            var cartItems = JSON.parse(localStorage.getItem("cart"));
            var cartArray = [];
            if(!('wheat' in cartItems)){
              cartItems.wheat = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));

            }
            if(!('maida' in cartItems)){
              cartItems.maida = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));

            }
            if(!('suji' in cartItems)){
              cartItems.suji = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));

            }
            if(!('chiroti' in cartItems)){
              cartItems.chiroti = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));

            }
            if(!('afgan' in cartItems)){
              cartItems.afgan = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));

            }
            if(!('indian' in cartItems)){
              cartItems.indian = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));

            }
            if(!('safawi' in cartItems)){
              cartItems.safawi = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));

            }
            if(!('mabroom' in cartItems)){
              cartItems.mabroom = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));

            }
            if(!('almond' in cartItems)){
              cartItems.almond = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));

            }
            if(!('cashew' in cartItems)){
              cartItems.cashew = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));

            }
            if(!('pista' in cartItems)){
              cartItems.pista = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));

            }if(!('hing' in cartItems)){
              cartItems.hing = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }
            if(!('vapor' in cartItems)){
              cartItems.vapor = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }if(!('oxymeter' in cartItems)){
              cartItems.oxymeter = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }if(!('scale1' in cartItems)){
              cartItems.scale1 = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }if(!('scale2' in cartItems)){
              cartItems.scale2 = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }if(!('respiratory' in cartItems)){
              cartItems.respiratory = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }if(!('bodyfat' in cartItems)){
              cartItems.bodyfat = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }if(!('eb600' in cartItems)){
              cartItems.eb600 = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }if(!('eb500' in cartItems)){
              cartItems.eb500 = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }if(!('st309' in cartItems)){
              cartItems.st309 = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }if(!('eb200' in cartItems)){
              cartItems.eb200 = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }if(!('pf120a' in cartItems)){
              cartItems.pf120a = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }if(!('eb100' in cartItems)){
              cartItems.eb100 = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }if(!('ic900' in cartItems)){
              cartItems.ic900 = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
      
            }if(!('rasam' in cartItems)){
              cartItems.rasam = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            }if(!('sambar' in cartItems)){
              cartItems.sambar = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            }     
            if(!('garam' in cartItems)){
              cartItems.garam = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            }        
            if(!('pulav' in cartItems)){
              cartItems.pulav = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            }          
            if(!('fish' in cartItems)){
              cartItems.fish = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            }       
            if(!('biryani' in cartItems)){
              cartItems.biryani = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            }   
            if(!('chicken' in cartItems)){
              cartItems.chicken = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            }   
            if(!('kabab' in cartItems)){
              cartItems.kabab = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            }            
            if(!('chilli' in cartItems)){
              cartItems.chilli = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            }                  
            if(!('turmeric' in cartItems)){
              cartItems.turmeric = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            } 
            if(!('coriander' in cartItems)){
              cartItems.coriander = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            }                        
            if(!('jeera' in cartItems)){
              cartItems.jeera = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            }
            if(!('pepper' in cartItems)){
              cartItems.pepper = [];
              localStorage.setItem("cart",JSON.stringify(cartItems));
            }
            cartArray = cartArray.concat(cartItems['wheat']);
            cartArray = cartArray.concat(cartItems['maida']);
            cartArray = cartArray.concat(cartItems['suji']);
            cartArray = cartArray.concat(cartItems['chiroti']);
            cartArray = cartArray.concat(cartItems['afgan']);
            cartArray = cartArray.concat(cartItems['indian']);
            cartArray = cartArray.concat(cartItems['mabroom']);
            cartArray = cartArray.concat(cartItems['safawi']);
            cartArray = cartArray.concat(cartItems['almond']);
            cartArray = cartArray.concat(cartItems['cashew']);
            cartArray = cartArray.concat(cartItems['pista']);
            cartArray = cartArray.concat(cartItems['hing']);
            cartArray = cartArray.concat(cartItems['vapor']);
            cartArray = cartArray.concat(cartItems['oxymeter']);
            cartArray = cartArray.concat(cartItems['scale1']);
            cartArray = cartArray.concat(cartItems['scale2']);
            cartArray = cartArray.concat(cartItems['respiratory']);
            cartArray = cartArray.concat(cartItems['bodyfat']);
            cartArray = cartArray.concat(cartItems['eb600']);
            cartArray = cartArray.concat(cartItems['eb500']);
            cartArray = cartArray.concat(cartItems['st309']);
            cartArray = cartArray.concat(cartItems['eb200']);
            cartArray = cartArray.concat(cartItems['pf120a']);
            cartArray = cartArray.concat(cartItems['eb100']);
            cartArray = cartArray.concat(cartItems['ic900']);
            cartArray = cartArray.concat(cartItems['rasam']);
            cartArray = cartArray.concat(cartItems['sambar']);
            cartArray = cartArray.concat(cartItems['garam']);
            cartArray = cartArray.concat(cartItems['pulav']);
            cartArray = cartArray.concat(cartItems['fish']);
            cartArray = cartArray.concat(cartItems['biryani']);
            cartArray = cartArray.concat(cartItems['chicken']);
            cartArray = cartArray.concat(cartItems['kabab']);
            cartArray = cartArray.concat(cartItems['chilli']);
            cartArray = cartArray.concat(cartItems['turmeric']);
            cartArray = cartArray.concat(cartItems['coriander']);
            cartArray = cartArray.concat(cartItems['jeera']);
            cartArray = cartArray.concat(cartItems['pepper']);

            setCartItems(cartArray);
            var images = [];
            var linkImages = [];
              setTotalPrice(JSON.parse(localStorage.getItem("totalPrice")))
              var quan = 0;
            cartArray.map(item => {
              quan+=parseInt(item.quantity);
              if(item.type == "wheat"){
                images.push(wheat);
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/wheat.png?alt=media&token=f3a66230-ec89-4615-86e2-95ec4e69692a");
              }else if(item.type == "maida"){
                images.push(maida);
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/maida.png?alt=media&token=64c7b457-aed0-4eed-bae5-a96c00fccacd");
              }else if(item.type == "chiroti"){
                images.push(chiroti);
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/chiroti.png?alt=media&token=ed8c91e0-ff8b-4f15-a1df-9820ae73bda2");
              }else if(item.type == "afgan"){
                images.push(afgan);
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/afghanraisinsfront.jpg?alt=media&token=0526ade6-31ed-4610-943f-85aba6ff0adb");
              }else if(item.type == "indian"){
                images.push(indian);
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/indianraisinsfront.jpg?alt=media&token=39f2dba6-8b6b-470a-a842-6861bf24c32e");
              }else if(item.type == "mabroom"){
                images.push(mabroom);
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/mabroomfront.jpg?alt=media&token=d462969b-4497-4ed9-9daf-3031f5e036b3");
              }else if(item.type == "safawi"){
                images.push(safawi);
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/safawifront.jpg?alt=media&token=091c8850-7c5d-4816-8a08-bd982a2bc6ca");
              }else if(item.type == "almond"){
                images.push(almond);
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/almond1.jpg?alt=media&token=eab27d9a-a30b-441c-8160-c25e02b072ed");
              }else if(item.type == "pista"){
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/pista1.jpg?alt=media&token=20daec7c-a17f-4e20-b529-446fb1e5c578");
                images.push(pista);
              }else if(item.type == "cashew"){
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/cashew1.jpg?alt=media&token=b1e9747d-d85d-456a-8323-1b2bc0893572");
                images.push(cashew);
              }else if(item.type == "vapor"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/newNikVaporizer.png?alt=media&token=cff0e0e0-ef8b-40de-bcd1-bdc887acfb04");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/newNikVaporizer.png?alt=media&token=cff0e0e0-ef8b-40de-bcd1-bdc887acfb04")
              }else if(item.type == "oxymeter"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/oxymeter.png?alt=media&token=2d9527ab-fc78-4f71-8069-dd05f4d278a4");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/oxymeter.png?alt=media&token=2d9527ab-fc78-4f71-8069-dd05f4d278a4")

              }else if(item.type == "scale1"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/pse101.png?alt=media&token=3bca7b96-e0ba-42ec-9261-a8151aa9245b");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/pse101.png?alt=media&token=3bca7b96-e0ba-42ec-9261-a8151aa9245b")

              }else if(item.type == "scale2"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/pse2011.png?alt=media&token=53567ae9-6142-4f88-b6a3-8364efaeec90");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/pse2011.png?alt=media&token=53567ae9-6142-4f88-b6a3-8364efaeec90")

              }else if(item.type == "respiratory"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/respiratory1.png?alt=media&token=6e031a24-490e-4c32-9779-1e7550c6b569");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/respiratory1.png?alt=media&token=6e031a24-490e-4c32-9779-1e7550c6b569")

              }else if(item.type == "bodyfat"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/wf2601.png?alt=media&token=c846b6bb-a886-4b66-ba14-3a59082ee55c");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/wf2601.png?alt=media&token=c846b6bb-a886-4b66-ba14-3a59082ee55c")

              }else if(item.type == "eb600"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb6001.png?alt=media&token=b0e02464-0fb5-412a-8953-7e16e1a1d4c5");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb6001.png?alt=media&token=b0e02464-0fb5-412a-8953-7e16e1a1d4c5")

              }else if(item.type == "eb500"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb5001.png?alt=media&token=2cbecd7b-bf8a-41d1-ade8-94d7bbc1dd79");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb5001.png?alt=media&token=2cbecd7b-bf8a-41d1-ade8-94d7bbc1dd79")

              }else if(item.type == "st309"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/stethescope1.png?alt=media&token=19f78807-a1dd-410c-ad92-478bb819957f");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/stethescope1.png?alt=media&token=19f78807-a1dd-410c-ad92-478bb819957f")

              }else if(item.type == "eb200"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb2001.png?alt=media&token=598cf0bc-8461-423b-ae30-c2b527276f8a");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb2001.png?alt=media&token=598cf0bc-8461-423b-ae30-c2b527276f8a")

              }else if(item.type == "pf120a"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/pf120A1.png?alt=media&token=13638d0c-0a24-41e5-bec7-18dd970cdb5d");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/pf120A1.png?alt=media&token=13638d0c-0a24-41e5-bec7-18dd970cdb5d")

              }else if(item.type == "eb100"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb100.png?alt=media&token=5ff832f2-29c9-4e48-a1eb-9b72f31829c4");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb100.png?alt=media&token=5ff832f2-29c9-4e48-a1eb-9b72f31829c4")

              }else if(item.type == "ic900"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/coolPack1.png?alt=media&token=9fa36616-6cf6-41d8-8035-006332bd2f6d");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/coolPack1.png?alt=media&token=9fa36616-6cf6-41d8-8035-006332bd2f6d")

              }else if(item.type=="hing"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Hing-1.jpg?alt=media&token=3ec12f0d-7044-4ae0-9e1d-14f1ab0db888");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Hing-1.jpg?alt=media&token=3ec12f0d-7044-4ae0-9e1d-14f1ab0db888");

              }else if(item.type=="rasam"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Rasam-Powder.jpg?alt=media&token=ae9d8ede-74eb-46a1-a78d-69d05955dbe7");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Rasam-Powder.jpg?alt=media&token=ae9d8ede-74eb-46a1-a78d-69d05955dbe7");

              }else if(item.type=="sambar"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Sambar-Powder.jpg?alt=media&token=9075867c-35bd-4c7f-af4c-709a79467a9f");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Sambar-Powder.jpg?alt=media&token=9075867c-35bd-4c7f-af4c-709a79467a9f");
              }else if(item.type=="garam"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Garam-Masala.jpg?alt=media&token=da95e516-c346-4bee-a221-89479c4653c4");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Garam-Masala.jpg?alt=media&token=da95e516-c346-4bee-a221-89479c4653c4");
              }else if(item.type=="pulav"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Veg-Pulav-Masala.jpg?alt=media&token=7fb7aaca-4401-4ad5-9104-6578b59d9002");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Veg-Pulav-Masala.jpg?alt=media&token=7fb7aaca-4401-4ad5-9104-6578b59d9002");
              }else if(item.type=="fish"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Fish-Fry-Masala.jpg?alt=media&token=eb3659fc-f792-4c40-bd1e-c8082630a4ab");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Fish-Fry-Masala.jpg?alt=media&token=eb3659fc-f792-4c40-bd1e-c8082630a4ab");
              }else if(item.type=="biryani"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Chicken-Biryani.jpg?alt=media&token=d0706d57-2575-4883-9f45-45bb0f5f874f");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Chicken-Biryani.jpg?alt=media&token=d0706d57-2575-4883-9f45-45bb0f5f874f");
              }else if(item.type=="chicken"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Chicken-Masala.jpg?alt=media&token=8e4f1cbc-c2d4-43c6-b07e-b76b84656ae9");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Chicken-Masala.jpg?alt=media&token=8e4f1cbc-c2d4-43c6-b07e-b76b84656ae9");
              }else if(item.type=="kabab"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Chicken-Kabab.jpg?alt=media&token=5b334f71-8f03-41c0-aebc-a5a6f540b434");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Chicken-Kabab.jpg?alt=media&token=5b334f71-8f03-41c0-aebc-a5a6f540b434");
              }else if(item.type=="chilli"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Red-Chilli-Powder.jpg?alt=media&token=51f4c5b1-df6b-451e-9f3e-f6f47c7334e9");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Red-Chilli-Powder.jpg?alt=media&token=51f4c5b1-df6b-451e-9f3e-f6f47c7334e9");
              }else if(item.type=="turmeric"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Turmeric-Powder.jpg?alt=media&token=8ea5a9ba-83a3-4c7d-869f-8776b94bb279");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Turmeric-Powder.jpg?alt=media&token=8ea5a9ba-83a3-4c7d-869f-8776b94bb279");
              }else if(item.type=="coriander"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Coriander-Powder.jpg?alt=media&token=faab4eba-6929-450f-9ac1-276f68645ac7");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Coriander-Powder.jpg?alt=media&token=faab4eba-6929-450f-9ac1-276f68645ac7");
              }else if(item.type=="jeera"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Jeera-Powder.jpg?alt=media&token=3211b88c-9efa-446c-86e7-0ba9c261834c");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Jeera-Powder.jpg?alt=media&token=3211b88c-9efa-446c-86e7-0ba9c261834c");
              }else if(item.type=="pepper"){
                images.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Pepper-Powder.jpg?alt=media&token=1cfc6de3-2ce7-41be-bb30-ebaf71f11d70");
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Pepper-Powder.jpg?alt=media&token=1cfc6de3-2ce7-41be-bb30-ebaf71f11d70");
              }
              else{
                images.push(suji_rava);
                linkImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/suji_rava.png?alt=media&token=b628f09f-1aae-4207-bcd3-9c8357d85ff2");
              }
            })
            setTotalQuantity(quan);
            setCartItemImages(images);
            setCheckoutImages(linkImages);
        }
        let uid = localStorage.getItem('token');
        var get_user = database.ref("User").child(uid);
        get_user.once("value").then(function(snapshot){
          const address = JSON.parse(snapshot.val().user_address);
          const userName = snapshot.val().user_name;
          const userPhone = snapshot.val().user_phone;
          const userEmail = snapshot.val().user_email;
          const lat = snapshot.val().user_lat;
          const long = snapshot.val().user_long;
          if(userEmail!==""){
            document.getElementById("checkoutEmail").value = userEmail;
          }if(userName!==""){
            document.getElementById("checkoutFullName").value = userName;
          }if(userPhone!==""){
            document.getElementById("checkoutMobileNumber").value = userPhone;
          }if(Object.keys(address).length!=0){
            setAddAddress(false);
            if(address.addressLine1!=null){
              document.getElementById("checkoutAddress1").value = address.addressLine1;
            }if(address.city!=null){
              document.getElementById("checkoutCity").value = address.city;
            }if(address.landmark!=null){
              document.getElementById("checkoutLandmark").value = address.landmark;
            }if(address.pincode!=null){
              document.getElementById("checkoutPincode").value = address.pincode;
            }if(address.gst!=null){
              document.getElementById("checkoutGstin").value = address.gst;
            }
          } 
        })  
      },[]);


    return (
        <div className={classes.root}>

        <Grid container spacing={0}>

        <Grid item xs={12} sm={6} style={{backgroundColor:'#efefef',height:'100vh'}}>
          <div className={classes.paperleft} elevation={0}>
              <div className={classes.leftbar}>
                  <div style={{display:'flex',justifyContent:'space-between'}}>
                <img src ={newlogo} className={classes.logo} />
                <div className={classes.cartlogo}>

                <Badge badgeContent={totalQuantity} max={9999} color="error" classes={{ badge: classes.badge }} >
                  <FontAwesomeIcon icon={basket} onClick={handleClickOpenCart} style={{paddingRight:"5px",fontSize:"25px",color:"#97bc77"}}></FontAwesomeIcon>
                </Badge>
                </div>
                </div>

              <Form>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label className={classes.contacttitle}>Contact Information</Form.Label>
                    <Form.Control type="email" style={{textTransform:"none"}} placeholder="Enter email" id="checkoutEmail" className={classes.inputtext} />
                </Form.Group>   

                <Form.Group controlId="formBasicPassword">
                    <Form.Label  className={classes.contacttitle}>Shipping Address</Form.Label>
                    <Form.Control type="text" placeholder="Full Name" id="checkoutFullName" className={classes.inputtext} />
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                    <Form.Control type="text" placeholder="Address" id="checkoutAddress1" className={classes.inputtext}/>
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                    <Form.Control type="text" placeholder="Landmark (optional)" id="checkoutLandmark" className={classes.inputtext} />
                </Form.Group>
                <Form.Group controlId="formBasicPassword" style={{display:'flex',justifyContent:'space-between'}}>
                    <Form.Control type="text" placeholder="City" id="checkoutCity" style={{marginRight:'10px'}} className={classes.inputtext}/>
                    <Form.Control type="text" placeholder="State" id="checkoutState" style={{marginRight:'10px'}} className={classes.inputtext} value="KARNATAKA"/>
                    <Form.Control type="number" placeholder="PinCode" id="checkoutPincode" className={classes.inputtext} />
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                    <Form.Control type="text" id="checkoutMobileNumber" placeholder="Mobile Number" className={classes.inputtext}/>
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                    <Form.Control type="text" id="checkoutGstin" placeholder="GST Number (optional)" className={classes.inputtext}/>
                </Form.Group>

                <Form.Group  className={classes.footer}>
                  <Link to={"/cart"} style={{textDecoration:"none"}}><div class="returncart"><KeyboardBackspaceIcon style={{marginRight:'5px',padddingBottom:'2px'}}/>Return to cart</div></Link>
                  <div class="returnToCartMobile" onClick={()=>setOpenEditCart(true)}><KeyboardBackspaceIcon style={{marginRight:'5px',padddingBottom:'2px'}}/>Return to cart</div>
                    <div className={classes.buttonFooter} >
                        <div className={classes.button} onClick={addOrUpdateAddress}>
                          {addAddress==true?"Add Address":"Update Address"}
                        </div>
                        <div className={classes.button} onClick={checkModeOfPayment}>
                            Place order
                        </div>
                    </div>
                </Form.Group>            
    
                </Form>
              </div>
          </div>
        </Grid>
        <Grid item xs={0} sm={6} className={classes.rigthbar} style={{height:"100vh",overflow:"scroll"}}>
          <Paper className={classes.paper} elevation={0}>
          <Grid container spacing={1} className={classes.gridheader}>
                <Grid item xs={4}>
                <Paper className={classes.paper} elevation={0}>
                    <div className={classes.tabletitle}>Products‎</div>
                </Paper>
                </Grid>
                <Grid item xs={6}>
                <Paper className={classes.paper}  elevation={0} >
                <div className={classes.tabletitle}>Product Name‎</div>
                </Paper>
                </Grid>
           
                <Grid item xs={2}>
                <Paper className={classes.paper}  elevation={0} >
                      <div className={classes.tabletitle}>Subtotal</div>
                </Paper>
                </Grid>
            </Grid>
            {
                cartItems.map((cartItem,index) => {
                  return <Grid container spacing={1} className={classes.gridheader}>
                    <Grid item xs={4}>
                      <Paper className={classes.paperproduct} elevation={0}>
                        <img className={classes.productimage} src={cartItemImages[index]}></img>
                      </Paper>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper className={classes.paperproduct}  elevation={0} >
                <div className={classes.producttitle}>{cartItem.name}</div>
                      </Paper>
                    </Grid>
                    <Grid item xs={2}>
                      <Paper className={classes.paperproduct}  elevation={0} >
                      <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div className={classes.producttitle}>₹{cartItem.quantity * cartItem.price}	</div>
                      </div>
                    </Paper>
                  </Grid>
              </Grid>
  
                })
            }

            <div className={classes.pricing}>
                <div className={classes.pricingdetails} >
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',padding:'10px'}}>
                        <div className={classes.mycartsubtotal}>Item subtotal</div>
                        <div className={classes.mycartsubtotal}>₹{totalPrice}</div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',padding:'10px'}}>
                        <div className={classes.mycartsubtotal}>Grand Total</div>
                        <div className={classes.mycartsubtotal}>₹{totalPrice}</div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',padding:'10px'}}>
                        <div className={classes.mycartsubtotal}style={{color:'#5B9D4F'}} >Amount to Pay</div>
                        <div className={classes.mycartsubtotal}>₹{totalPrice}</div>
                    </div>

                </div>
            </div>

          </Paper>
        </Grid>
        </Grid>

        <Dialog  aria-labelledby="customized-dialog-title"   fullScreen={fullScreen}  open={open}
          className={classes.dialog}
        >
            <div style={{minwidth:'80%'}}>
              <DialogTitle class="dialog-title" id="customized-dialog-title" onClose={()=>setOpen(false)}>
                  Mode Of Payment
              </DialogTitle>
              <DialogContent dividers>
                <Form>
                  <label onClick={()=>setActiveRadioButton("online")} style={{padding:"10px",border:"1px solid #bababa",borderRadius:"3px",display:"flex",justifyContent:"space-between",cursor:"pointer"}}>
                    <span>Online Payment</span>
                    {activeRadioButton=="online"?<input type="radio" name="payment" checked="checked"/>:<input type="radio" name="payment"/>}
                  </label>
                  <label onClick={()=>setActiveRadioButton("manual")} style={{padding:"10px",border:"1px solid #bababa",borderRadius:"3px",display:"flex",justifyContent:"space-between",marginTop:"10px",cursor:"pointer"}}>
                    <span>Manual Payment</span>
                    {activeRadioButton=="online"?<input type="radio" name="payment"/>:<input type="radio" name="payment" checked="checked"/>}
                  </label>
                  <div>
                  <Typography  class="dialogloginbutton" onClick={proceed} style={{cursor:"pointer",marginTop:"25px"}}>Proceed</Typography>
                  </div>
                </Form>
              </DialogContent>
            </div>
          </Dialog>


        <Dialog fullScreen open={opencCart} onClose={handleCloseCart} style={{width:'100%'}} >
            <AppBar className={classes.cartappBar} elevation={0}>
            <Toolbar style={{display:'flex',justifyContent:"space-between"}}>
                <Typography variant="h6" className={classes.carttitle}>
                        My Cart
                </Typography>
                <IconButton edge="start" color="inherit" onClick={handleCloseCart} aria-label="close">
                   <CloseIcon style={{fontSize:'18px'}}/>
                </IconButton>
            </Toolbar>
            </AppBar>
            <Paper className={classes.paper} elevation={0}>
          <Grid container spacing={0} className={classes.gridheader}>
                <Grid item xs={5}>
                <Paper className={classes.paper} elevation={0}>
                    <div className={classes.tabletitle}>Products‎</div>
                </Paper>
                </Grid>
                <Grid item xs={4}>
                <Paper className={classes.paper}  elevation={0} >
                <div className={classes.tabletitle}>Product Name‎</div>
                </Paper>
                </Grid>
           
                <Grid item xs={2}>
                <Paper className={classes.paper}  elevation={0} >
                      <div className={classes.tabletitle}>Subtotal</div>
                </Paper>
                </Grid>
            </Grid>

            {
                cartItems.map((cartItem,index) => {
                  return <Grid container spacing={1} className={classes.gridheader}>
                    <Grid item xs={4}>
                      <Paper className={classes.paperproduct} elevation={0}>
                        <img className={classes.productimage} src={cartItemImages[index]}></img>
                      </Paper>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper className={classes.paperproduct}  elevation={0} >
                <div className={classes.producttitle}>{cartItem.name}</div>
                      </Paper>
                    </Grid>
                    <Grid item xs={2}>
                      <Paper className={classes.paperproduct}  elevation={0} >
                      <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div className={classes.producttitle}>₹{cartItem.quantity * cartItem.price}	</div>
                      </div>
                    </Paper>
                  </Grid>
              </Grid>
  
                })
            }

            <div className={classes.pricing}>
                <div className={classes.pricingdetails} >
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',padding:'10px'}}>
                        <div className={classes.mycartsubtotal}>Item subtotal</div>
                        <div className={classes.mycartsubtotal}>₹{totalPrice}</div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',padding:'10px'}}>
                        <div className={classes.mycartsubtotal}>Grand Total</div>
                        <div className={classes.mycartsubtotal}>₹{totalPrice}</div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',padding:'10px'}}>
                        <div className={classes.mycartsubtotal}style={{color:'#5B9D4F'}} >Amount to Pay</div>
                        <div className={classes.mycartsubtotal}>₹{totalPrice}</div>
                    </div>

                </div>
            </div>

        </Paper>
                            
        </Dialog>

        <Dialog fullScreen open={openEditCart} onClose={handleCloseEditCart} style={{width:'100%'}} >
                            <AppBar className={classes.cartappBar} elevation={0}>
                              <Toolbar style={{display:'flex',justifyContent:"space-between"}}>
                                <Typography variant="h6" className={classes.carttitle}>
                                  My Cart
                                </Typography>
                                <Link to= "/" style={{color:'white'}}>
                                <IconButton edge="start" color="inherit" onClick={handleCloseEditCart} aria-label="close">
                              <CloseIcon style={{fontSize:'18px'}}/>
                                </IconButton>
                                </Link>
                              </Toolbar>
                            </AppBar>
                            <div className='mycart'>

                                {
                                    cartItems.length === 0 ? 
                                      <div style={{display:'flex',justifyContent:'center',flexDirection:'column'}}>
                                        <img style={{height:300,width:'90%',marginLeft:'5%',objectFit:'contain'}} src ={EmptyCart} alt="no items image" />
                                      </div>
                                    
                                  :
                                  cartItems.map((cartItem,index) => {
                                  
                                    return (<div style={{display:'flex',width:'100%',borderBottom:'1px solid #cdcdcd'}}>
                                      <div className={classes.basketdropdownimage}>
                                        <img src={cartItemImages[index]} className={classes.basketdropdownitemimage}></img>
                                      </div >
                                      <div className={classes.basketdropdowntitle}>
                                      {cartItem.name}
                                      <div className={classes.basketdropdownprice}>
                                      Price: ₹{cartItem.price}
                                      </div>
                                      <div  className={classes.basketdropdownprice}>
                                      Qty: {cartItem.weight}
                                      </div>
                                    </div>
                                    <div className={classes.basketdropdowndelete}>
                                      <FontAwesomeIcon icon={trash} onClick={()=>deleteItem(index,cartItem.type,cartItem.weight,cartItem.quantity,cartItem.price)} style={{paddingRight:"5px",marginLeft:'20%',textAlign:'center',fontSize:"20px",color:"#000"}}><span>Search</span></FontAwesomeIcon> 
                                      <div style={{width:'40%',marginRight:'10px',display:'flex'}}>
                                      <MDBBtn onClick={()=>quantityChange("minus",index,cartItem.type,cartItem.weight)} style={{borderRadius:8, borderWidth:1, borderColor:'#efefef',paddingRight:'12px',paddingLeft:'8px', margin:3,backgroundColor:'#f65f73',color:'white'}} >-</MDBBtn>
                                      <span className={classes.mobilecounter}>{cartItem.quantity}</span>
                                            <MDBBtn onClick={()=>quantityChange("plus",index,cartItem.type,cartItem.weight)} style={{borderRadius:8, borderWidth:1,paddingRight:'15px',paddingLeft:'7px',borderColor:'#efefef', margin:3,backgroundColor:'#97bc77',color:'white'}}>+</MDBBtn>
                                      </div>
                                    </div>
                                  </div>)
                                  })
                                }

                        
                  {       
                    cartItems.length === 0 ?
                     <div></div> :

                    <div style={{marginTop:'40px'}}>
                            <div style={{display:'flex',justifyContent:'space-between',width:'70%'}}>
                              <Typography className={classes.mycartsubtotal}>Subtotal:</Typography>
                              <Typography className={classes.mycartsubtotalprice}> ₹ {totalPrice}</Typography>
                            </div>
                            <div style={{display:'flex',justifyContent:'space-between' ,width:'70%'}}>
                              <Typography className={classes.mycartsubtotal}>Grand Total:</Typography>
                              <Typography className={classes.mycartsubtotalprice}> ₹ {totalPrice}</Typography>
                            </div>
                            <div style={{display:'flex',justifyContent:'space-between',width:'70%'}}>
                              <Typography className={classes.mycartsubtotal} style={{color:'#3c763d'}}>Amount to pay:</Typography>
                              <Typography className={classes.mycartsubtotalprice}> ₹ {totalPrice}</Typography>
                            </div>
                          </div>
                  }
                     {       
                    cartItems.length === 0 ?
                     <div></div> :

                            <div className={classes.bottomsubtotal}>
                            <div className={classes.basketdropdownsubtotalfooter}>
                              <div className={classes.basketdropdownsubtotal}>
                                Grand Total:<span className={classes.basketdropdownsubtotalprice}>&#x20B9;{totalPrice}</span>
                              </div>
                              <div style={{display:'flex'}}>
                              <Link style={{textDecoration:"none"}} to={"/checkout"}> <div className={classes.basketdropdownbutton}>Checkout</div></Link>
                              </div>
                            </div>
                            </div> 
                      }
                            </div>
           
                          </Dialog>

                          <Snackbar open={showSnackbar} autoHideDuration={2000} onClose={handleCloseSnackbar}>
                            <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                              <p style={{fontSize:"14px"}}>{snackbarMessage}</p>
                            </Alert>
                          </Snackbar>

        </div>
    )
}
