import React from 'react';
import EditAcc from '../containers/account/editacc';
import BrowsePageNavigation from '../components/navigation/browseNavigation'
import FooterPage from '../components/Footer'
import Navbar from '../components/navigation/navbar'
import Sidebar from '../components/navigation/sidebar';
export default function AddressBook(){
    return( 
        <div>
            <BrowsePageNavigation/>
            {/* <p>My Account</p> */}
            <div style={{display:"flex", marginTop:"3%"}} className="row">
                <div className="container">
                    <div className="sidebar">
                        <Sidebar />
                    </div>
                    <div style={{flex:"0 0 80%",textAlign:"left"}} className="contents">
                        <EditAcc/>
                    </div>
                
                </div>
            </div>
            <div style={{position:"relative",left:"0px", bottom:"0px",width:"100%"}} >
                <FooterPage/>
            </div>
        </div>
    )
}