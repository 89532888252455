import React from 'react';
import BrowsePageNavigation from '../navigation/browseNavigation'
import FooterPage from '../Footer';
import HomeComponentTop from '../homepage/homeTop';
import aboutUsHeader from '../../static/img/ban_2.jpg';


class TermsConditionsComponent extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            totalPrice: 0,
            cartItems: {"wheat":[],"maida":[],"suji":[],"chiroti":[]},
        }
      }

    render(){

        return (
            <div>
                <HomeComponentTop cartItems={this.state.cartItems} totalPrice={this.state.totalPrice}/>
                <div className="page_heading" style={{backgroundImage:`url(`+aboutUsHeader+`)`,backgroundRepeat:"no-repeat",color:"#ffb637",textAlign:"center",padding:"60px 0 60px 0"}}>
                    <div className="container">
                        <div className="row" style={{marginLeft: "0px"}}>
                            <div className="col-xs-12">
                                <div className="page-title">
                                    <h2>Terms & Conditions</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{marginLeft:"15vw",marginRight:"15vw",textAlign: "justify" ,textJustify: "inter-word"}} className = "c1">
                    <br/><br/>
                    <h2>Terms and Conditions</h2>
                    <p>Chariot Agro Limited is a Public Limited Company and the website www.chariotagro.com .
                    (” The Site”) is owned by Chariot Agro Limited.</p>
                    <p>These Terms and Conditions apply to placing an Order (defined below) or making a purchase
                    (defined below). Our privacy policy forms part of these terms and conditions. If you do not
                    agree to be bound by these terms and conditions and privacy policy, you may not place an
                    Order or make a purchase. By placing an order, you are giving your agreement to be bound
                    by these terms and conditions.</p>
                    <br/>
                    <h4>DEFINITIONS:</h4>
                    <ol>
                        <li>In these Terms and Conditions, the following words shall have the meaning set out
                        below</li>
                        <li>&quot;Contract&quot; means the contract between you and us formed as set out in Clause 4
                        (Purchase of Products on Website).</li>
                        <li>
                        &quot;Products&quot; / “Goods “means Products sold by Chariot Agro Limited” and Chariot
                        Agro Limited Affiliate’s name (“Products”) made available for sale by us.           
                        </li>
                        <li>
                            &quot;Order&quot; means a contractual offer by you to make a Purchase by submitting the
                            Order via the Website.
                        </li>
                        <li>
                            “Order Cancellation Email” means the email sent to you by us to confirm
                            cancellation of your Order or any email sent by you to us under the “Cancellation
                            and Returns Policy”.
                        </li>
                        <li>
                            &quot;Order Confirmation Email&quot; means the email sent to you to confirm receipt of your
                            Order as defined in Clause 4.1.5.
                        </li>
                        <li>
                            &quot;Personal Information&quot; means information about you or a third party provided by
                            you when submitting an Order or when using the Website.
                        </li>
                        <li>
                            &quot;Price&quot; means the Total Cost of the product mentioned on the site.
                        </li>
                        <li>
                            &quot;Purchase&quot; means the acceptance by us of an order for the purchase of products
                            placed via e commerce Website.
                        </li>
                        <li>
                            “Shipping Confirmation Email” means the email sent to you confirming that we have
                            shipped your Order or part of your Order.
                        </li>
                        <li>
                            &quot;Offer&quot; means an offer or promotion advertised by us.
                        </li>
                        <li>
                            &quot;Terms and Conditions&quot; means the terms and conditions of sale set out in this
                            document together with the Privacy Policy.
                        </li>
                        <li>
                            &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; means Chariot Agro Limited having its Registered Office at
                            # 3446/40, 1 st  ‘F’ Cross, Next to Bunts Sangha, Vijayanagar, Bangalore –
                            560040.
                        </li>
                        <li>
                            &quot;Website&quot; means the ecommerce website or any subsequent URL which may replace
                            it.
                        </li>
                    </ol>
                    <br/>
                    <h4>ACCESS:</h4>
                    <p>You are provided with access to the Website in accordance with our Terms and
                    Conditions mentioned herein.</p>
                    <br/>
                    <h4>ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION​</h4>
                    <ol>
                        <li>We are not responsible if information made available on this site is not complete or
                            current. The material on this site is provided for general information only and should
                            not be relied upon or used as the sole basis for making decisions without consulting
                            primary, more accurate, more complete or timelier sources of information. </li>
                        <li>This site may contain certain historical information. Historical information,
                        necessarily, is not current and is provided for your reference only. We reserve the
                        right to modify the contents of this site at any time, but we have no obligation to
                        update any information on our site. You agree that it is your responsibility to
                        monitor changes to our site.</li>
                    </ol>
                    <br/>
                    <h4>GENERAL CONDITIONS OF MAKING A PURCHASE:</h4>
                    <p>These Terms and Conditions shall apply to all orders and purchases.</p>
                    <ol>
                        <li>
                        By making an order or purchase you are hereby accepting all these terms and
                        conditions.
                        </li>
                        <li>
                        The advertisement of products on the website or in any publication does not
constitute an &quot;invitation to buy&quot; and are for information purposes only.
                        </li>
                        <li>
                        Your order to buy signifies that you are acting purely on your own will.
                        </li>
                        <li>
                        Nothing that we do or say will constitute an acceptance of your order until the order
number is generated in our system.
                        </li>
                        <li>
                        Products or goods are supplied for commercial use and for resale as well as for
domestic use. We exclude to the fullest extent permitted by law any warranties and
conditions regarding fitness of our product/goods for a particular purpose where you
use the product/goods for other than domestic use. We reserve the right to refuse
any orders without assigning any reasons.
                        </li>
                        <li>
                        We will not file a copy of these terms and conditions specifically in relation to your
purchase. We may update the version of these terms and conditions on the website
from time to time, and we do not guarantee that the version you have agreed to will
remain accessible. We therefore recommend that you download, print and retain a
copy of these terms and conditions for your records. Failing to do so will
automatically make you bound to the then current version of the terms and
conditions.
                        </li>
                        <li>
                        The only language in which we provide these terms and conditions is English.
                        </li>
                    </ol>
                    <br/>
                    <h4>MAKING A PURCHASE OF PRODUCTS/GOODS ON OUR WEBSITE</h4>
                    <p>To make a purchase on our website, you need to take the following steps:</p>
                    <ol>
                        <li>Click on the products you wish to buy.</li>
                        <li>Input the quantity and weight of the products selected that you wish to buy, then
click &quot;Add to Cart&quot;.</li>
                        <li>Once you have finished shopping, click on the “Shopping Cart” and review your
order. Make changes to your order (if necessary) and complete the delivery as well
as payment details. Indicate your preferred delivery method, then click on &quot;Confirm
Payment&quot;.</li>
                        <li>
                        You will then be transferred to a payment gateway to process your payment details,
at which point you will be subject to certain terms and conditions of payment. No
changes to your order will be possible once your payment has been processed,
although you can cancel your order as set out in the “Cancellation and Return
Policy”.
                        </li>
                        <li>
                        We will then send you an order confirmation email to confirm. You have placed your
order. If the order is a &#39;Cash on Delivery&#39;, we shall make a verification call subsequent
to sending an order confirmation email. In the event of the verification call not being
successful (you fail to respond to the same), then the order will stand cancelled.
                        </li>
                        <li>
                        When we are able to meet your order and payment has been successful, we may
send you a shipping confirmation email.
                        </li>
                        <li>
                        When we are unable to process your order due to the requested products being out
of stock or unavailable for any reason, we will advise you by any available means of
communication to us that we are unable to meet your order. You will be given the
opportunity to proceed with the partial order or to cancel the order. We will send
you an order cancellation email which will decline your order. If you want to proceed
with the order notwithstanding that there will be a delay in delivery of the
products/goods due to them being out of stock or unavailable. We will inform you
once we have dispatched products to you.
                        </li>
                    </ol>
                    <br/>
                    <h4>ACCURACY OF BILLING AND ACCOUNT INFORMATION</h4>
                    <ol>
                        <li>We reserve the right to refuse any order you place with us. We may, in our sole
discretion, limit or cancel quantities purchased per person, per household or per
order. These restrictions may include orders placed by or under the same customer
account, the same credit card, and/or orders that use the same billing and/or
shipping address. In the event that we make a change to or cancel an order, we shall
notify you by contacting the e-mail and/or billing address/phone number provided at
the time the order was made. We reserve the right to limit or prohibit or decline
orders in our sole judgment and without assigning any reasons.</li>
                        <li>
                        You agree to provide current, complete and accurate purchase and account
information for all purchases made at our store. You agree to promptly update your
account and other information, including your email address and credit card
numbers and expiration dates, so that we can complete your transactions and
contact you as needed.
                        </li>
                    </ol>
                    <br/>
                    <h4>AGE OF CONSENT​</h4>
                    <ol>
                        <li>Where products may only be purchased by persons of a certain age, you will be
asked when placing an order to declare that you are of the appropriate legal age to
purchase the products. By submitting an order, you are confirming to us that you are
of an appropriate legal age to purchase the products requested.</li>
                        <li>If we discover or are of the opinion (as to which we shall have sole discretion) that
you are not legally entitled to order certain products, we shall be entitled to cancel
the order immediately, without notice.</li>
                    </ol>
                    <br/>
                    <h4>PRICE AND PAYMENT​</h4>
                    <ol>
                        <li>
                        The price of the Products/goods shall be that stipulated on the Website. The price is
quoted in INR and is inclusive of applicable GST if any (&quot;Price&quot;). The product/goods
pricing is subject to change at any time without notice, at our sole discretion. We
reserve the right to discontinue any product/goods at any time.
                        </li>
                        <li>
                        The Website contains a large number of products/goods and is always possible that
some of the prices on the website and/or on our advertisements may be incorrect. If
we have mis-priced any products, we will not be liable to supply the products/goods
to you at the stated price, provided that we notify you of the change prior to
dispatching the products to you. In those circumstances, we will contact you to
intimate the correct price and proceed for cancellation of your order and refund of
the payment already received (Please refer our cancellation and return policy for the
same). If we are unable to contact you then we will treat the order as having been
cancelled and we shall appropriately proceed to refund the payment already
received.
                        </li>
                        <li>
                        Payment can be made either by Debit /Credit card, Net banking and / or any other
legal modes of payments and/or immediately upon delivery of products in case of
“Cash on Delivery (COD)”.
                        </li>
                        <li>
                        When making a purchase via our website, payment must be made in INR. All card
transactions are processed through a secure payment gateway. All credit/debit card
purchases are subject to validation checks and authorisation by the card issuer. If the
issuer of your payment card refuses to or does not for any reason authorise payment
to us for a particular order, we will not complete your Order.
                        </li>
                    </ol>
                    <br/>
                    <h4>PRIVACY​</h4>
                    <ol>
                        <li>Our Privacy Policy forms part of these Terms and Conditions and details how we will
deal with your personal information including payment information.</li>
                    </ol>
                    <br/>
                    <h4>OUR RIGHTS​</h4>
                    <ol>
                        <li>We reserve the right to withdraw any product/goods from the website at any time.
We shall not be liable to anyone for withdrawing any product/goods from the
website or for refusing to accept an order.</li>
                        <li>We reserve the right, but are not obligated, to limit the sales of our products to any
person, geographic region, or jurisdiction. We may exercise this right on a case-by-
case basis. We reserve the right to limit the quantities of any products that we offer
for stock level purposes.</li>
                        <li>We have a right to revise and amend these terms and conditions (including price)
without notice. However, any changes will not affect contracts which have already
been concluded.</li>
                    </ol>
                    <br/>
                    <h4>WARRANTY​</h4>
                    <ol>
                        <li>We cannot describe every detail of the product/goods on the Website and each
description is therefore limited. We endeavour to ensure that descriptions are
correct at the time of their input. Please note that the colours and appearance of
product/goods may vary from how they appear on screen, and, in particular, we are
not responsible for ensuring that your monitor will accurately display the colour of
the product/goods.</li>
                        <li>We do not warrant that the quality of any products, services, information, or other
material purchased or obtained by you will meet your expectations.</li>
<li>We expressly exclude warranties, conditions, or terms whether implied by statute,
common law or otherwise to the fullest extent permitted by law. Any
recommendation or suggestion relating to any of the products made by us is given in
good faith, but it is for you to satisfy yourself as to the suitability of the
product/goods for your purposes.</li>
<li>You warrant and undertake to us that, (a) you are legally capable of entering into
binding contracts, and you have full authority, power and capacity to agree to these
terms and conditions, (b) the information provided in your order is accurate and
complete and (c) you or your authorised person will be able to accept delivery of the
products.</li>
                    </ol>
                    <br/>
                    <h4>​SHIPPING​</h4>
                    <ol>
                        <li>Unless we agree otherwise with you, products supplied by us will be shipped within
the suggested time on our site. We further reiterate that these are suggestions, and
the actual execution may vary.</li>
<li>We shall use our reasonable endeavours to meet any date agreed for shipment.
Notwithstanding the foregoing, we shall not be liable for any losses, costs, damages
or expenses incurred by you or any third party arising directly or indirectly out of any
failure to meet any estimated shipping date.</li>
<li>Shipping of the products shall be made to the shipping address specified in the order
and you shall make all arrangements necessary to take receipt of the products.</li>
<li>Our courier will make all reasonable efforts to deliver the products to you. However,
if you are not residing at the contact address provided to us and/or have not made
arrangements to take delivery of the products and/or the courier is unable to
contact you for this purpose, your purchase will be deemed as “cancelled “and
payment will not be refunded.</li>
<li>Where the size/quantum of the product/goods requires someone to be present at
your address for delivery and you are not present, your placement of an order
authorises us to accept the signature of another person present at your address and
deliver the product/goods to them on your behalf or to leave the product/goods in a
reasonably safe place as determined by the delivery person or with a neighbour.</li>
                    </ol>
                    <br/>
                    <h4>TITLE AND RISK​</h4>
                    <ol>
                        <li>
                        Ownership of the product/goods will only pass to you upon later of: (a) delivery of
the product/goods; or (b) receipt by us of full payment of all sums due in respect of
the product/goods (including delivery charges). Delivery of the product/goods to you
will include leaving the item in a safe place or with a neighbour or delivering them to
your representative.
                        </li>
                        <li>
                        We will be entitled to recover payment for the product/goods where the products
have been delivered to you even where ownership has not passed to you.
                        </li>
                    </ol>
                    <br/>
                    <h4>CANCELLATION AND RETURNS</h4>
                    <ol>
                        <li>
                        Please refer to our cancellation and returns policy.
                        </li>
                    </ol>
                    <br/>
                    <h4>LIMITATION OF LIABILITY​</h4>
                    <ol>
                        <li>If we breach these terms and conditions, we shall be liable to you only for losses
which are reasonably foreseeable consequences to both of us at the time you placed
your order and shall be subject to the terms of this clause and these terms and
conditions. We shall not be liable to you for any indirect or consequential losses
whatsoever or however arising including (but not limited to) loss of anticipated
savings, wasted expenditure, loss of privacy or data, business interruption and any
other losses.</li>
                        <li>
                        The website is provided by us on an “as is” basis without any warranties or
guarantees of any kind, express or implied. In particular, but without limitation, we
shall not be liable to you for any incompatibility of the website with your equipment,
software, or internet connection, nor for any technical difficulties which may arise,

including errors with the website or interruptions in the availability of the website or
lack of suitability of the website, and no warranties are given as to merchantability
or fitness for a particular purpose.
                        </li>
                        <li>
                        It is understood and agreed between us that our entire and aggregate liability to you
shall in no circumstances exceed the price of the purchase giving rise to the claim.
                        </li>
                        <li>
                        Nothing in these terms and conditions shall exclude or limit your or our liability for
death or personal injury caused by negligence or breach of statutory duty.
                        </li>
                    </ol>
                    <br/>
                    <h4>WAIVER​</h4>
                    <ol>
                        <li>No failures to exercise and/or delay by us (whether express or implied) in enforcing
any of our rights under this contract shall operate as a waiver thereof.</li>
<li>A temporary waiver by us of any breach of the terms and conditions shall not
prevent the subsequent enforcement of our rights and shall not be deemed to be a
waiver of any subsequent breach of that or any other provision of these terms and
conditions.</li>
                    </ol>
                    <br/>
                    <h4>INDEMNITY AND INTELLECTUAL PROPERTY​</h4>
                    <ol>
                        <li>You may not use the website for transmitting material that is or which encourages
conduct which constitutes a criminal offence, results in a civil liability, or otherwise
breaches any laws, regulations, or codes of practice. You agree to indemnify and
keep us indemnified against all liabilities, claims and expenses that may arise out of
the content you submit, post or transmit via the website, or from the use or misuse
of the website or the violation (in whole or in part) of any of the terms and
conditions by you or by any person for whom you are responsible. You agree that
your dealings (if any) with any third-party suppliers identified or found on or through
the website are solely between you and the third party and you agree not to hold us
liable for any disputes you may have with the third-party supplier.</li>
                        <li>
                        The content of the website is protected by copyright, trademarks and other
intellectual property rights. You may store, retrieve and display the content of the
website on a monitor, store it in electronic form (other than on a server or other
storage device connected to a network) or print copies for your own personal, non-
commercial use, but must keep intact all and any copyright or proprietary notices.
Subject to that, you may not reproduce, copy or distribute, use for commercial
purposes, or modify any of the content of the website without our prior written
consent.
                        </li>
                    </ol>
                    <br/>
                    <h4>ENTIRE AGREEMENT​</h4>
                    <ol>
                        <li>These Terms and Conditions and any other document referred to herein constitute
the entire and only agreement between the parties in relation to its subject matter
and replaces and extinguishes all prior or simultaneous agreements, undertakings,
arrangements, understanding or statements of any nature made by the parties or
any of them whether oral or written (and, if written, whether or not in draft form)
with respect to such subject matter.</li>
                    </ol>
                    <br/>
                    <h4>FORCE MAJEURE​</h4>
                    <ol>
                        <li>We shall not be liable for any delay or failure to perform any of our obligations if the
delay or failure results from events or circumstances outside our reasonable control,
including but not limited to acts of God, lock outs, accidents, war, fire, terrorism,
pandemics, breakdown of plant or machinery or shortage or unavailability of raw
materials from source of supply, telecommunications failure, epidemic, perils of the
sea or air, flood, drought, explosion, sabotage, accident, embargo, riot, civil
commotion or civil authority, including but not limited to acts of local government
and parliamentary authority; disputes of whatever nature and for whatever cause
arising including (but without prejudice to the generality of the foregoing) work to
rule, overtime bar, strikes and walkouts. We shall endeavour to notify you as soon as
possible should any such events or circumstances occur.</li>
                    </ol>
                    <br/>
                    <h4>ASSIGNATION​</h4>
                    <ol>
                        <li>
                        You are not allowed to assign, novate, delegate or sub-contract any of your rights
and obligations under these terms and conditions. We may assign, novate, delegate
or sub-contract any of our rights and obligations under these terms and conditions at
Our discretion.
                        </li>
                    </ol>
                    <br/>
                    <h4>SEVERANCE​</h4>
                    <ol>
                        <li>If any term or provision of these terms and conditions is held invalid, illegal, or
unenforceable for any reason by any court of competent jurisdiction such provision
shall be severed to the minimum extent necessary and the remainder of the
provisions hereof shall continue in full force and effect as if these terms and
conditions had been agreed with the invalid, illegal or unenforceable provision
eliminated.</li>
                    </ol>
                    <br/>
                    <h4>YOUR LEGAL RIGHTS</h4>
                    <ol>
                        <li>​Nothing in these terms and conditions affect your rights under law.</li>
                    </ol>
                    <br/>
                    <h4>CUSTOMER SERVICE</h4>
                    <ol>
                        <li>In the event of a query or complaint about the website or us, please email us
at  atmanand@chariotagro.com or call us on 9945775555.</li>
                    </ol>
                </div>
                <div style={{marginLeft:"15vw",marginRight:"15vw",textAlign: "center" ,textJustify: "inter-word", fontSize:"20px"}} className = "c1">
                        <hr/>
                        <h2>Enquiry</h2>
                        <p style={{font:"23px !important"}}>Please call us on <b>0091 9945775555</b> or email to <b>atmanand@chariotagro.com</b><br/>for any product enquiry or for any information which you may need from<br/>Chariot Agro Limited.</p>
                </div>
                <FooterPage/>
            </div>  
        );
    }
}

export default TermsConditionsComponent;