import React from 'react';
import ChariotLogo from '../../static/img/newChariotLogo.png'
import {faMapPin as pin, faShoppingCart as cart, faPercent as offer, faUser as user, faSearch as search, faLifeRing as info, faPowerOff as logout,faWindowClose as close} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SignInMenu from '../menu/signinmenu'
import {LoginModal} from "../CenteredModal/LoginModal";
import {SignUpModal} from "../CenteredModal/SignUpModal"
import {auth, database} from "../../lib/firebase.prod";
import {Link} from 'react-router-dom';
import Badge from 'react-bootstrap/Badge'
import './browseNavigation.css';
import Dropdown from "react-bootstrap/Dropdown";

export default class BrowsePageNavigation extends React.Component{
    constructor() {
        super()
        this.state = {
            visible: false,
            userAddress : localStorage.getItem('location_LongName'),
            modalShow:false,
            modalShowSignUp:false,
            name:'',
            checkedUpdate:false,
            allProducts: [],
            allCategories: [],
            myId: '',
            sidebar: false,
        };
    }

    componentDidMount() {
        this.checkLoggedIn()
        this.getAllCategoriesProducts()
    }

    componentDidUpdate() {
        if(this.state.name == ""){
            this.checkLoggedIn();
        }
    }

    checkLoggedIn() {
        let uid = localStorage.getItem('token');
        if(uid != null){
            this.getUserInfo(uid)
        }
    }

    getUserInfo (uid) {
            var get_user = database.ref("User").child(uid);
            get_user.once('value', (snapshot) => {
                this.setState({name:snapshot.child("user_name").val()})
            });
    }

    async getAllCategories(){
        return new Promise( (resolve,reject) => {
            var getall_categories = database.ref("Category");
            console.log(getall_categories);
            getall_categories.once("value")
            .then((snapshot) => {
                var tempCategories = [];
                snapshot.forEach((childSnapshot) => {
                    var key = childSnapshot.key;
                    var childData = childSnapshot.val();
                    var category = {
                        id: key,
                        name: childData.category_name,
                        image: childData.category_url
                    }
                    tempCategories = [...tempCategories,category];
                })
                if(tempCategories)
                    resolve(tempCategories);
                else
                    reject(new Error("something went wrong"));
            });

        })
    }

    async getProductsForOneCategory(category_id){
        return new Promise( (resolve,reject) => {
            var tempProducts = [];
            var getall_products = database.ref("Product").child(category_id);
            getall_products.once("value")
            .then(function(snapshot) {
            snapshot.forEach(function(childSnapshot) {
                    var key = childSnapshot.key;
                    var childData = childSnapshot.val();
                    tempProducts = [...tempProducts,childData];
                });

                if(tempProducts)
                    resolve(tempProducts);
                else
                    reject(new Error("something went wrong"));
            });
        })
    }
    async getProductsForEveryCategory(categories){
        var tempProducts = []
        return new Promise( (resolve,reject) => {
            const len = categories.length;
            categories.map((category,i) =>{
                this.getProductsForOneCategory(category.id)
                    .then((products) => {
                        tempProducts.push({
                            key: category.id,
                            value: products
                        })
                        if( i+1 == len)
                            resolve(tempProducts);
                    });
            })
        })
    };

    getAllCategoriesProducts(){
        this.getAllCategories()
        .then( (categories) => {
            this.setState({
                allCategories: categories
            })
            this.getProductsForEveryCategory(categories)
            .then((tempProducts) => {
                console.log('tempProducts', tempProducts)

                this.setState({
                    allProducts : tempProducts
                })
            });
        });
    }

    toggleSidebar(){
        if(!this.state.sidebar){
            document.getElementsByClassName('overlay-background')[0].setAttribute("style","opacity: 1;position: fixed;top:0;left: 0;width: 100%;height: 100%;z-index: 1030;background: #000c;");
            document.getElementsByClassName('mySidebar')[0].setAttribute("style","display:flex;flex-direction:column;position:fixed;background-color:white;left:0;top:0;height:100%;width:50vh;z-index:2000;overflow:scroll");
            this.setState({
                sidebar: true
            })
        }
        else{
            document.getElementsByClassName('overlay-background')[0].setAttribute("style","");
            document.getElementsByClassName('mySidebar')[0].style.display = "none";
            this.setState({
                sidebar: false
            })
        }
    }

    render(){
        const cartBadge = localStorage.getItem('cart')?Object.keys(JSON.parse(localStorage.getItem('cart')).basket).length:0
        return(
            <div>
            <div className="sticky-top">
            <nav className="navbar navbar-expand-lg navbar-light" style={{padding:"15px"}}>
                    <button onClick={this.toggleSidebar.bind(this)} className="navbar-toggler bg-white" type="button" data-toggle="collapse" data-target="#menu" aria-controls="menu" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <a className="navbar-brand" href="/browse"><img style={{height:24, marginLeft:50}} src={ChariotLogo}></img></a>
                    <a style={{ marginRight:10}} class="add">{this.state.userAddress}</a>

                    <div className="collapse navbar-collapse" id="menu">
                        { this.state.name ==''? <ul className="navbar-nav ml-auto font-nav">
                                <li className="nav-item">
                                    <div style={{color:"#000"}}
                                            className="nav-link" href="#"><FontAwesomeIcon icon={search} style={{marginLeft:5, marginRight:5}}/><Link to={'/search'} >Search</Link>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div style={{color:"#000"}}
                                            className="nav-link" href="#"><FontAwesomeIcon icon={info} style={{marginLeft:5, marginRight:5}}/><Link to={'/helpsupport'}>Help</Link>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div style={{color:"#000",cursor:"pointer"}}
                                            onClick={() => this.setState({modalShow: true})} className="nav-link"
                                            href="#">Login
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div style={{color:"#000",cursor:"pointer"}}
                                            onClick={() => this.setState({modalShowSignUp: true})}
                                            className="nav-link" href="#">SignUp
                                    </div>
                                </li>

                            </ul> :

                            <ul className="navbar-nav ml-auto font-nav">
                                <li className="nav-item">
                                    <div style={{color:"#000"}}
                                            className="nav-link" href="#"><FontAwesomeIcon icon={search} style={{marginLeft:5, marginRight:5}}/><Link to={'/search'}>Search</Link>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div style={{color:"#000"}}
                                            className="nav-link" href="#"><FontAwesomeIcon icon={info} style={{marginLeft:5, marginRight:5}}/><Link to={'/helpsupport'}>Help</Link>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div style={{color:"#000"}} className="nav-link" href="#" ><FontAwesomeIcon icon={user} style={{marginLeft:5, marginRight:5}}/><Link to={'/account'}>{this.state.name}</Link></div>
                                </li>
                                <li className="nav-item">
                                    <Link to='/browse'><div style={{color:"#000",cursor:"pointer"}} onClick={() => this.logout()} className="nav-link" href="#"><FontAwesomeIcon icon={logout} style={{marginLeft:5, marginRight:5}}/>LogOut</div></Link>
                                </li>
                                <li className="nav-item">
                                    <div style={{color:"#000"}} className="nav-link" href="#"><Link to="/cart"><FontAwesomeIcon icon={cart} style={{marginLeft:5, marginRight:5}}/><Badge variant="danger">{cartBadge}</Badge></Link></div>
                                </li>
                            </ul>
                        }
                        </div>
                </nav>
                <div class="allDropdown">
                        <h2 style={{color:"#fff",fontSize:"1rem",padding:".375rem .75rem",marginTop:"4px"}}><Link to={{pathname: '/browse/'}} id="menuItem" style={{textDecoration:"none",color:"#ffffff"}}>HOME</Link></h2>
                        <Link to={{pathname: '/browse/product/'+'wheat',state:'Whole Wheat Chakki Atta'}}><h2 style={{color:"#fff",fontSize:"1rem",padding:".375rem .75rem",marginTop:"4px"}}>Whole Wheat Chakki Atta</h2></Link>
                        <Link to={{pathname: '/browse/product/'+'maida',state:'Maida'}}><h2 style={{color:"#fff",fontSize:"1rem",padding:".375rem .75rem",marginTop:"4px"}}>Maida</h2></Link>
                        <Link to={{pathname: '/browse/product/'+'sujirava',state:'Suji Rava'}}><h2 style={{color:"#fff",fontSize:"1rem",padding:".375rem .75rem",marginTop:"4px"}}>Suji Rava</h2></Link>
                        <Link to={{pathname: '/browse/product/'+'chirotirava',state:'Chiroti Rava'}}><h2 style={{color:"#fff",fontSize:"1rem",padding:".375rem .75rem",marginTop:"4px"}}>Chiroti Rava</h2></Link>
                        <Link to={{pathname: '/browse/product/'+'dryfruits',state:'Dry Fruits And Nuts'}}><h2 style={{color:"#fff",fontSize:"1rem",padding:".375rem .75rem",marginTop:"4px"}}>Dry Fruits And Nuts</h2></Link>
                        {/* <div style={{display:"flex"}}
                            onMouseLeave = {(e) => { this.setState({myId: ""})}}>
                            {this.state.allProducts.map((item) => (
                                    <Dropdown
                                        onMouseEnter = {(e) => { this.setState({myId: item.key})}}
                                        show={ this.state.myId === item.key}
                                    >
                                        <Dropdown.Toggle style={{backgroundColor:'#050b30', borderColor:'#050b30'}} varient="Secondary" id="dropdown-basic"
                                        >
                                            {item.key}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu id="dropDownmenu"
                                            >
                                            {item.value.map(
                                                    (item1) => (
                                                            <Dropdown.Item id="menuItem"><Link to={{pathname: '/browse/product/'+ item1.product_id,
                                                            state:item1}} id="menuItem" style={{textDecoration:"none",color:"#ffffff"}}>{item1.product_name}</Link></Dropdown.Item>
                                                    )
                                            )}                
                                        </Dropdown.Menu>
                                    </Dropdown>
                            ))}
                        </div> */}
                        
                </div>
                <LoginModal
                show={this.state.modalShow}
                onHide={() => this.setState({modalShow:false})}
                onSwitch={() => {
                    this.setState({modalShowSignUp: true})
                    this.setState({modalShow: false})
                }}
                />
                <SignUpModal
                    show={this.state.modalShowSignUp}
                    onHide={() => this.setState({modalShowSignUp:false})}
                    onSwitch={() => {
                        this.setState({modalShowSignUp: false})
                        this.setState({modalShow: true})
                    }}
                />
            </div>
            <div class="overlay-background"></div>
            <div className='mySidebar'>
                <div style={{display:"flex",flexDirection:"row",margin:"10px",borderBottom:"1px solid #cfcaca"}}>
                    <FontAwesomeIcon style={{transform:"scale(2)",marginTop:"15px"}} icon={user} /> 
                    <h3 style={{marginTop:"10px",marginLeft:"20px",fontSize:"25px",fontWeight:"500"}}>Hello, {this.state.name}</h3>
                    <FontAwesomeIcon icon={close} style={{transform:"scale(2)",marginTop:"15px",marginLeft:"19%"}} onClick={this.toggleSidebar.bind(this)}/>
                </div>
                { this.state.name ==''?<ul style={{lineHeight:"3",textAlign:"left",marginLeft:"30px"}}>
                        <li>
                            <div><FontAwesomeIcon icon={search} style={{marginLeft:5, marginRight:5}}/><Link to={'/search'}>Search</Link>
                            </div>
                        </li>
                        <li >
                            <div><FontAwesomeIcon icon={info} style={{marginLeft:5, marginRight:5}}/><Link to={'/helpsupport'}>Help</Link>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => {
                                this.setState({modalShow: true})
                                this.toggleSidebar()
                            }} >Login
                            </div>
                        </li>
                        <li>
                            <div onClick={() => {
                                this.setState({modalShowSignUp: true})
                                this.toggleSidebar()
                            }}>SignUp
                            </div>
                        </li>
                    </ul> :
                    <ul style={{lineHeight:"3",textAlign:"left",marginLeft:"30px"}}>
                        <li>
                            <div><FontAwesomeIcon icon={search} style={{marginLeft:5, marginRight:5}}/><Link to={'/search'} >Search</Link>
                            </div>
                        </li>
                        <li>
                            <div><FontAwesomeIcon icon={info} style={{marginLeft:5, marginRight:5}}/><Link to={'/helpsupport'} >Help</Link>
                            </div>
                        </li>
                        <li>
                            <div><FontAwesomeIcon icon={user} style={{marginLeft:5, marginRight:5}}/><Link to={'/account'}>{this.state.name}</Link></div>
                        </li>
                        <li>
                            <div  onClick={() => this.logout()}><FontAwesomeIcon icon={logout} style={{marginLeft:5, marginRight:5}}/>LogOut</div>
                        </li>
                        <li>
                            <div><Link to="/cart"><FontAwesomeIcon icon={cart} style={{marginLeft:5, marginRight:5}}/><Badge variant="danger">{cartBadge}</Badge></Link></div>
                        </li>
                    </ul>
                }
                <div class="addBorder">
                <h3 style={{marginTop:"10px",marginLeft:"20px",textAlign:"left",fontSize:"23px",fontWeight:"500"}}>CATEGORIES</h3>
                    {this.state.allCategories.map((item) => (
                        <ul style={{lineHeight:"3",textAlign:"left",marginLeft:"30px"}}>
                            <li>
                                <div onClick={this.toggleSidebar.bind(this)}><Link to={'/browse/category/'+item.name} ><p className="capitalize">{item.id}</p></Link>
                                </div>
                            </li>
                        </ul>
                    ))}
                </div>
            </div>
        </div>
            
        );
    }

    logout() {
        auth.signOut()
        localStorage.removeItem('token')
        this.setState({name: ''})
    }
}

