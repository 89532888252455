import React from 'react';
import ReactDOM from 'react-dom';
import {Link,NavLink} from 'react-router-dom';
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './sidebar.css';

export default function Sidebar(props){

    function openNav() {
        var sidenav = document.getElementById("mySidenav");
        console.log("OPENING NAV ::::::");
        ReactDOM.findDOMNode(sidenav).style.width = "250px";
      }
      
    function closeNav() {
        var sidenav = document.getElementById("mySidenav");
        console.log("CLOSING NAV ::::::::");
        ReactDOM.findDOMNode(sidenav).style.width = "0";
      }
    return (
        <div>
            <span className="hamburger" onClick={()=>openNav()}> <FontAwesomeIcon icon={faAngleDoubleRight} /> </span>
            <div id="mySidenav" className="sidenav" onClick={()=>closeNav()}>
                <Link to="/account">Dashboard</Link>
                <Link to="/editaccount">Edit Account</Link>
                <Link to="/address">Address Book</Link>
                <Link to="/orders">Orders</Link>
            </div>
        </div>
        
    );
}