import React, {useEffect} from 'react'
import HomeTop from "../components/homepage/homeTop"
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EditIcon from '@material-ui/icons/Edit';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import { Typography } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import {Form} from 'react-bootstrap';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {database} from "../lib/firebase.prod";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import emptyOrder from '../static/img/emptyOrder.png';
import './Account.css';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    Account:{
        marginTop:'30px'
    },
    Accounttitle:{
        fontSize:'20px',
        fontWeight:'600',
        fontFamily:' oswald, "sans serif"',
        textAlign:'start',
        padding:'20px',
        letterSpacing:'2px',
        color:'#28a745',
        marginLeft:15
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperleftcard:{
        padding: theme.spacing(2),
        textAlign: 'start',
        color: theme.palette.text.secondary,
        backgroundColor:'#ffb637',
        color:'white'
    },
    paperright:{
        padding: theme.spacing(2),
        textAlign: 'start',
        color: theme.palette.text.secondary,
        color:'black',
        marginLeft:'5%',
        width:'90%'
    },
    cards:{
        marginLeft:'2%',
        width:'96%'
    },
    editicon:{
        display:'none',
        [theme.breakpoints.only('xs')]: {
            display:'block',
          },
    },
    leftcardtitle:{
        display:'flex'
    },
    avatar:{
        fontSize:'60px',
        fontFamily:' Oswald, "sans serif"',
    },
    cardname:{
        fontSize:'20px',
        fontWeight:'600',
        fontFamily:' Rubik, "sans serif"',
        letterSpacing:'2px'
    },
    cardemail:{
        fontSize:'13px',
        fontWeight:'400',
        fontFamily:' Oswald, "sans serif"',
        letterSpacing:'1px'
    },
    cardlinks:{
        marginLeft:'2%',
        width:'96%',
        marginTop:'10px',
        backgroundColor:'#ffcc00',
        display:'flex',
        marginTop:'20px',
        padding:'10px'
    },
    ordertitle:{
        fontSize:'16px',
        fontWeight:'600',
        fontFamily:' Rubik, "sans serif"',
        letterSpacing:'2px',
        color:'white',
        marginLeft:'10px',
        cursor:'pointer'
    },
    rightbardiv:{
        display:'block',
        [theme.breakpoints.only('xs')]: {
            display:'none',
          },

    },
    accounttleright:{
        fontSize:'20px',
        fontWeight:'600',
        fontFamily:' oswald, "sans serif"',
        letterSpacing:'2px',
        marginLeft:'10px',
        color:'#28a745'
    },
    editbutton:{
        fontSize:'12px',
        fontWeight:'600',
        fontFamily:' Rubik, "sans serif"',
        marginLeft:'10px',
        color:'#dd4b59',
        cursor:'pointer'
    },
    righttitle:{
        fontSize:'12px',
        fontWeight:'500',
        fontFamily:' oswald, "sans serif"',
        letterSpacing:'2px',
        color:'#8c8c8c'
    },
    righttitle2:{
        fontSize:'16px',
        fontWeight:'3   00',
        fontFamily:' Rubik, "sans serif"',
        letterSpacing:'1.3px',
        color:'#000',
        marginTop:'5px'
    },
    editaccounttitle:{
        fontSize:'17px',
        fontWeight:'6   00',
        fontFamily:' oswald, "sans serif"',
        letterSpacing:'1px',
    },
    editinput:{
        width:'500px',
        fontSize:'14px',
        [theme.breakpoints.only('xs')]: {
            width:'100%',
          },
    },
    editinputAdress:{
        width:'500px',
        fontSize:'14px',
        [theme.breakpoints.only('xs')]: {
            width:'100%',
          },
    },
    updatebutton:{
        fontSize:'17px',
        fontWeight:'600',
        fontFamily:' oswald, "sans serif"',
        letterSpacing:'2px',
        padding:'10px',
        marginTop:'20px',
        textAlign:'center',
        color:'white',
        backgroundColor:'#008000'
    },
    appBar: {
        position: 'relative',
        backgroundColor:'#ffb637',
        color:'white'
      },
      title: {
        marginLeft: theme.spacing(2),
        flex: 1,
      },
      appbartitle:{
        fontSize:'17px',
        fontWeight:'6   00',
        fontFamily:' oswald, "sans serif"',
        letterSpacing:'1px',
      },
      gridheader:{
        borderBottom:'1px solid #e5e5e5',
        borderTop:'1px solid #e5e5e5',
    },
    tabletitle:{
        textAlign:'center',
        fontSize:'15px',
        marginTop:'15px',
        fontWeight:'300',
        fontFamily:' Rubik, "sans serif"',
        letterSpacing:'1px',
        textTransform :'uppercase',
        [theme.breakpoints.only('xs')]: {
           fontSize:'10px',
           marginTop:'0px',
          },
    },
    orderbox:{
        width:'70%',
        marginLeft:'13%',
        marginTop:'20px',
        borderRadius:'8px',
        [theme.breakpoints.only('xs')]: {
            fontSize:'10px',
            marginTop:'0px',
            marginTop:'10px',
            width:'90%',
            marginLeft:'5%',
           },
    },
    shoppingCarttitle:{
        textAlign:'center',
        fontSize:'30px',
        marginTop:'50px',
        fontWeight:'600',
        fontFamily:' Oswald, "sans serif"',
        color:'#97bc77',
        letterSpacing:'1.5px',
        [theme.breakpoints.only('xs')]: {
            marginTop:'20px',
           },
    },
    orderboxheader:{
        backgroundColor:'20px',
        borderBottom:'1px sloid #cdcdcd',
        backgroundColor:'#ffb637',
        color:'white',
        // borderTopLeftRadius:'5px',
        // borderTopRightRadius:'5px',
        padding:"10px",
        borderRadius:"5px",
    },
    orderboxheadertitle:{
        textAlign:'start',
        marginLeft:'20px',
        padding:'6px',
        
      
  
    },
    orderheadertitlemain:{
        fontSize:'12px',
        fontFamily:' Oswald, "sans serif"',
        fontWeight:'400',
        textAlign:'center',
        [theme.breakpoints.only('xs')]: {
            fontSize:'6px',
           },
    },
    orderheadertitlesub:{
        fontSize:'15px',
        fontFamily:' Rubik, "sans serif"',
        fontWeight:'600',
        textAlign:'center',
        [theme.breakpoints.only('xs')]: {
            fontSize:'6px',
           },
    },
    ordermaincontextbox:{
        border:'1px solid #efefef',
        borderTop:'0px',
        padding:'10px',
        justifyContent:'space-around',
        [theme.breakpoints.only('xs')]: {
           flexDirection:'column',
           padding:'10px',
           borderBottom:'1px solid #efefef'
           },
    },
    orderimage:{
        width:'100px',
        height:'100px',
        paddingTop:'20px',
        [theme.breakpoints.only('xs')]: {
            paddingTop:'10px'
        },
    },
    orderitemname:{
        fontWeight:'500',
        fontSize:'15px',
        paddingTop:'10px',
        fontFamily:' Rubik, "sans serif"',
        marginLeft:'20px',
        paddingTop:'20px',
        width:"100%",
        [theme.breakpoints.only('xs')]: {
            paddingTop:'10px',
            fontSize:'12px',
        },
    },
    buyagainbutton:{
        fontWeight:'500',
        fontSize:'15px',
        fontFamily:' oswald, "sans serif"',
        padding:'8px',
        backgroundColor:'#97bc77',
        color:'white',
        width:'30%',
        textAlign:'center',
        marginTop:'20px',
        letterSpacing:'1.5px',
        [theme.breakpoints.only('xs')]: {
            width:'100%',
            marginTop:'10px',
            fontSize:'12px',
            padding:'6px',
        },
    },
    orderdetailbox:{
        marginBottom:'20px',
        border:'1px solid #efefef',
        padding:'10px',
        borderRadius:'8px',
        [theme.breakpoints.only('xs')]: {
            padding:'10px', 
        },
    },
    orderDetailtitile:{
        textAlign:'start',
        fontWeight:'800',
        fontSize:'15px',
        fontFamily:' oswald, "sans serif"',
        padding:'8px',
        color:'#97bc77',
        marginLeft:'10px',
        [theme.breakpoints.only('xs')]: {
            fontSize:'12px',
        },
        
    },
    orderAddress:{
        textAlign:'start',
        fontWeight:'300',
        fontSize:'15px',
        fontFamily:' Rubik, "sans serif"',
        padding:'8px',
      
        marginLeft:'10px',
        lineHeight:1.4,
        [theme.breakpoints.only('xs')]: {
            fontSize:'8px',
        },
    },
    orderprice:{
        textAlign:'start',
        fontWeight:'300',
        fontSize:'15px',
        fontFamily:' Rubik, "sans serif"',
      
        marginLeft:'10px',
        lineHeight:1.7,
        letterSpacing:'1px',
        [theme.breakpoints.only('xs')]: {
            fontSize:'5px',
        },
    }

}))

export default function Account() {
    const classes = useStyles()
    const [openOrders, setOpenOrders] = React.useState(false);
    const [openOrderDetails, setOpenOrderDetails] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [fullName,setFullName] = React.useState("");
    const [updateFullName, setUpdateFullName] = React.useState("");
    const [email,setEmail] = React.useState("");
    const [updateEmail,setUpdateEmail] = React.useState("");
    const [phoneNumber,setPhoneNumber] = React.useState("");
    const [updatePhoneNumber,setUpdateNumber] = React.useState("");
    const [defaultAdd,setDefaultAdd] = React.useState("");
    const [updateDefaultAdd,setUpdateDefaultAdd] = React.useState("");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [updateCity,setUpdateCity] = React.useState("");
    const [updatePinCode,setUpdatePinCode] = React.useState("");
    const [updateLandmark,setUpdateLandmark] = React.useState("");
    const [showSnackbar,setShowSnackbar] = React.useState(false);
    const [previousOrders,setPreviousOrders] = React.useState([]);
    const [detailedOrder,setDetailedOrder] = React.useState({});
    const [previousOrdersImages,setPreviousOrdersImages] = React.useState([]);
    const [cartItems,setCartItems] = React.useState({"wheat":[],"maida":[],"suji":[],"chiroti":[]});
    const [gst,setGst] = React.useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };


    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpenOrders = () => {
        setOpenOrders(true);
    };
    
    const handleCloseOrders = () => {
        setOpenOrders(false);
    };



    const handleClickOpendetails = (index) => {
        var address = JSON.parse(previousOrders[index].user_address);
        var defaultAddress = "";
        if(Object.keys(address).length!=0){
            if(address.addressLine1!=null && address.addressLine1!=""){
                var addressSpace = address.addressLine1.split(",");
                for(let i in addressSpace){
                    defaultAddress+=addressSpace[i]+", ";
                }
            }if(address.city!=null && address.city!=""){
                defaultAddress+=address.city;
            }if(address.pincode!=null && address.pincode!=""){
                defaultAddress+=" - "+address.pincode;
            }
            console.log("previousOrders[index]",previousOrders[index]);
            previousOrders[index].detailedAddress = defaultAddress;
        }
        setDetailedOrder(previousOrders[index]);
        setOpenOrderDetails(true);
    };
    
    const handleCloseOrderdetails = () => {
        setOpenOrderDetails(false);
    };

    useEffect(()=>{
        var uid = localStorage.getItem("token");
        var get_user = database.ref("User").child(uid);
        get_user.once("value")
        .then(function(snapshot){
            setFullName(snapshot.val().user_name);
            setUpdateFullName(snapshot.val().user_name);
            setEmail(snapshot.val().user_email);
            setUpdateEmail(snapshot.val().user_email);
            setPhoneNumber(snapshot.val().user_phone);
            setUpdateNumber(snapshot.val().user_phone);
            var address = JSON.parse(snapshot.val().user_address);
            console.log("address",address);
            var defaultAddress = "";
            if(Object.keys(address).length!=0){
                if(address.addressLine1!=null && address.addressLine1!=""){
                    var addressSpace = address.addressLine1.split(",");
                    for(let i in addressSpace){
                        defaultAddress+=addressSpace[i]+", ";
                    }
                    setUpdateDefaultAdd(address.addressLine1);
                }if(address.city!=null && address.city!=""){
                    setUpdateCity(address.city);
                    defaultAddress+=address.city;
                }if(address.pincode!=null && address.pincode!=""){
                    setUpdatePinCode(address.pincode);
                    defaultAddress+="-"+address.pincode;
                }if(address.landmark!=null && address.landmark!=""){
                    setUpdateLandmark(address.landmark);
                }if(address.gst!=null && address.gst!=""){
                    setGst(address.gst);
                }
                setDefaultAdd(defaultAddress);
            }
        })
        var ref = database.ref('MyOrder').child(uid);
        var myOrders = [];        
        ref.once("value",snapshot => {
            var orders = snapshot.val();
            for(let i in orders){
                var quantitySplit = orders[i].item_quantity.split(",");
                var amountSplit = orders[i].item_amount.split(",");
                var namesSplit = orders[i].item_names.split(",");
                var weightsSplit = orders[i].item_weight.split(",");
                var payment_type = "";
                
                if(orders[i].payment_type==undefined){
                    payment_type = "Online";
                }else{
                    payment_type = orders[i].payment_type;
                }

                var orderImages = [];
                for(let j in namesSplit){
                    if(namesSplit[j]=="Whole Wheat Chakki Atta"){
                        orderImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/wheat.png?alt=media&token=cb186b98-a4a9-48f1-bfa6-0f2872255292")
                    }else if(namesSplit[j]=="Maida"){
                        orderImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/maida.png?alt=media&token=5de8b706-f941-48c2-8e5c-128f0777df8d")
                    }else if(namesSplit[j]=="Suji Rawa"){
                        orderImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/suji_rava.png?alt=media&token=28ecba7b-1360-41d6-9ee5-76c89c2a63b8");
                    }else if(namesSplit[j]=="Rawa"){
                        orderImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/chiroti.png?alt=media&token=167a4950-e172-4740-9740-fc1973e7b5ed");
                    }else if(namesSplit[j]=="Indian Raisins"){
                        orderImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/indianraisinsfront.jpg?alt=media&token=39f2dba6-8b6b-470a-a842-6861bf24c32e");
                    }else if(namesSplit[j]=="Afghan Raisins"){
                        orderImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/afghanraisinsfront.jpg?alt=media&token=1f27d626-e4dd-4395-a990-79caf5e6fc9c");
                    }else if(namesSplit[j]=="Safawi Dates"){
                        orderImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/safawifront.jpg?alt=media&token=e2698b80-063d-48c5-b43a-e435e4355e10");
                    }else if(namesSplit[j]=="Mabroom Dates"){
                        orderImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/mabroomfront.jpg?alt=media&token=d462969b-4497-4ed9-9daf-3031f5e036b3");
                    }else if(namesSplit[j]=="Roasted & Salted Almonds"){
                        orderImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/almond1.jpg?alt=media&token=eab27d9a-a30b-441c-8160-c25e02b072ed");
                    }else if(namesSplit[j]=="Roasted & Salted Cashews"){
                        orderImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/cashew1.jpg?alt=media&token=b1e9747d-d85d-456a-8323-1b2bc0893572");
                    }else if(namesSplit[j]=="Roasted & Salted Pistachio"){
                        orderImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/pista1.jpg?alt=media&token=20daec7c-a17f-4e20-b529-446fb1e5c578");
                    }else if(namesSplit[j]=="Strong Hing"){
                        orderImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/Hing-1.jpg?alt=media&token=3ec12f0d-7044-4ae0-9e1d-14f1ab0db888");
                    }else if(namesSplit[j]=="Newnik Vaporizer V108"){
                        orderImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/newNikVaporizer.png?alt=media&token=cff0e0e0-ef8b-40de-bcd1-bdc887acfb04");
                    }else if(namesSplit[j]=="Newnik Pulse Oximeter PX701"){
                        orderImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/oxymeter.png?alt=media&token=2d9527ab-fc78-4f71-8069-dd05f4d278a4");
                    }else if(namesSplit[j]=="Newnik Personal Scale PSE101"){
                        orderImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/pse101.png?alt=media&token=3bca7b96-e0ba-42ec-9261-a8151aa9245b");
                    }else if(namesSplit[j]=="Newnik Personal Scale PSE201"){
                        orderImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/pse2011.png?alt=media&token=53567ae9-6142-4f88-b6a3-8364efaeec90");
                    }else if(namesSplit[j]=="Newnik Respiratory Exerciser RE201"){
                        orderImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/respiratory1.png?alt=media&token=6e031a24-490e-4c32-9779-1e7550c6b569");
                    }else if(namesSplit[j]=="Rossmax EB600 Cardiology Stethoscope (Black)"){
                        orderImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb6001.png?alt=media&token=b0e02464-0fb5-412a-8953-7e16e1a1d4c5");
                    }else if(namesSplit[j]=="Rossmax EB500 Rappaport Stethoscope (Black)"){
                        orderImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb5001.png?alt=media&token=2cbecd7b-bf8a-41d1-ade8-94d7bbc1dd79");
                    }else if(namesSplit[j]=="Newnik Stethoscope ST309"){
                        orderImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/stethescope1.png?alt=media&token=19f78807-a1dd-410c-ad92-478bb819957f");
                    }else if(namesSplit[j]=="Rossmax EB200 Stethoscope (Black)"){
                        orderImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb2001.png?alt=media&token=598cf0bc-8461-423b-ae30-c2b527276f8a");
                    }else if(namesSplit[j]=="Rossmax EB100 Stethoscope (Black)"){
                        orderImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/eb100.png?alt=media&token=5ff832f2-29c9-4e48-a1eb-9b72f31829c4");
                    }else if(namesSplit[j]=="Newnik Ice Bag IC900 Plain"){
                        orderImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/coolPack1.png?alt=media&token=9fa36616-6cf6-41d8-8035-006332bd2f6d");
                    }else if(namesSplit[j]=="Rossmax Wf260 Body Fat Monitor- Silver"){
                        orderImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/wf2601.png?alt=media&token=c846b6bb-a886-4b66-ba14-3a59082ee55c");
                    }else if(namesSplit[j]=="Rossmax PF120A Peak Flow Meter (Adult- White) (Grey)"){
                        orderImages.push("https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/pf120A1.png?alt=media&token=13638d0c-0a24-41e5-bec7-18dd970cdb5d");
                    }
                }
                orders[i].images = orderImages;
                orders[i].quantitySplit = quantitySplit.slice(0,-1);
                orders[i].amountSplit = amountSplit.slice(0,-1);
                orders[i].namesSplit = namesSplit.slice(0,-1);
                orders[i].weightsSplit = weightsSplit.slice(0,-1);
                orders[i].payment_type = payment_type;
                orders[i].invoice_url = "https://firebasestorage.googleapis.com/v0/b/chariot-trade-aca9d.appspot.com/o/pdfs%2F"+orders[i].order_id+".pdf?alt=media&token=a2775264-db32-404f-bda7-0d0ff1ebf194";
                myOrders.push(orders[i]);
            }
            myOrders = myOrders.reverse();
            setPreviousOrders(myOrders);
        })

    },[])

    const changeHandler = (e,type) => {
        if(type=="fullName"){
            setUpdateFullName(e.target.value);
        }else if(type == "email"){
            setUpdateEmail(e.target.value);
        }else if(type == "phoneNumber"){
            setUpdateNumber(e.target.value);
        }else if(type == "defaultAdd"){
            setUpdateDefaultAdd(e.target.value);
        }else if(type=="city"){
            setUpdateCity(e.target.value);
        }else if(type=="pincode"){
            setUpdatePinCode(e.target.value);
        }else if(type == "landmark"){
            setUpdateLandmark(e.target.value);
        }else if(type == "gst"){
            setGst(e.target.value);
        }
    }

    const updateDetails = () => {
        setFullName(updateFullName);
        setPhoneNumber(updatePhoneNumber);
        setEmail(updateEmail);
        setGst(gst);
        var add = "";
        if(updateDefaultAdd!=null && updateDefaultAdd!=""){
            var addSpace = updateDefaultAdd.split(",");
            for(let i in addSpace){
                add+=addSpace[i]+", ";
            }
        }if(updateCity!=null && updateCity!=""){
            add+=updateCity;
        }if(updatePinCode!=null && updatePinCode!=""){
            add+=" - "+updatePinCode;
        }
        setDefaultAdd(add);
        var get_user = database.ref("User").child(localStorage.getItem("token"));
        const address = {
            addressLine1: updateDefaultAdd,
            addressState: "Karnataka",
            city: updateCity,
            customerId: localStorage.getItem("token"),
            landmark: updateLandmark,
            pincode: updatePinCode,
            phone: updatePhoneNumber,
            gst:gst,
          }
        get_user.update({
            'user_address':JSON.stringify(address),
            'user_email':updateEmail,
            'user_name':updateFullName,
            'user_phone': updatePhoneNumber,
        }).then(()=>{
            setOpen(false);
            setShowSnackbar(true);
        })
    }

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    }

    return (
        <div>
            <HomeTop cartItems={cartItems}/>

            <div className={classes.Account}>
                <div className={classes.Accounttitle}>
                    My Account
                </div>
            <div className={classes.cards}>
            <Grid container spacing={0}  >
                <Grid item xs={12} sm={5}elevation={0}>
                <Paper className={classes.paperleftcard} elevation={0}>
                    <div style={{display:'flex',justifyContent:'space-between',width:'100%'}}>
                        <div className={classes.leftcardtitle}>
                        <AccountCircleIcon  className={classes.avatar}/>
                        <div style={{marginLeft:'20px'}}>
                            <div className={classes.cardname}>
                                {fullName}
                            </div>
                            <div className={classes.cardemail}>
                                {email}
                            </div>
                            <div className={classes.cardemail}>
                                {phoneNumber}
                            </div>
                        </div>
                        </div>
                        <EditIcon onClick={handleClickOpen} className={classes.editicon}/>
                    </div>

                    <Paper  elevation={0} className={classes.cardlinks} onClick={handleClickOpenOrders} style={{cursor:"pointer"}}>
                        <ShoppingBasketIcon style={{fill:"white",fontSize:'20px'}}/>
                        <Typography className={classes.ordertitle} onClick={handleClickOpenOrders}>Order History</Typography>
                    </Paper>

                </Paper>
                </Grid>
                <Grid item xs={7} elevation={0} className={classes.rightbardiv}>
                <Paper className={classes.paperright}elevation={1}>
                   <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div className={classes.accounttleright}>Account Details</div>
                        <div className={classes.editbutton} onClick={handleClickOpen}> Edit</div>
                   </div>

                   <div style={{display:'flex',justifyContent:'space-between',marginTop:'30px',width:'80%'}}>
                        <div>
                            <div className={classes.righttitle}>
                                Fullname
                            </div >
                            <div className={classes.righttitle2}>
                                {fullName}
                            </div>
                        </div>
                        <div>
                             <div className={classes.righttitle}>
                                Mobile Number
                            </div>
                            <div className={classes.righttitle2}>
                                {phoneNumber}
                            </div>

                        </div>
                   </div>

                   {
                       defaultAdd==""||defaultAdd==null?<div style={{display:'flex',justifyContent:'space-between',marginTop:'30px',width:'80%'}}>
                       <div>
                           <div className={classes.righttitle}>
                               Email Id
                           </div>
                           <div className={classes.righttitle2}>
                               {email}
                           </div>
                       </div>
                       <div style={{marginLeft:'26%'}}>
                            <div id="testing" className={classes.righttitle}>
                               Default Address
                           </div>
                           <div className={classes.righttitle2}>
                               {defaultAdd==""||defaultAdd==null?"Enter Address":defaultAdd}
                           </div>

                       </div>
                  </div>
:                   <div style={{display:'flex',justifyContent:'space-between',marginTop:'30px',width:'100%'}}>
<div>
    <div className={classes.righttitle}>
        Email Id
    </div>
    <div className={classes.righttitle2}>
        {email}
    </div>
</div>
<div style={{marginLeft:'26%'}}>
     <div id="testing" className={classes.righttitle}>
        Default Address
    </div>
    <div className={classes.righttitle2}>
        {defaultAdd==""||defaultAdd==null?"Enter Address":defaultAdd}
    </div>

</div>
</div>

                   }
                   <div style={{display:'flex',justifyContent:'space-between',marginTop:'30px',width:'100%'}}>
                        <div>
                            <div className={classes.righttitle}>
                                GST Number
                            </div>
                            <div className={classes.righttitle2}>
                                {gst=="" || gst==null?"Enter GST Number":gst}
                            </div>
                        </div>
                   </div>

                </Paper>
                </Grid>
             </Grid>
             </div>
            </div>

            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                className={classes.dialog}
            >
                <DialogTitle id="responsive-dialog-title" style={{backgroundColor:'#ffb637',color:'white'}}>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div className={classes.editaccounttitle}>Edit Account</div>
                        <CloseIcon onClick={handleClose} style={{fontSize:'20px',cursor:'pointer'}}/>
                    </div>
                </DialogTitle>
                <DialogContent>
                <DialogContentText>
                <Form>
                    <Form.Group controlId="formBasicEmail">
                    <Form.Label className={classes.editinput} style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}}>Full Name</Form.Label>
                    <Form.Control value={updateFullName} onChange={(e)=>changeHandler(e,"fullName")} style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}} type="text" placeholder="Enter Name"/>
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className={classes.editinput} style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}}>Email address</Form.Label>
                     <Form.Control value={updateEmail} onChange={(e)=>changeHandler(e,"email")}  style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}} type="email" placeholder="Enter email" />
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className={classes.editinput} style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}}>Mobile</Form.Label>
                     <Form.Control value={updatePhoneNumber} onChange={(e)=>changeHandler(e,"phoneNumber")}  style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}} type="text" placeholder="Enter Mobile Number"/>
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className={classes.editinput} style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}}>Address</Form.Label>
                     <Form.Control  value={updateDefaultAdd} onChange={(e)=>changeHandler(e,"defaultAdd")} as="textarea" rows="4"  style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px',textAlign:'start'}} type="text" placeholder="Enter Address" />
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className={classes.editinput} style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}}>City</Form.Label>
                     <Form.Control  value={updateCity} onChange={(e)=>changeHandler(e,"city")} rows="4"  style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px',textAlign:'start'}} type="text" placeholder="Enter City" />
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className={classes.editinput} style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px'}}>GST Number</Form.Label>
                     <Form.Control  value={gst} onChange={(e)=>changeHandler(e,"gst")} rows="4"  style={{fontFamily:' Oswald, "sans serif"',letterSpacing:'1px',textAlign:'start'}} type="text" placeholder="Enter GST Number" />
                  </Form.Group>

                  <Typography style={{cursor:"pointer"}} className={classes.updatebutton} onClick={updateDetails}>Update Details</Typography>
                </Form>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>


            <Dialog fullScreen open={openOrders} onClose={handleCloseOrders} >
                <AppBar className={classes.appBar} elevation={0}>
                <Toolbar style={{display:'flex'}}>
                    <IconButton edge="start" color="inherit" onClick={handleCloseOrders} aria-label="close">
                        < ArrowBackIcon  style={{fontSize:'20px',fontWeight:'800',marginRight:'10px'}}/>
                    </IconButton>
                    <Typography variant="h6" className={classes.appbartitle}>
                    Orders History
                    </Typography>
                </Toolbar>
                </AppBar>
                <div className={classes.shoppingCarttitle}>
                    ORDER HISTORY
                </div>
                
                {
                    previousOrders.length == 0?                
                    <div style={{display:'flex',flexDirection:'center',marginTop:"30px"}}> 
                        <img style={{height:400,width:'90%',marginLeft:'5%',objectFit:'contain'}} src ={emptyOrder} alt="no items image" />
                    </div>:
                    previousOrders.map((previousOrder,index)=> {
                        return <div className={classes.orderbox}>
                            <div className={classes.orderboxheader}>
                                <Grid container spacing={0}>
                                    <Grid item xs={3}>
                                    <div className={classes.orderboxheadertitle} elevation={0}>
                                        <div className={classes.orderheadertitlemain}>Order Placed</div>
                                        <div className={classes.orderheadertitlesub}>{previousOrder.order_date} {previousOrder.order_month} {previousOrder.order_year}</div>
                                    </div>    
                                    </Grid>
                                    <Grid item xs={2}>
                                    <div className={classes.orderboxheadertitle} elevation={0}>
                                        <div className={classes.orderheadertitlemain}>Total</div>
                                        <div className={classes.orderheadertitlesub}>₹ {previousOrder.total_amount}</div>
                                    </div>   
                                    </Grid>
                                    <Grid item xs={3}>
                                    <div className={classes.orderboxheadertitle} elevation={0}>
                                        <div className={classes.orderheadertitlemain}>Ship To</div>
                                        <div className={classes.orderheadertitlesub}>{previousOrder.user_name}</div>
                                    </div>   
                                    </Grid>
                                    <Grid item xs={4}>
                                    <div className={classes.orderboxheadertitle}  elevation={0}>
                                        <div className={classes.orderheadertitlemain} style={{textAlign:'end',marginRight:'20px'}}>Order #  {previousOrder.order_id}</div>
                                        <div style={{display:"flex",justifyContent:"flex-end"}}>
                                            <div className={classes.orderheadertitlesub} onClick={() => handleClickOpendetails(index)} class="orderDetails">View Order Details</div>
                                            <a target="_blank" class="orderDetails" href={previousOrder.invoice_url}>Invoice Bill</a>
                                        </div>
                                    </div>   
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    })
                }
      </Dialog>

      <Dialog fullScreen open={openOrderDetails} onClose={handleCloseOrderdetails} >
                <AppBar className={classes.appBar} elevation={0}>
                <Toolbar style={{display:'flex'}}>
                <IconButton edge="start" color="inherit" onClick={handleCloseOrderdetails} aria-label="close">
                        < ArrowBackIcon  style={{fontSize:'20px',fontWeight:'800',marginRight:'10px'}}/>
                    </IconButton>
                    <Typography variant="h6" className={classes.appbartitle}>
                    Order Details
                    </Typography>
                </Toolbar>
                </AppBar>

                <div className={classes.shoppingCarttitle}>
                    ORDER DETAILS
                </div>
                {Object.keys(detailedOrder).length!=0?
                <div className={classes.orderbox}>

                    <div className={classes.orderdetailbox}>
                    <Grid container spacing={0}>
                        <Grid item xs={4}>
                            <div className={classes.orderDetailtitile}>
                                    Shipping Address
                            </div>
                            <div className={classes.orderAddress}>
                                {detailedOrder.detailedAddress}
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className={classes.orderDetailtitile}>
                                    Payment Method
                            </div>
                            <div className={classes.orderAddress}>
                                {detailedOrder.payment_type}
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                             <div className={classes.orderDetailtitile}>
                                    Order Summary
                            </div>
                            <div style={{display:'flex',justifyContent:'space-between',paddingTop:'8px'}}>
                                <div className={classes.orderprice} >
                                Item(s) Subtotal:
                                </div>
                                <div className={classes.orderprice} >
                                ₹ {detailedOrder.total_amount}
                                </div>
                            </div>  
                            {/* <div style={{display:'flex',justifyContent:'space-between'}}>
                                <div className={classes.orderprice} >
                                Shipping:
                                </div>
                                <div className={classes.orderprice} >
                                ₹ 425.00
                                </div>
                            </div>   */}
                            <div style={{display:'flex',justifyContent:'space-between'}}>
                                <div className={classes.orderprice} >
                                Total:
                                </div>
                                <div className={classes.orderprice} >
                                ₹ {detailedOrder.total_amount}
                                </div>
                            </div>  
                            <div style={{display:'flex',justifyContent:'space-between'}}>
                                <div className={classes.orderprice} style={{fontWeight:'800'}}>
                                GrandTotal:
                                </div>
                                <div className={classes.orderprice} style={{fontWeight:'800'}}>
                                ₹ {detailedOrder.total_amount}
                                </div>
                            </div>  
                        </Grid>
                    </Grid>
                    </div> 

                    <div className={classes.orderboxheader}>
                    <Grid container spacing={0}>
                        <Grid item xs={3}>
                        <div className={classes.orderboxheadertitle} elevation={0}>
                            <div className={classes.orderheadertitlemain}>Order Placed</div>
                            <div className={classes.orderheadertitlesub}>{detailedOrder.order_date} {detailedOrder.order_month} {detailedOrder.order_year}</div>
                        </div>    
                        </Grid>
                        <Grid item xs={2}>
                       <div className={classes.orderboxheadertitle} elevation={0}>
                            <div className={classes.orderheadertitlemain}>Total</div>
                        <div className={classes.orderheadertitlesub}>₹ {detailedOrder.total_amount}</div>
                        </div>   
                        </Grid>
                        <Grid item xs={3}>
                       <div className={classes.orderboxheadertitle} elevation={0}>
                            <div className={classes.orderheadertitlemain}>Ship To</div>
                        <div className={classes.orderheadertitlesub}>{detailedOrder.user_name}</div>
                        </div>   
                        </Grid>
                        <Grid item xs={4}>
                       <div className={classes.orderboxheadertitle}  elevation={0}>
                            <div className={classes.orderheadertitlemain} style={{textAlign:'end',marginRight:'20px'}}>Order id</div>
                        <div className={classes.orderheadertitlesub}  style={{textAlign:'end',marginRight:'20px',color:'#fff',cursor:'pointer'}}>Order #  {detailedOrder.order_id}</div>
                        </div>   
                        </Grid>
                    </Grid>
                    </div>
                    <div className={classes.ordermaincontextbox}>
                        {detailedOrder.namesSplit.map((order,item)=> {
                            return <div style={{display:'flex',}}>
                                <div>
                                    <img src={detailedOrder.images[item]} className={classes.orderimage}/>
                                </div>
                                <div  className={classes.orderitemname}>
                                    {order}<br/>
                                    <span style={{color:"#97bc77"}}>Quantity :</span> {detailedOrder.quantitySplit[item]}<br/>
                                    <span style={{color:"#97bc77"}}>Price :</span> Rs. {detailedOrder.amountSplit[item]}<br/>
                                    <span style={{color:"#97bc77"}}>Weight :</span> {detailedOrder.weightsSplit[item]}<br/>
                                {/* <div className={classes.buyagainbutton} >Buy it again</div> */}
                            </div>
                        </div>
                        })}
                        
                    </div>
                </div>
                :null}
    </Dialog>
            <Snackbar open={showSnackbar} autoHideDuration={2000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success">
                    <p style={{fontSize:"14px"}}>Account Details Updated.</p>
                </Alert>
            </Snackbar>

        </div>
    )
}
