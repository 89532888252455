import React from 'react';
import BrowsePageNavigation from '../navigation/browseNavigation'
import FooterPage from '../Footer';
import HomeComponentTop from '../homepage/homeTop';
import aboutUsHeader from '../../static/img/ban_2.jpg';


class VisionComponent extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            totalPrice: 0,
            cartItems: {"wheat":[],"maida":[],"suji":[],"chiroti":[]},
        }
    }

    render(){
        return (
            <div>
                <HomeComponentTop cartItems={this.state.cartItems} totalPrice={this.state.totalPrice}/>
                <div className="page_heading" style={{backgroundImage:`url(`+aboutUsHeader+`)`,backgroundRepeat:"no-repeat",color:"#ffb637",textAlign:"center",padding:"60px 0 60px 0"}}>
                    <div className="container">
                        <div className="row" style={{marginLeft: "0px"}}>
                            <div className="col-xs-12">
                                <div className="page-title">
                                    <h2>Vision</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{marginLeft:"15vw",marginRight:"15vw",textAlign: "justify" ,textJustify: "inter-word"}} className = "c1">
                    <br/><br/>
                    <h1>Our Vision</h1>
                    <p>To provide food, which is safe, nutritious, and wholesome. We make efforts to understand
                        customers requirements at every step, to ensure that we meet our customers expectations.
                        We would like to get recognised nationally as a premium-quality wheat and other edible
                        Agri products provider. We are poised to build a large number of happy customers across
                        India by providing pure, unadulterated, and hygienically made wheat-based and other
                        edible Agri products at a very reasonable price. The ever-growing demand for quality and
                        unadulterated packed wheat-based products such as Premium Quality Whole Wheat Chakki Atta, Suji, Chiroti
                        Rava, Maida and other edible Agri products such as, rice and other food grains, pulses, oil
                        seeds, spices, edible oil, all kind of dry fruits and nuts, sugar, Tea, coffee etc. has made us to
                        get into this segment. We hope to continue providing consistent and best quality edible Agri
                        products to our customers.</p>
                    <br/>
                    <h4>Why Us?</h4>
                    <p>We use the best quality of wheat grains and ensure effective quality checks at
                    each stage. We follow GMP and GHP practices and provide to all our customers
                    safe and hygienic edible Agri products protecting all nutrient properties. We
                    have talented Chariot Agro team members, and we use ethical business
                    practices.</p>
                    <br/>
                    <h4>Expertise</h4>
                    <p>We have the expertise and the capability to provide wheat products such as
                    Whole wheat Chakki Atta, Suji Rawa, Rawa, Maida and other edible Agri
                    products as per our customers needs at a reasonable price.</p>
                    <br/>
                    <h4>QUALITY POLICY</h4>
                    <p>Our products are known for their quality, consistency and timely delivery and that is why we
                    have international food chains, star hotels and mechanised bakeries as few of our key
                    customers. Our unique methodology of Chakki Atta milling and commitment to quality
                    enables our customers to have new and improved products regularly. We at Chariot Agro
                    Limited continuously make efforts to understand our customer requirements and
                    match their expectations by our efficient quality management system. We
                    review our achievements on a real time basis by defining the quality objectives,
                    executing the required corrective and preventive actions and always adhering to
                    the applicable statutory and regulatory requirements.</p>
                    <br/>
                    <h4>QUALITY ASSURANCE</h4>
                    <p>At Chariot Agro Limited, we believe that building quality is a continuous process.
                    We source premium quality wheat and other edible Agri products that has
                    unique characteristics from across the country. With stringent testing
                    procedures at every step of processing, production, packing and delivery, we
                    achieve the desired quality of final products delivered to our customer.</p>
                    <br/>
                    <h4>WHEAT PROCUREMENT</h4>
                    <p>At Chariot Agro Limited, we strongly believe that wheat sourcing and procurement is most
                    important activity and is the key to success in providing quality and nutrient products to our
                    end customer. In India, premium quality wheat is produced in various states and wheat is
                    largely sourced from Rajasthan, Madhya Pradesh, Uttar Pradesh etc. If needed, Chariot Agro
                    Limited has also built capability to source premium quality wheat from countries like
                    Australia, USA who produce wheat more than their consumption and who are keen to
                    export wheat to India. Stringent quality checks are done before souring wheat and the
                    wheat is tested for Gluten content, Sedimentation, Moisture, Unusable refraction,
                    Hectolitre Weight, and Falling Number. The wheat grains are sourced only if the parameters
                    are within the acceptable levels to ensure safe, hygienic, and nutrient end products.</p>
                    <br/>
                    <h4>STORAGE</h4>
                    <p>Wheat stocks are maintained throughout the year to ensure timely supply to end customer.
                    Efficient and hygienic storage is critical to maintaining the consistency of end product. The
                    wheat is stored based on the origin of product. The wheat is stored in the origin station and
                    will be transported to milling location based on requirement. Separate lots are maintained
                    for each lot of wheat purchased and first-in-first-out system is strictly followed. Stocks are
                    regularly monitored and checked for any infestation and are regularly fumigated.</p>
                    <br/>
                    <h4>MILLING</h4>
                    <p>Efficient milling requires two critical parameters – optimum moisture level and least
                    possible foreign matter. We do repeated checks at regular intervals to make sure that these parameters are achieved.</p>
                    <br/>
                    <h4>FINISHED PRODUCT TESTING – PRE-DISPATCH/VEHICLE LOADING</h4>
                    <p>Products such as refined wheat flour (Maida) Semolina (Suji Rawa) Chakki Atta, Rawa
                    etc. are checked at regular intervals. Various tests such as Granularity, Moisture, Gluten,
                    Filth test, Ash, Acid insoluble, SRC test, etc are carried out at a predefined frequency to test
                    the consistency of the products. Our products are also tested in the laboratories to make
                    sure that they meet the food safety standards before dispatch. The delivery vehicle is
                    checked for any possible cross-contamination. We make sure that the food product is
                    completely covered during transit. Hygiene and cleanliness of the vehicle are ensured
                    before loading the finished product.</p>
                    <br/>
                    <h4>KEY ADVANTAGES OF OUR PRODUCTS</h4>
                    <p>
                        <ol>
                            <li>
                                <p><b>Separate Retail Division : </b>Our separate retail division and stockist as well as wide distributors
                                    network provides flexibility and ease to pack different products as per end customer
                                    requirements based on the feedback received from customer, distributors, and stockists.
                                </p>
                            </li>
                            <li>
                                <p><b>Safe and Hygienic Products : </b>All products are sourced from manufacturers having
                                    ISO 22000 certification and who meet HACCP norms and who always strictly follow
                                    good manufacturing practises (GMP) and good hygiene practises (GHP).
                                </p>
                            </li>
                            <li>
                                <p><b>Automated Packing : </b>All our products are filled by automatic servo driven form fill seal
                                    machines to ensure the product is accurately measured, packed and is untouched by
                                    hand.
                                </p>
                            </li>
                            <li>
                                <p><b>Specialized Equipment : </b>We use milling facility which is equipped with fluidised bed
                                    drier that helps reducing the moisture in the product without altering the colour and
                                    taste. We also use milling facility that have fumigation chamber to fumigate the final
                                    product before packing for freshness and enhanced shelf life.
                                </p>
                            </li>
                            <li>
                                <p><b>Custom Packing : </b>We have capability to pack and supply our products as per our
                                    customers choice and with their brand.
                                </p>
                            </li>
                            <li>
                                <p><b>Barcode, Label and Printing : </b>We also use facility which is equipped with high-speed
                                    printers to print barcode, wind and unwind the laminated packing rolls.
                                </p>
                            </li>
                        </ol>
                    </p>
                    <br/>
                    <h4>RETAIL</h4>
                    <p>PREMIUM QUALITY WHEAT AND OTHER EDIBLE AGRI PRODUCTS.<br/>
                    ALL WHEAT PRODUCTS UNDER ONE ROOF.<br/>
                    WE USE MILLING FACILITY WITH ISO, FSSAI AND HALAL CERTIFIED.<br/>
                    WIDE STOCKIST AND DISTRIBUTORS NETWORK.<br/>
                    DELIVERING TO MULTIPLE LOCATIONS.<br/>
                    STORAGE.<br/>
                    SAFE TRANSIT.<br/>
                    WE USE MODULAR TECHNOLOGY.</p>
                    <br/>
                    <h4>OUR PREMIUM PRODUCT "<b>CHARIOT GOLD WHOLE WHEAT CHAKKI ATTA</b>"</h4>
                    <p>Premium blend of whole wheat chakki atta for daily consumption. Great colour,
                        extensibility and taste. 100 % Natural and free from any additives. Unique blend of
                        Sharbati, Lokwan and Raj wheat. Premium quality Atta.<br/>
                        Product features: Excellent aroma, Extended softness, Superior taste.</p>
                    <br/>
                    <h4>CHARIOT AGRO LIMITED IS PROUD TO LAUNCH BELOW PRODUCTS :</h4>
                    <p>
                        Premium Quality Chariot Gold Whole Wheat Chakki Atta<br/>
                        Premium Quality Chariot Gold Maida (Refined Wheat Flour)<br/>
                        Premium Quality Chariot Gold Suji Rawa<br/>
                        Premium Quality Chariot Gold Rawa<br/>
                        Premium Quality Chariot Gold Dry Fruits And Nuts<br/>
                    </p>
                </div>
                <br/>
                <div style={{marginLeft:"15vw",marginRight:"15vw",textAlign: "center" ,textJustify: "inter-word", fontSize:"20px"}} className = "c1">
                        <hr/>
                        <h2>Enquiry</h2>
                        <p style={{font:"23px !important"}}>Please call us on <b>0091 9945775555</b> or email to <b>atmanand@chariotagro.com</b><br/>for any product enquiry or for any information which you may need from<br/>Chariot Agro Limited.</p>
                </div>
                <FooterPage />
            </div>
        );
    }
}

export default VisionComponent;