import React from 'react';
import BrowsePageNavigation from '../navigation/browseNavigation';
import FooterPage from '../Footer';
import Distributors from '../../static/img/chariot_img.png';
import './partnerWithUsComponent.css';
import HomeComponentTop from '../homepage/homeTop';
import aboutUsHeader from '../../static/img/ban_2.jpg';

class PartnerComponent extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            totalPrice: 0,
            cartItems: {"wheat":[],"maida":[],"suji":[],"chiroti":[]},
        }
    }

    render(){

        return (
            <div>
                                <div style={{position:"sticky",top:"0px",zIndex:"1000"}}>
                <HomeComponentTop cartItems={this.state.cartItems} totalPrice={this.state.totalPrice}/>
                </div>
                <div className="page_heading" style={{backgroundImage:`url(`+aboutUsHeader+`)`,backgroundRepeat:"no-repeat",color:"#ffb637",textAlign:"center",padding:"60px 0 60px 0"}}>
                    <div className="container">
                        <div className="row" style={{marginLeft: "0px"}}>
                            <div className="col-xs-12">
                                <div className="page-title">
                                    <h2>Partner with Us</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <div style={{marginLeft:"15vw",marginRight:"15vw",textAlign: "center" ,textJustify: "inter-word"}} className = "c1">
                    <br/><br/>
                    <h1>Partner With Us</h1>
                    <br/>
                    <center><img src={Distributors} className="distributors" /></center>
                </div>
                <br/>
                <FooterPage/>
            </div>  
        );
    }
}

export default PartnerComponent;