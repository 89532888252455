import React,{useState,useEffect} from 'react';
import './styles.css';
import {auth,database} from '../../lib/firebase.prod';
import BrowsePageNavigation from '../../components/navigation/browseNavigation'
import FooterPage from "../../components/Footer";
import AddressImage from '../../static/img/address_image.jpg';
import {CheckoutButton} from "../../components/checkout/styles/checkout";

export default function Address(){


    let uid = localStorage.getItem('token');
    const [name,setName] = useState("")
    const [phoneNum,setNumber] = useState("")
    const [emailID,setEmail] = useState("")

    useEffect(() => {

        var get_user = database.ref("User").child(uid);
        get_user.once("value")
        .then(function(snapshot){
            var user_id = snapshot.child("user_id").val();
            //take any data in similar fashion
            const userName = snapshot.val().user_name;
            const userPhone = snapshot.val().user_phone;
            const userEmail = snapshot.val().user_email;
            setName(userName);
            setNumber(userPhone);
            setEmail(userEmail);
        });
    }, [])
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Name:", name);
        console.log("Phone:", phoneNum);
        console.log("Email ID:", emailID);

        if(uid === null)
        {
            alert("You need to login");
        }
        else
        {
            // var data_res = JSON.stringify(address);
            var update_user = database.ref('User');
            var new_update_user = update_user.child(uid);
            new_update_user.update({
                'user_name': name,
                'user_phone': phoneNum,
                'user_email': emailID
            }).then(() => {

                alert('User Details Updated!')
                //this.props.navigation.goBack();

            });
        }

    }

    return (
        <div>

        {/* <BrowsePageNavigation/> */}
            <h3 className="page_title"> My Information </h3>
                <div className="container card address_container">
                    <form onSubmit={handleSubmit} className="form">
                        <div className="form_container container">
                        <label> Name </label>
                        <input type="text" className="large_input" onChange={(e) => {
                            setName(e.target.value);
                        }}
                        value={name}
                        />

                        {/* <label> Last Name </label>
                        <input type="text" className="large_input" onChange={(e) => {
                            setAddressLine2(e.target.value);
                        }} value={addressLine2} /> */}

                        <label> Email Address </label>
                        <input type="text" className="large_input" onChange={(e) => {
                            setEmail(e.target.value);
                        }} value={emailID} />

                        <label> Mobile </label>
                        <input type="text" className="large_input" onChange={(e) => {
                            setNumber(e.target.value);
                        }} value={phoneNum}/>

                        <CheckoutButton style={{background:'#050b30'}} type="submit" >Update Account</CheckoutButton>
                        </div>
                    </form>

                </div>
                {/* <FooterPage /> */}
            </div>

    );
}
