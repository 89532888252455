import React,{useState,useEffect} from 'react';
import {database} from '../../lib/firebase.prod';
import Fuse from 'fuse.js';
import Grid from '../../components/Grid';
import Card from '../../components/card';
import * as ROUTES from '../../constants/routes';
import {Link, useHistory} from "react-router-dom";
import FooterPage from "../../components/Footer";
import BrowsePageNavigation from "../../components/navigation/browseNavigation";
import {MDBBtn} from "mdbreact";
import {SCREEN_HEIGHT, SCREEN_WIDTH} from "../../components/functions";
import './searchContainer.css';

export default function SearchContainer(){

    //Products array
    const [products,setProducts] = useState([{}]);
    //Categories array
    const [categories, setCategories] = useState([{}]);

    //Search products
    const [searchProducts, setSearchProducts] = useState([]);

    //Search categories
    const [searchCategories,setSearchCategories] = useState([]);

    //Search Term
    const [searchTerm,setSearchTerm] = useState("");

    const history = useHistory();
    var tempProducts;
    var tempCategories;

    async function getAllCategories(){
        return new Promise( (resolve,reject) => {

            var getall_categories = database.ref("Category");
            console.log(getall_categories);
            getall_categories.once("value")
            .then((snapshot) => {
                tempCategories = [];
                snapshot.forEach((childSnapshot) => {
                    var key = childSnapshot.key;
                    var childData = childSnapshot.val();
                    var category = {
                        id: key,
                        name: childData.category_name,
                        image: childData.category_url
                    }
                    tempCategories = [...tempCategories,category];
                    // console.log("CATEGORIES ",categories);
                    // console.log("UPDATE CATEGORIES ",[...categories,category]);
                    // setCategories([...categories,category])
                })
                console.log("Temp CATEGORIES : ", tempCategories);
                setCategories(categories => tempCategories);

                if(tempCategories)
                    resolve(tempCategories);
                else
                    reject(new Error("something went wrong"));
            });

        })
    }

    async function getProductsForOneCategory(category_id){
        return new Promise( (resolve,reject) => {
            var tempProducts = [];
            var getall_products = database.ref("Product").child(category_id);
            getall_products.once("value")
            .then(function(snapshot) {
            snapshot.forEach(function(childSnapshot) {

                    var key = childSnapshot.key;
                    var childData = childSnapshot.val();
                    //console.log("CHILD DATA: ",childData);
                    tempProducts = [...tempProducts,childData];
                });

                if(tempProducts)
                    resolve(tempProducts);
                else
                    reject(new Error("something went wrong"));
            });
        })
    }
    async function getProductsForEveryCategory(categories){
        tempProducts = []
        return new Promise( (resolve,reject) => {
            const len = categories.length;
            categories.map((category,i) =>{
                //console.log("CATEGORY : ", category);
                getProductsForOneCategory(category.id)
                    .then((products) => {
                        tempProducts = [...tempProducts, ...products];

                        if( i+1 == len)
                            resolve();
                    });
            })
        })
    }

    useEffect( () => {
        getAllCategories()
            .then( (categories) => {
                console.log("GOT ALL CATEGORIES");
                getProductsForEveryCategory(categories)
                .then(() => {
                    console.log("temp products ", tempProducts);
                    setProducts(tempProducts);
                });
            });

    }, []);

    const handleChange = (e) => {
        setSearchTerm(e.target.value);
        if(e.target.value.length > 3){
            handleSearch("test");
        }
    }

    const handleClick = ({product}) => {
        let path = ROUTES.BROWSE_PRODUCT+product.product_id;
        history.push({
            pathname: path,
            state: product
        });

    }
    const handleSearch = (e) => {
        console.log("Searching");
        console.log("products: ",products);
        console.log("categories : ",categories);
        //Categories
        const CategoryOptions = {
             isCaseSensitive: false,
            // includeScore: false,
            // shouldSort: true,
            // includeMatches: false,
            // findAllMatches: false,
            // minMatchCharLength: 1,
            // location: 0,
               threshold: 0.3,
            // distance: 100,
            // useExtendedSearch: false,
            // ignoreLocation: false,
            // ignoreFieldNorm: false,
            keys: [
              "id",
              "name"
            ]
          };

          const ProductOptions = {
            isCaseSensitive: false,
           // includeScore: false,
           // shouldSort: true,
           // includeMatches: false,
           // findAllMatches: false,
           // minMatchCharLength: 1,
           // location: 0,
              threshold: 0.3,
           // distance: 100,
           // useExtendedSearch: false,
           // ignoreLocation: false,
           // ignoreFieldNorm: false,
           keys: [
             "product_name",
             "category_id"
           ]
         };
          const fuseCategory = new Fuse(categories, CategoryOptions);
          const pattern = searchTerm;
          var temp = fuseCategory.search(pattern);
          console.log("Subset category: ",temp);
          setSearchCategories(temp);

          const fuseProduct = new Fuse(products, ProductOptions);
          temp = fuseProduct.search(pattern);
          console.log("subset Product", temp[0]);
          setSearchProducts(temp);
    }
    return (
        <div style={{width:SCREEN_WIDTH, height:SCREEN_HEIGHT, justifyContent:'space-between'}}>
            <BrowsePageNavigation/>
            <div className="container">
                <div className="row">
                    <input
                        placeholder={'Search'}
                    type="text" id="searchbar"
                    className="form-control form-control-lg"
                    onChange={handleChange}/>
                    <MDBBtn class="inputSearch" onClick={handleSearch}>Search</MDBBtn>
                </div>
            </div>
            <div className="container">
                { searchCategories.length != 0?<div>
                     <Grid.Title> Categories </Grid.Title>
                    <Grid.Section>
                        {
                            searchCategories.map((item,index,array) => {
                                const ele = item.item;
                                console.log("ele category: ",ele);
                                if(!ele)
                                    return null;
                                return (
                                <Card style={{borderRadius:8}} >
                                    <Card.Image style={{borderRadius:8}} src={ele.image}/>
                                    <Card.Title> {ele.name} </Card.Title>
                                    <Card.Button><Link to={'/browse/category/'+ ele.name}>SHOP NOW</Link></Card.Button>
                                </Card>
                            );
                        })

                        }
                    </Grid.Section>
                </div>:null}


            </div>
            <div className="container">
                { searchProducts.length != 0?
                    <div>
                    <Grid.Title> Products </Grid.Title>
                    <Grid.Section>
                        {
                            searchProducts.map((item,index,array) => {
                                //console.log("item: ", item.item);
                                const ele = item.item;
                                if(!ele)
                                    return null;
                                return (
                                <Card style={{borderRadius:8}} onClick={() => handleClick({product : ele})}>
                                    <Card.Image style={{borderRadius:8}} src={ele.product_url}/>
                                    <Card.Title> {ele.product_name} </Card.Title>
                                    <Card.SubTitle> {ele.product_description}</Card.SubTitle>
                                    <Card.Text>
                                        <Card.DeliveryTime> {ele.multiple}</Card.DeliveryTime>
                                        <Card.Price> {ele.product_price_user} </Card.Price>
                                    </Card.Text>
                                    <Card.Button>SHOP NOW</Card.Button>
                                </Card>
                            );
                        })

                        }
                    </Grid.Section>
                </div>
                    :
                    <div>
                    <img className="cart__image" src={require('../../static/img/empty_search.jpg')}/>
                    <h6>Sorry! We couldn't find anything realted to your search.</h6>
                        <MDBBtn style={{borderRadius: 8, borderWidth: 1, borderColor: '#efefef', margin: 5, width:SCREEN_WIDTH/5}}><Link to="/browse">HOME</Link></MDBBtn>
                    </div>
                }
            </div>

        </div>
    );
}
