import React from 'react';
import { Link } from 'react-router-dom';
import FooterPage from '../components/Footer';
import PageNotFound from '../static/img/404.jpg'

class NotFoundPage extends React.Component{
    render(){
        return <div>
            <h1 style={{color:"#0503b0"}}>Page Not Found</h1>
            <img style={{maxWidth:"100%"}} src={PageNotFound}  />
            <p style={{textAlign:"center", borderWidth:1, fontSize:"1.5rem"}}>
              <Link to="/">Go to Homepage </Link>
            </p>
            <FooterPage/>
          </div>;
    }
}
export default NotFoundPage;