import React, {useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import "./styles.css"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import {auth, database} from "../lib/firebase.prod";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root:{
        flex:1,
    },

    header:{
        display:"flex",
        justifyContent:'space-between',
        padding:"10px 10px",
        margin:"10px 10px",
       
    },
    headertitle:{
        
    },
    orderbutton:{
        backgroundColor:'#ffc107',
        color:'#fff',
        fontSize:14,
        margin:'0px 20px',
        "&:hover":{
            backgroundColor:'#ffc107',
            color:'#fff',
        }
    },
    totalstocksdiv:{
        display:'flex',
        padding:20,
        paddingLeft:40
    },
    stocktotal:{
        paddingRight:20,
        marginTop:14
    },
    stocktotlatextfeild:{
        fontSize:14
    },
    eachstock:{
        marginLeft:"50px",
        marginRight:"50px",
        marginTop:"15px",
    },
    stocksdiv:{
        display:'flex',
        // justifyContent:'space-around',
        [theme.breakpoints.only('xs')]: {
            flexDirection:'column',
            alignItems:'center'
          },
    },
    stockname:{
        fontSize:20,
        paddingBottom:10
    },
    tabelmain:{
        width:'90%',
        marginLeft:'5%',
        marginTop:20
    },
    dialogtitle:{
        fontSize:20,
        fontWeight:600,
        padding:20
    },
    dialogtextfield:{
        width:'100%',
        marginBottom:10
    },
    buttoncontainer:{
        display:"flex",
        justifyContent:'center',
        marginTop:20
    },
    ordertitle:{
        padding:25,
        paddingLeft:40,
        textAlign:'start',
        color:'#ffc107',
        fontSize:16,
        fontWeight:600
    }
}))

export default function StockMaintanence() {
    const classes=useStyles();
    const [open, setOpen] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [severity,setSeverity] = React.useState("");
    const [alertText, setAlertText] = React.useState("asdasdads");
    const [brokerOrders, setBrokerOrders] = React.useState([]);
    const [addedOrders, setAddedOrders] = React.useState([]);
    const [customerOrders, setCustomerOrders] = React.useState([]);
    const [showLogin,setShowLogin] = React.useState(true);
    const [atta0,setAtta0] = React.useState(0);
    const [leftatta0,setLeftAtta0] = React.useState(0);

    const [atta1,setAtta1] = React.useState(0);
    const [leftatta1,setLeftAtta1] = React.useState(0);

    const [atta2,setAtta2] = React.useState(0);
    const [leftatta2,setLeftAtta2] = React.useState(0);

    const [maida0,setMaida0] = React.useState(0);
    const [leftmaida0,setLeftMaida0] = React.useState(0);

    const [maida1,setMaida1] = React.useState(0);
    const [leftmaida1,setLeftMaida1] = React.useState(0);

    const [maida2,setMaida2] = React.useState(0);
    const [leftmaida2,setLeftMaida2] = React.useState(0);

    const [suji0,setSuji0] = React.useState(0);
    const [leftsuji0,setLeftSuji0] = React.useState(0);

    const [suji1,setSuji1] = React.useState(0);
    const [leftsuji1,setLeftSuji1] = React.useState(0);

    const [rawa0,setRawa0] = React.useState(0);
    const [leftrawa0,setLeftRawa0] = React.useState(0);

    const [rawa1,setRawa1] = React.useState(0);
    const [leftrawa1,setLeftRawa1] = React.useState(0);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
    const handleClickOpen = () => {
      setOpen(true);
    };

    useEffect(() => {
        var wheat1Kg = 0;
        var wheat5Kg = 0;
        var maida500g = 0;
        var suji500g = 0;
        var rawa500g = 0;
        database.ref("BrokerOrder").on('value',(snapshot)=>{
            var x = snapshot.val();
            var allBrokerOrders = [];
            for(let i in x){
                var add = JSON.parse(JSON.parse(JSON.stringify(x[i].user_address)));
                var actualAddress = add.addressLine1;
                var type = "Online";
                var namesSplit = x[i].item_names.split(",");
                var quantitySplit = x[i].item_quantity.split(",");
                var weightsSplit = x[i].item_weight.split(",");
                var allKilos = [];
                var totalKilos = 0;
                for(let y=0;y<weightsSplit.length-1;y++){
                    var tt = 0;
                    if(weightsSplit[y].slice(-2)=="Kg"){
                        if(namesSplit[y]=="Whole Wheat Chakki Atta"){
                            if(weightsSplit[y].slice(0,-2)=="1"){
                                wheat1Kg+=parseInt(weightsSplit[y].slice(0,-2))*parseInt(quantitySplit[y]);
                            }else if(weightsSplit[y].slice(0,-2)=="5"){
                                wheat5Kg+=(5*parseInt(quantitySplit[y]));
                            }else if(weightsSplit[y].slice(0,-2)=="10"){
                                wheat1Kg+=parseInt(weightsSplit[y].slice(0,-2))*parseInt(quantitySplit[y]);
                            }
                        }else if(namesSplit[y]=="Maida"){
                            if(weightsSplit[y].slice(0,-2)=="1"){
                                maida500g+=2*(parseInt(weightsSplit[y].slice(0,-2))*parseInt(quantitySplit[y]));
                            }else if(weightsSplit[y].slice(0,-2)=="10"){
                                maida500g+=2*(parseInt(weightsSplit[y].slice(0,-2))*parseInt(quantitySplit[y]));
                            }
                        }else if(namesSplit[y]=="Suji Rawa"){
                            if(weightsSplit[y].slice(0,-2)=="1"){
                                suji500g+=2*(parseInt(weightsSplit[y].slice(0,-2))*parseInt(quantitySplit[y]));
                            }
                        }else if(namesSplit[y]=="Rawa"){
                            if(weightsSplit[y].slice(0,-2)=="1"){
                                rawa500g+=2*(parseInt(weightsSplit[y].slice(0,-2))*parseInt(quantitySplit[y]));
                            }
                        }
                        tt = parseInt(weightsSplit[y].slice(0,-2))*parseInt(quantitySplit[y]);
                    }else{
                        if(weightsSplit[y]=="100g"){
                            tt = parseInt(quantitySplit[y])/10;
                        }else if(weightsSplit[y]=="250g"){
                            tt = parseInt(quantitySplit[y])/4;
                        }else if(weightsSplit[y]=="500g"){
                            if(namesSplit[y]=="Maida"){
                                maida500g+=parseInt(quantitySplit[y]);    
                            }else if(namesSplit[y]=="Suji Rawa"){
                                suji500g+=parseInt(quantitySplit[y]);    
                            }else if(namesSplit[y]=="Rawa"){
                                rawa500g+=parseInt(quantitySplit[y]);    
                            }
                            tt = parseInt(quantitySplit[y])/2;
                        }
                    }
                    totalKilos+=tt;
                    allKilos.push(tt);
                }
                x[i].orderdate = x[i].order_date.toString()+" "+x[i].order_month.toString()+" "+x[i].order_year.toString();
                x[i].actualAddress = actualAddress;
                x[i].type = type;
                x[i].namesSplit = namesSplit.slice(0,-1);
                x[i].quantitySplit = quantitySplit.slice(0,-1);
                x[i].weightsSplit = weightsSplit.slice(0,-1);
                x[i].allKilos = allKilos;
                x[i].totalKilos = totalKilos.toFixed(2);
                allBrokerOrders.push(x[i]);
            }
            database.ref("BrokerAcceptedOrder").on('value',(snapshot1)=>{
                var y = snapshot1.val();
                for(let i in y){
                    add = JSON.parse(JSON.parse(JSON.stringify(y[i].user_address)));
                    actualAddress = add.addressLine1;
                    type = "Online";
                    namesSplit = y[i].item_names.split(",");
                    quantitySplit= y[i].item_quantity.split(",");
                    weightsSplit = y[i].item_weight.split(",");
                    allKilos = [];
                    totalKilos = 0;
                    for(let z=0;z<weightsSplit.length-1;z++){
                        var tt = 0;
                        if(weightsSplit[z].slice(-2)=="Kg"){
                            if(namesSplit[z]=="Whole Wheat Chakki Atta"){
                                if(weightsSplit[z].slice(0,-2)=="1"){
                                    wheat1Kg+=parseInt(weightsSplit[z].slice(0,-2))*parseInt(quantitySplit[z]);
                                }else if(weightsSplit[z].slice(0,-2)=="5"){
                                    wheat5Kg+=(5*parseInt(quantitySplit[y]));
                                }else if(weightsSplit[z].slice(0,-2)=="10"){
                                    wheat1Kg+=parseInt(weightsSplit[z].slice(0,-2))*parseInt(quantitySplit[z]);
                                }
                            }
                            else if(namesSplit[z]=="Maida"){
                                if(weightsSplit[z].slice(0,-2)=="1"){
                                    maida500g+=2*(parseInt(weightsSplit[z].slice(0,-2))*parseInt(quantitySplit[z]));
                                }else if(weightsSplit[z].slice(0,-2)=="10"){
                                    maida500g+=2*(parseInt(weightsSplit[z].slice(0,-2))*parseInt(quantitySplit[z]));
                                }
                            }else if(namesSplit[z]=="Suji Rawa"){
                                if(weightsSplit[z].slice(0,-2)=="1"){
                                    suji500g+=2*(parseInt(weightsSplit[z].slice(0,-2))*parseInt(quantitySplit[z]));
                                }
                            }else if(namesSplit[z]=="Rawa"){
                                if(weightsSplit[z].slice(0,-2)=="1"){
                                    rawa500g+=2*(parseInt(weightsSplit[z].slice(0,-2))*parseInt(quantitySplit[z]));
                                }
                            }
                            tt = parseInt(weightsSplit[z].slice(0,-2))*parseInt(quantitySplit[z]);
                        }else{
                            if(weightsSplit[z]=="100g"){
                                tt = parseInt(quantitySplit[z])/10;
                            }else if(weightsSplit[z]=="250g"){
                                tt = parseInt(quantitySplit[z])/4;
                            }else if(weightsSplit[z]=="500g"){
                                if(namesSplit[z]=="Maida"){
                                    maida500g+=parseInt(quantitySplit[z]);
                                }else if(namesSplit[z]=="Suji Rawa"){
                                    suji500g+=parseInt(quantitySplit[z]);    
                                }else if(namesSplit[z]=="Rawa"){
                                    rawa500g+=parseInt(quantitySplit[z]);    
                                }
                                tt = parseInt(quantitySplit[z])/2;
                            }
                        }
                        totalKilos+=tt;
                        allKilos.push(tt);
                    }
                    y[i].orderdate = y[i].order_date.toString()+" "+y[i].order_month.toString()+" "+y[i].order_year.toString();
                    y[i].actualAddress = actualAddress;
                    y[i].type = type;
                    y[i].namesSplit = namesSplit.slice(0,-1);
                    y[i].quantitySplit = quantitySplit.slice(0,-1);
                    y[i].weightsSplit = weightsSplit.slice(0,-1);
                    y[i].allKilos = allKilos;
                    y[i].totalKilos = totalKilos.toFixed(2);
                    allBrokerOrders.push(y[i]);
                }   
                setBrokerOrders(allBrokerOrders);
            })
        });

        database.ref("AddedOrder").on('value',(snapshot)=>{
            if(snapshot.val()!=undefined){
                var allKeys = Object.keys(snapshot.val());
                var allProducts = snapshot.val();
                var setProducts = [];
                for(let key in allKeys){
                    var pdt = allProducts[allKeys[key]];
                    setProducts.push(pdt);
                    for(let index in pdt.pdtNames){
                        if(pdt.pdtNames[index]=="Whole Wheat Chakki Atta"){
                            if(pdt.pdtWeights[index]=="1Kg"){
                                wheat1Kg+=parseInt(pdt.pdtWeightQuantity[index]);
                            }else if(pdt.pdtWeights[index]=="5Kg"){
                                wheat5Kg+=(parseInt(pdt.pdtWeightQuantity[index]));
                            }
                        }else if(pdt.pdtNames[index]=="Maida"){
                            maida500g+=(2*parseInt(pdt.pdtWeightQuantity[index]));
                        }else if(pdt.pdtNames[index]=="Suji Rawa"){
                            suji500g+=(2*parseInt(pdt.pdtWeightQuantity[index]));
                        }else if(pdt.pdtNames[index]=="Rawa"){
                            rawa500g+=(2*parseInt(pdt.pdtWeightQuantity[index]));
                        }
                    }
                }
                setAddedOrders(setProducts);    
            }
        })

        database.ref("NewOrder").on('value',(snapshot)=>{
            var x = snapshot.val();
            var allBrokerOrders = [];
            for(let i in x){
                var add = JSON.parse(JSON.parse(JSON.stringify(x[i].user_address)));
                var actualAddress = add.addressLine1;
                var type = "Online";
                var namesSplit = x[i].item_names.split(",");
                var quantitySplit = x[i].item_quantity.split(",");
                var weightsSplit = x[i].item_weight.split(",");
                var allKilos = [];
                var totalKilos = 0;
                for(let y=0;y<weightsSplit.length-1;y++){
                    var tt = 0;
                    if(weightsSplit[y].slice(-2)=="Kg"){
                        if(namesSplit[y]=="Whole Wheat Chakki Atta"){
                            if(weightsSplit[y].slice(0,-2)=="1"){
                                wheat1Kg+=parseInt(weightsSplit[y].slice(0,-2))*parseInt(quantitySplit[y]);
                            }else if(weightsSplit[y].slice(0,-2)=="5"){
                                wheat5Kg+=(5*parseInt(quantitySplit[y]));
                            }else if(weightsSplit[y].slice(0,-2)=="10"){
                                wheat1Kg+=parseInt(weightsSplit[y].slice(0,-2))*parseInt(quantitySplit[y]);
                            }
                        }else if(namesSplit[y]=="Maida"){
                                maida500g+=2*(parseInt(weightsSplit[y].slice(0,-2))*parseInt(quantitySplit[y]));
                        }else if(namesSplit[y]=="Suji Rawa"){
                            if(weightsSplit[y].slice(0,-2)=="1"){
                                suji500g+=2*(parseInt(weightsSplit[y].slice(0,-2))*parseInt(quantitySplit[y]));
                            }
                        }else if(namesSplit[y]=="Rawa"){
                            if(weightsSplit[y].slice(0,-2)=="1"){
                                rawa500g+=2*(parseInt(weightsSplit[y].slice(0,-2))*parseInt(quantitySplit[y]));
                            }
                        }
                        tt = parseInt(weightsSplit[y].slice(0,-2))*parseInt(quantitySplit[y]);
                    }else{
                        if(weightsSplit[y]=="100g"){
                            tt = parseInt(quantitySplit[y])/10;
                        }else if(weightsSplit[y]=="250g"){
                            tt = parseInt(quantitySplit[y])/4;
                        }else if(weightsSplit[y]=="500g"){
                            if(namesSplit[y]=="Maida"){
                                maida500g+=parseInt(quantitySplit[y]);    
                            }else if(namesSplit[y]=="Suji Rawa"){
                                suji500g+=parseInt(quantitySplit[y]);    
                            }else if(namesSplit[y]=="Rawa"){
                                rawa500g+=parseInt(quantitySplit[y]);    
                            }
                            tt = parseInt(quantitySplit[y])/2;
                        }
                    }
                    totalKilos+=tt;
                    allKilos.push(tt);
                }
                x[i].orderdate = x[i].order_date.toString()+" "+x[i].order_month.toString()+" "+x[i].order_year.toString();
                x[i].actualAddress = actualAddress;
                x[i].type = type;
                x[i].namesSplit = namesSplit.slice(0,-1);
                x[i].quantitySplit = quantitySplit.slice(0,-1);
                x[i].weightsSplit = weightsSplit.slice(0,-1);
                x[i].allKilos = allKilos;
                x[i].totalKilos = totalKilos.toFixed(2);
                allBrokerOrders.push(x[i]);
            }
            database.ref("AcceptedOrder").on('value',(snapshot1)=>{
                var y = snapshot1.val();
                for(let i in y){
                    add = JSON.parse(JSON.parse(JSON.stringify(y[i].user_address)));
                    actualAddress = add.addressLine1;
                    type = "Online";
                    namesSplit = y[i].item_names.split(",");
                    quantitySplit= y[i].item_quantity.split(",");
                    weightsSplit = y[i].item_weight.split(",");
                    allKilos = [];
                    totalKilos = 0;
                    for(let z=0;z<weightsSplit.length-1;z++){
                        var tt = 0;
                        if(weightsSplit[z].slice(-2)=="Kg"){
                            if(namesSplit[z]=="Whole Wheat Chakki Atta"){
                                if(weightsSplit[z].slice(0,-2)=="1"){
                                    wheat1Kg+=parseInt(weightsSplit[z].slice(0,-2))*parseInt(quantitySplit[z]);
                                }else if(weightsSplit[z].slice(0,-2)=="5"){
                                    wheat5Kg+=(5*parseInt(quantitySplit[z]));
                                }else if(weightsSplit[z].slice(0,-2)=="10"){
                                    wheat1Kg+=parseInt(weightsSplit[z].slice(0,-2))*parseInt(quantitySplit[z]);
                                }
                            }else if(namesSplit[z]=="Maida"){
                                    maida500g+=2*(parseInt(weightsSplit[z].slice(0,-2))*parseInt(quantitySplit[z]));
                            }else if(namesSplit[z]=="Suji Rawa"){
                                if(weightsSplit[z].slice(0,-2)=="1"){
                                    suji500g+=2*(parseInt(weightsSplit[z].slice(0,-2))*parseInt(quantitySplit[z]));
                                }
                            }else if(namesSplit[z]=="Rawa"){
                                if(weightsSplit[z].slice(0,-2)=="1"){
                                    rawa500g+=2*(parseInt(weightsSplit[z].slice(0,-2))*parseInt(quantitySplit[z]));
                                }
                            }
                            tt = parseInt(weightsSplit[z].slice(0,-2))*parseInt(quantitySplit[z]);
                        }else{
                            if(weightsSplit[z]=="100g"){
                                tt = parseInt(quantitySplit[z])/10;
                            }else if(weightsSplit[z]=="250g"){
                                tt = parseInt(quantitySplit[z])/4;
                            }else if(weightsSplit[z]=="500g"){
                                if(namesSplit[z]=="Maida"){
                                    maida500g+=parseInt(quantitySplit[z]);    
                                }else if(namesSplit[z]=="Suji Rawa"){
                                    suji500g+=parseInt(quantitySplit[z]);    
                                }else if(namesSplit[z]=="Rawa"){
                                    rawa500g+=parseInt(quantitySplit[z]);    
                                }
                                tt = parseInt(quantitySplit[z])/2;
                            }
                        }
                        totalKilos+=tt;
                        allKilos.push(tt);
                    }
                    y[i].orderdate = y[i].order_date.toString()+" "+y[i].order_month.toString()+" "+y[i].order_year.toString();
                    y[i].actualAddress = actualAddress;
                    y[i].type = type;
                    y[i].namesSplit = namesSplit.slice(0,-1);
                    y[i].quantitySplit = quantitySplit.slice(0,-1);
                    y[i].weightsSplit = weightsSplit.slice(0,-1);
                    y[i].allKilos = allKilos;
                    y[i].totalKilos = totalKilos.toFixed(2);
                    allBrokerOrders.push(y[i]);
                } 
                setCustomerOrders(allBrokerOrders);
                database.ref("StockOrdered").on('value',(snapshot)=>{
                    var attaValues = snapshot.val()['atta'];
                    var maidaValues = snapshot.val()['maida'];
                    var sujiValues = snapshot.val()['suji'];
                    var rawaValues = snapshot.val()['rawa'];
                    setAtta0(attaValues[0]);
                    setAtta1(attaValues[1]);
                    setAtta2(attaValues[2]);
                    setLeftAtta0(attaValues[0]-wheat1Kg);
                    setLeftAtta1(attaValues[1]-wheat5Kg);
                    setLeftAtta2(0);
                    setMaida0(maidaValues[0]);
                    setMaida1(maidaValues[1]);
                    setMaida2(maidaValues[2]);
                    setLeftMaida0(maidaValues[0]-(maida500g/2));
                    setLeftMaida1(0);
                    setLeftMaida2(0);
    
                    setSuji0(sujiValues[0]);
                    setSuji1(sujiValues[1]);
                    setLeftSuji0(sujiValues[0]-(suji500g/2));
                    setLeftSuji1(0);  

                    setRawa0(rawaValues[0]);
                    setRawa1(rawaValues[1]);
                    setLeftRawa0(rawaValues[0]-(rawa500g/2));
                    setLeftRawa1(0);    
                })        
            })
        });

    },[])

    const handleAdd = () => {
        var name = document.getElementById("name").value;
        var address = document.getElementById("address").value;
        var phone = document.getElementById("phone").value;
        var sampleorder = document.getElementById("sample/order").value;
        // var orderdate =  document.getElementById("orderdate").value;

        var totalatta = document.getElementById("totalatta").value;
        var totalmaida = document.getElementById("totalmaida").value;
        var totalsuji = document.getElementById("totalsuji").value;
        var totalrawa = document.getElementById("totalrawa").value;

        var weightatta = document.getElementById("weightatta").value;
        var weightmaida = document.getElementById("weightmaida").value;
        var weightsuji = document.getElementById("weightsuji").value;
        var weightrawa = document.getElementById("weightrawa").value;

        var quantityatta = document.getElementById("quantityatta").value;
        var quantitymaida = document.getElementById("quantitymaida").value;
        var quantitysuji = document.getElementById("quantitysuji").value;
        var quantityrawa = document.getElementById("quantityrawa").value;

        if(name=="" || address=="" || phone=="" || sampleorder==""){
            setOpenAlert(true);
            setAlertText("Please add name,address,phone number!");
            setSeverity("warning");
        }else if(totalatta=="" && totalmaida=="" && totalsuji=="" && totalrawa==""){
            setOpenAlert(true);
            setAlertText("Please add atleast quantity of one product");
            setSeverity("warning");
        }else{
            var num = Math.floor(100000 + Math.random() * 900000);
            var total = 0;
            var names = [];
            var weights = [];
            var quantities = [];
            var pdtWeightQuantity = [];
            if(totalatta!="" && weightatta!="" && quantityatta!=""){
                names.push("Whole Wheat Chakki Atta");
                weights.push(weightatta);
                if(weightatta=="1kg"){
                    setLeftAtta0(leftatta0-totalatta);
                }else if(weightatta=="5Kg"){
                    setLeftAtta1(leftatta1-totalatta);
                }
                quantities.push(quantityatta);
                pdtWeightQuantity.push(totalatta);
                total+=parseInt(totalatta);
            }if(totalmaida!="" && weightmaida!="" && quantitymaida!=""){
                names.push("Maida");
                weights.push(weightmaida);
                quantities.push(quantitymaida);
                pdtWeightQuantity.push(totalmaida);
                setLeftMaida0(leftmaida0-totalmaida);
                total+=parseInt(totalmaida);
            }if(totalsuji!="" && weightsuji!="" && quantitysuji!=""){
                names.push("Suji Rawa");
                weights.push(weightsuji);
                quantities.push(quantitysuji);
                pdtWeightQuantity.push(totalsuji);
                setLeftSuji0(leftsuji0-totalsuji);
                total+=parseInt(totalsuji);
            }if(totalrawa!="" && weightrawa!="" && quantityrawa!=""){
                names.push("Rawa");
                weights.push(weightrawa);
                quantities.push(quantityrawa);
                pdtWeightQuantity.push(totalrawa);
                setRawa0(leftrawa0-totalrawa);
                total+=parseInt(totalrawa);
            }
            if(total!=0){
                var create_add_order = database.ref("AddedOrder").child(num.toString());
                create_add_order.set({
                    'name':name,
                    'address':address,
                    'phone':phone,
                    'sampleororder':sampleorder,
                    'pdtNames':names,
                    'pdtWeights':weights,
                    'pdtQuanties':quantities,
                    'pdtWeightQuantity':pdtWeightQuantity,
                    'total':total,
                    'type':'Added',
                }).then(()=>{
                    setOpenAlert(true);
                    setAlertText("Added Order!!");
                    setSeverity("success");   
                    setOpen(false); 
                })
            }
        }
    }
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleLogin = () => {
        var email = document.getElementById("emailid").value;
        var password = document.getElementById("password").value;
        if(email=="admin@chariotagro.com" && password=="chariotagroadmin"){
            setShowLogin(false);
        }
    }

    const handleCloseAlert = () => {
        setOpenAlert(false);
    }
    return (
        <div className={classes.root}>
            {
                showLogin==true?<Dialog
                fullScreen={fullScreen}
                open={showLogin}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                fullWidth={true}
                maxWidth={"sm"}
                disableBackdropClick
                >
                <Typography className={classes.dialogtitle} id="responsive-dialog-title">Login For Access</Typography>
                <DialogContent>
                <DialogContentText>
                    <TextField id="outlined-basic" 
                        className={classes.dialogtextfield}
                        label="Email Id" variant="outlined"
                        inputProps={{style: {fontSize: 16}}} 
                        InputLabelProps={{style: {fontSize: 16}}} 
                        autoComplete="off"
                        id="emailid"
                    />
                    <TextField id="outlined-basic" 
                        className={classes.dialogtextfield}
                        label="Password" variant="outlined"
                        inputProps={{style: {fontSize: 16}}} 
                        InputLabelProps={{style: {fontSize: 16}}} 
                        autoComplete="off"
                        id="password"
                        type="password"
                    />

                    <div className={classes.buttoncontainer}>
                        <Button variant="contained" className={classes.orderbutton} style={{backgroundColor:'#9cc63d'}} onClick={handleLogin}>LOGIN</Button>
                    </div>
                     
                </DialogContentText>
                </DialogContent>
    
            </Dialog>
:
                <div>
            <Paper className={classes.header}>
                <Typography variant="h4" className={classes.headertitle}>Stock Maintanence</Typography>
                <Button variant="contained" className={classes.orderbutton} onClick={handleClickOpen}>Add Order</Button>
            </Paper>

            <div className={classes.totalstocksdiv}>
                <Typography variant="h4" className={classes.stocktotal}>STOCK ORDERED (All values are in Kg)</Typography>
            </div>

            <div className={classes.stocksdiv}>
                <div className={classes.eachstock}>
                    <Typography className={classes.stockname}>Atta (1Kg)</Typography>
                    <TextField autoComplete="off" placeholder="(in Kg)" value={atta0} variant="outlined" inputProps={{style: {fontSize: 16}}} InputLabelProps={{style: {fontSize: 16}}}  className={classes.stocktotlatextfeild} />
                </div>
                <div className={classes.eachstock}>
                    <Typography className={classes.stockname}>Atta (5Kg)</Typography>
                    <TextField autoComplete="off" placeholder="(in Kg)" value={atta1} variant="outlined" inputProps={{style: {fontSize: 16}}} InputLabelProps={{style: {fontSize: 16}}}  className={classes.stocktotlatextfeild} />
                </div>
                <div className={classes.eachstock}>
                    <Typography className={classes.stockname}>Atta (10Kg)</Typography>
                    <TextField autoComplete="off" placeholder="(in Kg)" value={atta2} variant="outlined" inputProps={{style: {fontSize: 16}}} InputLabelProps={{style: {fontSize: 16}}}  className={classes.stocktotlatextfeild} />
                </div>
            </div>
            <div className={classes.stocksdiv}>
                <div className={classes.eachstock}>
                    <Typography className={classes.stockname}>Maida (500g)</Typography>
                    <TextField autoComplete="off" placeholder="(in Kg)" value={maida0} variant="outlined" inputProps={{style: {fontSize: 16}}} InputLabelProps={{style: {fontSize: 16}}}  className={classes.stocktotlatextfeild} />
                </div>
                <div className={classes.eachstock}>
                    <Typography className={classes.stockname}>Maida (1Kg)</Typography>
                    <TextField autoComplete="off" placeholder="(in Kg)" value={maida1} variant="outlined" inputProps={{style: {fontSize: 16}}} InputLabelProps={{style: {fontSize: 16}}}  className={classes.stocktotlatextfeild} />
                </div>
                <div className={classes.eachstock}>
                    <Typography className={classes.stockname}>Maida (10Kg)</Typography>
                    <TextField autoComplete="off" placeholder="(in Kg)" value={maida2} variant="outlined" inputProps={{style: {fontSize: 16}}} InputLabelProps={{style: {fontSize: 16}}}  className={classes.stocktotlatextfeild} />
                </div>
            </div>
            <div className={classes.stocksdiv}>
                <div className={classes.eachstock}>
                    <Typography className={classes.stockname}>Suji Rawa (500g)</Typography>
                    <TextField autoComplete="off" placeholder="(in Kg)" value={suji0} variant="outlined" inputProps={{style: {fontSize: 16}}} InputLabelProps={{style: {fontSize: 16}}}  className={classes.stocktotlatextfeild} />
                </div>
                <div className={classes.eachstock}>
                    <Typography className={classes.stockname}>Suji Rawa (1Kg)</Typography>
                    <TextField autoComplete="off" placeholder="(in Kg)" value={suji1} variant="outlined" inputProps={{style: {fontSize: 16}}} InputLabelProps={{style: {fontSize: 16}}}  className={classes.stocktotlatextfeild} />
                </div>
            </div>
            <div className={classes.stocksdiv}>
                <div className={classes.eachstock}>
                    <Typography className={classes.stockname}>Rawa (500g)</Typography>
                    <TextField autoComplete="off" placeholder="(in Kg)" value={rawa0} variant="outlined" inputProps={{style: {fontSize: 16}}} InputLabelProps={{style: {fontSize: 16}}}  className={classes.stocktotlatextfeild} />
                </div>
                <div className={classes.eachstock}>
                    <Typography className={classes.stockname}>Rawa (1Kg)</Typography>
                    <TextField autoComplete="off" placeholder="(in Kg)" value={rawa1} variant="outlined" inputProps={{style: {fontSize: 16}}} InputLabelProps={{style: {fontSize: 16}}}  className={classes.stocktotlatextfeild} />
                </div>
            </div>

            <div className={classes.totalstocksdiv}>
                <Typography variant="h4" className={classes.stocktotal}>STOCK AVAILABLE AFTER DELIVERY (All values are in Kg)</Typography>
            </div>

            <div className={classes.stocksdiv}>
                <div className={classes.eachstock}>
                    <Typography className={classes.stockname}>Atta (1Kg)</Typography>
                    <TextField autoComplete="off" placeholder="(in Kg)" value={leftatta0} variant="outlined" inputProps={{style: {fontSize: 16}}} InputLabelProps={{style: {fontSize: 16}}}  className={classes.stocktotlatextfeild} />
                </div>
                <div className={classes.eachstock}>
                    <Typography className={classes.stockname}>Atta (5Kg)</Typography>
                    <TextField autoComplete="off" placeholder="(in Kg)" value={leftatta1} variant="outlined" inputProps={{style: {fontSize: 16}}} InputLabelProps={{style: {fontSize: 16}}}  className={classes.stocktotlatextfeild} />
                </div>
                <div className={classes.eachstock}>
                    <Typography className={classes.stockname}>Atta (10Kg)</Typography>
                    <TextField autoComplete="off" placeholder="(in Kg)" value={leftatta2} variant="outlined" inputProps={{style: {fontSize: 16}}} InputLabelProps={{style: {fontSize: 16}}}  className={classes.stocktotlatextfeild} />
                </div>
            </div>
            <div className={classes.stocksdiv}>
                <div className={classes.eachstock}>
                    <Typography className={classes.stockname}>Maida (500g)</Typography>
                    <TextField autoComplete="off" placeholder="(in Kg)" value={leftmaida0} variant="outlined" inputProps={{style: {fontSize: 16}}} InputLabelProps={{style: {fontSize: 16}}}  className={classes.stocktotlatextfeild} />
                </div>
                <div className={classes.eachstock}>
                    <Typography className={classes.stockname}>Maida (1Kg)</Typography>
                    <TextField autoComplete="off" placeholder="(in Kg)" value={leftmaida1} variant="outlined" inputProps={{style: {fontSize: 16}}} InputLabelProps={{style: {fontSize: 16}}}  className={classes.stocktotlatextfeild} />
                </div>
                <div className={classes.eachstock}>
                    <Typography className={classes.stockname}>Maida (10Kg)</Typography>
                    <TextField autoComplete="off" placeholder="(in Kg)" value={leftmaida2} variant="outlined" inputProps={{style: {fontSize: 16}}} InputLabelProps={{style: {fontSize: 16}}}  className={classes.stocktotlatextfeild} />
                </div>
            </div>
            <div className={classes.stocksdiv}>
                <div className={classes.eachstock}>
                    <Typography className={classes.stockname}>Suji Rawa (500g)</Typography>
                    <TextField autoComplete="off" placeholder="(in Kg)" value={leftsuji0} variant="outlined" inputProps={{style: {fontSize: 16}}} InputLabelProps={{style: {fontSize: 16}}}  className={classes.stocktotlatextfeild} />
                </div>
                <div className={classes.eachstock}>
                    <Typography className={classes.stockname}>Suji Rawa (1Kg)</Typography>
                    <TextField autoComplete="off" placeholder="(in Kg)" value={leftsuji1} variant="outlined" inputProps={{style: {fontSize: 16}}} InputLabelProps={{style: {fontSize: 16}}}  className={classes.stocktotlatextfeild} />
                </div>
            </div>
            <div className={classes.stocksdiv}>
                <div className={classes.eachstock}>
                    <Typography className={classes.stockname}>Rawa (500g)</Typography>
                    <TextField autoComplete="off" placeholder="(in Kg)" value={leftrawa0} variant="outlined" inputProps={{style: {fontSize: 16}}} InputLabelProps={{style: {fontSize: 16}}}  className={classes.stocktotlatextfeild} />
                </div>
                <div className={classes.eachstock}>
                    <Typography className={classes.stockname}>Rawa (1Kg)</Typography>
                    <TextField autoComplete="off" placeholder="(in Kg)" value={leftrawa1} variant="outlined" inputProps={{style: {fontSize: 16}}} InputLabelProps={{style: {fontSize: 16}}}  className={classes.stocktotlatextfeild} />
                </div>
            </div>


            <Snackbar open={openAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
                    <Alert onClose={handleCloseAlert} severity={severity}>
                            <p style={{fontSize:"14px"}}>{alertText}</p>
                    </Alert>
            </Snackbar>

        <Typography variant="h4" className={classes.ordertitle}>Distributor Orders(online) :</Typography>
            {
                brokerOrders.length!==0?brokerOrders.map((order,index)=>{
                    return <div class="search-table-outter wrapper">
                        <table  className={classes.tabelmain}>
                            <tr>
                                <th class="online">Sl.No</th>
                                <th class="name">Name</th>
                                <th class="address">Address</th>
                                <th class="phone">Phone</th>
                                <th class="online">Online/Added</th>
                                <th class="online">Order Date</th>
                                {order.namesSplit.map((name)=>{
                                    return <th style={{width:"4%"}}>{name} (in Kg)</th> 
                                })}
                                <th class="total">Total (in Kg)</th>
                            </tr>
                            <tr>
                                <td>{index+1}</td>
                                <td>{order.user_name}</td>
                                <td>{order.actualAddress}</td>
                                <td>{order.user_phone}</td>
                                <td>{order.type}</td>
                                <td>{order.orderdate}</td>
                                {order.allKilos!=undefined?order.allKilos.map((kilo,index)=>{
                                    return <td>{kilo} ({order.weightsSplit[index]} x {order.quantitySplit[index]})</td>
                                }):null}
                                <td>{order.totalKilos}</td>
                            </tr>
                        </table>
                    </div>
        
                }):null
            }
        <Typography variant="h4" className={classes.ordertitle}>Distributor Orders(phone/email) :</Typography>
        {
                addedOrders.length!==0?addedOrders.map((order,index)=>{
                    return <div class="search-table-outter wrapper">
                        <table  className={classes.tabelmain}>
                            <tr>
                                <th class="online">Sl.No</th>
                                <th class="name">Name</th>
                                <th class="address">Address</th>
                                <th class="phone">Phone</th>
                                <th class="online">Sample/Order</th>
                                {/* <th class="online">Order Date</th> */}
                                <th class="online">Online/Added</th>
                                {order.pdtNames!=undefined?order.pdtNames.map((name)=>{
                                    return <th style={{width:"5%"}}>{name} (in Kg)</th> 
                                }):null}
                                <th class="total">Total (in Kg)</th>
                            </tr>
                            <tr>
                                <td>{index+1}</td>
                                <td>{order.name}</td>
                                <td>{order.address}</td>
                                <td>{order.phone}</td>
                                <td>{order.sampleororder}</td>
                                {/* <td>{order.orderdate}</td> */}
                                <td>{order.type}</td>
                                {order.pdtWeightQuantity!=undefined?order.pdtWeightQuantity.map((weight,index)=>{
                                    return <td>{weight} ({order.pdtWeights[index]} x {order.pdtQuanties[index]})</td>
                                }):null}
                                <td class="total">{order.total}</td>
                            </tr>
                        </table>
                    </div>        
                }):null
            }
            <Typography variant="h4" className={classes.ordertitle}>Customer Orders :</Typography>
            {
                customerOrders.length!==0?customerOrders.map((order,index)=>{
                    return <div class="search-table-outter wrapper">
                        <table  className={classes.tabelmain}>
                            <tr>
                                <th class="online">Sl.No</th>
                                <th class="name">Name</th>
                                <th class="address">Address</th>
                                <th class="phone">Phone</th>
                                <th class="online">Online/Added</th>
                                <th class="online">Order Date</th>
                                {order.namesSplit.map((name)=>{
                                    return <th style={{width:"4%"}}>{name} (in Kg)</th> 
                                })}
                                <th class="total">Total (in Kg)</th>
                            </tr>
                            <tr>
                                <td>{index+1}</td>
                                <td>{order.user_name}</td>
                                <td>{order.actualAddress}</td>
                                <td>{order.user_phone}</td>
                                <td>{order.type}</td>
                                <td>{order.orderdate}</td>
                                {order.allKilos!=undefined?order.allKilos.map((kilo,index)=>{
                                    return <td>{kilo} ({order.weightsSplit[index]} x {order.quantitySplit[index]})</td>
                                }):null}
                                <td>{order.totalKilos}</td>
                            </tr>
                        </table>
                    </div>
        
                }):null
            }
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                fullWidth={true}
                maxWidth={"sm"}
                disableBackdropClick
            >
                <Typography className={classes.dialogtitle} id="responsive-dialog-title">Add Order</Typography>
                <DialogContent>
                <DialogContentText>
                     <TextField id="outlined-basic" 
                     className={classes.dialogtextfield}
                     label="Name" variant="outlined"
                     inputProps={{style: {fontSize: 16}}} 
                     InputLabelProps={{style: {fontSize: 16}}} 
                     autoComplete="off"
                     id="name"
                      />
                       <TextField id="outlined-basic" 
                     className={classes.dialogtextfield}
                     label="Address" variant="outlined"
                     inputProps={{style: {fontSize: 16}}} 
                     InputLabelProps={{style: {fontSize: 16}}} 
                     multiline={true}
                     rows={7}
                     autoComplete="off"
                     id="address"
                      />
                       <TextField id="outlined-basic" 
                     className={classes.dialogtextfield}
                     label="Phone" variant="outlined"
                     inputProps={{style: {fontSize: 16}}} 
                     InputLabelProps={{style: {fontSize: 16}}} 
                     autoComplete="off"
                     id="phone"
                      />
                    <TextField id="outlined-basic" 
                     className={classes.dialogtextfield}
                     label="Sample/Order" variant="outlined"
                     inputProps={{style: {fontSize: 16}}} 
                     InputLabelProps={{style: {fontSize: 16}}} 
                     autoComplete="off"
                     id="sample/order"
                      />
                    {/* <TextField id="outlined-basic" 
                     className={classes.dialogtextfield}
                     label="Order Date(Ex: 31 July 2021)" variant="outlined"
                     inputProps={{style: {fontSize: 16}}} 
                     InputLabelProps={{style: {fontSize: 16}}} 
                     autoComplete="off"
                     id="orderdate"
                    /> */}


                      <Grid container spacing={3}>
                          <Grid item xs={4} sm={4} style={{display:"flex",alignItems:"center"}}>
                            <TextField id="outlined-basic" 
                                className={classes.dialogtextfield}
                                label="Total Atta(Example: 30)" variant="outlined"
                                inputProps={{style: {fontSize: 16}}} 
                                InputLabelProps={{style: {fontSize: 16}}} 
                                autoComplete="off"
                                id="totalatta"
                            />
                            <p style={{fontSize:"20px",marginLeft:"10px"}}>:</p>
                          </Grid>
                          <Grid item xs={4} sm={4} style={{display:"flex",alignItems:"center"}}>
                            <TextField id="outlined-basic" 
                                className={classes.dialogtextfield}
                                label="Weight Atta(Example: 1Kg)" variant="outlined"
                                inputProps={{style: {fontSize: 16}}} 
                                InputLabelProps={{style: {fontSize: 16}}} 
                                autoComplete="off"
                                id="weightatta"
                            />
                            <p style={{fontSize:"20px",marginLeft:"10px"}}>x</p>
                          </Grid> 
                          <Grid item xs={4} sm={4}>
                            <TextField id="outlined-basic" 
                                className={classes.dialogtextfield}
                                label="Quantity Atta(Example: 30)" variant="outlined"
                                inputProps={{style: {fontSize: 16}}} 
                                InputLabelProps={{style: {fontSize: 16}}} 
                                autoComplete="off"
                                id="quantityatta"
                            />
                          </Grid>

                          <Grid item xs={4} sm={4} style={{display:"flex",alignItems:"center"}}>
                            <TextField id="outlined-basic" 
                                className={classes.dialogtextfield}
                                label="Total Maida(Example: 15)" variant="outlined"
                                inputProps={{style: {fontSize: 16}}} 
                                InputLabelProps={{style: {fontSize: 16}}} 
                                autoComplete="off"
                                id="totalmaida"
                            />
                            <p style={{fontSize:"20px",marginLeft:"10px"}}>:</p>
                          </Grid>
                          <Grid item xs={4} sm={4} style={{display:"flex",alignItems:"center"}}>
                            <TextField id="outlined-basic" 
                                className={classes.dialogtextfield}
                                label="Weight Maida(Example: 500g)" variant="outlined"
                                inputProps={{style: {fontSize: 16}}} 
                                InputLabelProps={{style: {fontSize: 16}}} 
                                autoComplete="off"
                                id="weightmaida"
                            />
                            <p style={{fontSize:"20px",marginLeft:"10px"}}>x</p>
                          </Grid> 
                          <Grid item xs={4} sm={4}>
                            <TextField id="outlined-basic" 
                                className={classes.dialogtextfield}
                                label="Quantity Maida(Example: 30)" variant="outlined"
                                inputProps={{style: {fontSize: 16}}} 
                                InputLabelProps={{style: {fontSize: 16}}} 
                                autoComplete="off"
                                id="quantitymaida"
                            />
                          </Grid>

                          <Grid item xs={4} sm={4} style={{display:"flex",alignItems:"center"}}>
                            <TextField id="outlined-basic" 
                                className={classes.dialogtextfield}
                                label="Total Suji(Example: 15)" variant="outlined"
                                inputProps={{style: {fontSize: 16}}} 
                                InputLabelProps={{style: {fontSize: 16}}} 
                                autoComplete="off"
                                id="totalsuji"
                            />
                            <p style={{fontSize:"20px",marginLeft:"10px"}}>:</p>
                          </Grid>
                          <Grid item xs={4} sm={4} style={{display:"flex",alignItems:"center"}}>
                            <TextField id="outlined-basic" 
                                className={classes.dialogtextfield}
                                label="Weight Suji(Example: 500g)" variant="outlined"
                                inputProps={{style: {fontSize: 16}}} 
                                InputLabelProps={{style: {fontSize: 16}}} 
                                autoComplete="off"
                                id="weightsuji"
                            />
                            <p style={{fontSize:"20px",marginLeft:"10px"}}>x</p>
                          </Grid> 
                          <Grid item xs={4} sm={4}>
                            <TextField id="outlined-basic" 
                                className={classes.dialogtextfield}
                                label="Quantity Suji(Example: 30)" variant="outlined"
                                inputProps={{style: {fontSize: 16}}} 
                                InputLabelProps={{style: {fontSize: 16}}} 
                                autoComplete="off"
                                id="quantitysuji"
                            />
                          </Grid>

                          <Grid item xs={4} sm={4} style={{display:"flex",alignItems:"center"}}>
                            <TextField id="outlined-basic" 
                                className={classes.dialogtextfield}
                                label="Total Rawa(Example: 15)" variant="outlined"
                                inputProps={{style: {fontSize: 16}}} 
                                InputLabelProps={{style: {fontSize: 16}}} 
                                autoComplete="off"
                                id="totalrawa"
                            />
                            <p style={{fontSize:"20px",marginLeft:"10px"}}>:</p>
                          </Grid>
                          <Grid item xs={4} sm={4} style={{display:"flex",alignItems:"center"}}>
                            <TextField id="outlined-basic" 
                                className={classes.dialogtextfield}
                                label="Weight Rawa(Example: 500g)" variant="outlined"
                                inputProps={{style: {fontSize: 16}}} 
                                InputLabelProps={{style: {fontSize: 16}}} 
                                autoComplete="off"
                                id="weightrawa"
                            />
                            <p style={{fontSize:"20px",marginLeft:"10px"}}>x</p>
                          </Grid> 
                          <Grid item xs={4} sm={4}>
                            <TextField id="outlined-basic" 
                                className={classes.dialogtextfield}
                                label="Quantity Rawa(Example: 30)" variant="outlined"
                                inputProps={{style: {fontSize: 16}}} 
                                InputLabelProps={{style: {fontSize: 16}}} 
                                autoComplete="off"
                                id="quantityrawa"
                            />
                          </Grid>


                      </Grid>
                        <div className={classes.buttoncontainer}>
                        <Button variant="contained" className={classes.orderbutton}  style={{backgroundColor:'#dc3545'}}  onClick={handleClose}>Close</Button>
                        <Button variant="contained" className={classes.orderbutton} style={{backgroundColor:'#9cc63d'}} onClick={handleAdd}>ADD</Button>
                        </div>
                     
                </DialogContentText>
                </DialogContent>
    
            </Dialog>
                </div>    
            }
            
        </div>
    )
}
