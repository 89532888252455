import React,{useEffect,useState} from 'react';
import {database} from '../../lib/firebase.prod';
import './styles.css';
import BrowsePageNavigation from '../../components/navigation/browseNavigation'
import FooterPage from "../../components/Footer";
import NoOrdersImage from "../../static/img/noorders.png";
import OrderTracker from '../../components/OrderTracker';
import OrderModal from '../../components/CenteredModal/orderModal';
import OrdersCard from '../../components/OrdersCard';
export default function OrdersContainer(){
    
    const [orders,setOrders] = useState(null);
    const uid = localStorage.getItem('token');
    const [show,setShow] = useState(false);
    const [item,setItem] = useState(null);
    useEffect(() => {
        (async function () {

            if(uid === null)
                {
                    alert("Login first");
                    return;
                }
        
            var tempOrders = [];
            var peek = database.ref("MyOrder").child(uid);
            peek.once("value")
            .then(function(snapshot) {
                snapshot.forEach(function(childSnapshot) {
                    var key = childSnapshot.key;
                    var childData = childSnapshot.val();
                    //setOrders(childData);
                    console.log("Orders",childData);
                    tempOrders = [...tempOrders,childData];
                });
                setOrders(tempOrders);
            });
        })();
    }, [])
    
    const handleClick = (item) => {
        setShow(true);
        setItem(item);
    }
    return (
        <div>
            {/* <BrowsePageNavigation /> */}
                {
                    !orders  &&
                    <div>
                        <center>
                        <img src={NoOrdersImage} className="noorders_image"/>
                        <p className="noorders_text"> You Currently have no orders to show! </p>
                        <button className="continue_shopping"> Continue Shopping! </button>
                        </center>
                    </div>
                    
                }
                { orders &&
                <div className="container page_container">
                    <h3 className="page_title"> Your Orders </h3>
                    <div className="container">
                    {orders.map((item) => {
                        var item_names = item.item_names.split(",,,");
                        var item_qty = item.item_quantity.split(",,,");
                        console.log("ITEM :::: ",item);
                        console.log("ITEM NAMES :: ",item_names);
                        return (
                            
                            <OrdersCard onClick={() => {handleClick(item)}}>
                                <OrdersCard.Row>
                                    <OrdersCard.Column style={{flex:0.4}}>
                                        {item_names.map((name,index) => {
                                            if(name === "")
                                                return null;
                                            return (
                                                <div>
                                                    <OrdersCard.ProductName>
                                                        {name} x {item_qty[index]}
                                                    </OrdersCard.ProductName>
        
                                                    
                                                </div>
                                            )
                                        })}    
                                    </OrdersCard.Column>
                                
                                <OrdersCard.Column style={{flex:0.2}}>
                                    <OrdersCard.Date>
                                        {item.order_date}
                                    </OrdersCard.Date>
                                    <OrdersCard.ProductPrice>
                                        {item.total_amount}
                                    </OrdersCard.ProductPrice>
                                </OrdersCard.Column>

                                <div style={{flex:0.2}}>
                                    <OrdersCard.Status>
                                    {
                                        item && (item.status=="done"?<OrdersCard.ProductPrice style={{color:"green"}}>Order Completed</OrdersCard.ProductPrice>:<OrdersCard.ProductPrice style={{color:"rgb(199, 170, 6)"}}>Order Pending</OrdersCard.ProductPrice>)
                                    }
                                    </OrdersCard.Status>
                                    <OrdersCard.Button>
                                        <span style={{fontWeight:"700"}}>View order </span>
                                    </OrdersCard.Button>
                                </div>
                                </OrdersCard.Row>
                            </OrdersCard>
                        );
                    })}
                    </div>
                </div>
                }
                <OrderModal 
                    show={show} 
                    onHide={() => setShow(false)}
                    item={item}
                />
            {/* <FooterPage /> */}
        </div>
    );
}

// <table className="orders_table">
//                     <tr>
//                         <th> Product </th>
//                         <th> Qty </th>
//                         <th> Price </th>
//                         <th> Total </th>
//                     </tr>
//                     {orders && orders.map((item) => {
        
//                         return (
                            
//                             <tr onClick={() => {handleClick(item)}} className="order_row">
//                                 <td> 
//                                     <div className="order_card">
//                                             <h4 className="order_card_title">
//                                                 {item.item_names}
//                                             </h4>
//                                             <p> {item.status} </p>
//                                     </div>
//                                 </td>
//                                 <td>
//                                     <p> {item.item_quantity}</p>
//                                 </td>

//                                 <td>
//                                     <p> {item.item_amount}</p>
//                                 </td>
//                                 <td>
//                                     <p> {item.total_amount}</p>
//                                 </td>
                                
//                             </tr>
//                         );
        
        
//                     })} 
        
//                     </table>