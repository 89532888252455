import React from 'react';
import {Container,FixedRow, Row, Column, Price, Image, Strikethrough, Button, Title, Description, CounterButton, Input} from './styles/product';

export default function Product({children,...restProps}){

    return <Container {...restProps}> {children} </Container>
}

Product.Row = function ProductRow({children,...restProps}){

    return <Row {...restProps}> {children} </Row>
}

Product.FixedRow = function ProductFixedRow({children,...restProps}){

    return <FixedRow {...restProps}> {children} </FixedRow>
}

Product.Column = function ProductColumn({children, ...restProps}){
    return <Column {...restProps}> {children} </Column>
}

Product.Image = function ProductImage({...restProps}){
    return <Image {...restProps} />
}

Product.Price = function ProductPrice({children, ...restProps}){
    return <Price {...restProps}> {children} </Price>
}

Product.Strikethrough = function ProductStrikethrough({children,...restProps}){
    return <Strikethrough {...restProps}> {children} </Strikethrough>
}

Product.Button = function ProductButton({children ,...restProps}){
    return <Button {...restProps}> {children} </Button>
}

Product.Title = function ProductTitle({children, ...restProps}){
    return <Title {...restProps}> {children} </Title>
}

Product.Description = function ProductDescription({children, ...restProps}){
    return <Description {...restProps}> {children} </Description>
}

Product.CounterButton = function ProductCounterButton({children,...restProps}){
    return <CounterButton {...restProps}> {children} </CounterButton>
}

Product.Input = function ProductInput({ ...restProps}){
    return <Input {...restProps} />
}