import React, { Component } from "react";
import "./signinmenu.css";
 
class SignInMenu extends Component {
  render() {
    var visibility = "hide";
 
    if (this.props.menuVisibility && this.props.signInForm == "signin") {
      visibility = "show";
    }
 
    return (
      <div>
        <div>
          <div id="SignInMenu" onMouseDown={this.props.handleMouseDown} className={visibility}>
            <h2><a href="#">Login Form</a></h2>
          </div>
        </div>
        <div id="overlay" onMouseDown={this.props.handleMouseDown} className={visibility}></div>
      </div>
    );
  }
}
 
export default SignInMenu;