import React from 'react';
import BrowsePageNavigation from '../navigation/browseNavigation'
import FooterPage from '../Footer'
import HomeComponentTop from '../homepage/homeTop';
import aboutUsHeader from '../../static/img/ban_2.jpg';

class AboutUsComponent extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            totalPrice: 0,
            cartItems: {"wheat":[],"maida":[],"suji":[],"chiroti":[]},
        }
    }
    render(){

        return (
            <div>
                <div style={{position:"sticky",top:"0px",zIndex:"1000"}}>
                <HomeComponentTop cartItems={this.state.cartItems} totalPrice={this.state.totalPrice}/>
                </div>
                <div className="page_heading" style={{backgroundImage:`url(`+aboutUsHeader+`)`,backgroundRepeat:"no-repeat",color:"#ffb637",textAlign:"center",padding:"60px 0 60px 0"}}>
                    <div className="container">
                        <div className="row" style={{marginLeft: "0px"}}>
                            <div className="col-xs-12">
                                <div className="page-title">
                                    <h2>About Us</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <br/>
                <div style={{marginLeft:"15vw",marginRight:"15vw",textAlign: "justify" ,textJustify: "inter-word"}} className = "c1">
                    <br/><br/>
                    <p><strong>About Us</strong></p>
                    <p>Chariot Agro Limited is a closely held public limited company
                        headquartered in Bengaluru, registered under the Company’s Act of 2013.
                        The key promoter of Chariot Agro Limited is Chariot World Tours Limited which is mainly
                        promoted by first generation entrepreneur, Atmanand Krishna Shanbhag along with K C
                        Madhukiran and Koushik Varaghur. K. C. Madhukiran and Koushik Varaghur no longer serve
                        on the board of Chariot World Tours Limited since March 2020. Nicholas Karthik, Sachin
                        Shetty, Karthik P. Nayak, Deepa Shanbhag and Deepak Prabhu are the other board of
                        directors of CWTL.
                        Chariot Agro Limited will be in the business of procuring high quality agricultural produce
                        such as wheat, wheat-based products, rice and other food grains, pulses, oil seeds, spices,
                        edible oil, all kind of dry fruits and nuts, sugar, Tea, coffee etc. and get them processed in
                        hygienic conditions and deliver to the customers after getting them well packed to protect
                        all the nutritional properties of the products. Chariot Agro Limited is entering the space of
                        the rapidly growing demand for unadulterated, pure and hygienic edible agricultural
                        products and add value to customers as well as value to the farmers who grow these
                        products. Chariot Agro Limited is committed towards farmers education and empowerment
                        of farmers by providing continued inputs to farmers on best farming practices and hygienic
                        storage practices. Chariot Agro Limited is poised to make a difference to the lives of every
                        stake holder that is, customers, farmers, employees, shareholders and the public at large.
                        <br/><br/><br/>
                        <h4>REGISTERED OFFICE</h4>
                        <b># 3446/40,</b><br/>
                        <b>1st ‘F’ Cross,</b><br/>
                        <b>Next to Bunts Sangha, </b><br/>
                        <b>Vijayanagar, Bangalore – 560040</b><br/>
                        atmanand@chariotagro.com <br/>
                        Website: www.chariotagro.com<br/>
                        <br/><br/>
                        <h4>CHARIOT AGRO LIMITED PROMOTERS PROFILE:</h4>
                        <br/>
                        <h5>Atmanand Shanbhag</h5>
                        Atmanand is the driving force behind Chariot Agro Limited. With remarkable
                        finance acumen, Atmanand is an excellent administrator on one hand and an able strategist
                        on the other. Atmanand comes with over 35 years of consolidated experience out of which
                        he spent 16 years in Life Insurance Corporation of India, 5 Years in ING Vysya Life Insurance
                        and 14 years in travel services. He held a range of positions in diverse functions at different
                        geographical locations across India while at LIC. An associate member of the Insurance
                        Institute of India, Atmanand was one of the first employees to join ING Vysya Life Insurance
                        in India and made significant contributions in setting up the distribution channel in his 5-
                        year tenure at ING Vysya Life. Atmanand is Chairman and Managing Director at Chariot
                        World Tours Limited and plays multiple roles from handling finance, to operations and sales.

                        He has completed IATA training and certification. Atmanand holds a commerce degree from
                        Karnatak University. He is married and resides with his wife and sons in Bengaluru.
                        <br/><br/>
                        <h5>Sachin R. Shetty</h5>
                        Sachin Rajeev Shetty is a Commerce Graduate from Bangalore University. He joined Chariot
                        World Tours Limited finance team in 2014 and involved himself in taxation, accounting &amp;
                        statutory requirements of the company. He takes care of the legal aspects of the company.
                        Before joining Chariot, he is specialised in handling statutory audit, internal audit, tax audit
                        &amp; labour laws with audit firms and worked with different companies in the field of finance,
                        administration &amp; operations.
                        <br/><br/>
                        <h5>Deepa Shanbhag</h5>
                        Deepa Shanbhag joined the board of Chariot World Tours Limited as a non-executive
                        additional director since March 2017. Deepa Shanbhag is an educationist with a passion to
                        assist, orient and shape children at early years of their education. She runs a voluntary after-
                        school teaching program assisting children to cope with their curriculum. Mrs. Shanbhag is a
                        commerce graduate and has completed IATA training and certification.  She is married and
                        lives with her family in Bengaluru.
                        <br/><br/>
                        <h5>Anish Shanbhag</h5>
                        Anish Shanbhag holds an Engineering degree in Computer Science from BMS College of Engineering, 
                        Basavanagudi, Bangalore. Early on, Anish showed an entrepreneurial flair that led him to being 
                        involved in many projects like IndiScan, StreamShiksha which have 5k+ downloads during the course of his education. 
                        Before being inducted into the board of directors at Chariot Agro Limited, Anish worked with 
                        LogMeIn one of the top 10 SaaS companies in the world and was involved in the development of 
                        mission critical applications for the company. 
                        Chariot Agro's e-commerce initiatives will be driven by Anish including building a proprietary tech platform, 
                        scaling it and maintenance. 
                    </p>
                    <br/>
                </div>
                <div style={{marginLeft:"15vw",marginRight:"15vw",textAlign: "center" ,textJustify: "inter-word", fontSize:"20px"}} className = "c1">
                        <hr/>
                        <h2>Enquiry</h2>
                        <p style={{font:"23px !important"}}>Please call us on <b>0091 9945775555</b> or email to <b>atmanand@chariotagro.com</b><br/>for any product enquiry or for any information which you may need from<br/>Chariot Agro Limited.</p>
                </div>
                <div style={{marginBottom:"-50vw"}} >
                <FooterPage/>
                </div>
            </div>
        );
    }
}

export default AboutUsComponent;
