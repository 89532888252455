import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import {Link} from 'react-router-dom';



export const mainListItems = (
  <div>
    <ListItem button>
      <Link to="/"><div class="drawermainlisttextactive" >HOME</div></Link>
    </ListItem>

    <ListItem button>
    <Link to="/aboutus"><div class="drawermainlisttext" >ABOUT US</div></Link>
    </ListItem>
  
    <ListItem button>
    <div style={{display:'flex',justifyContent:'space-between'}}>
    <Link to="/allProducts"><div class="drawermainlisttext" >OUR PRODUCTS </div></Link>
        {/* <AddIcon /> */}
    </div>
    </ListItem>
  </div>
);

