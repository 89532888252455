import React from 'react';
import {Container,Title, Section} from './styles/grid';

export default function Grid({children,...restProps}){
    return <Container className="container" {...restProps}>{children} </Container>
}

Grid.Title = function GridTitle({children, ...restProps}){
    return <Title {...restProps}> {children}</Title>
}

Grid.Section = function GridSection({children, ...restProps}){
    return <Section {...restProps}> {children}</Section>
}

Grid.SectionBreak = function GridSectionBreak({...restProps}){
    return <p> -------------- </p>
}
