import React,{useEffect,useState} from 'react';
import {useLocation} from 'react-router-dom';
import Product from '../components/product';
import BrowsePageNavigation from '../components/navigation/browseNavigation';
import {firebase,database} from '../lib/firebase.prod';
import {useCart} from '../contexts/CartContext';
import FooterPage from "../components/Footer";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Card from '../components/card';
import CarouselCard from '../components/CarouselCard';
import {useHistory} from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import maida from '../static/img/maida.png';
import chirotirava from '../static/img/chirotirava.png';
import sujirava from '../static/img/sujirava.png';
import chariotatta from '../static/img/chariotatta.jpg';
import DryFruits from '../static/img/DryFruits.jpg';

export default function ProductPage(props){

    const location = useLocation();
    const product = location.state;
    const [suggestions,setSuggestions] = useState([]);
    const history = useHistory();
    const uid = localStorage.getItem('token');
    const [open,setOpen] = useState(false);
    const [addedToCart,setAddedToCart] = useState(false);
    const [{basket},dispatch] = useCart();
    const [name,setName] = useState('');
    const [pdtImage,setPdtImage] = useState('');
    //console.log("Product", product);

    const [itemCount, setItemCount] = useState(1);
    const handleAdd = () => {
        setItemCount(itemCount + 1);
    }

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    useEffect( () => {
        //console.log("Basket of product : " ,basket[product.product_id]);

        if(!basket)
        {
            setItemCount(1);
            return;
        }
        setItemCount(basket[product.product_id]?basket[product.product_id].quantity: 1);
    },[basket]);

    async function getProductsForOneCategory(category_id){
        return new Promise( (resolve,reject) => {
            var tempProducts = [];
            var getall_products = database.ref("Product").child(category_id);
            getall_products.once("value")
            .then(function(snapshot) {
            snapshot.forEach(function(childSnapshot) {

                    var key = childSnapshot.key;
                    var childData = childSnapshot.val();
                    //console.log("CHILD DATA: ",childData);
                    tempProducts = [...tempProducts,childData];
                });

                if(tempProducts)
                    resolve(tempProducts);
                else
                    reject(new Error("something went wrong"));
            });
        })
    }

    useEffect( () => {
        //console.log("Suggestions: ",suggestions);
        setName(location.state);
        if(location.state == 'Whole Wheat Chakki Atta'){
            setPdtImage(chariotatta);
        }else if(location.state == 'Maida'){
            setPdtImage(maida);
        }else if(location.state == 'Suji Rava'){
            setPdtImage(sujirava);
        }else if(location.state == 'Chiroti Rava'){
            setPdtImage(chirotirava);
        }else{
            setPdtImage(DryFruits);
        }
        //get products for category 
        if(product.category_id)
        {
            getProductsForOneCategory(product.category_id).then((products) => {
                setSuggestions([...products]);
            });
        }

    }, [location])

    const handleSub = () => {
        if(itemCount > 1)
            setItemCount(itemCount - 1);
    }

    const addToCart = () => {
        console.log("Adding to cart");
        if(!uid){
            setOpen(true);
        }
        setAddedToCart(true);
        dispatch({
            type : "ADD_TO_CART",
            item : {
                ...product,
                quantity : itemCount
            }
        })
    }
    //console.log("price " , product.product_price_user);

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };

    function handleClick (product_id,item){
        history.push({pathname: '/browse/product/'+product_id,state: item})
    }

    return (
        <div>
            <BrowsePageNavigation />
            <br/><br/>
            <div className="row" style={{display:"flex",alignItems:"center"}}> 
                <div style={{marginTop:"-24px !important", marginBottom:"-24px !important"}}  className="d-none d-md-block col-md-6">
                    <img src={pdtImage} alt="" className="img-fluid w-100 h-100"></img>
                </div>
                <div className="col-xs-12 col-md-6">
                    <p style={{fontSize:"35px"}}>Chariot Gold <br/> <b>Premium Quality {name}</b> </p>
                </div>
            </div>
            <div style={{marginLeft:"15vw",marginRight:"15vw",textAlign: "center" ,textJustify: "inter-word", fontSize:"20px"}} className = "c1">
                <hr/>
                <h2>Enquiry</h2>
                <p style={{font:"23px !important"}}>Please call us on <b>0091 9945775555</b> or email to <b>aatmanand@chariotagro.com</b><br/>for any product enquiry or for any information which you may need from<br/>Chariot Agro Limited.</p>
            </div>
            {/* <div className="container">
                <Product style={{borderRadius:8}}>
                <Product.Row>
                    <Product.Image src = {product.product_url}/>
                    <Product.Column>
                        <Product.Title> {product.product_name} </Product.Title>
                        <Product.FixedRow>
                            <Product.Strikethrough> 
                                <span style={{color:"#888"}}>
                                    ₹ {parseFloat(product.product_price_user*1.1)}/pack
                                </span>
                            </Product.Strikethrough>
                            <Product.Price>
                                <span style={{fontWeight:"normal",fontSize:"20px",lineHeight:"24px"}}>
                                    ₹ {product.product_price_user}
                                </span>
                            /pack </Product.Price>
                        </Product.FixedRow>
                        <Product.Description> {product.product_description}</Product.Description>
                        <Product.FixedRow>
                            <Product.CounterButton style={{borderRadius:8}} onClick={handleSub}>-</Product.CounterButton>
                            <Product.Input style={{borderRadius:8}} value={itemCount} />
                            <Product.CounterButton style={{borderRadius:8}} onClick={handleAdd}>+</Product.CounterButton>
                            <Product.Button onClick={addToCart}><span style={{color:"white"}}> Add to Cart</span></Product.Button>
                        </Product.FixedRow>
                        <Product.FixedRow>
                            <Product.Price> Total price : ₹ {product.product_price_user * itemCount}</Product.Price>
                        </Product.FixedRow>
                    </Product.Column>
                </Product.Row>
                <Snackbar open={open} autoHideDuration={2000} onClose={() => setOpen(false)}>
                    <Alert onClose={() => setOpen(false)} severity="success">
                        Please login before updating cart!
                    </Alert>
                </Snackbar>

                <Snackbar open={addedToCart} autoHideDuration={2000} onClose={() => setAddedToCart(false)}>
                    <Alert onClose={() => setAddedToCart(false)} severity="success">
                        Added to cart
                    </Alert>
                </Snackbar>
            </Product>
            </div>
            <div className="container">
                {suggestions.length > 1 && <span
                    style={{fontSize:"20px",fontWeight:"600",color:"rgba(5,11,48)",alignSelf:"flex-start",marginBottom:"20px"}}
                > You may also like!</span>}
            <Carousel
            swipeable={false}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel_suggestions"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
        >
            <div className="carousel_suggestions">
            {suggestions.map((item) => {
                if(product.product_id === item.product_id)
                    return null;
                
                return (
                    <div className="container">
                        <CarouselCard onClick={() =>{handleClick(item.product_id,item)}}>
                            <CarouselCard.Image src={item.product_url}  style={{borderRadius:8}}/>
                            <CarouselCard.Title style={{fontSize:"13px"}}> {item.product_name} </CarouselCard.Title>
                            <CarouselCard.Text>
                                <CarouselCard.DeliveryTime>₹ {item.product_price_user} </CarouselCard.DeliveryTime>
                                <CarouselCard.Price style={{textDecoration:'line-through'}}> {parseFloat(item.product_price_user * 1.1)} </CarouselCard.Price>
                            </CarouselCard.Text>
                            <CarouselCard.Button>SHOP NOW</CarouselCard.Button>
                        </CarouselCard>
                    </div>
                )
            })}
            </div>
        </Carousel>

            
            </div> */}
            <br/>
            <div style={{marginBottom:"-50vw"}}>
                <FooterPage/>
            </div>
        </div>
    );
}
