import React,{useEffect,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import CheckIcon from '@material-ui/icons/Check';
import HomeComponentTop from '../components/homepage/homeTop';
import {useLocation} from 'react-router-dom';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import FooterPage from '../components/Footer';
import './EachProduct.css';
import {database} from "../lib/firebase.prod";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faWindowClose as close} from '@fortawesome/free-solid-svg-icons';
import PubSub from 'pubsub-js';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        display:'column',
        alignItems:'start'
      },
    gridcontainer:{
        marginTop:40
    },
    image :{
        height:400,
        width:"100%",
        objectFit:'contain'
    },
    smallimage:{
        height:80,
        width:80,
        objectFit:'contain',
        border:'1px soild orange'
    },
    gridright:{ 
        textAlign:'start',
        paddingLeft:10
    },
    subtitle:{
        textAlign:'start',
        fontSize:16,
        fontFamily:'lato',
    },
    title:{
        textAlign:'start',
        fontSize:22,
        fontFamily:'Rubik',
        fontWeight:300
    },
    mrp:{
        font:12,
        color:'#8c8c8c',
        fontFamily:'Rubik',
        marginTop:10,
    },
    price:{
        fontWeight:300,
        color:'#000',
        fontSize:16,
        marginLeft:3,
        marginRight:3
    },
    addbasketview:{
        display:'flex',
        flexDirection:'row',
        width:'100%',
        marginTop:30,
    },
    numberleft:{
        padding:"10px 10px 10px 10px",
        border:'1px solid #cdcdcd',
        borderRadius:8,
        margin:"0px 10px",
        cursor:'pointer'
    },
    number:{
        paddingTop:'10px',
        margin:"0px 10px",
        fontSize:16
    },
    addbasket:{
        padding:"10px 10px",
        margin:"0px 10px",
        padding:"10px 10px 10px 10px",
        backgroundColor:"#ffb637",
        borderRadius:8,
        color:'#fff',
        fontWeight:600,
        fontSize:16,
        fontFamily:'Rubik',
        cursor:'pointer'
    },
    savebutton:{
        padding:"10px 10px",
        margin:"0px 10px",
        padding:"10px 30px",
        border:'1px solid #cdcdcd',
        borderRadius:8,
        color:'#ffb637',
        fontSize:16,
        fontFamily:'Rubik',
        cursor:'pointer'
    },
    descriptionview:{
        padding:'30px 30px',
        textAlign:'start'
    },
    descriptiontitle:{
        textAlign:'start',
        fontSize:22,
        fontFamily:'Rubik',
        fontWeight:300,
        paddingTop:20,
        paddingBottom:20,
        borderBottom:'1px solid #cdcdcd'
    },
    descriptionsubtitle:{
        padding:'20px 20px',
        fontSize:18,
        fontFamily:'Rubik',
    },
    description:{
        padding:'5px 20px',
        fontSize:16,
        fontFamily:'Rubik',
        color:'#8c8c8c',
        paddingBottom:'30px',
        borderBottom:'1px solid #cdcdcd'
    },
    descriptionlist:{
        padding:'5px 20px',
        fontSize:16,
        fontFamily:'Rubik',
        color:'#8c8c8c',
    },
    quantityview:{
        border:'1px solid #efefef',
        margin:'10px 20px',
        borderRadius:4,
        width:'60%',
        ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
            width: '80%'
          }
    },
    quantitytick:{
        padding:'13px'
    },
    quantitytickselected:{
        padding:'13px 13px',
        backgroundColor:'#9cc63d',
        borderTopRightRadius:4,
        borderBottomRightRadius:4
    }

}))

export default function EachProduct() {
    const classes = useStyles()
    const location = useLocation();
    const [name,setName] = React.useState("");
    const [numOfImages,setNumOfImages] = React.useState([]);
    const [weights,setWeights] = React.useState([]);
    const [prices,setPrices] = React.useState([]);
    const [inactivePrices,setInactivePrices] = React.useState([]);
    const [activeImage,setActiveImage] = React.useState("");
    const [aboutTheProduct,setAboutTheProduct] = React.useState("");
    const [ingredients,setIngredients] = React.useState("");
    const [nutritional,setNutritional] = React.useState("");
    const [shelf,setShelf] = React.useState("");
    const [manufacturer,setManufacturer]= React.useState("");
    const [manufacturerAdd1,setManufacturerAdd1]= React.useState("");
    const [manufacturerAdd2,setManufacturerAdd2]= React.useState("");
    const [manufacturerAdd3,setManufacturerAdd3]= React.useState("");
    const [manufacturerAdd4,setManufacturerAdd4]= React.useState("");
    const [activeTick,setActiveTick] = React.useState("0");
    const [activeWeight,setActiveWeight] = React.useState("");
    const [activePrice,setActivePrice] = React.useState("");
    const [inactivePrice,setInactivePrice] = React.useState("");
    const [activeQuantity,setActiveQuantity] = React.useState("1");
    const [cartItems,setCartItems] = React.useState({"wheat":[],"maida":[],"suji":[],"chiroti":[],"afgan":[],"indian":[],"safawi":[],"mabroom":[],"almond":[],"cashew":[],"pista":[],"rasam":[],"sambar":[],"garam":[],"pulav":[],"fish":[],"biryani":[],"chicken":[],"kabab":[],"chilli":[]})
    const [totalPrice,setTotalPrice] = React.useState(0);
    const [coo,setCoo] = React.useState("");
    const [category,setCategory] = React.useState("");
    const [what,setWhat] = React.useState("");
    const [benefits,setBenefits] = React.useState([]);
    const [features,setFeatures] = React.useState([]);
    const [showSnackbar1,setShowSnackbar1] = React.useState(false);
    const [showSnackbarWarning,setShowSnackbarWarning] = React.useState(false);
    const [highlighted,setHighlighted] = React.useState(false);
    const [enlargedImage1,setEnlargedImage1] = React.useState("");
    const [user,setUser] = React.useState("customer");
    const [alertText,setAlertText] = React.useState("");
    const [multiple,setMultiple] = React.useState(0);

    useEffect( () => {
        window.scrollTo(0, 0);
        setWeights(location.state.weights);
        setPrices(location.state.prices);
        setInactivePrices(location.state.inactivePrices);
        setMultiple(location.state.multiple);
        setActiveWeight(location.state.weights[0]);
        setActivePrice(location.state.prices[0]);
        setInactivePrice(location.state.inactivePrices[0]);
        setActiveImage(location.state.numOfImages[0]);
        setNumOfImages(location.state.numOfImages);
        setName(location.state.name);
        setCartItems(location.state.cartItem);
        setTotalPrice(location.state.totalPrice);
        var product_details = database.ref('ProductDetails').child(location.state.name);
        product_details.on('value', (snapshot) => {
                var details = snapshot.val();
                if(details.category == "food"){
                    setAboutTheProduct(details.About);
                    setIngredients(details.Ingredients);
                    setNutritional(details.Nutritional);
                    setShelf(details.Shelf);
                }else{
                    setWhat(details.What);
                    setBenefits(details.benefits);
                    setFeatures(details.features);
                }
                setCategory(details.category);

                setManufacturer(details.ManufacturerName);
                setManufacturerAdd1(details.ManufacturerAddress1);
                var addresses = details.ManufacturerAddress1.split("\\n");
                setManufacturerAdd1(addresses[0]);
                if(addresses.length>1){
                    setManufacturerAdd3(addresses[1]);
                }
                setCoo(details.COO);
                var addresses2 = details.ManufacturerAddress2.split("\\n");
                setManufacturerAdd2(addresses2[0]);
                if(addresses2.length>1){
                    setManufacturerAdd4(addresses2[1]);
                }
        })
    }, [])
    

    const changeActiveImage = (src) => {
        setActiveImage(src);
    }

    const changeActive = (index,weight,price,inactive) => {
        setActiveTick(index);
        setActiveWeight(weight);
        setActivePrice(price);
        setInactivePrice(inactive);
    }

    const handleInputChange = (e) => {
        const {name,value} = e.target;
        setActiveQuantity(value);
    }

    const addToCart = () => {
        // if(name=="Strong Hing"){
        //     setAlertText("Launching Shortly")
        //     setShowSnackbarWarning(true);
        // }else{
            if(activeQuantity=="0"||activeQuantity==""){
                setAlertText("Please enter quantity")
                setShowSnackbarWarning(true);
            }else{
                if(localStorage.getItem("user")==null){
                    localStorage.setItem("user","customer");
                    place(1);
                }else{
                    setUser(localStorage.getItem("user"));
                    if(localStorage.getItem("user")=="broker"){
                        if(category=="food"){
                            if(activeWeight.slice(-2)=="Kg"){
                                var div = multiple/parseInt(activeWeight.slice(0,-2));
                                if(parseInt(activeQuantity)%div==0){
                                    place(multiple);
                                }else{
                                    setAlertText("Please enter in multiples of "+div.toString())
                                    setShowSnackbarWarning(true);
                                }
                            }else if(activeWeight.slice(-2)=="0g"){
                                if(name=="Indian Raisins" || name=="Afghan Raisins"){
                                    if(activeWeight=="100g"){
                                        if(parseInt(activeQuantity)%36==0){
                                            place(36);
                                        }else{
                                            setAlertText("Please enter in multiples of 36");
                                            setShowSnackbarWarning(true);        
                                        }
                                    }else if(activeWeight=="250g"){
                                        if(parseInt(activeQuantity)%16==0){
                                            place(16);
                                        }else{
                                            setAlertText("Please enter in multiples of 16");
                                            setShowSnackbarWarning(true);        
                                        }
                                    }else if(activeWeight=="500g"){
                                        if(parseInt(activeQuantity)%8==0){
                                            place(8);
                                        }else{
                                            setAlertText("Please enter in multiples of 8");
                                            setShowSnackbarWarning(true);        
                                        }
                                    }
                                }else if(name=="Safawi Dates" || name=="Mabroom Dates"){
                                    if(parseInt(activeQuantity)%16==0){
                                        place(16);
                                    }else{
                                        setAlertText("Please enter in multiples of 16");
                                        setShowSnackbarWarning(true);        
                                    }
                                }else if(name=="Roasted & Salted Almonds"){
                                    if(activeWeight=="250g"){
                                        if(parseInt(activeQuantity)%16==0){
                                            place(16);
                                        }else{
                                            setAlertText("Please enter in multiples of 16");
                                            setShowSnackbarWarning(true);        
                                        }
                                    }else if(activeWeight=="500g"){
                                        if(parseInt(activeQuantity)%8==0){
                                            place(8);
                                        }else{
                                            setAlertText("Please enter in multiples of 8");
                                            setShowSnackbarWarning(true);        
                                        }
                                    }
                                }else if(name=="Roasted & Salted Cashews"){
                                    if(activeWeight=="100g"){
                                        if(parseInt(activeQuantity)%36==0){
                                            place(36);
                                        }else{
                                            setAlertText("Please enter in multiples of 36");
                                            setShowSnackbarWarning(true);        
                                        }
                                    }else if(activeWeight=="250g"){
                                        if(parseInt(activeQuantity)%16==0){
                                            place(16);
                                        }else{
                                            setAlertText("Please enter in multiples of 16");
                                            setShowSnackbarWarning(true);        
                                        }
                                    }
                                }else if(name=="Roasted & Salted Pistachio"){
                                    if(activeWeight=="80g"){
                                        if(parseInt(activeQuantity)%36==0){
                                            place(36);
                                        }else{
                                            setAlertText("Please enter in multiples of 36");
                                            setShowSnackbarWarning(true);        
                                        }
                                    }else if(activeWeight=="200g"){
                                        if(parseInt(activeQuantity)%16==0){
                                            place(16);
                                        }else{
                                            setAlertText("Please enter in multiples of 16");
                                            setShowSnackbarWarning(true);        
                                        }
                                    }else if(activeWeight=="400g"){
                                        if(parseInt(activeQuantity)%8==0){
                                            place(8);
                                        }else{
                                            setAlertText("Please enter in multiples of 8");
                                            setShowSnackbarWarning(true);        
                                        }
                                    }
                                }else if(name=="Strong Hing"){
                                    if(activeWeight=="10g"){
                                        if(parseInt(activeQuantity)%100==0){
                                            place(100);
                                        }else{
                                            setAlertText("Please enter in multiples of 100");
                                            setShowSnackbarWarning(true);        
                                        }
                                    }else if(activeWeight=="20g"){
                                        if(parseInt(activeQuantity)%50==0){
                                            place(50);
                                        }else{
                                            setAlertText("Please enter in multiples of 50");
                                            setShowSnackbarWarning(true);        
                                        }
                                    }
                                }else if(name=="Garam Masala Powder"){
                                    if(activeWeight=="20g"){
                                        if(parseInt(activeQuantity)%20==0){
                                            place(20);
                                        }else{
                                            setAlertText("Please enter in multiples of 20");
                                            setShowSnackbarWarning(true);        
                                        }
                                    }
                                }else if(name=="Veg Pulav Masala Powder"){
                                    if(activeWeight=="20g"){
                                        if(parseInt(activeQuantity)%20==0){
                                            place(20);
                                        }else{
                                            setAlertText("Please enter in multiples of 20");
                                            setShowSnackbarWarning(true);        
                                        }
                                    }
                                }else if(name=="Fish Fry Masala Powder"){
                                    if(activeWeight=="40g"){
                                        if(parseInt(activeQuantity)%20==0){
                                            place(20);
                                        }else{
                                            setAlertText("Please enter in multiples of 20");
                                            setShowSnackbarWarning(true);        
                                        }
                                    }
                                }else if(name=="Chicken Biryani Masala Powder"){
                                    if(activeWeight=="20g"){
                                        if(parseInt(activeQuantity)%20==0){
                                            place(20);
                                        }else{
                                            setAlertText("Please enter in multiples of 20");
                                            setShowSnackbarWarning(true);        
                                        }
                                    }
                                }else if(name=="Chicken Kabab Masala Powder"){
                                    if(activeWeight=="40g"){
                                        if(parseInt(activeQuantity)%20==0){
                                            place(20);
                                        }else{
                                            setAlertText("Please enter in multiples of 20");
                                            setShowSnackbarWarning(true);        
                                        }
                                    }
                                }else if(name=="Red Chilli Powder"){
                                    if(activeWeight=="40g"){
                                        if(parseInt(activeQuantity)%20==0){
                                            place(20);
                                        }else{
                                            setAlertText("Please enter in multiples of 20");
                                            setShowSnackbarWarning(true);        
                                        }
                                    }
                                }else if(name=="Turmeric Powder"){
                                    if(activeWeight=="40g"){
                                        if(parseInt(activeQuantity)%20==0){
                                            place(20);
                                        }else{
                                            setAlertText("Please enter in multiples of 20");
                                            setShowSnackbarWarning(true);        
                                        }
                                    }
                                }else if(name=="Coriander Powder"){
                                    if(activeWeight=="40g"){
                                        if(parseInt(activeQuantity)%20==0){
                                            place(20);
                                        }else{
                                            setAlertText("Please enter in multiples of 20");
                                            setShowSnackbarWarning(true);        
                                        }
                                    }
                                }else if(name=="Jeera Powder"){
                                    if(activeWeight=="30g"){
                                        if(parseInt(activeQuantity)%20==0){
                                            place(20);
                                        }else{
                                            setAlertText("Please enter in multiples of 20");
                                            setShowSnackbarWarning(true);        
                                        }
                                    }
                                }else if(name=="Pepper Powder"){
                                    if(activeWeight=="10g"){
                                        if(parseInt(activeQuantity)%20==0){
                                            place(20);
                                        }else{
                                            setAlertText("Please enter in multiples of 20");
                                            setShowSnackbarWarning(true);        
                                        }
                                    }
                                }
                                else{
                                    var div = multiple*1000/parseInt(activeWeight.slice(0,-1));
                                    if(parseInt(activeQuantity)%div==0){
                                        place(multiple)
                                    }else{
                                        setAlertText("Please enter in multiples of "+div.toString())
                                        setShowSnackbarWarning(true);
                                    }    
                                }
                            }else if(activeWeight.slice(-2)=="5g"){
                                if(name=="Rasam Powder"){
                                    if(activeWeight=="25g"){
                                        if(parseInt(activeQuantity)%20==0){
                                            place(20);
                                        }else{
                                            setAlertText("Please enter in multiples of 20");
                                            setShowSnackbarWarning(true);        
                                        }
                                    }
                                }else if(name=="Sambar Powder"){
                                    if(activeWeight=="25g"){
                                        if(parseInt(activeQuantity)%20==0){
                                            place(20);
                                        }else{
                                            setAlertText("Please enter in multiples of 20");
                                            setShowSnackbarWarning(true);        
                                        }
                                    }
                                }else if(name=="Chicken Masala Powder"){
                                    if(activeWeight=="25g"){
                                        if(parseInt(activeQuantity)%20==0){
                                            place(20);
                                        }else{
                                            setAlertText("Please enter in multiples of 20");
                                            setShowSnackbarWarning(true);        
                                        }
                                    }
                                }
                            }
                        }
                        else if(category=="electronics"){
                            if(parseInt(activeQuantity)%multiple==0){
                                place(multiple);
                            }else{
                                setAlertText("Please enter in multiples of "+multiple.toString())
                                setShowSnackbarWarning(true);
                            }
                        }                  
                    }else{
                        place(1);
                    }
                }      
            }
        // }
    }

    const place = (mul) => {
        const item = {};
        var check = false;
        var items;
        var price = 0;
        if(localStorage.getItem('cart')==null){
          items = JSON.parse(JSON.stringify(cartItems));
        }else{
          items = JSON.parse(localStorage.getItem('cart'))      
        }
        if(localStorage.getItem('totalPrice')!=null){
          price = JSON.parse(localStorage.getItem('totalPrice'));
        }
        item["type"] = location.state.cartName;
        item['quantity'] = parseInt(activeQuantity);
        item['weight'] = activeWeight;
        item['price'] = parseFloat(activePrice).toFixed(1);
        item['inactivePrice'] = parseFloat(inactivePrice).toFixed(1);
        item["multiple"] = parseInt(mul);
        item['name'] = name;
        item['gst'] = location.state.gst;
        if(!(location.state.cartName in items)){
            items[location.state.cartName] = [];
        }
        items[location.state.cartName].map(existingItem => {
        if(existingItem['weight']==item['weight']){
            check = true;
            existingItem.quantity=parseInt(existingItem.quantity)+parseInt(activeQuantity);
            setCartItems(items);
            setTotalPrice(price+item['quantity']*item['price'])
            localStorage.setItem("cart",JSON.stringify(items));
            localStorage.setItem("totalPrice",JSON.stringify(price+item['quantity']*item['price']));      
        }
        })
        if(check == false){
        items[location.state.cartName].push(item);
        setCartItems(items);
        setTotalPrice(price+item['quantity']*item['price'])
        localStorage.setItem("cart",JSON.stringify(items));
        localStorage.setItem("totalPrice",JSON.stringify(price+item['quantity']*item['price']));    
        }
        setShowSnackbar1(true);    

    }

    const handleClose = () => {
        setShowSnackbar1(false);
        setShowSnackbarWarning(false);
    }

    const highlightImage = (e) => {
        e.stopPropagation();
        setHighlighted(true);
        setEnlargedImage1(activeImage);
    }

    const closeDiv = () => {
        setHighlighted(false);
    }


    return (
        <div className={classes.root}>
            {
                highlighted?                
                <div style={{width:"100vw",height:"100vh",zIndex:"2000",background:"#fffffff0",position:"fixed",top:"0",bottom:"0",left:"0",right:"0"}}>
                <img src={enlargedImage1} className="imageSize"/>
                <div style={{position:"fixed",right:"1.56rem",top:"0.625rem",height:"34px",width:"34px",margin:"0",padding:"0",zIndex:"2006",cursor:"pointer"}} onClick={()=>closeDiv()}>
                    <div style={{fontSize:"15px",lineHeight:"15px",height:"34px",width:"34px",border:"2px solid #28292b",borderRadius:"100%",opacity:"0.3",textAlign:"center",textDecoration:"none",color:"#222",backgroundColor:"#fff"}}>
                    <FontAwesomeIcon icon={close} style={{verticalAlign:"-webkit-baseline-middle"}}></FontAwesomeIcon>
                    </div>
                </div>
                </div>
                :null  
            }
                <div style={{position:"sticky",top:"0px",zIndex:"1000"}}>
                    <HomeComponentTop 
                        cartItems={location.state.cartItem} totalPrice={location.state.totalPrice}
                    />
                </div>
            <Grid container spacing={1} className={classes.gridcontainer} style={{marginTop:"-35px !important"}}>
                <Grid item xs={12} sm={6}>
                    <div class="checking">     
                    <img src ={activeImage} style={{cursor:"pointer"}} alt="image" className={classes.image} onClick={(e)=>highlightImage(e)}/>
                    <div style={{display:"flex",justifyContent:"center",marginTop:10}}>
                        {
                            numOfImages.map((src)=>{
                               return(
                                   activeImage==src?
                                    <div style={{border:'1px solid orange',marginLeft:10,cursor:"pointer"}} onClick={()=>changeActiveImage(src)}>
                                        <img src={src} className={classes.smallimage}/>
                                    </div>:<div style={{border:'1px solid #f0f0f0',marginLeft:10,cursor:"pointer"}} onClick={()=>changeActiveImage(src)}>
                                        <img src={src} className={classes.smallimage}/>
                                    </div>
                               )
                            })
                        }
                    </div>
                    </div>
                </Grid >
                <Grid item xs={12} sm={6}>
                    <div className={classes.gridright}>
                    {category=="food"?<Typography className={classes.subtitle} style={{color:"#97bc77"}}>Chariot Gold</Typography>:null}
                    {category=="food"?<Typography className={classes.title}>{name},  {activeWeight}</Typography>:<Typography className={classes.title}>{name}</Typography>}
                        <Typography className={classes.mrp}><span className={classes.price}> Price: Rs.{activePrice} </span>(inclusive of all taxes)</Typography>

                        <div className={classes.addbasketview}>
                        <div class="quantity-input-block">

                                        <div  style={{display:'flex',flexDirection:'row',marginRight:"10px"}}>
                                            <label class="quantity-textfeils-name">Qty</label>
                                            <input class="quantity-textfeils" type="text" id="fname" name="fname" value={activeQuantity} onChange={(e) => handleInputChange(e)}/>
                                        </div>
                                        <div class="quantitycart" style={{marginLeft:"10px",display:"flex",justifyContent:"center"}} onClick={()=>addToCart()}>
                                            Add to cart <span><ShoppingBasketIcon style={{marginBottom :"5px"}}/></span>
                                        </div> 
                                        </div>
                        </div>

                        <div className={classes.addbasketview}>
                            <LocalShippingIcon style={{color:'#cdcdcd',fontSize:20,margin:'10px 10px'}}/>
                            <div className={classes.mrp}>Shipping in taluk headquaters</div>
                        </div>

                        <div>
                            <p style={{margin:'10px 10px',fontWeight:"600"}}>Pack Sizes</p>
                        </div>
                    </div>

                    {
                        weights.map((data,index)=>{
                            return (
                                activeTick==index?
                                <div className={classes.quantityview} onClick={()=>changeActive(index,data,prices[index],inactivePrices[index])}>
                                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:"center",border:"none",background:"#84c22533",cursor:"pointer"}}>        
                                    {category=="electronics"?<div class="quantity-title-block">
                                        <div class="quantity-title">1</div>
                                        <div class="quantity-subtitle">Unit</div>
                                    </div>: <div class="quantity-title-block">
                                        <div class="quantity-title">{data}</div>
                                    </div>
                                    }
                                        <div class="quantity-price">Rs {prices[index]}</div>
                                        <div className={classes.quantitytick} style={{color:"white",background:"#9cc63d",borderRadius:"5px"}}>
                                            <CheckIcon />
                                        </div>
                                    </div>
                                </div>:
                                <div className={classes.quantityview} onClick={()=>changeActive(index,data,prices[index],inactivePrices[index])}>
                                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:"center",cursor:"pointer"}}>
                                    <div class="quantity-title-block">
                                        <div class="quantity-title">{data}</div>
                                        </div>
                                        <div class="quantity-price">Rs {prices[index]}</div>
                                        
                                        <div className={classes.quantitytick}>
                                            <CheckIcon />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }


                </Grid>
            </Grid>
            <Snackbar open={showSnackbar1} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    <p class="snackbarStyle">Item Added to Cart!</p>
                </Alert>
            </Snackbar>
            <Snackbar open={showSnackbarWarning} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">
                <p class="snackbarStyle">{alertText}.</p>
                </Alert>
            </Snackbar>

            {
                category=="food"?<div className={classes.descriptionview}>
                <Typography className={classes.descriptiontitle}>{name}</Typography>
                <Typography className={classes.descriptionsubtitle}>About the Product</Typography>
                <Typography className={classes.description}>
                {aboutTheProduct}
                </Typography>
                <Typography className={classes.descriptionsubtitle}>Nutritional Information</Typography>
                <Typography className={classes.description}>
                {nutritional}
                </Typography>
                <Typography className={classes.descriptionsubtitle}>Ingredients</Typography>
                <Typography className={classes.description}>
                {ingredients}
                </Typography>
                <Typography className={classes.descriptionsubtitle}>Shelf Life</Typography>
                <Typography className={classes.description}>
                {shelf}
                </Typography>
                <Typography className={classes.descriptionsubtitle}>Manufacturer Details</Typography>
                <Typography className={classes.description}>
                {manufacturer}<br/><br/>
                {
                    manufacturerAdd3!="" && manufacturerAdd4!=""?
                    <div>1.) <span>{manufacturerAdd1}<br/>&nbsp;&nbsp;&nbsp;&nbsp;{manufacturerAdd2}</span><br/><br/>
                    2.)<span>{manufacturerAdd3}<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{manufacturerAdd4}</span></div>:
                    <div><span>{manufacturerAdd1}<br/>{manufacturerAdd2}</span><br/></div>
                }

                

                </Typography>
                <Typography className={classes.descriptionsubtitle}>Marketed By</Typography>
                <Typography className={classes.description}>
                Chariot Agro Limited<br/># 3446/40, 1st F Cross, Next to Bunts Sangha<br/>Vijayanagar<br/>Bengaluru – 560040
                </Typography>
                <Typography className={classes.descriptionsubtitle}>Country Of Origin</Typography>
                <Typography className={classes.description}>
                {coo}
                </Typography>
                <Typography className={classes.descriptionsubtitle}>Disclaimer</Typography>
                <Typography className={classes.description}>
                We have made every effort to ensure the accuracy of all information of the product. However, the actual product materials and packaging may contain additional and/or different information. It is advised not to solely rely on the information presented.
                </Typography>
                <Typography className={classes.descriptionsubtitle}>Customer Care Details</Typography>
                <Typography className={classes.description}>
                Customer Care No: 9945775555<br/>
                Customer Care Email Id: feedback@chariotagro.com
                </Typography>

                </div>:
                
                <div className={classes.descriptionview}>
                <Typography className={classes.descriptiontitle}>{name}</Typography>
                <Typography className={classes.descriptionsubtitle}>What is {name}</Typography>
                <Typography className={classes.description}>
                {what}
                </Typography>
                <Typography className={classes.descriptionsubtitle}>Features</Typography>
                <Typography className={classes.description}>
                <ul>
                    {
                        features.map((feature)=>{
                            return <li>{feature}</li>
                        })
                    }
                </ul>
                </Typography>
                <Typography className={classes.descriptionsubtitle}>Benefits</Typography>
                <Typography className={classes.description}>
                <ul>
                    {
                        benefits.map((benefit)=>{
                            return <li>{benefit}</li>
                        })
                    }
                </ul>
                </Typography>
                <Typography className={classes.descriptionsubtitle}>Manufacturer Details</Typography>
                <Typography className={classes.description}>
                {manufacturer}<br/><br/>
                {
                    manufacturerAdd3!="" && manufacturerAdd4!=""?
                    <div>1.) <span>{manufacturerAdd1}<br/>&nbsp;&nbsp;&nbsp;&nbsp;{manufacturerAdd2}</span><br/><br/>
                    2.)<span>{manufacturerAdd3}<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{manufacturerAdd4}</span></div>:
                    <div><span>{manufacturerAdd1}<br/>{manufacturerAdd2}</span><br/></div>
                }

                

                </Typography>
                <Typography className={classes.descriptionsubtitle}>Marketed By</Typography>
                <Typography className={classes.description}>
                Chariot Agro Limited<br/># 3446/40, 1st F Cross, Next to Bunts Sangha<br/>Vijayanagar<br/>Bengaluru – 560040
                </Typography>
                <Typography className={classes.descriptionsubtitle}>Country Of Origin</Typography>
                <Typography className={classes.description}>
                {coo}
                </Typography>
                <Typography className={classes.descriptionsubtitle}>Disclaimer</Typography>
                <Typography className={classes.description}>
                We have made every effort to ensure the accuracy of all information of the product. However, the actual product materials and packaging may contain additional and/or different information. It is advised not to solely rely on the information presented.
                </Typography>
                <Typography className={classes.descriptionsubtitle}>Customer Care Details</Typography>
                <Typography className={classes.description}>
                Customer Care No: 9945775555<br/>
                Customer Care Email Id: feedback@chariotagro.com
                </Typography>

                </div>
            }
            
            <FooterPage/>

        </div>
    )
}