import React from 'react';
import {Container,ProductPrice,ProductStatus,ProductName,Column,Row,Button,Date} from './styles/ordersCard';

export default function OrdersCard({children,...restProps}){
    return <Container {...restProps}>{children}</Container>;
}


OrdersCard.ProductName = function OrdersCardProductName({children,...restProps}){
    return <ProductName {...restProps}>{children}</ProductName>;
}

OrdersCard.ProductPrice = function OrdersCardProductPrice({children,...restProps}){
    return <ProductPrice {...restProps}> {children}</ProductPrice>
}

OrdersCard.Column = function OrdersCardColumn({children,...restProps}){
    return <Column {...restProps}> {children}</Column>
}

OrdersCard.Row = function OrdersCardRow({children,...restProps}){
    return <Row {...restProps}> {children}</Row>
}

OrdersCard.Status = function OrdersCardStatus({children,...restProps}){
    return <ProductStatus {...restProps}>{children}</ProductStatus>
}

OrdersCard.Button = function OrdersCardButton({children,...restProps}){
    return <Button {...restProps}>{children}</Button>
}

OrdersCard.Date = function OrdersCardDate({children,...restProps}){
    return <Date {...restProps}>{children}</Date>
}