import React,{useState,useEffect} from 'react';
import './styles.css';
import {auth,database} from '../../lib/firebase.prod';
import BrowsePageNavigation from '../../components/navigation/browseNavigation'
import FooterPage from "../../components/Footer";
import AddressImage from '../../static/img/address_image.jpg';
import { faAddressBook,faUserCircle,faPhone,faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function Address(){


    let uid = localStorage.getItem('token');
    const [name,setName] = useState("")
    const [phoneNum,setNumber] = useState("")
    const [emailID,setEmail] = useState("")
    const [addressLine1,setAddressLine1] = useState("");
    const [addressLine2,setAddressLine2] = useState("");
    const [country,setCountry] = useState("");
    const [state,setState] = useState("");
    const [city,setCity] = useState("");
    const [pincode,setPincode] = useState("");
    const [landmark,setLandmark] = useState("");

    useEffect(() => {

        var get_user = database.ref("User").child(uid);
        get_user.once("value")
        .then(function(snapshot){
            var user_id = snapshot.child("user_id").val();
            //take any data in similar fashion
            console.log("Snapshot: ",snapshot.val());
            const userName = snapshot.val().user_name;
            console.log(userName)
            const userPhone = snapshot.val().user_phone;
            const userEmail = snapshot.val().user_email;
            const address = JSON.parse(snapshot.val().user_address);
            console.log("USER ADDRESS : ", address);
            setAddressLine1(address.addressLine1);
            setAddressLine2(address.addressLine2);
            setCountry(address.country);
            setState(address.addressState);
            setCity(address.city);
            setPincode(address.pincode);
            setLandmark(address.landmark);
            setName(userName);
            setNumber(userPhone);
            setEmail(userEmail);

        });
    }, [])
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("City", city);
        console.log("Address 1", addressLine1);
        console.log("Address 2", addressLine2);
        console.log("Country", country);
        console.log("Pincode", pincode);
        console.log("State", state);
        console.log("Landmark", landmark);

        const address = {
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            addressState: state,
            city: city,
            country: country,
            customerId: uid,
            landmark: landmark,
            pincode: pincode
        }

        if(uid === null)
        {
            alert("You need to login");
        }
        else
        {
            var data_res = JSON.stringify(address);
            var update_user = database.ref('User');
            var new_update_user = update_user.child(uid);
            new_update_user.update({
                'user_address': data_res,
                'user_lat': 0,
                'user_long': 0
            }).then(() => {

                alert('Address Updated!')
                //this.props.navigation.goBack();

            });
        }

    }

    return (
        <div>

        {/* <BrowsePageNavigation/> */}
            
                
                <div className="container card account_card">
                    
                    <h6 className="name">Hello {name} !</h6>
                    <p>From your My Account Dashboard you have the ability to view a snapshot of your recent account activity and update your account information. Select a link below to view or edit information.</p>
                    <br/>
                    <h5>ACCOUNT INFORMATION</h5>
                    <br/>
                    <div className="card_row">
                        <div className="contact_card">
                            <span className="blue_header">Contact  Details &nbsp; &nbsp; &nbsp; </span>
                            <span className="blue_text"> <FontAwesomeIcon icon={faUserCircle} />  {name}</span>
                            <span className="blue_text"><FontAwesomeIcon icon={faPhone} />  {phoneNum}</span>
                            <span className="blue_text"><FontAwesomeIcon icon={faEnvelope} />  {emailID}</span>
                                
                        </div>
                        <div className = "address_card">
                            <span className="blue_header">Address <FontAwesomeIcon icon={faAddressBook} />&nbsp; &nbsp; &nbsp; </span>
                            <span className="blue_text" >{addressLine1} {addressLine2} {city} {state} {pincode}</span>
                        </div>
                    </div>
                </div>
                {/* <FooterPage /> */}
            </div>

    );
}
