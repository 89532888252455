import React, {useState, useEffect,useContext} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {firebase,database} from '../../lib/firebase.prod';
import Grid from '../../components/Grid';
import Card from '../../components/card';
import * as ROUTES from '../../constants/routes';

export default function ProductsContainer(props){

    const location = useLocation();
    const params = useParams();
    const [products,setProducts] = useState([]);
    const history = useHistory();
    async function getProductsForOneCategory(category_id){
        return new Promise( (resolve,reject) => {
            var tempProducts = [];
            var getall_products = database.ref("Product").child(category_id);
            console.log('getall_products', getall_products)
            getall_products.once("value")
            .then(function(snapshot) {
            snapshot.forEach(function(childSnapshot) {

                    var key = childSnapshot.key;
                    var childData = childSnapshot.val();
                    //console.log("CHILD DATA: ",childData);
                    tempProducts = [...tempProducts,childData];
                });

                if(tempProducts)
                    resolve(tempProducts);
                else
                    reject(new Error("something went wrong"));
            });
        })
    }

    useEffect(() => {
        //Fetch data for a category
        //console.log("ID: ",params.id);
        getProductsForOneCategory(params.id)
            .then( (products) => {
                console.log(products);
                setProducts([...products]);
            })
    }, [params]);

    const handleClick = ({product}) => {
        let path = ROUTES.BROWSE_PRODUCT+product.product_id;
        history.push({
            pathname: path,
            state: product,
            suggestions: products
        });

    }

    return (
                <div className="container">
                    <Grid.Title id={"category" + params.id}> {params.id} </Grid.Title>
                    <Grid.Section id={"section"}>
                        {
                            products.map((item,index,array) => {
                                return (
                                <Card onClick={() => handleClick({product : item})}  style={{borderRadius:8}}>
                                    <Card.Image src={item.product_url}  style={{borderRadius:8}}/>
                                    <Card.Title> {item.product_name} </Card.Title>
                                    <Card.SubTitle> {item.product_description}</Card.SubTitle>
                                    <Card.Text>
                                        <Card.DeliveryTime>₹ {item.product_price_user} </Card.DeliveryTime>
                                        <Card.Price style={{textDecoration:'line-through'}}> {parseFloat(item.product_price_user * 1.1)} </Card.Price>
                                    </Card.Text>
                                    <Card.Button>SHOP NOW</Card.Button>
                                </Card>
                            );
                        })

                        }
                        </Grid.Section>

                    </div>
    );
}
